import React, {useEffect, useRef, useState} from 'react';
import {IonButtons, IonHeader, IonPage} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {RouteComponentProps, useHistory} from "react-router-dom";

import {log} from "@services/firebaseAnalytics.service";

import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '@components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage} from "@components/button/button.style";

import Pane from "@components/pane/pane.component";

import ArrowLeftImage from "@assets/images/arrow-left.svg";
import {RideRoute} from "@models/travelAllowance/rideRoute";
import {getRideRoute} from "@services/travelAllowance/rideRoute.service";
import RideRouteAddEditPane from "@app/travelAllowance/rideRoute/panes/rideRouteAddEditPane.component";
import {RideRoutePaneType} from "@enums/travelAllowance/rideRoute";
import TravelAllowanceFormSkeleton from "@app/travelAllowance/common/components/travelAllowanceFormSkeleton.component";

interface RouterProps {
    rideRouteId: string;
}

interface RideRouteEditPageProps extends RouteComponentProps<RouterProps> {
}

const RideRouteEditPage: React.FC<RideRouteEditPageProps> = ({match}: RideRouteEditPageProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const {params: {rideRouteId}} = match;

    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(false);
    const [rideRoute, setRideRoute] = useState<RideRoute>();

    useEffect(() => {
        log('page_visit', {
            page: 'Trasy -> Edycja'
        });

        updateHeight();
    }, []);

    useEffect(() => {
        setLoading(true);

        fetchRideRoute()
            .then(() => {
                setLoading(false)
            });
    }, []);

    const fetchRideRoute = async () => {
        if (rideRouteId !== undefined) {
            const rideRouteData = await getRideRoute(parseInt(rideRouteId));

            setRideRoute(rideRouteData);
        }
    }

    const updateHeight = () => {
        if (header.current?.clientHeight === 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const handleBack = () => {
        history.goBack();
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("rideRouteEditPage.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                {
                    loading &&
                    <Pane topEdge={topEdge}>
                        <TravelAllowanceFormSkeleton/>
                    </Pane>
                }
                {
                    !loading && <RideRouteAddEditPane type={RideRoutePaneType.EDIT} topEdge={topEdge} rideRouteDefaultValues={rideRoute}/>
                }
            </StyledIonContent>
        </IonPage>
    );
};

export default RideRouteEditPage;