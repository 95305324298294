import React, {useEffect, useState} from 'react';
import {IonSkeletonText} from "@ionic/react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import {GOOGLE_MAPS_API_KEY} from "../../../variables/variables";

import {StyledAutocompleteList, StyledAutocompleteListItem} from "@components/google/cityAutocomplete/cityAutocomplete.style";
import {StyledInput} from "@components/form/input/input.style";

interface CityAutocompleteProps extends React.HTMLAttributes<HTMLInputElement> {
    setValue?: (value: string) => void,
    setPlaceId?: (placeId: string) => void,
    setCity?: (city: string) => void,
    setCountry?: (country: string) => void,
    onChangeCallback?: (...event: any[]) => void;
    debounce?: number,
    disabled?: boolean,
}

/**
 * @see src/components/google/cityAutocomplete/README.md
 */
const CityAutocomplete: React.FC<CityAutocompleteProps> = ({setValue, setPlaceId, setCity, setCountry, debounce, onChangeCallback, disabled, ...props}: CityAutocompleteProps) => {
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading
    } = useGoogle({
        apiKey: GOOGLE_MAPS_API_KEY,
        debounce: debounce ?? 500
    });

    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [loadedPlaces, setLoadedPlaces] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoadedPlaces([]);
    }, []);

    useEffect(() => {
        if (!isFocused && loadedPlaces.length > 0) {
            clearSetters();
        }
    }, [isFocused]);

    useEffect(() => {
        setSearchValue(props.defaultValue as string);
    }, [props.defaultValue]);

    useEffect(() => {
        if (placePredictions.length && placesService) {
            setLoadedPlaces([]);
            setLoading(true);

            placePredictions.forEach((placePrediction: any) => {
                placesService.getDetails(
                    {
                        placeId: placePrediction.place_id,
                    },
                    (placeDetails: any) => handleSetLoadedPlace(placeDetails)
                );
            });

            setLoading(false);
        }
    }, [placePredictions]);

    const handleSetLoadedPlace = (placeDetails: any) => {
        if (placeDetails.hasOwnProperty('business_status')) return;

        setLoadedPlaces((prevState: any[]) => [...prevState, placeDetails]);
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        getPlacePredictions({input: e.target.value});

        if (e.target.value === '') {
            clearSetters();
        }
    }

    const handlePlaceSelect = (place: any) => {
        setSearchValue(place.formatted_address);
        setLoadedPlaces([]);

        const addressComponents = place.address_components;
        let city = '';
        let country = '';

        addressComponents.forEach((component: any) => {
            if (component.types.includes('locality') || component.types.includes('administrative_area_level_1')) {
                city = component.long_name;
            }
            if (component.types.includes('country')) {
                country = component.long_name;
            }
        });

        if (typeof setValue === 'function') {
            setValue(place.formatted_address);
        }

        if (typeof setPlaceId === 'function') {
            setPlaceId(place.place_id);
        }

        if (typeof setCity === 'function') {
            setCity(city);
        }

        if (typeof setCountry === 'function') {
            setCountry(country);
        }

        if (onChangeCallback) {
            onChangeCallback(place.formatted_address);
        }
    }

    const clearSetters = () => {
        if (typeof setValue === 'function') {
            setValue('');
        }

        if (typeof setPlaceId === 'function') {
            setPlaceId('');
        }

        if (typeof setCity === 'function') {
            setCity('');
        }

        if (typeof setCountry === 'function') {
            setCountry('');
        }

        if (onChangeCallback) {
            onChangeCallback('');
        }
    }

    return (
        <>
            <StyledInput readOnly={false}
                         value={searchValue !== undefined ? searchValue : props.defaultValue}
                         onChange={(e) => handleSearch(e)}
                         disabled={disabled}
                         onFocus={() => setIsFocused(true)}
                         onBlur={() => setTimeout(() => setIsFocused(false), 200)}
                         {...props}
            />
            {(isPlacePredictionsLoading || loading) && isFocused && (
                <StyledAutocompleteList>
                    <IonSkeletonText animated style={{
                        width: '100%',
                        height: '12px',
                        paddingTop: '4px',
                        paddingBottom: '4px',
                        borderRadius: 0
                    }}/>
                </StyledAutocompleteList>
            )}
            {!isPlacePredictionsLoading && !loading && loadedPlaces.length > 0 && searchValue !== '' && isFocused && (
                <StyledAutocompleteList>
                    {loadedPlaces.map((place) => {
                        return <StyledAutocompleteListItem key={place.place_id} onClick={() => handlePlaceSelect(place)}>{place.formatted_address}</StyledAutocompleteListItem>
                    })}
                </StyledAutocompleteList>
            )}
        </>
    );
};

export default CityAutocomplete;