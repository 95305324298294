import {SecureStoragePlugin} from "capacitor-secure-storage-plugin";
import {offlineStorageGet, offlineStorageSet} from "./offlineSync.service";
import {LogisticContainer, Order, Report} from "../app/reportPage/reportPage.component";
import {Preferences} from "@capacitor/preferences";
import moment from "moment/moment";
import {ReportStatus} from "../enums/reportStatus";
import {Report as ReportApi, ReportWorker} from "../models/raport";
import {DataSelectable} from "../app/reportPage/components/report/reportRowForm.component";
import {v4 as uuidv4} from 'uuid';
import {Contract as ContractModel} from "@models/contract";

export interface OfflineDataReport {
    componentNumbers: DataSelectable[];
    defects: {
        specification_defects: DataSelectable[],
        general_defects: DataSelectable[],
    };
    order: Order;
    workers: ReportWorker[];
    measuringCategories: OfflineDataReportMeasurement[] | null,
    errors: string[];
    containers: LogisticContainer[] | null;
    activeContract: ContractModel
}

export type OfflineDataReportMeasurement = {
    measuring_equipment_item_category: DataSelectable;
    items: DataSelectable[] | null;
    multiplier?: number;
    missing?: boolean;
    hide?: boolean;
}

export const OFFLINE_REPORT_ID_PREFIX = 'report_';

const findSpecificationByNumber = async (number: bigint | string) => {
    let specId:any = null;
    let keys = await SecureStoragePlugin.keys();
    let workerNumber = await Preferences.get({'key': 'employee_number'});

    if (keys.value.length > 0) {
        for (let key of keys.value.filter(k => k.startsWith('pos_'))) {
            let reports:OfflineDataReport[] = await offlineStorageGet(key) as unknown as [];

            if (reports.length > 0) {
                reports.map((posData:OfflineDataReport) => {
                    if (specId === null &&
                        posData.order.specification != null &&
                        posData.order.specification.id &&
                        posData.order.specification.id == parseInt(number.toString()) &&
                        workerExistsInReport(posData, workerNumber.value ? workerNumber.value : '')) {
                        specId = number;
                    }
                })
            }
        }
    }

    return specId;
}

const checkSpecification = async (id: bigint | string, reportId = '', skipDetailedCheck:boolean = false) => {
    let orderData:any = null;
    let keys = await SecureStoragePlugin.keys();

    if (keys.value.length > 0) {
        for (let key of keys.value.filter(k => k.startsWith('pos_'))) {
            let reports:OfflineDataReport[] = await offlineStorageGet(key) as unknown as [];

            if (reports.length > 0) {
                reports.map((posData: OfflineDataReport) => {
                    if (orderData === null && posData.order.specification != null && posData.order.specification.id && posData.order.specification.id == parseInt(id.toString())) {
                        if (posData.errors.length > 0 && !skipDetailedCheck) {
                            orderData = posData.errors.shift();
                        } else {
                            orderData = posData.order;
                        }
                    }
                })
            }
        }
    }

    return orderData;
}

const findOrderById = async (number: bigint | string) => {
    let orderData: Order | null = null;
    let keys = await SecureStoragePlugin.keys();

    if (keys.value.length > 0) {
        for (let key of keys.value.filter(k => k.startsWith('pos_'))) {
            let reports:OfflineDataReport[] = await offlineStorageGet(key) as unknown as [];

            if (reports.length > 0) {
                reports.map((posData: OfflineDataReport) => {
                    if (orderData === null && posData.order.id == number) {
                        orderData = posData.order;
                    }
                })
            }
        }
    }

    return orderData;
}

const findOrderByNumber =  async (number: bigint | string, skipDetailedCheck:boolean = false) => {
    let orderData:any = null;
    let keys = await SecureStoragePlugin.keys();
    let workerNumber = await Preferences.get({'key': 'employee_number'});

    if (keys.value.length > 0) {
        for (let key of keys.value.filter(k => k.startsWith('pos_'))) {
            let reports:OfflineDataReport[] = await offlineStorageGet(key) as unknown as [];

            if (reports.length > 0) {
                reports.map((posData: OfflineDataReport) => {
                    if (posData.order.sub_type === 'OPTIMIZATION' || posData.order.sub_type === 'CONTROL') {
                        return 'ORDER_TYPE_NOT_ALLOWED';
                    }
                    if (orderData === null && posData.order.specification == null &&
                        posData.order.formatted_number == number &&
                        workerExistsInReport(posData, workerNumber.value ? workerNumber.value : '')) {
                            if (posData.errors.length > 0 && !skipDetailedCheck) {
                                orderData = posData.errors.shift();
                            } else {
                                orderData = posData.order;
                            }
                    }
                })
            }
        }
    }

    return orderData ? orderData : 'COMMON';
}

const checkWorker = async (orderId: number|string, number: string, serviceDate: string) => {
    let workerData: ReportWorker | null = null;
    let keys = await SecureStoragePlugin.keys();

    if (keys.value.length > 0) {
        for (let key of keys.value.filter(k => k.startsWith('pos_'))) {
            let reports:OfflineDataReport[] = await offlineStorageGet(key) as unknown as [];

            if (reports.length > 0) {
                reports.map((posData: OfflineDataReport) => {
                    if (workerData === null && posData.order.id == orderId) {
                        posData.workers.map((worker) => {
                            if (worker.number == number) {
                                workerData = worker;
                            }
                        })
                    }
                })
            }
        }
    }

    return workerData;
}

const getReport = async (reportId: number|string) => {
    return await offlineStorageGet(OFFLINE_REPORT_ID_PREFIX + reportId.toString());
}

const orderComponentsNumbers = async (orderId: number|string) => {
    let components: DataSelectable[] | null = null;
    let keys = await SecureStoragePlugin.keys();

    if (keys.value.length > 0) {
        for (let key of keys.value.filter(k => k.startsWith('pos_'))) {
            let reports:OfflineDataReport[] = await offlineStorageGet(key) as unknown as [];

            if (reports.length > 0) {
                reports.map((posData: OfflineDataReport) => {
                    if (components === null && posData.order.id == orderId) {
                        components = posData.componentNumbers;
                    }
                })
            }
        }
    }

    return components;
}

const orderContainers = async (orderId: number|string) => {
    let containers: LogisticContainer[] | null = null;
    let keys = await SecureStoragePlugin.keys();

    if (keys.value.length > 0) {
        for (let key of keys.value.filter(k => k.startsWith('pos_'))) {
            let reports:OfflineDataReport[] = await offlineStorageGet(key) as unknown as [];

            if (reports.length > 0) {
                reports.map((posData: OfflineDataReport) => {
                    if (containers === null && posData.order.id == orderId) {
                        containers = posData.containers;
                    }
                })
            }
        }
    }

    return containers;
}

const findContainerById = async (id: bigint | string) => {
    let container: LogisticContainer | null = null;
    let keys = await SecureStoragePlugin.keys();

    if (keys.value.length > 0) {
        for (let key of keys.value.filter(k => k.startsWith('pos_'))) {
            let reports:OfflineDataReport[] = await offlineStorageGet(key) as unknown as [];

            if (reports.length > 0) {
                reports.map((posData: OfflineDataReport) => {
                    if (container === null && posData.containers) {
                        posData.containers.map((c) => {
                            if (c.id == id) {
                                container = c;
                            }
                        })
                    }
                })
            }
        }
    }

    return container;
}

const findContainerByNumber = async (number: bigint | string, orderId: bigint | string) => {
    let containerData:any = null;
    let keys = await SecureStoragePlugin.keys();

    if (keys.value.length > 0) {
        for (let key of keys.value.filter(k => k.startsWith('pos_'))) {
            let reports:OfflineDataReport[] = await offlineStorageGet(key) as unknown as [];

            if (reports.length > 0) {
                reports.map((posData: OfflineDataReport) => {
                    if (posData.order.id == orderId && containerData === null && posData.containers) {
                        posData.containers.map((c) => {
                            if (c.main_number == number) {

                                if (c.status == 'DONE') {
                                    return 'CONTAINER_DONE';
                                }

                                containerData = c.id;
                            }
                        })
                    }
                })
            }
        }
    }

    return containerData ? containerData : 'NO_CONTAINER';
}

const orderDefects = async (specificationId: number|string) => {
    let defects: { specification_defects: DataSelectable[]; general_defects: DataSelectable[]; } | null = null;
    let keys = await SecureStoragePlugin.keys();

    if (keys.value.length > 0) {
        for (let key of keys.value.filter(k => k.startsWith('pos_'))) {
            let reports:OfflineDataReport[] = await offlineStorageGet(key) as unknown as [];

            if (reports.length > 0) {
                reports.map((posData:OfflineDataReport) => {
                    if (defects === null && posData.order.specification != null && posData.order.specification.id && posData.order.specification.id == specificationId) {
                        defects = posData.defects;
                    }
                })
            }
        }
    }

    return defects;
}

const getWorkerData = async (orderId: string, number: string, serviceDate: string) => {
    let workerData:any = await checkWorker(orderId, number, serviceDate)
        .then(response => {
            return response;
        });

    return workerData;
}

const orderWorkers = async (orderId: number|string, serviceDate: string, page: number = 1, searchText: string = '') => {
    const perPage = 50;
    let workers:ReportWorker[] = [];
    let keys = await SecureStoragePlugin.keys();

    if (keys.value.length > 0) {
        for (let key of keys.value.filter(k => k.startsWith('pos_'))) {
            let reports:OfflineDataReport[] = await offlineStorageGet(key) as unknown as [];

            if (reports.length > 0) {
                reports.map((posData: OfflineDataReport) => {
                    if (workers.length === 0 && posData.order.id == orderId) {
                        workers = posData.workers;
                    }
                })
            }
        }
    }

    if (workers && searchText) {
        workers.filter(ele => ele.name.toLowerCase().includes(searchText.toLowerCase()));
    }

    if (workers) {
        return Object.entries(workers).slice((page - 1) * perPage, ((page - 1) * perPage) + perPage).map(worker => worker[1]);
    }

    return workers;
}

const workerExistsInReport = (report:OfflineDataReport, workerNumber:string) => {
    let workers:ReportWorker[] = report.workers;
    let workerExists = false;

    workers.map((worker: ReportWorker) => {
        if (worker.number == workerNumber) {
            workerExists = true;
        }
    })

    return workerExists;
}

const saveReport = async (order: Order, data: Report, reportId?: string, draft = false, onlineBackup = false, multipleOrders: boolean = false) => {
    if (!data.offlineUuid) {
        data.offlineUuid = uuidv4();
    }

    if (!reportId || reportId === '0') {
        reportId = data.offlineUuid;
    }

    data.orderId = order.id;
    data.order = order;
    data.orderNumber = order.formatted_number;
    data.status = draft ? ReportStatus.OFFLINE_DRAFT : ReportStatus.OFFLINE_READY_TO_SYNC;
    data.reportId = reportId;
    data.onlineBackup = onlineBackup;
    data.multipleOrders = multipleOrders;
    data.createdAt = moment().format();

    let workerId = await Preferences.get({'key': 'worker_id'}).then((value => {
        return value.value;
    }));

    if (workerId) {
        data.createdByWorkerId = workerId;
    }

    let key:string = OFFLINE_REPORT_ID_PREFIX + data.reportId;
    await offlineStorageSet(key, data);

    return reportId ? getReport(reportId) : [];
}

const getReports = async (status: ReportStatus, rawOutput = false, skipOnlineBackup = false, onlyBackup = false) => {
    let reports:any[] = [];
    let keys = await SecureStoragePlugin.keys();
    let userName = await Preferences.get({
        key: 'full_name'
    });

    let workerId = await Preferences.get({'key': 'worker_id'}).then((value => {
        return value.value;
    }));

    if (keys.value.length > 0) {
        for (let key of keys.value.filter(k => k.startsWith(OFFLINE_REPORT_ID_PREFIX))) {
            let report:Report = await offlineStorageGet(key) as unknown as Report;
            if (workerId && report.createdByWorkerId == workerId && report.status === status && (!skipOnlineBackup || (skipOnlineBackup && !report.onlineBackup))) {
                if (onlyBackup && !report.onlineBackup) {
                    continue;
                }

                if (!rawOutput) {
                    reports.push({
                        offline: true,
                        online_backup: report.onlineBackup,
                        multiple_orders: report.additionalOrders?.length,
                        avg_time_per_component: "",
                        checked_parts_sum: 0,
                        created_by: userName.value,
                        hours_sum_humanized: "",
                        is_editable: false,
                        order_factory_number: report.orderFactoryNumber || '',
                        place_of_service: report.order?.place_of_service.name,
                        workers: [],
                        components: {
                            numbers: [],
                            names: []
                        },
                        order: {
                            id: report.orderId && parseInt(report.orderId) || 0,
                            formatted_number: report.orderFactoryNumber || '',
                            sub_type: '',
                            complaint_number: '',
                            enable_nested_reporting: false,
                            enable_single_components: false,
                            is_extended_reporting: false,
                            nested_reporting_components: [],
                            specification: {
                                id: report.specificationId && parseInt(report.specificationId) || 0
                            }
                        },
                        service_date: new Date(report.serviceDate),
                        status: report.status,
                        id: report.reportId ?? key.replace(OFFLINE_REPORT_ID_PREFIX, ''),
                        formatted_number: report.orderNumber + '/' + moment(report.createdAt, moment.ISO_8601).format('DD-MM-YYYY HH:mm:ss'),
                        ep2_scanned_specification_id: report.specificationId && parseInt(report.specificationId) || undefined,
                        created_at: report.createdAt
                    });
                } else {
                    reports.push(report);
                }
            }
        }
    }

    if (!rawOutput) {
        reports.sort((a, b) => {
            return moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1;
        })
    }

    return reports;
}

const removeReport = async (reportId: number|string) => {
    await SecureStoragePlugin.remove({key: OFFLINE_REPORT_ID_PREFIX + reportId.toString()})
}

export const getMeasuringEquipments = async (specificationId: number | string, serviceDate: string) => {
    let keys = await SecureStoragePlugin.keys();

    let measurements: any = [];
    if (keys.value.length > 0) {
        for (let key of keys.value.filter(k => k.startsWith('pos_'))) {
            let reports:OfflineDataReport[] = await offlineStorageGet(key) as unknown as [];

            if (reports.length > 0) {
                reports.map((posData: OfflineDataReport) => {
                    if (measurements.length === 0 && posData.order.specification != null && posData.order.specification.id && posData.order.specification.id == specificationId && posData.measuringCategories) {
                        measurements = posData.measuringCategories;
                    }
                })
            }
        }
    }

    return measurements;
}

export const getMeasuringEquipmentsItems = async (specificationId: number | string, serviceDate: string, categoryId: number | string) => {
    let keys = await SecureStoragePlugin.keys();

    let measurementsItems: any = [];
    if (keys.value.length > 0) {
        for (let key of keys.value.filter(k => k.startsWith('pos_'))) {
            let reports:OfflineDataReport[] = await offlineStorageGet(key) as unknown as [];

            if (reports.length > 0) {
                reports.map((posData: OfflineDataReport) => {
                    if (measurementsItems.length === 0 && posData.order.specification != null && posData.order.specification.id && posData.order.specification.id == specificationId && posData.measuringCategories) {
                        posData.measuringCategories.map((category: OfflineDataReportMeasurement) => {
                            if (category.measuring_equipment_item_category.id == categoryId) {
                                measurementsItems = category.items;
                            }
                        })
                    }
                })
            }
        }
    }

    return measurementsItems;
}

export {
    findSpecificationByNumber,
    checkSpecification,
    findOrderById,
    findOrderByNumber,
    getReport,
    orderComponentsNumbers,
    orderDefects,
    getWorkerData,
    orderWorkers,
    saveReport,
    getReports,
    removeReport,
    findContainerById,
    findContainerByNumber,
    orderContainers
}