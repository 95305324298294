import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
    IonButtons,
    IonHeader,
    IonPage,
    useIonViewDidEnter
} from '@ionic/react';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '../../components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import CheckImage from '../../assets/images/check.svg';
import {StyledInput} from '../../components/form/input/input.style';
import {useForm} from 'react-hook-form';
import {StyledContent} from './manualBarcodePage.style';
import Form from '../../components/form';
import StaticPane from '../../components/pane/static-pane.component';
import {useTranslation} from 'react-i18next';
import {QRCodePageConfiguration} from '../../services/qrCodePage.service';
import {Links} from "../links";
import {findSpecificationByNumber as findSpecificationByNumberFactory} from "../../services/reportFactory.service";
import {checkSpecification as checkSpecificationFactory} from "../../services/reportFactory.service";

const ManualBarcodePage: React.FC = () => {

    const fieldName = "barcode";
    const history = useHistory();
    const {t} = useTranslation();
    const [error, setError] = useState<string>();
    const [specificationId, setSpecificationId] = useState<string>();

    const [configuration, setConfiguration] = useState<QRCodePageConfiguration>();

    const handleBack = () => {
        history.goBack();
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const {register, formState: {isValid}, getValues, handleSubmit} = useForm({
        mode: 'all'
    });

    const handleSave = async () => {
        let specId: string = await findSpecificationByNumberFactory(getValues(fieldName));

        if (specId) {
            setSpecificationId(specId);
            if (configuration?.handleScan) {
                let error = await configuration?.handleScan(specId);

                if (typeof error == 'string') {
                    if (error == 'SPEC_NO_NEWEST') {
                        setError(t('reportsTab.orders.scanSpecificationPage.error.spec_no_newest'))
                    } else if (error == 'SPEC_NO_ACCEPTED') {
                        setError(t('reportsTab.orders.scanSpecificationPage.error.spec_no_accepted'))
                    } else if (error == 'ORDER_BLOCKED') {
                        setError(t('reportsTab.orders.scanSpecificationPage.error.order_blocked'))
                    } else if (error == 'ORDER_CLOSED') {
                        setError(t('reportsTab.orders.scanSpecificationPage.error.order_closed'))
                    } else if (error == 'WORKER_NO_ALLOWED') {
                        setError(t('reportsTab.orders.scanSpecificationPage.error.worker_no_allowed'))
                    } else if (error == 'WORKER_REPORTING_BLOCKED') {
                        setError(t('reportsTab.orders.scanSpecificationPage.error.worker_reporting_blocked'))
                    } else if (error == 'CLIENT_REPORTING_NOT_ENABLE') {
                        setError(t('reportsTab.orders.scanSpecificationPage.error.client_reporting_not_enable'))
                    } else if (error == 'ORDER_LIMITS_EXCEEDED') {
                        setError(t('reportsTab.orders.scanSpecificationPage.error.order_limits_exceeded'))
                    } else if (error == 'ORDER_APT_NOT_ALLOWED') {
                        setError(t('reportsTab.orders.scanSpecificationPage.error.order_apt_not_allowed'))
                    } else if (error == 'ORDER_BLOCKED_BY_STATUS') {
                        setError(t('reportsTab.orders.scanSpecificationPage.error.order_blocked_by_status'))
                    } else if (error == 'WORKER_NOT_ENABLED') {
                        setError(t('reportsTab.orders.scanSpecificationPage.error.worker_not_enabled'))
                    } else if (error == 'TL_REPORTING_BLOCKED') {
                        setError(t('reportsTab.orders.scanSpecificationPage.error.tl_reporting_blocked'))
                    } else {
                        setError(t('reportsTab.orders.scanSpecificationPage.error.common'))
                    }
                }
            }
        } else {
            setError(t('reportsTab.orders.scanSpecificationPage.error.common'))
        }
    };

    const qrConfig = () => {
        setConfiguration({
            title: 'reportsTab.orders.scanSpecificationPage.title',
            subtitle: 'reportsTab.orders.scanSpecificationPage.subtitle',
            manualButtonText: 'reportsTab.orders.scanSpecificationPage.manualButton',
            errorText: 'reportsTab.orders.scanSpecificationPage.error.common',
            handleManualButton: () => history.push(Links.manualBarcode),
            handleScan: async (val) => {
                let orderData:any = await checkSpecificationFactory(val);

                if (typeof orderData == 'string') {
                    return orderData;
                }
                if (orderData.logistic_container) {
                    history.push(`${Links.qrCodeContainer}/${orderData.id}/${val}`);
                } else if (orderData) {
                    history.push(`${Links.report}/${orderData.id}/${val}/0/0/0`);
                }
            }
        });
    }

    useEffect(() => {
        updateHeight();
    });

    useEffect(() => {
        qrConfig();
    }, []);

    useIonViewDidEnter(() => {
        document.getElementsByName(fieldName)[0].focus();
    });

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle className="wrap-text">
                            <div className="wrap-text">
                                {t('manualBarcode.title')}
                            </div>
                        </StyledIonTitle>
                        <IonButtons slot="end">
                            <StyledHeaderButton className="save-btn" onClick={() => handleSave()}>
                                <StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                    <Form.Container onSubmit={handleSubmit(handleSave)}>
                        <StyledContent>
                            <h3 className="desc">{t("manualBarcode.number")}</h3>
                            <StyledInput name={fieldName} className={error && 'hasErrors'} inputMode="tel"
                                         ref={register({
                                             required: true
                                         })}/>
                            {error && <p className="errorMessage">{error}</p>}
                            <Form.Button type="submit" disabled={!isValid}>{t("manualBarcode.addButton")}</Form.Button>
                        </StyledContent>
                    </Form.Container>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default ManualBarcodePage;
