import React, {Ref, useState} from 'react';
import styled, {css} from 'styled-components';
import ShowImage from '../../../assets/images/b-preview-02.svg'
import HideImage from '../../../assets/images/b-preview.svg'
import ClearImage from '../../../assets/images/e-remove.svg';
import SearchImage from '../../../assets/images/search.svg';
import SearchWhiteImage from '../../../assets/images/search-white.svg';
import {IonInput, IonRow} from "@ionic/react";
import { StyledButton } from '../../button/button.style';
import ExactPhoneInput from "./phoneInput.component";

type StyledInputType = {
    error?: boolean,
};

type StyledInputGroupType = {
    unit?: boolean,
}

type StyledInputUnitType = {
    icon?: boolean,
}

export const StyledPinInput = styled.input<StyledInputType>`
  width: 56px;
  height: 72px;
  background: #FFFFFF;
  border: 1px solid #0085FF;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 12px;
  margin: 8px 4px;
  display: inline-flex;
  align-items: center;
  jutify-content: center;
  flex-grow: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  text-align: center;

  &:placeholder-shown {
    background: #F4F4F9;
    border: none;
  }

  &:focus {
    background: #FFFFFF;
    border: 1px solid #0085FF;
  }
`;

export const StyledInputGroup = styled(IonRow)<StyledInputGroupType>`
  ${props => props.unit && css`
    ion-col:first-of-type {
      padding-right: unset;
    }
    
    ion-col:last-of-type {
      padding-left: unset;
    }
  `}
  
  input,
  ion-input {
    border-right: none;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  }

  &.error,
  &.warning {
    input,
    ion-input,
    div > div {
      border-top: 1px solid;
      border-bottom: 1px solid;
    }

    input,
    ion-input {
      border-left: 1px solid;
    }
  }

  &.error {
    input,
    ion-input,
    div > div {
      border-color: #F43030 !important;
    }
  }
  
  &.warning {
    input,
    ion-input,
    div > div {
      border-color: #f8ac59;
    }
  }
`;

export const StyledInputUnit = styled.div<StyledInputUnitType>`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #888A9833;
  color: black;
  border-radius: 0 12px 12px 0;

  ${props => props.icon && css`
    background: #D7EAFB;
    border-radius: 12px;
    color: #0082f9;
  `}
`;

export const StyledInput = styled.input<StyledInputType>`
  border: 1px solid ${props => props.color === 'dark' ? '#363543' : '#F4F4F9'};
  background: ${props => props.color === 'dark' ? '#363543' : '#F4F4F9'};
  border-radius: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 14px 20px;
  margin-bottom: 8px;
  width: 100%;
  position: relative;
  color: ${props => props.color === 'dark' ? '#ffffff' : '#000000'};

  &::placeholder {
    color: ${props => props.color === 'dark' ? '#ffffff' : 'unset'};
  }

  &.gray-placeholder::placeholder {
    color: #aaa;
  }

  &.bolded {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &.icon {
    padding-right: 30px;
  }

  &.hide-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }

  &.hide-spin-button::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.hide-spin-button::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled,
  &:read-only {
    color: rgba(0, 0, 0, 0.3);
  }
  
  &.error {
    border-color: #F43030;
  }

  &.warning {
    border-color: #f8ac59;
  }
`;

export const StyledIonInput = styled(IonInput)`
  border: 1px solid #F4F4F9;
  background: #F4F4F9;
  border-radius: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 14px 20px;
  margin-bottom: 8px;
  width: 100%;
  position: relative;

  &.bolded {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  &.no-margin {
    margin-bottom: 0;
  }
`;

const StyledPasswordInput = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;

  img {
    position: absolute;
    width: 14px;
    height: 14px;
    z-index: 2;
    right: 14px;
    top: 14px;
  }
`;

export const StyledInputEmail = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;

  img {
    position: absolute;
    width: 14px;
    height: 14px;
    z-index: 2;
    right: 14px;
    top: 14px;
  }
`;

type InputPropsType = {
    placeholder?: string;
    name?: string;
    margin?: number;
    className?: string;
    wrapperClassName?: string,
    setValue?: any;
    initValue?: string;
    color?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    type?: string;
};

export const PasswordInput = React.forwardRef((props: InputPropsType, ref: Ref<HTMLInputElement>) => {
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    return (
        <StyledPasswordInput>
            <StyledInput name={props.name} type={passwordShown ? "text" : "password"} ref={ref}
                         placeholder={props.placeholder}
                         className={props.className}
                         onChange={props.onChange}
            />
            <img src={passwordShown ? ShowImage : HideImage} onClick={() => togglePasswordVisiblity()}/>
        </StyledPasswordInput>
    );
});

const StyledClearableInput = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;

  img {
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: 2;
    right: 16px;
    top: 16px;
  }
`;

export const ClearableInput = React.forwardRef((props: InputPropsType, ref: Ref<HTMLInputElement>) => {

    const clear = () => {
        const setValue = props.setValue;
        if (typeof setValue === 'function' &&
            props.name) {
            setValue(props.name, undefined);
        }
    }

    return (
        <StyledClearableInput>
            <StyledInput name={props.name} type={props.type ? props.type : 'text'} ref={ref}
                         placeholder={props.placeholder}
                         className={props.className}
            />
            <img src={ClearImage} onClick={() => clear()}/>
        </StyledClearableInput>
    );
});

type StyledSearchInputProps = {
  margin?: number;
  dark?: boolean;
}

const StyledSearchInput = styled.div<StyledSearchInputProps>`
  display: inline-block;
  width: calc(100% - ${props => ((props.margin ?? 0) * 2)}px);
  position: relative;
  margin: 0 ${props => (props.margin ?? 0)}px;

  img {
    position: absolute;
    width: 14px;
    height: 14px;
    z-index: 2;
    left: 16px;
    top: 14.5px;
  }

  input {
    padding-left: 41px;
  }
`;

export const SearchInput = React.forwardRef((props: InputPropsType, ref: Ref<HTMLInputElement>) => {
  return (
      <StyledSearchInput margin={props.margin}>
          <img src={props.color === 'dark' ? SearchWhiteImage : SearchImage}/>
          <StyledInput name={props.name} type="text" ref={ref} value={props.initValue}
                       color={props.color}
                       placeholder={props.placeholder}
                       onChange={props.onChange}
                       className={props.className}
          />
      </StyledSearchInput>
  );
});

type StyledInputWithButtonProps = {
  buttonText: string;
  placeholder?: string;
  inputName?: string;
  type?: string;
  showListAbove?: boolean;
  onButtonClick?: (value: string) => void;
  value?: string;
}

const StyledInputWithButton = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  margin-top: 16px;

  input {
    padding-right: calc(25% + 10px);
    margin: 0;
    
    &.phone-input {
       float: left;
    }
  }

  button {
    position: absolute;
    right: 0;
    margin: 0;
    width: 25%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    
    &.phone-input-button {
        height: 45px;
    }
  }
`;

export const InputWithButton = React.forwardRef((props: StyledInputWithButtonProps, ref: Ref<HTMLInputElement>) => {

    const handleClick = () => {
        if (props.onButtonClick) {
            props.onButtonClick(value);
        }
    };

    const [value, updateValue] = useState<string>('');

    return (
        <StyledInputWithButton>
            <StyledInput name={props.inputName || 'field'} type={props.type || 'text'} ref={ref}
                         placeholder={props.placeholder}
                         onChange={(event) => {
                             updateValue(event.target.value)
                         }}
                         value={props.value}
            />
            <StyledButton disabled={!value} onClick={() => handleClick()}>{props.buttonText}</StyledButton>
        </StyledInputWithButton>
    );
});

export const PhoneInputWithButton = React.forwardRef((props: StyledInputWithButtonProps, ref: Ref<HTMLInputElement>) => {

    const handleClick = () => {
        if (props.onButtonClick) {
            props.onButtonClick(value);
        }
    };

    const [value, updateValue] = useState<string>('');
    const [missingPhone, setMissingPhone] = useState(true);

    return (
        <StyledInputWithButton>
            <ExactPhoneInput phoneNumer={value}
                             showListAbove={props.showListAbove}
                             errorChanged={(val) => {
                                 setMissingPhone(val)
            }} phoneNumerChanged={(val) => {
                updateValue(val)
            }} />
            <StyledButton className="phone-input-button" disabled={missingPhone} onClick={() => handleClick()}>{props.buttonText}</StyledButton>
        </StyledInputWithButton>
    );
});


export const StyledField = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 16px;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 8px;

    &.required:after {
      content: '*';
      color: red;
    }
  }
`;

export const StyledFakeField = styled.div`
  border: 1px solid #F4F4F9;
  background: #F4F4F9;
  border-radius: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 14px 20px;
  margin-bottom: 8px;
  width: 100%;
  position: relative;
  
  &.hasErrors {
     border: 1px solid #F43030;
  }
`;