import {CancelTokenSource} from "axios";

import {appApi, travelAllowanceApi} from "@services/api.service";
import {getWorkerId} from "@utils/tools/worker";

import {CalculateDistance, PlaceOfService, RideRoute, RideRouteForm, SubRoute, SubRouteFormField} from "@models/travelAllowance/rideRoute";
import {Coordinator} from "@models/travelAllowance/ride";
import moment from "moment/moment";

const getRideRoutes = async (cancelToken?: CancelTokenSource): Promise<RideRoute[]> => {
    const workerId = await getWorkerId();
    const response: any = await travelAllowanceApi.get(`ride-routes/worker/${workerId}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const getRideRoute = async (rideRouteId: number, cancelToken?: CancelTokenSource): Promise<RideRoute> => {
    const workerId = await getWorkerId();
    const response: any = await travelAllowanceApi.get(`ride-routes/${rideRouteId}/worker/${workerId}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const addRideRoute = async (rideRouteData: RideRouteForm) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.post(`ride-routes/worker/${workerId}`, rideRouteData)
}

const editRideRoute = async (rideRouteId: number, rideRouteData: RideRouteForm) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.put(`ride-routes/${rideRouteId}/worker/${workerId}`, rideRouteData)
}

const removeRideRoute = async (rideRouteId: number) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.patch(`ride-routes/${rideRouteId}/worker/${workerId}/unlink-worker`)
}

const getPossibleCoordinators = async (date?: string, cancelToken?: CancelTokenSource, page: number = 1, searchText: string = ''): Promise<Coordinator[]> => {
    if (date) {
        date = moment(date).format('YYYY-MM-DD');
    }

    const response: any = await appApi.get(`route/possible-coordinators?page=${page}&searchText=${searchText}` + (date ? `&date=${date}` : ''), {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const getPlacesOfService = async (coordinatorId: number, cancelToken?: CancelTokenSource, page: number = 1, searchText: string = ''): Promise<PlaceOfService[]> => {
    const response: any = await appApi.get(`route/possible-places-of-service?coordinatorId=${coordinatorId}&page=${page}&searchText=${searchText}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const calculateDistance = async (calculateDistanceData: CalculateDistance, cancelToken?: CancelTokenSource): Promise<{ distance: number }> => {
    const response = await travelAllowanceApi.get(`ride-routes/calculate-distance`, {
        params: calculateDistanceData,
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const validateRideRoute = async (rideRouteId: number, date?: string) => {
    if (date) {
        date = moment(date).format('YYYY-MM-DD');
    }

    return travelAllowanceApi.post(`ride-routes/${rideRouteId}/validate` + (date ? `?date=${date}` : ''));
}

const transformSubRoutesToFormField = (subRoutes: SubRoute[]): SubRouteFormField[] => {
    return subRoutes.map((subRoute, index) => ({
        startingPlace: subRoute.startingPlace,
        startingPlaceCountry: subRoute.startingPlaceCountry,
        destinationPlace: subRoute.destinationPlace,
        destinationPlaceCountry: subRoute.destinationPlaceCountry,
        distance: subRoute.distance,
        visible: index !== subRoutes.length - 1
    }));
}

export {
    getRideRoutes,
    getRideRoute,
    addRideRoute,
    editRideRoute,
    removeRideRoute,
    getPossibleCoordinators,
    getPlacesOfService,
    calculateDistance,
    validateRideRoute,
    transformSubRoutesToFormField,
};
