import React, {useEffect, useRef, useState} from 'react';
import {IonAlert, IonButtons, IonCol, IonFooter, IonGrid, IonHeader, IonPage, IonRow, IonToast} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {RouteComponentProps, useHistory} from "react-router-dom";

import {log} from "@services/firebaseAnalytics.service";
import useNavigation from "@services/navigation.service";
import {getRideReport, sendToAssecoRideReport} from "@services/travelAllowance/rideReport.service";
import {getRideMatrixByRideReportId} from "@services/travelAllowance/rideMatrix.service";

import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '@components/content/content.style';
import {StyledButton, StyledHeaderButton, StyledHeaderButtonImage,} from "@components/button/button.style";
import {StyledButtonOption} from "@app/travelAllowance/travelAllowance.style";

import ArrowLeftImage from '@assets/images/arrow-left.svg';

import SubNavigation, {SubNavigationOption} from "@components/sub-navigation/subNavigation.component";
import Pane from "@components/pane/pane.component";
import MyDataSkeleton from "@app/myData/components/myDataSkeleton.component";
import RideReportRejectActionSheet from "@app/travelAllowance/rideReport/components/rideReportRejectActionSheet.component";

import {RideReport} from "@models/travelAllowance/rideReport";
import {RideMatrix} from "@models/travelAllowance/rideMatrix";
import RideDocumentPane from "@app/travelAllowance/rideReport/panes/rideDocumentPane.component";
import {RideDocumentType} from "@enums/travelAllowance/rideDocument";

import {Links} from "@app/links";
import {getRideDocumentPdfByType} from "@services/travelAllowance/rideDocument.service";
import {Device} from "@capacitor/device";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {blobToBase64} from "@services/file.service";
import {FileOpener} from "@capacitor-community/file-opener";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";

enum RideReportPageOptions {
    CONTRACT = 0,
    TRAVEL_ORDER = 1,
    REPORT = 2,
}

interface RouterProps {
    reportId: string,
}

interface RideReportPreviewPageProps extends RouteComponentProps<RouterProps> {
}

const RideReportPreviewPage: React.FC<RideReportPreviewPageProps> = ({match}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);
    const {params: {reportId}} = match;

    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const [selectedOption, setSelectedOption] = useState<number | undefined>(undefined);
    const [isRideReportRejectModalOpen, setIsRideReportRejectModalOpen] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);
    const [showAcceptButton, setShowAcceptButton] = useState<boolean>(false);
    const [showSubNavigation, setShowSubNavigation] = useState<boolean>(false);

    const [report, setReport] = useState<RideReport>();
    const [rideMatrix, setRideMatrix] = useState<RideMatrix>();
    const [options, setOptions] = useState<SubNavigationOption[]>([]);

    const defaultOptions: SubNavigationOption[] = [
        {
            name: t("travelAllowance.report.contractDocument"),
            visible: true,
        },
        {
            name: t("travelAllowance.report.travelOrderDocument"),
            visible: true,
        },
        {
            name: t("travelAllowance.report.reportDocument"),
            visible: true,
        },
    ];

    useEffect(() => {
        log('page_visit', {
            page: 'Raporty przejazdów -> Podgląd'
        });

        updateHeight();
    }, []);

    useEffect(() => {
        setLoading(true);

        Promise.all([fetchRideMatrix(), fetchRideReport()])
            .then(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (getVisibleOptions().length > 1) {
            setShowSubNavigation(true);
        } else {
            setShowSubNavigation(false);
        }
    }, [options]);

    useEffect(() => {
        if (rideMatrix) {
            let tempOptions = defaultOptions;

            if (!rideMatrix.isRideReport) {
                tempOptions = tempOptions.map(option => option.name === t("travelAllowance.report.reportDocument") ? {...option, visible: false} : option);
            } else {
                setSelectedOption(RideReportPageOptions.REPORT);
            }

            if (!rideMatrix.isTravelOrder) {
                tempOptions = tempOptions.map(option => option.name === t("travelAllowance.report.travelOrderDocument") ? {...option, visible: false} : option);
            } else {
                setSelectedOption(RideReportPageOptions.TRAVEL_ORDER);
            }

            if (!rideMatrix.isRideContract) {
                tempOptions = tempOptions.map(option => option.name === t("travelAllowance.report.contractDocument") ? {...option, visible: false} : option);
            } else {
                setSelectedOption(RideReportPageOptions.CONTRACT);
            }

            setOptions(tempOptions);
        }
    }, [rideMatrix]);

    const fetchRideReport = async () => {
        const rideReportData: RideReport = await getRideReport(parseInt(reportId));

        if (rideReportData.status !== 'WAITING') {
            history.goBack();
        }

        setReport(rideReportData);
    }

    const fetchRideMatrix = async () => {
        const rideMatrixData: RideMatrix = await getRideMatrixByRideReportId(parseInt(reportId));

        setRideMatrix(rideMatrixData);
    }

    const handleAcceptRideReport = async () => {
        setLoading(true);

        sendToAssecoRideReport(parseInt(reportId))
            .then(() => {
                history.goBack();
            })
            .catch((error) => {
                setToast(t("travelAllowance.rideReport.acceptError"));
                setShowToast(true);

                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const downloadRideDocumentPdf = async (type: RideDocumentType) => {
        if (!report) return;

        const info = await Device.getInfo();
        let deniedPermissions = false;
        if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
            let hasPermissions = await Filesystem.checkPermissions();
            deniedPermissions = hasPermissions.publicStorage === 'denied';
        }

        if (deniedPermissions) {
            setShowPermissionAlert(true);
        } else {
            let fileData = null;
            fileData = await getRideDocumentPdfByType(report.id, type)
                .then(response => {
                    return response;
                })

            let base64 = null;
            if (fileData) {
                await blobToBase64(fileData).then(value => base64 = value);
                if (base64) {
                    try {
                        const result = await Filesystem.writeFile({
                            path: 'ExactPeople/' + report.number.replace(/\//g, '_') + '_' + type + '.pdf',
                            data: base64,
                            directory: Directory.Library,
                            recursive: true
                        })

                        FileOpener.open({
                            'filePath': result.uri,
                            'contentType': 'application/pdf'
                        });
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        }
    };

    const handleSelect = (option?: SubNavigationOption) => {
        if (!options || selectedOption === undefined) return;

        const setOption = (optionIndex: number) => {
            navigation.setParam('selectedOption', optionIndex.toString());
            setSelectedOption(optionIndex);
            setShowAcceptButton(optionIndex === getLastVisibleOptionsIndex());
        };

        if (option) {
            const optionIndex = options.indexOf(option);
            if (optionIndex !== selectedOption) {
                setOption(optionIndex);
            }
        } else {
            const nextVisibleOptionIndex = options.slice(selectedOption + 1).findIndex(option => option.visible);
            if (nextVisibleOptionIndex !== -1) {
                setOption(selectedOption + 1 + nextVisibleOptionIndex);
            }
        }
    };

    const getVisibleOptions = () => {
        return options.filter(option => option.visible);
    }

    const getLastVisibleOptionsIndex = () => {
        return (options.length) - 1 - [...options].reverse().findIndex(option => option.visible);
    }

    const updateHeight = () => {
        if (header.current?.clientHeight === 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const handleBack = () => {
        history.goBack();
    };

    const handleOpenRideReportRejectActionSheet = () => {
        setIsRideReportRejectModalOpen(true);
    };

    const handleCloseRideReportRejectActionSheet = () => {
        setIsRideReportRejectModalOpen(false);
    };

    return (
        <IonPage>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);

                            await NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("rideReportPreviewPage.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                    {
                        showSubNavigation && selectedOption !== undefined &&
                        <SubNavigation paramName="selectedOption" defaultValue={selectedOption} options={options} onSelect={(option) => handleSelect(option)}/>
                    }
                </IonHeader>
                <RideReportRejectActionSheet rideId={parseInt(reportId)} isOpen={isRideReportRejectModalOpen} onClose={handleCloseRideReportRejectActionSheet}/>
                {
                    loading &&
                    <Pane topEdge={topEdge}>
                        <MyDataSkeleton/>
                    </Pane>
                }
                {
                    !loading && report && selectedOption === RideReportPageOptions.CONTRACT &&
                    <RideDocumentPane header={header} report={report} type={RideDocumentType.CONTRACT}/>
                }
                {
                    !loading && report && selectedOption === RideReportPageOptions.TRAVEL_ORDER &&
                    <RideDocumentPane header={header} report={report} type={RideDocumentType.TRAVEL_ORDER}/>
                }
                {
                    !loading && report && selectedOption === RideReportPageOptions.REPORT &&
                    <RideDocumentPane header={header} report={report} type={RideDocumentType.REPORT}/>
                }
            </StyledIonContent>
            {
                !loading &&
                <IonFooter style={{background: "white"}}>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                {
                                    !showAcceptButton && showSubNavigation &&
                                    <StyledButton onClick={() => handleSelect()}>
                                        <div className="btn center">
                                            <span>{t('travelAllowance.report.continue')}</span>
                                        </div>
                                    </StyledButton>
                                }
                                {
                                    (showAcceptButton || !showSubNavigation) &&
                                    <StyledButton onClick={() => handleAcceptRideReport()}>
                                        <div className="btn center">
                                            <span>{t('travelAllowance.report.accept')}</span>
                                        </div>
                                    </StyledButton>
                                }
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="6">
                                <StyledButton className="danger" style={{margin: 0}} onClick={handleOpenRideReportRejectActionSheet}>
                                    {t('travelAllowance.report.reject')}
                                </StyledButton>
                            </IonCol>
                            <IonCol size="6">
                                {
                                    selectedOption === RideReportPageOptions.CONTRACT &&
                                    <StyledButtonOption style={{margin: 0}} onClick={() => downloadRideDocumentPdf(RideDocumentType.CONTRACT)}>
                                        <div className="btn-option center">
                                            <span>{t('travelAllowance.report.downloadContract')}</span>
                                        </div>
                                    </StyledButtonOption>
                                }
                                {
                                    selectedOption === RideReportPageOptions.TRAVEL_ORDER &&
                                    <StyledButtonOption style={{margin: 0}} onClick={() => downloadRideDocumentPdf(RideDocumentType.TRAVEL_ORDER)}>
                                        <div className="btn-option center">
                                            <span>{t('travelAllowance.report.downloadTravelOrder')}</span>
                                        </div>
                                    </StyledButtonOption>
                                }
                                {
                                    selectedOption === RideReportPageOptions.REPORT &&
                                    <StyledButtonOption style={{margin: 0}} onClick={() => downloadRideDocumentPdf(RideDocumentType.REPORT)}>
                                        <div className="btn-option center">
                                            <span>{t('travelAllowance.report.downloadReport')}</span>
                                        </div>
                                    </StyledButtonOption>
                                }
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonFooter>
            }
        </IonPage>
    );
};

export default RideReportPreviewPage;