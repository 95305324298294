import React, {useEffect, useState} from 'react';
import {IonAlert, IonCol, IonItem, IonLoading, IonRow, IonToast} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {Controller, useForm} from "react-hook-form";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {Camera, CameraResultType, CameraSource} from "@capacitor/camera";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";

import Accordion from "@components/accordion/accordion";
import Form from "@components/form";
import PaginatedListModal from "@components/modal/paginatedListModal.component";

import {IonCardShadowStyle, StyledButton, StyledButtonOption, StyledButtonWrapper, StyledTravelAllowanceContent} from "@app/travelAllowance/travelAllowance.style";
import {StyledInput, StyledInputGroup, StyledInputUnit} from "@components/form/input/input.style";
import {StyledIonLabel} from "@components/form/input/label.style";
import {StyledIonCheckbox} from "@components/form/input/checkbox.style";

import {Car, CarBrand, CarForm, CarModel} from "@models/travelAllowance/car";

import {addCar, editCar, getCarBrands, getCarModels} from "@services/travelAllowance/car.service";

import {Links} from "@app/links";

import {ReactComponent as AddIcon} from "@assets/images/travelAllowance/add.svg"
import {ReactComponent as ZoomIcon} from "@assets/images/travelAllowance/zoom.svg"
import {ReactComponent as SaveIcon} from '@assets/images/travelAllowance/save.svg';
import {ReactComponent as BinIcon} from '@assets/images/travelAllowance/bin.svg';

import {CarPaneType} from "@enums/travelAllowance/car";
import PhotoPreviewModalComponent from "@components/modal/photoPreviewModal.component";
import {getRideMatrix} from "@services/travelAllowance/rideMatrix.service";
import {RideMatrix} from "@models/travelAllowance/rideMatrix";
import StaticPane from "@components/pane/static-pane.component";
import TravelAllowanceFormSkeleton from "@app/travelAllowance/common/components/travelAllowanceFormSkeleton.component";

type CarAddEditPaneProps = {
    type: CarPaneType,
    topEdge?: number,
    carDefaultValues?: Car
}

const CarAddEditPane: React.FC<CarAddEditPaneProps> = ({type, topEdge, carDefaultValues}: CarAddEditPaneProps) => {
    const cancelToken = axios.CancelToken.source();

    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const {control, register, handleSubmit, setValue, errors, watch} = useForm<CarForm>(
        {
            mode: 'onChange',
            defaultValues: {
                brand: carDefaultValues?.brand,
                model: carDefaultValues?.model,
                capacity: carDefaultValues?.capacity,
                licensePlate: carDefaultValues?.licensePlate,
                vehicleRegistrationDocument: carDefaultValues?.vehicleRegistrationDocumentBase64Content,
                isCompanyVehicle: carDefaultValues?.isCompanyVehicle,
            }
        }
    );

    const [isBrandModalOpen, setIsBrandModalOpen] = useState<boolean>(false);
    const [isModelModalOpen, setIsModelModalOpen] = useState<boolean>(false);

    const [brand, setBrand] = useState<string>(carDefaultValues?.brand || '');
    const [model, setModel] = useState<string>(carDefaultValues?.model || '');
    const [photoData, setPhotoData] = useState<string | undefined>(carDefaultValues?.vehicleRegistrationDocumentBase64Content);

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const [rideMatrix, setRideMatrix] = useState<RideMatrix>();
    const [showRemoveVehicleRegistrationDocumentPhotoAlert, setShowRemoveVehicleRegistrationDocumentPhotoAlert] = useState<boolean>(false);
    const [photoPreviewModalOpen, setPhotoPreviewModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [showLoader, setShowLoader] = useState<boolean>(false);

    const capacity = watch('capacity');
    const licensePlate = watch('licensePlate');

    useEffect(() => {
        const shouldFetch = location.pathname.includes(Links.travelAllowance.car.add) || location.pathname.includes(Links.travelAllowance.car.edit);
        if (!shouldFetch) return;

        setLoading(true);

        getRideMatrix()
            .then((rideMatrix: RideMatrix) => setRideMatrix(rideMatrix))
            .finally(() => {
                setLoading(false);
            });
    }, [location.pathname]);

    const handleBrandChoose = (brand: CarBrand) => {
        setBrand(brand.name);
        setValue('brand', brand.name, {shouldValidate: true});

        setModel('');
        setValue('model', '');
    }

    const handleModelChoose = (model: CarModel) => {
        setModel(model.name);
        setValue('model', model.name, {shouldValidate: true});
    }

    const takeVehicleRegistrationDocumentPhoto = async () => {
        const hasPermissions = await Camera.checkPermissions();

        if (hasPermissions.camera === 'denied') {
            setShowPermissionAlert(true);
        } else {
            const image = await Camera.getPhoto({
                source: CameraSource.Camera,
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.Base64
            });

            if (image.base64String) {
                setPhotoData(image.base64String);
                setValue('vehicleRegistrationDocument', image.base64String, {shouldValidate: true});
            }
        }
    };

    const removeVehicleRegistrationDocumentPhoto = () => {
        setPhotoData(undefined);
        setValue('vehicleRegistrationDocument', null)
    }

    const onSubmit = (data: CarForm) => {
        setShowLoader(true);

        const handleSuccess = () => {
            history.replace(Links.main + Links.travelAllowance.car.list);

            setShowLoader(false);
        };

        const handleError = (error: any, errorMessageKey: string) => {
            const message = prepareErrorResponseToastMessage(error.response.data['invalid-params']);

            if (message) {
                setToast(t(message));
            } else {
                setToast(t(errorMessageKey));
            }
            setShowToast(true);
            setShowLoader(false);

            console.error(error);
        };

        if (type === CarPaneType.ADD) {
            addCar(data)
                .then(() => handleSuccess())
                .catch((error) => handleError(error, "travelAllowance.car.addError"));
        }

        if (carDefaultValues?.id && type === CarPaneType.EDIT) {
            editCar(carDefaultValues.id, data)
                .then(() => handleSuccess())
                .catch((error) => handleError(error, "travelAllowance.car.editError"));
        }
    };

    const prepareErrorResponseToastMessage = (invalidParams: { name: string, reason: string }[]): string | undefined => {
        const errorMessages: { [key: string]: string } = {
            'licensePlate': 'travelAllowance.car.licensePlateInUse',
        };

        for (const param of invalidParams) {
            if (errorMessages[param.name]) {
                return errorMessages[param.name];
            }
        }

        return undefined;
    };

    return (
        <StaticPane topEdge={topEdge} marginTop={40} paddingTop={30} paddingBottom={147} hideGrabber={true}>
            {
                <StyledTravelAllowanceContent>
                    <IonLoading onDidDismiss={() => setShowLoader(false)} isOpen={showLoader}/>
                    <IonAlert
                        isOpen={showPermissionAlert}
                        onDidDismiss={() => setShowPermissionAlert(false)}
                        header={t('common.file_permissions.alert')}
                        buttons={[
                            {
                                text: t('common.alertCancel'),
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: () => {
                                    setShowPermissionAlert(false);
                                },
                            },
                            {
                                text: t('common.file_permissions.goToAppSettings'),
                                handler: async () => {
                                    setShowPermissionAlert(false);
                                    await NativeSettings.open({
                                        optionAndroid: AndroidSettings.ApplicationDetails,
                                        optionIOS: IOSSettings.App
                                    })
                                }
                            }
                        ]}
                    />
                    <IonAlert
                        isOpen={showRemoveVehicleRegistrationDocumentPhotoAlert}
                        onDidDismiss={() => setShowRemoveVehicleRegistrationDocumentPhotoAlert(false)}
                        header={t('travelAllowance.removeVehicleRegistrationDocumentPhoto.alert')}
                        buttons={[
                            {
                                text: t('common.alertCancel'),
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: () => {
                                    setShowRemoveVehicleRegistrationDocumentPhotoAlert(false);
                                },
                            },
                            {
                                text: t('common.alertConfirm'),
                                handler: () => {
                                    removeVehicleRegistrationDocumentPhoto();
                                }
                            }
                        ]}
                    />
                    <IonToast
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message={toast}
                        duration={6000}
                        position="top"
                        color="danger"
                    />
                    {loading
                        ? (
                            <TravelAllowanceFormSkeleton/>
                        )
                        : (
                            <>
                                {photoData && <PhotoPreviewModalComponent isOpen={photoPreviewModalOpen} photo={photoData} onClose={() => setPhotoPreviewModalOpen(false)}/>}
                                <Form.Container onSubmit={handleSubmit(onSubmit)}>
                                    <Accordion isOpen={true} allowToggle={true} title={t('travelAllowance.generalData')}>
                                        {
                                            <section>
                                                <PaginatedListModal isOpen={isBrandModalOpen}
                                                                    fetchData={getCarBrands}
                                                                    fetchParams={{cancelToken: cancelToken}}
                                                                    setValue={handleBrandChoose}
                                                                    allowManual={true}
                                                                    onClose={() => setIsBrandModalOpen(false)}/>
                                                <PaginatedListModal isOpen={isModelModalOpen}
                                                                    fetchData={getCarModels}
                                                                    fetchParams={{brand: brand, cancelToken: cancelToken}}
                                                                    setValue={handleModelChoose}
                                                                    allowManual={true}
                                                                    onClose={() => setIsModelModalOpen(false)}/>
                                                <IonRow>
                                                    <IonCol size="12" className="label mt-8 required">
                                                        {t('travelAllowance.car.brand')}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size="12" className="label">
                                                        <StyledInput readOnly={true}
                                                                     name="brand"
                                                                     ref={register({
                                                                         required: true
                                                                     })}
                                                                     className={errors.brand ? 'error' : ''}
                                                                     value={brand}
                                                                     onClick={() => setIsBrandModalOpen(true)}
                                                        />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size="12" className="label mt-8 required">
                                                        {t('travelAllowance.car.model')}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size="12" className="label">
                                                        <StyledInput disabled={!brand}
                                                                     readOnly={true}
                                                                     name="model"
                                                                     ref={register({
                                                                         required: true
                                                                     })}
                                                                     className={errors.model ? 'error' : ''}
                                                                     value={model}
                                                                     onClick={() => setIsModelModalOpen(true)}
                                                        />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size="12" className="label mt-8 required">
                                                        {t('travelAllowance.car.capacity')}
                                                    </IonCol>
                                                </IonRow>
                                                <StyledInputGroup unit={true} className={errors.capacity ? 'error' : ''}>
                                                    <IonCol size="10" className="label">
                                                        <StyledInput readOnly={false}
                                                                     placeholder=""
                                                                     name="capacity"
                                                                     type="number"
                                                                     ref={register({
                                                                         required: true,
                                                                         valueAsNumber: true,
                                                                     })}
                                                                     value={capacity}
                                                        />
                                                    </IonCol>
                                                    <IonCol size="2" className="label">
                                                        <StyledInputUnit>ccm</StyledInputUnit>
                                                    </IonCol>
                                                </StyledInputGroup>
                                                <IonRow>
                                                    <IonCol size="12" className="label mt-8 required">
                                                        {t('travelAllowance.car.licensePlate')}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size="12" className="label">
                                                        <StyledInput readOnly={false}
                                                                     placeholder=""
                                                                     name="licensePlate"
                                                                     ref={register({
                                                                         required: true
                                                                     })}
                                                                     className={errors.licensePlate ? 'error' : ''}
                                                                     value={licensePlate}
                                                        />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonItem lines="none">
                                                        <StyledIonLabel className="ion-text-wrap">{t('travelAllowance.car.isCompanyVehicle')}</StyledIonLabel>
                                                        <Controller
                                                            name="isCompanyVehicle"
                                                            control={control}
                                                            defaultValue={carDefaultValues?.isCompanyVehicle || false}
                                                            render={({onChange, value}) => (
                                                                <StyledIonCheckbox
                                                                    slot="start"
                                                                    style={{marginLeft: "5px"}}
                                                                    checked={value}
                                                                    onIonChange={(e) => onChange(e.detail.checked)}
                                                                />
                                                            )}
                                                        />
                                                    </IonItem>
                                                </IonRow>
                                            </section>
                                        }
                                    </Accordion>
                                    {
                                        rideMatrix?.isVehicleRegistrationDocumentRequired || photoData
                                            ? <Accordion isOpen={true} allowToggle={true} title={t('travelAllowance.car.license_plate')}>
                                                {
                                                    <section>
                                                        {
                                                            rideMatrix?.isVehicleRegistrationDocumentRequired &&
                                                            <>
                                                                <IonRow>
                                                                    <IonCol size="12">
                                                                        <StyledButtonOption>
                                                                            <input type="hidden"
                                                                                   name="vehicleRegistrationDocument"
                                                                                   ref={register({
                                                                                       required: true
                                                                                   })}
                                                                                   accept="image/*"
                                                                            />
                                                                            <div onClick={() => takeVehicleRegistrationDocumentPhoto()}
                                                                                 className="btn-option center"
                                                                                 style={{width: "100%"}}
                                                                            >
                                                                                <AddIcon/>
                                                                                <span>{t('travelAllowance.car.addLicensePlatePhoto')}</span>
                                                                            </div>
                                                                        </StyledButtonOption>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow>
                                                                    <IonCol size="12" className="required">
                                                            <span style={{fontSize: '12px', color: "grey", marginLeft: "0"}}>
                                                                {t('travelAllowance.car.vehicleRegistrationDocument')}
                                                            </span>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </>
                                                        }
                                                        <IonCardShadowStyle style={{width: "99%", marginLeft: "0.5%"}} className={errors.vehicleRegistrationDocument ? 'error' : ''}>
                                                            <div className="wrapper">
                                                                <div>
                                                                    <IonRow className="">
                                                                        {photoData &&
                                                                            <img
                                                                                src={`data:image/jpeg;base64,${photoData}`}
                                                                                style={{
                                                                                    width: "150px",
                                                                                    height: "40px",
                                                                                    objectFit: "cover"
                                                                                }}
                                                                                alt={t('travelAllowance.car.vehicleRegistrationDocument')}/>}
                                                                    </IonRow>
                                                                </div>
                                                                <div className="wrapper-btn"
                                                                     style={{flexDirection: "row", alignItems: "center"}}>
                                                                    <StyledButton type="button" onClick={() => photoData && setPhotoPreviewModalOpen(true)}>
                                                                        <div className="btn center">
                                                                            <ZoomIcon/>
                                                                        </div>
                                                                    </StyledButton>
                                                                    {
                                                                        rideMatrix?.isVehicleRegistrationDocumentRequired &&
                                                                        <StyledButton type="button" onClick={() => setShowRemoveVehicleRegistrationDocumentPhotoAlert(true)}>
                                                                            <div className="btn center">
                                                                                <BinIcon/>
                                                                            </div>
                                                                        </StyledButton>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </IonCardShadowStyle>
                                                    </section>
                                                }
                                            </Accordion>
                                            : <></>
                                    }
                                    <StyledButtonWrapper>
                                        <StyledButton type="submit" className="center" style={{width: "99%", marginLeft: "0.5%"}}>
                                            <div className="btn center">
                                                <SaveIcon/>
                                                <span>{t('travelAllowance.save')}</span>
                                            </div>
                                        </StyledButton>
                                    </StyledButtonWrapper>
                                </Form.Container>
                            </>
                        )
                    }
                </StyledTravelAllowanceContent>
            }
        </StaticPane>
    );
};

export default CarAddEditPane;