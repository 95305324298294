import React, {PropsWithChildren, useEffect, useMemo, useRef, useState} from 'react';
import {IonPopover} from '@ionic/react';
import {StyledFakeField} from "./input.style";
import {StyledTime, StyledTimeButton, StyledTimeInput} from "@components/form/input/time.style";
import {isValid, parseISO} from "date-fns";
import {Keyboard} from "@capacitor/keyboard";
import {useTranslation} from "react-i18next";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
moment.tz.setDefault('Europe/Warsaw');

type TimeProps = {
    value?: string | null;
    disabled?: boolean;
    cancelText?: string;
    doneText?: string;
    placeholder?: string;
    onChange: (value: string) => void;
    className?: string;
    minuteValues?: string;
    keepContentsMounted?: boolean;
    defaultPopoverValue?: string;
    onWillPresent?: (e: any) => void;
}

const TimeInput: React.FC<PropsWithChildren<TimeProps>> = (props) => {
    const {t} = useTranslation();

    const hoursRef = useRef<null | HTMLIonInputElement>(null);
    const minutesRef = useRef<null | HTMLIonInputElement>(null);

    const [selectedValue, setSelectedValue] = useState<string>();
    const [showPopover, setShowPopover] = useState<boolean>(false);
    const [hoursReadable, setHoursReadable] = useState<string>();
    const [minutesReadable, setMinutesReadable] = useState<string>();
    const [hours, setHours] = useState<string>();
    const [minutes, setMinutes] = useState<string>();
    const [isRounded, setIsRounded] = useState<boolean>(false);

    useEffect(() => {
        if (showPopover) {
            Keyboard.show();

            if (hoursRef.current) {
                focusInputType(hoursRef.current);
            }

            setIsRounded(false);
        }

        if (!showPopover) {
            Keyboard.hide();
        }
    }, [showPopover]);

    useEffect(() => {
        const timeValue = props.value || props.defaultPopoverValue;

        if (timeValue) {
            setSelectedValue(timeValue);
            handleHours(moment(timeValue).format("HH"));
            handleMinutes(moment(timeValue).format("mm"));
        }
    }, [props.value, props.defaultPopoverValue]);

    useEffect(() => {
        if (hours) {
            handleHoursReadable(hours);
        }
    }, [hours]);

    useEffect(() => {
        if (minutes) {
            handleMinutesReadable(minutes);
        }
    }, [minutes]);

    const formattedValue = useMemo(() => {
        if (props.value && isValid(parseISO(props.value.toString()))) {
            return moment(props.value.toString(), moment.ISO_8601).format('HH:mm');
        }

        return props.placeholder ?? '';
    }, [props.value, props.placeholder]);


    const handleShowPopover = () => {
        if (!props.disabled) {
            setShowPopover(true);
        }
    };

    const handleHours = (hoursValue: string) => {
        if (!hoursValue) return;

        const hoursIntValue = parseInt(hoursValue);
        if (Number.isNaN(hoursIntValue)) {
            hoursRef.current?.classList.add('error');
            return;
        } else {
            hoursRef.current?.classList.remove('error');
        }

        if (hoursIntValue > 23) {
            setHours('23');
            setHoursReadable('23');
            return;
        }

        if (hoursIntValue < 0) {
            setHours('0');
            setHoursReadable('00');
            return;
        }

        setHours(hoursValue);
    }

    const handleMinutes = (minutesValue: string) => {
        if (!minutesValue) return;

        const minutesIntValue = parseInt(minutesValue);
        if (Number.isNaN(minutesIntValue)) {
            minutesRef.current?.classList.add('error');
        } else {
            minutesRef.current?.classList.remove('error');
        }

        if (minutesIntValue > 59) {
            setMinutes('59');
            setMinutesReadable('59');
            return;
        }

        if (minutesIntValue < 0) {
            setMinutes('0');
            setMinutesReadable('00');
            return;
        }

        if (props.minuteValues) {
            const closestMinutesValue = getClosestMinute(minutesIntValue)
            setMinutes(closestMinutesValue);

            if (closestMinutesValue && parseInt(closestMinutesValue ) !== minutesIntValue) {
                setIsRounded(true);
            }

            return;
        }

        setMinutes(minutesValue);
    }

    const handleHoursReadable = (hoursValue: string) => {
        if (hoursValue.length === 1) {
            setHoursReadable(`0${hoursValue}`);
        } else {
            setHoursReadable(hoursValue);
        }
    }

    const handleMinutesReadable = (minutesValue: string) => {
        if (minutesValue.length === 1) {
            setMinutesReadable(`0${minutesValue}`);
        } else {
            setMinutesReadable(minutesValue);
        }
    }

    const getClosestMinute = (minutesValue: number) => {
        if (props.minuteValues) {
            return props.minuteValues.split(',').reduce((prev, curr) =>
                Math.abs(parseInt(curr) - minutesValue) < Math.abs(parseInt(prev) - minutesValue) ? curr : prev
            );
        }
    };

    const checkHoursLength = () => {
        const value = hoursRef.current?.value as string;

        if (value.length === 2 && minutesRef.current) {
            focusInputType(minutesRef.current)
        }
    }

    const handleSave = () => {
        if (!hours || !minutes) {
            return;
        }

        props.onChange(moment(selectedValue).set({hour: hours, minute: minutes}).format());

        setShowPopover(false);
    };

    const handleCancel = () => {
        setShowPopover(false);
    }

    const focusInputType = (inputElement: HTMLIonInputElement) => {
        setTimeout(() => {
            inputElement.setFocus();
        }, 150);
    }

    return (
        <>
            <StyledFakeField className={props.className} onClick={handleShowPopover}>
                {formattedValue}
            </StyledFakeField>
            <IonPopover
                keepContentsMounted={props.keepContentsMounted}
                isOpen={showPopover}
                onWillPresent={(e) => {
                    if (props.onWillPresent) {
                        props.onWillPresent(e);
                    }
                }}
                onDidDismiss={handleCancel}
            >
                <StyledTime>
                    <StyledTimeInput
                        ref={hoursRef}
                        type="tel"
                        maxlength={2}
                        value={hoursReadable}
                        onIonInput={checkHoursLength}
                        onIonChange={(e) => handleHours(e.detail.value as string)}
                    />
                    :
                    <StyledTimeInput
                        ref={minutesRef}
                        type="tel"
                        maxlength={2}
                        value={minutesReadable}
                        onIonChange={(e) => handleMinutes(e.detail.value as string)}
                    />
                </StyledTime>
                {
                    isRounded && <p className="warningMessage" style={{padding: "0 15px"}}>{t('time.minutesHasBeenRounded')}</p>

                }
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <StyledTimeButton size="small" fill="clear" onClick={handleCancel}>{props.cancelText}</StyledTimeButton>
                    <StyledTimeButton size="small" fill="clear" onClick={handleSave}>{props.doneText}</StyledTimeButton>
                </div>
            </IonPopover>
        </>
    );
}

export default React.memo(TimeInput);