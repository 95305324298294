export enum RemarkType {
    PERSONAL_DATA = 'PERSONAL_DATA',
    ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
    CONTRACT_DATE = 'CONTRACT_DATE',
    PHONE_NUMBER = 'PHONE_NUMBER',
    ADDRESS = 'ADDRESS',
    POSITION = 'POSITION',
    RATE = 'RATE',
    OTHER = 'OTHER',
}
