import React, {useEffect, useState} from 'react';
import {IonLoading, IonToast} from '@ionic/react';
import {useForm} from 'react-hook-form';
import {FORM_FIELDS, FormFieldsType} from './loginForm.types';
import Form from '../../../components/form'
import {useHistory, useLocation} from 'react-router-dom';
import {validateEmail} from '../../../services/validation.service';
import {
    isEmployed,
    login,
    logout,
    storeProfile,
    storeToken,
    useAuth
} from '../../../services/auth.service';
import {getProfile, getRedirectAfterLogin} from '../../../services/profile.service';
import {AuthResult} from '../../../types/auth-result';
import {useTranslation} from 'react-i18next';
import {push} from "../../../services/push.service";
import {Links} from "../../links";
import AppApprovalsModal from "../../mainPage/tabs/settings/modals/appApprovalsModal.component";
import TeamMemberPhoneModal from "../../../modals/teamMemberPhone/teamMemberPhone.component";
import {Preferences} from "@capacitor/preferences";
import {StyledText, StyledTextLink} from "../../../components/text";
import {StyledLoginTextContainer} from "../login.styles";
import {RecruitmentPathTestVersion} from "../../../enums/recruitmentPathTestVersion";
import ExactLoginWhite from "@assets/images/exact-logo-white.svg";
import greenCheck from "@assets/images/check-green.svg";
import {StyledInput, StyledInputEmail} from "@components/form/input/input.style";


interface LocationState {
    toastInit: string;
    showToastInit: boolean;
}

const LoginForm: React.FC = () => {
    const auth = useAuth();
    const {register, handleSubmit, watch, errors, formState, setValue} = useForm({
        mode: 'onBlur'
    });
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const watchEmail = watch(FORM_FIELDS.EMAIL, '');
    const watchPassword = watch(FORM_FIELDS.PASSWORD, '');

    const disableButtonCondition: boolean = (watchEmail === '' || watchPassword === '');

    const location = useLocation<LocationState>();
    const {toastInit, showToastInit} = location.state || {toastInit: "", showToastInit: false};

    const [showToast, setShowToast] = useState(showToastInit);
    const [toast, setToast] = useState<any>(toastInit);
    const [showApprovals, updateShowApprovals] = useState<boolean>(false);
    const [showTeamMemberPhone, updateShowTeamMemberPhone] = useState<boolean>(false);
    const [countFailLogin, updateCountFailLogin] = useState<number>(0);
    const [showLoading, updateShowLoading] = useState(false);

    let invalidEmailError = t("login.invalidEmail");

    const setLastLoginEmail = async () => {
        let email = await Preferences.get({'key': 'last_login_email'});
        if (email.value) {
            setValue(FORM_FIELDS.EMAIL, email.value);
        }
    };

    useEffect(() => {
        setLastLoginEmail()
    }, [])

    const onSubmit = async (data: FormFieldsType) => {
        updateShowLoading(true)
        if (!disableButtonCondition) {
            const response = await login(data)
                .then(response => {
                    let authResult = response.data as AuthResult;
                    authResult.is_fb_user = false;
                    storeToken(authResult);
					push();
                    return true;
                })
                .catch(error => {
                    if (error.response && error.response.status === 400) {
                        updateCountFailLogin(countFailLogin + 1);
                        try {
                            let message = error.response.data.error_description;
                            if (message === "Account is disabled.") {
                                setToast(t("login.disabledAccount"));
                            } else {
                                setToast(t("login.badCredentialsError"));
                            }
                        } catch (e) {
                            setToast(t("login.badCredentialsError"));
                        }
                    } else if (error.response && error.response.status === 403) {
                        setToast(t("login.lockedAccount"));
                    } else {
                        setToast(t("common.serverErrorMsg"));
                    }
                    setShowToast(true);
                    return false;
                });

            if (response === true) {
                let number = '';
                let terms = null;
                let phone = null;
                let isExact = false;
                let profileResponseData = null;
                let recruitmentHash = null;
                let recruitmentContractFormCompleted = null;
                let recruitmentPathFlowVersion = null;
                let lang = 'en';

                const profileResponse = await getProfile(true)
                    .then(response => {
                        number = response.data.number;
                        isExact = response.data.is_coordinator || response.data.is_team_leader || response.data.is_quality;
                        terms = response.data.ep2_terms_acceptance;
                        phone = response.data.phone;
                        profileResponseData = response.data;
                        recruitmentHash = response.data.recruitment_hash;
                        recruitmentContractFormCompleted = response.data.contract_form_completed;
                        lang = response.data.lang;

                        i18n.changeLanguage(lang);
                        document.documentElement.lang = lang;
                        Preferences.set({'key': 'i18nextLng', 'value': lang});

                        recruitmentPathFlowVersion = response.data.recruitment_path_flow_version;
                        return true;
                    })
                    .catch(error => {
                        logout();
                        if (error.response && error.response.status === 401) {
                            if (error.response.headers['www-authenticate'] && error.response.headers['www-authenticate'].includes('app.security.no_role_account_is_disabled.please_contact_admin')) {
                                setToast(t("app.security.no_role_account_is_disabled.please_contact_admin"));
                            } else if (error.response.data.text === "You should change your password") {
                                setToast(t("login.pleaseChangePassword"));
                            } else {
                                setToast(t("login.disabledAccount"));
                            }
                        } else {
                            setToast(t("common.serverErrorMsg"));
                        }
                        setShowToast(true);
                        updateShowLoading(false)
                        return false;
                    });
                await storeProfile(profileResponseData, true);

                let redirect = !isExact ? await getRedirectAfterLogin(number) : null;

                if (profileResponse) {
                    if (recruitmentHash && (recruitmentContractFormCompleted == false || (recruitmentContractFormCompleted == true && recruitmentPathFlowVersion == RecruitmentPathTestVersion.VER_2))) {
                        auth.signIn();
                        history.push(Links.recruitment + '/' + recruitmentHash);
                    } else {
                        if (!isExact && !terms) {
                            updateShowApprovals(true);
                        } else if (!phone && number) {
                            updateShowTeamMemberPhone(true);
                        } else {
                            auth.signIn();
                            if (redirect) {
                                history.replace(redirect);
                            } else {
                                history.replace(Links.main);
                            }
                        }
                    }
                }
            }

            updateShowLoading(false)
        }
    }

    return (
        <>
            <Form.Container onSubmit={handleSubmit(onSubmit)}>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={toast}
                    duration={6000}
                    position="top"
                    color="danger"
                />
                <StyledInputEmail>
                    <StyledInput name={FORM_FIELDS.EMAIL}
                                 ref={register({
                                     required: true,
                                     validate: value =>
                                         validateEmail(value) || invalidEmailError
                                 })}
                                 placeholder={t("login.inputEmailPlaceholder")}
                                 className={errors.email && 'hasErrors'}
                                 type="email"
                    />
                    {
                        watchEmail && !errors.email &&
                        <>
                            <img src={greenCheck}/>
                        </>
                    }
                </StyledInputEmail>

                {errors.email && errors.email.message && <p className="errorMessage">{errors.email.message}</p>}

                <Form.PasswordInput
                    name={FORM_FIELDS.PASSWORD}
                    placeholder={t("login.inputPasswordPlaceholder")}
                    ref={register({
                        required: true
                    })}/>
                <Form.Button type="submit" disabled={disableButtonCondition}>
                    <img className={"position-left"} src={ExactLoginWhite}/>
                    {t("login.button")}
                </Form.Button>
            </Form.Container>
            <AppApprovalsModal withLogout={true} isOpen={showApprovals} onClose={() => updateShowApprovals(false)} onSubmit={ async () => {
                updateShowApprovals(false);
                auth.signIn();
                let number = await isEmployed();
                let redirect = await getRedirectAfterLogin(number == '0' ? null : '1');
                if (redirect) {
                    history.replace(redirect);
                }
            }}></AppApprovalsModal>

            <TeamMemberPhoneModal isOpen={showTeamMemberPhone} onClose={() => updateShowTeamMemberPhone(false)} onSubmit={ async (phone) => {
                updateShowTeamMemberPhone(false);
                auth.signIn();
                await Preferences.set({'key': 'phone', 'value': phone});
                let number = await isEmployed();
                let redirect = await getRedirectAfterLogin(number == '0' ? null : '1');
                if (redirect) {
                    history.replace(redirect);
                }
            }}></TeamMemberPhoneModal>

            <IonLoading onDidDismiss={() => updateShowLoading(false)} isOpen={showLoading}/>
        </>
    )
}

export default LoginForm;
