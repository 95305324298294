import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {App as AppPlugin} from "@capacitor/app";
import {Capacitor} from "@capacitor/core";
import {ConnectionStatus, Network} from "@capacitor/network";
import {getStatus} from "../../services/api.service";
import StatusModal, {StatusModalType} from "./status.component";
import {MainPageContext} from "../../app/mainPage/mainPage.component";
import {ReportsContext} from "../../app/mainPage/tabs/reports/panes/reportsPane.component";
import {ReportsTabContext} from "../../app/mainPage/tabs/reports/reportsTab.component";
import {getCurrentNetworkStatus, getOfflineDataStatus} from "../../services/offlineSync.service";
import {getMenu} from "../../services/menu.service";
import {EventType} from "@enums/eventType";
import {useEvent} from "@hooks/useEvent";

enum API_STATE {
    OK = 'OK',
    TIMEOUT = 'connection.apiError.timeout',
    SERVER_ERROR = 'connection.apiError.internalServerError',
}

const StatusConnectionModal: React.FC = () => {
    const {t} = useTranslation();
    const {dispatch} = useEvent();

    const [isNetworkConnection, setIsNetworkConnection] = useState<boolean>(true);
    const [apiState, setApiState] = useState<string>(API_STATE.OK);
    const [isAppActive, setIsAppActive] = useState<boolean>(true);
    const [isOfflineModeReady, updateIsOfflineModeReady] = useState<boolean>();

    const mainPageManager = useContext(MainPageContext);
    const reportsManager = useContext(ReportsContext);
    const reportsTabManager = useContext(ReportsTabContext);

    useEffect(() => {
        const appStateChangeListener = Capacitor.isNativePlatform()
            ? AppPlugin.addListener("appStateChange", async (state) => setIsAppActive(state.isActive))
            : null;

        return () => {
            appStateChangeListener?.remove();
        };
    }, []);

    useEffect(() => {
        let networkInterval: NodeJS.Timeout | undefined;
        let apiInterval: NodeJS.Timeout | undefined;
        let networkTimeout: NodeJS.Timeout | undefined;
        let apiTimeout: NodeJS.Timeout | undefined;

        if (isAppActive) {
            const initNetworkStatus = async () => {
                const networkStatusListener = Network.addListener("networkStatusChange", handleNetworkStatusChange);
                return () => networkStatusListener.remove();
            };

            initNetworkStatus();

            networkInterval = setInterval(checkNetworkStatus, 10000);
            apiInterval = setInterval(checkApiStatus, 60000);

            networkTimeout = setTimeout(checkNetworkStatus, 2500);
            apiTimeout = setTimeout(checkApiStatus, 5000);
        } else {
            if (networkInterval) clearInterval(networkInterval);
            if (apiInterval) clearInterval(apiInterval);
            if (networkTimeout) clearTimeout(networkTimeout);
            if (apiTimeout) clearTimeout(apiTimeout);
        }

        return () => {
            if (networkInterval) clearInterval(networkInterval);
            if (apiInterval) clearInterval(apiInterval);
            if (networkTimeout) clearTimeout(networkTimeout);
            if (apiTimeout) clearTimeout(apiTimeout);
        };
    }, [isAppActive]);

    useEffect(() => {
        if (isNetworkConnection) {
            reloadMenu();
            updateIsOfflineModeReady(undefined);
        } else {
            isOfflineDataReady();
        }
        dispatchNetworkStatus(isNetworkConnection)
    }, [isNetworkConnection]);

    const checkNetworkStatus = async () => {
        const status = await Network.getStatus();
        setIsNetworkConnection(status.connected);
    };

    const isOfflineDataReady = async () => {
        let offlineDataStatus = await getOfflineDataStatus();
        updateIsOfflineModeReady(offlineDataStatus.valid);
    }

    const handleNetworkStatusChange = async (status: ConnectionStatus) => {
        setIsNetworkConnection(status.connected);
        dispatch(EventType['REPORT.NETWORK_STATUS_CHANGE'])
        isOfflineDataReady();
    };

    const checkApiStatus = async () => {
        let networkStatus = await getCurrentNetworkStatus();
        if (networkStatus) {
            try {
                const response = await getStatus();
                setApiState(response.data);
            } catch (error) {
                handleApiError(error);
            }
        }
    };

    const handleApiError = (error: any) => {
        if (error.code === 'ECONNABORTED') {
            setApiState(t(API_STATE.TIMEOUT));
        } else {
            try {
                setApiState(error.response.data);
            } catch (e) {
                setApiState(t(API_STATE.SERVER_ERROR));
            }
        }
    };

    const reloadMenu = async () => {
        await getMenu().then(response => {
            mainPageManager.setMenuItems(response.data);
        });
    };

    const dispatchNetworkStatus = (status: boolean) => {
        mainPageManager.setNetworkStatus(status);
        reportsManager.setNetworkStatus(status);
        reportsTabManager.setNetworkStatus(status);
    }

    return (
        <StatusModal
            isOpen={(!isNetworkConnection || apiState !== API_STATE.OK) && isOfflineModeReady === false}
            message={t('connection.apiError.title')}
            description={apiState !== API_STATE.OK ? apiState : ''}
            status={StatusModalType.ERROR}
            onClose={() => setApiState(API_STATE.OK)}
            onRefresh={checkApiStatus}
            allowCloseApp={true}
            maxHeight="99%"
        />
    );
};

export default StatusConnectionModal;
