import {appApi} from "@services/api.service";
import {DataSelectable} from "@models/common";
import {Preferences} from "@capacitor/preferences";

const endpointPrefix = 'profiles/coordinator';

const getCoordinatorWorkers = async (page: number = 1, searchText: string = ''): Promise<DataSelectable[]> => {
    const profileId = await Preferences.get({'key': 'profile_id'});
    const response: any = await appApi.get(`${endpointPrefix}/workers?profileId=${profileId.value}&page=${page}&searchText=${searchText}`);

    return response.data;
}

export {
    getCoordinatorWorkers,
};
