import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import Pane from '../../../components/pane/pane.component';
import {StyledNoAuditReports, StyledAuditReports} from './auditReports.style';
import NoOrdersImage from '../../../assets/images/no-orders.svg';
import AddImage from '../../../assets/images/e-add.svg';
import AvatarImage from '../../../assets/images/logo/x-gray.png';
import ReportsImage from '../../../assets/images/chart-pie-36.svg';
import {StyledSocialButton} from '../../../components/button/button.style';
import {Links} from '../../links';
import {IonSkeletonText, useIonViewWillEnter} from "@ionic/react";
import {log} from "../../../services/firebaseAnalytics.service";
import {Profile} from "../../../models/profile";
import {getPermission} from "../../../services/permission.service";
import {getAuditReports, getShitPeriodReadable} from "../../../services/audit.service";
import {AuditReport} from "../../../models/auditReport";
import {AuditType} from "../../../enums/audit";
import AuditReportsSkeleton from "@app/auditReport/components/auditReportsSkeleton.component";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
const momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

type ReportsPaneProps = {
    auditType: AuditType;
    topEdge?: number;
    profile?: Profile;
}

const AuditReportsPane: React.FC<ReportsPaneProps> = (props: ReportsPaneProps) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [editPermission, setEditPermission] = useState<boolean>(false);
    const [reports, setReports] = useState<[]|undefined>(undefined);

    const history = useHistory();

    useEffect(() => {
        fetchPermission();
        fetchReports();
    }, []);

    useIonViewWillEnter( () => {
        fetchReports();
    });

    useEffect(() => {
        log('page_visit', {
            page: 'Audyty -> Audyty'
        });
    }, []);

    const fetchReports = async () => {
        if (reports === undefined && !loading) {
            setLoading(true);
            setReports([]);

            await getAuditReports(props.auditType)
                .then(response => {
                    setReports(response.data);
                })
                .catch(reason => {
                    setReports([]);
                });

            setLoading(false);
        }
    }

    const fetchPermission = async () => {
        await getPermission('QUALITY_DEPARTMENT', 'EDIT')
            .then(response => {
                setEditPermission(response.data);
            })
    }

    const addReport = (type: AuditType) => {
        history.push(Links.auditStart + '/' + type);
    };

    const getPlaceOfServiceName = (report: AuditReport) => {
        if (report?.place_of_service) {
            return report.place_of_service.name;
        }

        if (report?.place_of_service_manual) {
            return report.place_of_service_manual;
        }

        return '';
    }

    return (
        <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
            {
                <>
                    {
                        editPermission &&
                        <StyledSocialButton className="inverted" onClick={() => addReport(props.auditType)}>
                            <img src={AddImage}/>
                            {t('auditsTab.addReportButton')}
                        </StyledSocialButton>
                    }
                    {
                        !loading && reports && reports.length === 0 &&
                        <StyledNoAuditReports>
                            <img src={NoOrdersImage}/>
                            <img className="reports" src={ReportsImage}/>
                            <span className="message">{t('auditsTab.noReports')}</span>
                        </StyledNoAuditReports>
                    }
                    {
                        !loading && reports && reports.length !== 0 &&
                        <StyledAuditReports>
                            {
                                reports.map((report: AuditReport, i) => {
                                        return <React.Fragment key={i}>
                                            <div className="order">
                                                <div className="order-number">
                                                    #{report.id} {getPlaceOfServiceName(report)}
                                                </div>
                                                <div className="members"></div>
                                                <div className="details">
                                                    <div className="detail">{t('auditsTab.date')}:&nbsp;
                                                        <span className="value">{ moment(report.date, moment.ISO_8601).tz('Europe/Warsaw').format('DD.MM.YYYY')}</span>
                                                    </div>
                                                    <div className="detail">{t('auditsTab.placeOfService')}:&nbsp;
                                                        <span className="value">{getPlaceOfServiceName(report)}</span>
                                                    </div>
                                                    <div className="detail">{t('auditsTab.order.number')}:&nbsp;
                                                        <span className="value">{report.order?.formatted_number ?? '---'}</span>
                                                    </div>
                                                    <div className="detail">{t('auditsTab.type')}:&nbsp;
                                                        <span className="value">{report.type && t(`auditReports.${report.type.toLowerCase()}`)}</span>
                                                    </div>
                                                    <div className="detail">{t('auditsTab.shift_period')}:&nbsp;
                                                        <span className="value">{getShitPeriodReadable(report.shift_period)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                )
                            }
                        </StyledAuditReports>
                    }
                    {
                        loading && <AuditReportsSkeleton/>
                    }
                </>
            }
        </Pane>
    );
};

export default AuditReportsPane;
