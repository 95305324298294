import React, {useEffect, useState} from 'react';
import {IonIcon, IonLoading} from "@ionic/react";
import {Links} from "@app/links";
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router-dom";

import RideImage from '../../assets/images/ride.svg';
import RideImageActive from '../../assets/images/ride-active.svg';
import RideRouteImage from '../../assets/images/route.svg';
import RideRouteImageActive from '../../assets/images/route-active.svg';
import CarImage from '../../assets/images/car.svg';
import CarImageActive from '../../assets/images/car-active.svg';
import ReportsImage from '../../assets/images/reports.svg';
import ReportsImageActive from "../../assets/images/reports-active.svg";

import {SubMenuItem} from "@models/menu";
import SubMenuModal from "@modals/menu/subMenu.component";
import RegulationTravelPreviewModal from "@app/subMenuPage/modals/regulationTravelPreviewModal.component";
import {acceptRegulation, getRegulationInfo} from "@services/travelAllowance/regulation.service";

type TravelAllowanceSubMenuModalProps = {
    topEdge?: number,
}

const TravelAllowanceSubMenuModal: React.FC<TravelAllowanceSubMenuModalProps> = ({topEdge}: TravelAllowanceSubMenuModalProps) => {
    const history = useHistory();
    const location = useLocation();

    const [showLoader, setShowLoader] = useState<boolean>(false);

    const [showRegulationTravelPreviewModal, updateShowRegulationTravelPreviewModal] = useState<boolean>(false);
    const [regulationData, updateRegulationData] = useState<any>(false);

    useEffect(() => {
        setShowLoader(true);

        getRegulationInfoData()
            .finally(() => setShowLoader(false));
    }, []);

    const subMenuItems: SubMenuItem[] = [
        {
            id: 1,
            reference: 'app.mobile_menu.rides',
        },
        {
            id: 2,
            reference: 'app.mobile_menu.ride_routes',
        },
        {
            id: 3,
            reference: 'app.mobile_menu.cars',
        },
        {
            id: 4,
            reference: 'app.mobile_menu.ride_reports',
        }
    ]

    const redirect = async (subMenuItem: SubMenuItem) => {
        let url = null;

        switch (subMenuItem.reference) {
            case 'app.mobile_menu.rides':
                url = Links.main + Links.travelAllowance.ride.list;
                break;
            case 'app.mobile_menu.ride_routes':
                url = Links.main + Links.travelAllowance.rideRoute.list;
                break;
            case 'app.mobile_menu.cars':
                url = Links.main + Links.travelAllowance.car.list;
                break;
            case 'app.mobile_menu.ride_reports':
                url = Links.main + Links.travelAllowance.rideReport.list;
                break;
        }

        if (url) {
            history.push(url);
        }
    }

    const getIcon = (menuItem: SubMenuItem) => {
        let pathname = location.pathname;

        switch (menuItem.reference) {
            case 'app.mobile_menu.rides':
                return pathname.startsWith('/main/travel-allowance/ride/')
                    ? <IonIcon src={RideImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
                    : <IonIcon src={RideImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>;
            case 'app.mobile_menu.ride_routes':
                return pathname.startsWith('/main/travel-allowance/ride-route/')
                    ? <IonIcon src={RideRouteImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
                    : <IonIcon src={RideRouteImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>;
            case 'app.mobile_menu.cars':
                return pathname.startsWith('/main/travel-allowance/car/')
                    ? <IonIcon src={CarImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
                    : <IonIcon src={CarImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>;
            case 'app.mobile_menu.ride_reports':
                return pathname.startsWith('/main/travel-allowance/ride-reports/')
                    ? <IonIcon src={ReportsImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
                    : <IonIcon src={ReportsImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>;
        }
    }

    const getRegulationInfoData = async () => {
        const response = await getRegulationInfo();

        if (response.valid === false) {
            updateShowRegulationTravelPreviewModal(true);
            updateRegulationData(response.data);
        }
    };

    const accept = async () => {
        if (regulationData) {
            acceptRegulation(regulationData.id)
                .then(() => {
                    updateShowRegulationTravelPreviewModal(false);
                });
        }
    }

    return (
        <>
            <IonLoading onDidDismiss={() => setShowLoader(false)} isOpen={showLoader}/>
            <RegulationTravelPreviewModal
                isOpen={showRegulationTravelPreviewModal}
                onConfirm={() => {
                    accept();
                }}
                onClose={() => {
                    updateShowRegulationTravelPreviewModal(false);
                    history.goBack();
                }}
                regulation={regulationData}
            > </RegulationTravelPreviewModal>
            <SubMenuModal topEdge={topEdge} subMenuItems={subMenuItems} redirect={redirect} getIcon={getIcon}/>
        </>
    );
};

export default TravelAllowanceSubMenuModal;