import React from 'react';
import Modal from "@components/modal/modal.component";
import {StyledModalContent} from "@components/modal/modal.style";
import {SubRoute} from "@models/travelAllowance/rideRoute";
import {IonRow} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {Ride} from "@models/travelAllowance/ride";

type RideDetailsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    ride?: Ride;
}

const RideDetailsModal: React.FC<RideDetailsModalProps> = ({isOpen, onClose, ride}: RideDetailsModalProps) => {
    const {t} = useTranslation();

    return (
        <Modal initialBreakpoint={0.75} breakpoints={[0, 0.75]} isOpen={isOpen} onDidDismiss={() => onClose()}>
            {
                ride &&
                <>
                    <StyledModalContent>
                        <h1 className="title center">{t("travelAllowance.ride.detailsTitle")}</h1>
                        <div className="content">
                            <IonRow class="data-row">
                                {t("travelAllowance.ride.startingPlace")}: {ride.rideRoute.startingPlace}
                            </IonRow>
                            <IonRow class="data-row">
                                {t("travelAllowance.ride.destinationPlace")}: {ride.rideRoute.destinationPlace}
                            </IonRow>
                            {
                                ride.rideRoute.subRoutes && ride.rideRoute.subRoutes.map((subRoute: SubRoute, index: number) => {
                                    return (
                                        <IonRow key={subRoute.id} class="data-row">
                                            {t("travelAllowance.ride.subRoute")} {index + 1}: {subRoute.destinationPlace}
                                        </IonRow>
                                    )
                                })
                            }
                            <IonRow class="data-row">
                                {t("travelAllowance.ride.distance")}:{ride.rideRoute.distance}&nbsp;km
                            </IonRow>
                            <IonRow class="data-row">
                                {t("travelAllowance.ride.coordinator")}: {ride.rideRoute.coordinator.name}
                            </IonRow>
                            <IonRow class="data-row">
                                {t("travelAllowance.ride.placeOfService")}: {ride.rideRoute.placeOfService.name}
                            </IonRow>
                            <IonRow class="data-row">
                                {t("travelAllowance.ride.car")}: {ride.car.brand} {ride.car.model} ({ride.car.capacity}cm3)
                            </IonRow>
                        </div>
                    </StyledModalContent>
                </>
            }

        </Modal>

    );
};

export default RideDetailsModal;