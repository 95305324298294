import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import useNavigation from "../../../../../services/navigation.service";
import {log} from "../../../../../services/firebaseAnalytics.service";
import {StyledContent, StyledNewInvitation} from '../planningTab.style';
import {IonCol, IonGrid, IonItem, IonRow, IonSelectOption} from '@ionic/react';
import {StyledIonDatetime} from '../../../../../components/form/input/date.style';
import ArrowRightImage from '../../../../../assets/images/arrow-right.svg';
import Form from '../../../../../components/form';
import {StyledIonSelect} from "../../../../../components/form/input/select.style";
import {StyledTextarea} from '../../../../../components/form/input/textarea.style';
import {Links} from '../../../../links'
import StaticPane from "../../../../../components/pane/static-pane.component";
import {ProposalSelectable} from "../../../../../models/proposal";
import {getPlaceOfServices, getProjects} from "../../../../../services/proposal.service";
import {useForm} from "react-hook-form";
import {StyledField, StyledInput} from "../../../../../components/form/input/input.style";
import {StyledIonSelectOption} from "../../../../../components/form/input/selectOption.style";
import {b64EncodeUnicode} from "../../../../../utils/tools/string";
import {StyledIonLabel} from "../../../../../components/form/input/label.style";
import {StyledIonCheckbox} from "../../../../../components/form/input/checkbox.style";
import DateInput from "../../../../../components/form/input/date.component";
import TimeInput from "@components/form/input/time.component";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
moment.tz.setDefault('Europe/Warsaw');

type NewInvitationPaneProps = {
    topEdge?: number;
}

export type Invitation = {
    date?: Date;
    start?: Date;
    end?: Date;
    project?: string;
    placeOfService?: string;
    description?: string;
    jobPosition?: string;
    numberOfPeople?: number;
    sendForNextDays?: boolean;
    nextDaysAmount?: number;
    skipSaturdays?: boolean;
    skipSundays?: boolean;
    workers?: bigint[];
}

const NewInvitationPane: React.FC<NewInvitationPaneProps> = (props: NewInvitationPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);

    const [invitation, updateInvitation] = useState<Invitation>({});

    const [projects, setProjects] = useState<ProposalSelectable[]>();
    const [selectedProject, setSelectedProject] = useState<string>();
    const [sendForNextDays, setSendForNextDays] = useState<boolean>(false);
    const [nextDaysAmount, setNextDaysAmount] = useState<number>();

    const [placeOfServices, setPlaceOfServices] = useState<ProposalSelectable[]>();
    const [selectedPlaceOfService, setSelectedPlaceOfService] = useState<string>();
    const [date, setDate] = useState<Date>();
    const [timeStart, setTimeStart] = useState<Date>();
    const [timeEnd, setTimeEnd] = useState<Date>();

    useEffect(() => {
        log('page_visit', {
            page: 'Planowanie -> Nowe Zaproszenie'
        });
    }, []);

    const onSubmit = (formData: any) => {
        let data = b64EncodeUnicode(JSON.stringify(invitation));
        history.push(Links.selectPeople + '/' + data);
    };

    const onDateChange = (value: string) => {
        invitation.date = new Date(value);
        setDate(invitation.date);
    };

    const onStartDateChange = (value: string) => {
        invitation.start = new Date(value);
        setTimeStart(invitation.start);
    };

    const onEndDateChange = (value: string) => {
        invitation.end = new Date(value);
        setTimeEnd(invitation.end);
    };

    const handleSelectProject = (val: string) => {
        invitation.project = val;
        setSelectedProject(val);

        invitation.placeOfService = undefined;
        setSelectedPlaceOfService(undefined);
    };

    const handleSelectPlaceOfService = (val: string) => {
        invitation.placeOfService = val;
        setSelectedPlaceOfService(val);
    };

    const fetchProjects = async () => {
        await getProjects()
            .then(response => {
                setProjects(response.data);
            });
    };

    const fetchPlaceOfServices = async (projectId: string | bigint) => {
        await getPlaceOfServices(projectId)
            .then(response => {
                setPlaceOfServices(response.data);
            });
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    useEffect(() => {
        if (selectedProject) {
            fetchPlaceOfServices(selectedProject);
        }
    }, [selectedProject]);

    const {register, errors, setValue, handleSubmit, formState} = useForm({
        mode: 'onChange'
    });

    const [disabled, updateDisabled] = useState<boolean>(true);

    const isDisabled = () => {
        return !selectedProject || !selectedPlaceOfService || !date || !timeStart || !timeEnd || (sendForNextDays && !nextDaysAmount);
    }

    useEffect(() => {
        updateDisabled(isDisabled());
    }, [selectedProject, selectedPlaceOfService, date, timeStart, timeEnd, sendForNextDays, nextDaysAmount]);

    return (
        <StaticPane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
            <Form.Container onSubmit={handleSubmit(onSubmit)}>

            <StyledContent>
                <StyledNewInvitation>
                    <IonGrid className="ion-no-padding">
                        <IonRow>
                            <IonCol className="title no-margin"
                                    size="12">{t('planningTab.newInvitationPane.date')}</IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <DateInput
                                    format="DD.MM.YYYY"
                                    presentation="date"
                                    className={errors['date'] ? 'hasErrors' : ''}
                                    cancelText={t("common.dateCancel")}
                                    doneText={t("common.dateDone")}
                                    placeholder={t('planningTab.newInvitationPane.provideDate')}
                                    onChange={e => onDateChange(e)}
                                    value={invitation.date ? moment(invitation.date).format() : moment().format('YYYY-MM-DD')}
                                    min={moment().format('YYYY-MM-DD')}
                                    max={moment().add(1, 'year').format('YYYY-MM-DD')}
                                    minuteValues="0,15,30,45"/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="title" size="5.5">{t('planningTab.newInvitationPane.begin')}</IonCol>
                            <IonCol size="1"></IonCol>
                            <IonCol className="title" size="5.5">{t('planningTab.newInvitationPane.finish')}</IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="5.5">
                                <TimeInput
                                    keepContentsMounted={true}
                                    className={errors['startDate'] ? 'hasErrors' : ''}
                                    cancelText={t("common.dateCancel")}
                                    doneText={t("common.dateDone")}
                                    placeholder={t("common.type")}
                                    onChange={e => onStartDateChange(e)}
                                    value={invitation.start ? moment(invitation.start).format() : ''}
                                    minuteValues="0,15,30,45"/>
                            </IonCol>
                            <IonCol className="arrow" size="1"><img src={ArrowRightImage}/></IonCol>
                            <IonCol size="5.5">
                                <TimeInput
                                    keepContentsMounted={true}
                                    className={errors['endDate'] ? 'hasErrors' : ''}
                                    cancelText={t("common.dateCancel")}
                                    doneText={t("common.dateDone")}
                                    placeholder={t("common.type")}
                                    onChange={e => onEndDateChange(e)}
                                    value={invitation.end ? moment(invitation.end).format() : ''}
                                    minuteValues="0,15,30,45"/>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol size="12">
                                <IonItem lines="none">
                                    <StyledIonLabel className="ion-text-wrap">{t('planningTab.newInvitationPane.sendForNextDays')}</StyledIonLabel>
                                    <StyledIonCheckbox name="sendForNextDays" slot="start" onIonChange={(e) => {
                                        invitation.sendForNextDays = e.detail.checked;
                                        setSendForNextDays(e.detail.checked);
                                    }}/>
                                </IonItem>
                            </IonCol>
                        </IonRow>

                        {sendForNextDays &&
                            <IonRow>
                                <IonCol size="6">
                                    <IonRow>
                                        <IonCol className="title" size="6">{t('planningTab.newInvitationPane.nextDaysAmount')}</IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12">
                                            <StyledInput className="bolded no-margin"
                                                         name="nextDaysAmount"
                                                         type="number"
                                                         min={1}
                                                         max={30}
                                                         onChange={e => {
                                                             invitation.nextDaysAmount = parseInt(e.target.value);
                                                             setNextDaysAmount(parseInt(e.target.value));
                                                         }}
                                            ></StyledInput>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>

                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <StyledIonLabel
                                            className="ion-text-wrap">{t('planningTab.newInvitationPane.skipSaturdays')}</StyledIonLabel>
                                        <StyledIonCheckbox name="sendForNextDays" slot="start" onIonChange={(e) => {
                                            invitation.skipSaturdays = e.detail.checked;
                                        }}/>
                                    </IonItem>

                                    <IonItem lines="none">
                                        <StyledIonLabel
                                            className="ion-text-wrap">{t('planningTab.newInvitationPane.skipSundays')}</StyledIonLabel>
                                        <StyledIonCheckbox name="sendForNextDays" slot="start" onIonChange={(e) => {
                                            invitation.skipSundays = e.detail.checked;
                                        }}/>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        }

                        <IonRow>
                            <IonCol className="title" size="5.5">{t('planningTab.newInvitationPane.project')}</IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <StyledIonSelect placeholder={t("planningTab.newInvitationPane.selectProject")}
                                                 className={errors['endDate'] ? 'data-details extended bolded hasErrors' : 'data-details extended bolded'}
                                                 onClick={e => e.stopPropagation()}
                                                 justify="end"
                                                 interfaceOptions={
                                                     {
                                                         header: t('planningTab.newInvitationPane.project'),
                                                         cssClass: 'alert-full-option'
                                                     }
                                                 }
                                                 okText={t('common.selectOk')} cancelText={t('common.selectCancel')}
                                                 value={invitation.project} onIonChange={e => {
                                    handleSelectProject(e.detail.value);
                                }}>
                                    {
                                        projects?.map((project, i) =>
                                            <StyledIonSelectOption key={i}
                                                             value={project.id.toString()}>{project.name}</StyledIonSelectOption>
                                        )
                                    }
                                </StyledIonSelect>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="title"
                                    size="5.5">{t('planningTab.newInvitationPane.placeOfService')}</IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <StyledIonSelect placeholder={t("planningTab.newInvitationPane.selectPlaceOfService")}
                                                 disabled={!selectedProject}
                                                 justify="end"
                                                 interfaceOptions={
                                                     {
                                                         header: t('planningTab.newInvitationPane.placeOfService'),
                                                         cssClass: 'alert-full-option'
                                                     }
                                                 }
                                                 className={errors['endDate'] ? 'data-details extended bolded hasErrors' : 'data-details extended bolded'}

                                                 onClick={e => e.stopPropagation()}
                                                 okText={t('common.selectOk')} cancelText={t('common.selectCancel')}
                                                 value={invitation.placeOfService} onIonChange={e => {
                                    handleSelectPlaceOfService(e.detail.value);
                                }}>
                                    {
                                        placeOfServices?.map((placeOfService, i) =>
                                            <StyledIonSelectOption key={i}
                                                             value={placeOfService.id.toString()}>{placeOfService.name}</StyledIonSelectOption>
                                        )
                                    }
                                </StyledIonSelect>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                        <StyledField>
                            <label>{t('planningTab.newInvitationPane.numberOfPeople')}</label>
                            <StyledInput className="bolded no-margin"
                                         name="numberOfPeople"
                                         type="number"
                                         min={1}
                                         onChange={e => {
                                             invitation.numberOfPeople = parseInt(e.target.value);
                                         }}
                            ></StyledInput>
                            </StyledField>
                        </IonRow>

                        <IonRow>
                            <IonCol className="title"
                                    size="12">{t('planningTab.newInvitationPane.jobPosition')}<span>{t('planningTab.newInvitationPane.optional')}</span></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <StyledTextarea onChange={e => {
                                    invitation.jobPosition = e.target.value;
                                }} className="full-width"></StyledTextarea>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol className="title"
                                    size="12">{t('planningTab.newInvitationPane.message')}<span>{t('planningTab.newInvitationPane.optional')}</span></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <StyledTextarea onChange={e => {
                                    invitation.description = e.target.value;
                                }} className="full-width"></StyledTextarea>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <Form.Button type="submit" disabled={disabled}>
                        {t('planningTab.newInvitationPane.next')}
                    </Form.Button>
                </StyledNewInvitation>
            </StyledContent>
            </Form.Container>
        </StaticPane>
    );
};

export default NewInvitationPane;
