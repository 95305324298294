import {createContext, Dispatch, SetStateAction, useContext} from "react";

import {PlaceOfService, RideRoute} from "@models/travelAllowance/rideRoute";
import {Car} from "@models/travelAllowance/car";
import {Coordinator, Passenger, Ride, RideChange} from "@models/travelAllowance/ride";
import {RidePaneType} from "@enums/travelAllowance/ride";
import {RideMatrix} from "@models/travelAllowance/rideMatrix";

export type TRideFormContext = {
    type: RidePaneType,
    rideRoutes: RideRoute[],
    cars: Car[],
    passengers: Passenger[],
    rideMatrix: RideMatrix | undefined,
    rideDefaultValues?: Ride,
    coordinator: Coordinator | undefined;
    setCoordinator: Dispatch<SetStateAction<Coordinator | undefined>>;
    placeOfService: PlaceOfService | undefined;
    setPlaceOfService: Dispatch<SetStateAction<PlaceOfService | undefined>>;
    rideChanges?: RideChange
};

export const RideFormContext = createContext<TRideFormContext | undefined>(undefined);

export const useRideFormContext = () => {
    const RideFormContextData = useContext(RideFormContext);

    if (RideFormContextData === undefined) {
        throw new Error('useRideFormContext must be used with RideFormContext');
    }

    return RideFormContextData;
}