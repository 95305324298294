import {useState} from "react";
import {useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter} from "@ionic/react";

export const useLoadingOnViewChange = () => {
    const [loading, setLoading] = useState(true);

    useIonViewWillEnter(() => {
        setLoading(true);
    })

    useIonViewDidEnter(() => {
        setLoading(false);
    })

    useIonViewDidLeave(() => {
        setLoading(true);
    })

    return loading;
};

export default useLoadingOnViewChange;
