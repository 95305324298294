import React, {useEffect, useState} from 'react';
import {IonCol, IonLoading, IonRow} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {Controller, useFieldArray, useFormContext} from "react-hook-form";

import {StyledButtonOption} from '../../travelAllowance.style';
import {StyledInputGroup, StyledInputUnit} from "@components/form/input/input.style";

import {SubRouteFormField} from "@models/travelAllowance/rideRoute";

import {ReactComponent as AddIcon} from "@assets/images/travelAllowance/add.svg"
import {ReactComponent as BinIcon} from "@assets/images/travelAllowance/bin.svg"

import CityAutocomplete from "@components/google/cityAutocomplete/cityAutocomplete.component";
import {calculateDistance} from "@services/travelAllowance/rideRoute.service";
import useComponentMounted from "@hooks/useComponentMounted";

type SubRoutesFormFieldProps = {
    prefix?: 'ride.route' | 'backRide.route',
    setParentShouldFetchDistance?: (shouldFetchDistance: boolean) => void,
    disabled?: boolean,
}

const SubRoutesFormField: React.FC<SubRoutesFormFieldProps> = ({prefix, setParentShouldFetchDistance, disabled}) => {
    const {t} = useTranslation();

    const {control, setValue, watch, getValues, errors} = useFormContext();
    const {fields, append, remove, insert} = useFieldArray<SubRouteFormField>({
        control: control,
        name: prefix ? `${prefix}.subRoutes` : 'subRoutes',
    });
    const isMounted = useComponentMounted();

    const [shouldFetchDistance, setShouldFetchDistance] = useState<boolean>(false);
    const [showLoader, setShowLoader] = useState<boolean>(false);

    const generateFieldName = (prefix: string | undefined, fieldName: string) => {
        return prefix ? `${prefix}.${fieldName}` : fieldName;
    };

    const subRoutes = watch(generateFieldName(prefix, "subRoutes"));
    const startingPlace = watch(generateFieldName(prefix, "startingPlace"));
    const destinationPlace = watch(generateFieldName(prefix, "destinationPlace"));

    useEffect(() => {
        if (!isMounted) return;

        updateHiddenFields();
    }, [startingPlace, destinationPlace]);

    useEffect(() => {
        if (fields.length === 1 && !fields[0].visible) {
            remove(0);
        }
    }, [fields]);

    useEffect(() => {
        if (!shouldFetchDistance) return;

        let hasPlacesValid = true;

        subRoutes.forEach((subRoute: SubRouteFormField) => {
            if (subRoute.startingPlace === '' || subRoute.destinationPlace === '') {
                hasPlacesValid = false;
            }
        })

        if (!hasPlacesValid) {
            setShouldFetchDistance(false);
            return;
        }

        setShowLoader(true);

        fetchDistance()
            .finally(() => {
                setShouldFetchDistance(false);
                setShowLoader(false);
            })
    }, [shouldFetchDistance]);

    const fetchDistance = async () => {
        if (subRoutes.length > 1) {
            let distanceSum = 0;
            let index = 0;

            for (const subRoute of subRoutes) {
                const distanceData = await calculateDistance({
                    startingPlace: subRoute.startingPlace,
                    destinationPlace: subRoute.destinationPlace
                });

                setValue(generateFieldName(prefix, `subRoutes.${index}.distance`), distanceData.distance);

                distanceSum += distanceData.distance;
                index++;
            }

            setValue(generateFieldName(prefix, 'distance'), distanceSum, {shouldValidate: true});
        } else {
            if (setParentShouldFetchDistance) {
                setParentShouldFetchDistance(true);
            }
        }
    }

    const updateHiddenFields = () => {
        subRoutes.forEach((subRoute: SubRouteFormField, index: number) => {
            const startingPlaceField = generateFieldName(prefix, `subRoutes.${index}.startingPlace`);
            const destinationPlaceField = generateFieldName(prefix, `subRoutes.${index}.destinationPlace`);
            const startingPlaceCountryField = generateFieldName(prefix, `subRoutes.${index}.startingPlaceCountry`);
            const destinationPlaceCountryField = generateFieldName(prefix, `subRoutes.${index}.destinationPlaceCountry`);

            if (index > 0 && index !== subRoutes.length - 1) {
                setValue(startingPlaceField, getValues(generateFieldName(prefix, `subRoutes.${index - 1}.destinationPlace`)));
                setValue(startingPlaceCountryField, getValues(generateFieldName(prefix, `subRoutes.${index - 1}.destinationPlaceCountry`)));
            }

            if (index === 0) {
                setValue(startingPlaceField, getValues(generateFieldName(prefix, 'startingPlace')));
                setValue(startingPlaceCountryField, getValues(generateFieldName(prefix, 'startingPlaceCountry')));
            }

            if (index === subRoutes.length - 1) {
                setValue(startingPlaceField, getValues(generateFieldName(prefix, `subRoutes.${index - 1}.destinationPlace`)));
                setValue(startingPlaceCountryField, getValues(generateFieldName(prefix, `subRoutes.${index - 1}.destinationPlaceCountry`)));
                setValue(destinationPlaceField, getValues(generateFieldName(prefix, 'destinationPlace')));
                setValue(destinationPlaceCountryField, getValues(generateFieldName(prefix, 'destinationPlaceCountry')));
            }

            setShouldFetchDistance(true);
        });
    }

    const handleSubRouteAddNewRow = () => {
        if (fields.length === 0) {
            append({startingPlace: '', destinationPlace: '', startingPlaceCountry: '', destinationPlaceCountry: '', distance: 0, visible: true});
            append({startingPlace: '', destinationPlace: '', startingPlaceCountry: '', destinationPlaceCountry: '', distance: 0, visible: false});

            return;
        }

        const indexOfInvisible = fields.findIndex(subRoute => subRoute.visible === false);
        insert(indexOfInvisible, {startingPlace: '', destinationPlace: '', startingPlaceCountry: '', destinationPlaceCountry: '', distance: 0, visible: true});
        setValue(generateFieldName(prefix, 'distance'), undefined, {shouldValidate: true});

        updateHiddenFields();
    }

    const handleSubRouteDelete = (index: number) => {
        remove(index);

        updateHiddenFields();
    }

    const getNestedValue = (obj: Record<string, any>, path: string): any => path.split('.').reduce((acc, key) => acc?.[key], obj);

    return (
        <section>
            <IonLoading onDidDismiss={() => setShowLoader(false)} isOpen={showLoader}/>
            {
                (() => {
                    let visibleIndex = 0;

                    return fields.map((subRoute, index) => {
                        if (subRoute.visible !== false) {
                            visibleIndex++;

                            return (
                                <div key={subRoute.id}>
                                    <IonRow>
                                        <IonCol size="12" className="label mt-8 required">
                                            {t('travelAllowance.rideRoute.subRoute')} {visibleIndex}
                                        </IonCol>
                                    </IonRow>
                                    <StyledInputGroup
                                        className={prefix ? (getNestedValue(errors, `${prefix}.subRoutes.${index}`) ? 'error' : '') : (getNestedValue(errors, `subRoutes.${index}`) ? 'error' : '')}>
                                        <IonCol size={disabled ? "12" : "10"} className="label">
                                            <Controller
                                                name={generateFieldName(prefix, `subRoutes.${index}.startingPlace`)}
                                                control={control}
                                                render={(props) => <input type="hidden" {...props} />}
                                            />
                                            <Controller
                                                name={generateFieldName(prefix, `subRoutes.${index}.startingPlaceCountry`)}
                                                control={control}
                                                render={(props) => <input type="hidden" {...props} />}
                                            />
                                            <Controller
                                                name={generateFieldName(prefix, `subRoutes.${index}.destinationPlaceCountry`)}
                                                control={control}
                                                render={(props) => <input type="hidden" {...props} />}
                                            />
                                            <Controller
                                                name={generateFieldName(prefix, `subRoutes.${index}.distance`)}
                                                control={control}
                                                defaultValue={subRoute.distance}
                                                render={(props) => <input type="hidden" {...props} />}
                                                rules={{valueAsNumber: true}}
                                            />
                                            <Controller
                                                name={generateFieldName(prefix, `subRoutes.${index}.destinationPlace`)}
                                                control={control}
                                                defaultValue={subRoute.destinationPlace}
                                                rules={{required: true}}
                                                render={({onChange, value}) => (
                                                    <CityAutocomplete
                                                        onChangeCallback={(place: any) => {
                                                            onChange(place);
                                                            updateHiddenFields();
                                                        }}
                                                        disabled={disabled}
                                                        setCountry={(country) => setValue(generateFieldName(prefix, `subRoutes.${index}.destinationPlaceCountry`), country)}
                                                        defaultValue={value || subRoute.destinationPlace}
                                                        style={{borderRadius: "12px"}}
                                                    />
                                                )}
                                            />
                                        </IonCol>
                                        {
                                            !disabled &&
                                            <IonCol size="2" className="label" onClick={() => handleSubRouteDelete(index)}>
                                                <StyledInputUnit icon={true}>
                                                    <BinIcon/>
                                                </StyledInputUnit>
                                            </IonCol>
                                        }
                                    </StyledInputGroup>
                                </div>
                            );
                        }

                        return (
                            <React.Fragment key={subRoute.id}>
                                <Controller
                                    name={generateFieldName(prefix, `subRoutes.${index}.startingPlace`)}
                                    control={control}
                                    render={(props) => <input type="hidden" {...props} />}
                                />
                                <Controller
                                    name={generateFieldName(prefix, `subRoutes.${index}.startingPlaceCountry`)}
                                    control={control}
                                    render={(props) => <input type="hidden" {...props} />}
                                />
                                <Controller
                                    name={generateFieldName(prefix, `subRoutes.${index}.destinationPlace`)}
                                    control={control}
                                    render={(props) => <input type="hidden" {...props} />}
                                />
                                <Controller
                                    name={generateFieldName(prefix, `subRoutes.${index}.destinationPlaceCountry`)}
                                    control={control}
                                    render={(props) => <input type="hidden" {...props} />}
                                />
                                <Controller
                                    name={generateFieldName(prefix, `subRoutes.${index}.distance`)}
                                    control={control}
                                    defaultValue={subRoute.distance}
                                    render={(props) => <input type="hidden" {...props} />}
                                    rules={{valueAsNumber: true}}
                                />
                            </React.Fragment>
                        );
                    });
                })()
            }
            {
                !disabled &&
                <IonRow>
                    <IonCol size="12">
                        <StyledButtonOption>
                            <div className="btn-option center" onClick={() => handleSubRouteAddNewRow()}>
                                <AddIcon/>
                                <span>{t('travelAllowance.rideRoute.addSubRoute')}</span>
                            </div>
                        </StyledButtonOption>
                    </IonCol>
                </IonRow>
            }
        </section>
    );
};

export default React.memo(SubRoutesFormField);
