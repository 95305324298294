import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {StyledFileContainer, StyledFileContent} from "./file.style";
import {ReportRowFile} from "../../reportPage.component";
import {API_ENDPOINT} from "../../../../variables/variables";
import {StyledLightRedButton} from "../../../../components/button/button.style";
import {IonAlert} from "@ionic/react";

type ReportFileProps = {
    onRemove: (file: ReportRowFile) => void;
    file: ReportRowFile;
    reportPreview: boolean;
}

const ReportFile: React.FC<ReportFileProps> = (props: ReportFileProps) => {
    const {t} = useTranslation();
    const [showRemoveAlert, updateShowRemoveAlert] = useState<boolean>(false);

    return (
        <StyledFileContainer>
            <StyledFileContent>
                <div className="title">{props.file.original_name}</div>
                <span className="preview">
                    {
                        !props.file?.offline_base64 && props.file?.original_name && props.file?.extension != 'pdf' &&
                            <img src={API_ENDPOINT + 'file/uploads/' + props.file?.path}/>
                    }
                    {
                        props.file?.offline_base64 &&
                            <img src={'data:image/jpeg;base64, ' + props.file.offline_base64} />
                    }
                </span>
                {!props.reportPreview && <StyledLightRedButton
                    onClick={() => updateShowRemoveAlert(true)}
                    disabled={props.reportPreview}>
                    {t("reportPage.files.remove")}
                </StyledLightRedButton>}
            </StyledFileContent>

            <IonAlert
                isOpen={showRemoveAlert}
                onDidDismiss={() => updateShowRemoveAlert(false)}
                header={t('common.alertHeader')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            updateShowRemoveAlert(false);
                        },
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: async () => {
                            props.onRemove(props.file)
                            updateShowRemoveAlert(false);
                        }
                    }
                ]}
            />
        </StyledFileContainer>
    );
};

export default ReportFile;