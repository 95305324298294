import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IonAlert, IonLoading, isPlatform} from '@ionic/react';
import {Report, ReportRowFile} from '../reportPage.component';
import {StyledButton, StyledSocialButton} from "../../../components/button/button.style";
import {Camera, CameraResultType, CameraSource} from "@capacitor/camera";
import {changeBase46ToBlob, generateFileName, uploadFile} from "../../../services/file.service";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import moment from "moment";
import ReportFile from "../components/file/file.component";
import {StyledFilesContainer} from "../components/file/file.style";
import {CloseImage, AddImage} from "../../../utils/styles/icons";
import {StyledIonModal, StyledModalContent} from "../../../components/modal/modal.style";
import {StyledContent} from "./commentsModal.style";
import {getCurrentNetworkStatus} from "../../../services/offlineSync.service";

type FilesModalProps = {
    isOpen: boolean;
    reportPreview: boolean;
    report: Report;
    files: ReportRowFile[];
    onClose: () => void;
    onSubmit: (files: ReportRowFile[]) => void;
};

const FilesModal: React.FC<FilesModalProps> = (props: FilesModalProps) => {

    const {t} = useTranslation();
    const [showModal, updateShowModal] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);
    const [files, setFiles] = useState<ReportRowFile[]>([]);
    const [showLoading, updateShowLoading] = useState(false);

    const handleClose = () => {
        props.onClose();
    };

    const takeSystemPhoto = async (source: CameraSource) => {
        let hasPermissions = await Camera.checkPermissions();
        if (hasPermissions.camera === 'denied') {
            setShowPermissionAlert(true);
        } else {
            const image = await Camera.getPhoto({
                source: source,
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.Base64,
                width: 1024,
                height: 1024,
            });

            if (image.base64String) {
                saveFile(image.base64String)
            }
        }
    };

    const saveFile = async (base64String: string) => {
        if (base64String) {
            updateShowLoading(true);
            let filename = generateFileName(moment().format('YYYY-MM-DD H:m:s'), null, base64String);
            const networkStatus = await getCurrentNetworkStatus();
            if (!networkStatus) {
                let tmpFiles:ReportRowFile[] = files;
                let file = {
                    offline_base64: base64String,
                    original_name: filename,
                };

                tmpFiles = [...tmpFiles, file];
                setFiles(tmpFiles);
            } else {
                let blob = changeBase46ToBlob(base64String);
                await uploadFile(blob, filename)
                    .then(response => {
                        let tmpFiles:ReportRowFile[] = files;
                        for(let f of response.data.files) {
                            let file = f as ReportRowFile;

                            tmpFiles = [...tmpFiles, file];
                        }

                        setFiles(tmpFiles);
                    });
            }

            updateShowLoading(false);
        }
    }

    const removeFile = (file: ReportRowFile) => {
        const tmpFiles = files.filter((f) => file.path !== f.path);
        setFiles(tmpFiles);
    }

    useEffect(() => {
        handleOpen();
        setFiles(props.files);
    }, [props.isOpen, props.files]);

    const handleOpen = () => {
        updateShowModal(props.isOpen);
    };

    const handleSubmit = () => {
        props.onSubmit(files);
    };

    return (
        <StyledIonModal
            isOpen={showModal}
            onDidDismiss={() => {handleClose()}}
            initialBreakpoint={0.99}
            breakpoints={[0, 0.99]}
        >
            <StyledModalContent className="modal-wrap">
                <img src={CloseImage} className="close" onClick={() => handleClose() } />
                <StyledContent className="content full-height">
                    <StyledFilesContainer>
                        <div className="label">{t('reportPage.files.title')}</div>

                        {files && files.length > 0 && files.map((f, key) =>
                            <ReportFile
                                key={'file_'+key}
                                file={f}
                                onRemove={removeFile}
                                reportPreview={props.reportPreview}
                            />
                        )}

                        {!props.reportPreview && (isPlatform('ios') || isPlatform('android'))  && <StyledSocialButton onClick={() => {
                            takeSystemPhoto(CameraSource.Camera)
                        }}>
                            <img className="button-image" src={AddImage} /> {t('reportPage.files.takePhoto')}
                        </StyledSocialButton>}

                        {!props.reportPreview && <StyledSocialButton onClick={() => {
                            takeSystemPhoto(CameraSource.Photos)
                        }}>
                            <img className="button-image" src={AddImage} /> {t('reportPage.files.loadPhoto')}
                        </StyledSocialButton>}
                        {!props.reportPreview && <StyledButton onClick={handleSubmit}>{t('reportPage.files.save')}</StyledButton>}
                    </StyledFilesContainer>
                </StyledContent>
            </StyledModalContent>

            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
            <IonLoading onDidDismiss={() => updateShowLoading(false)} isOpen={showLoading} duration={10000}/>
        </StyledIonModal>
    );
};

export default FilesModal;