import { SplashScreen } from "@capacitor/splash-screen";
import { IonContent, IonPage, isPlatform, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import {StyledBlueButton} from "../../components/button/button.style";
import Logo from '../../components/logo';
import { StyledText, StyledTextLink } from '../../components/text';
import { StyledTextHref } from "../../components/text/text.styles";
import StyledTitle from '../../components/title/index';
import {PRIVACY_POLICY} from "../../variables/variables";
import AppUpdateModal from "../../modals/appUpdate/appUpdate.component";
import LanguageSwitcherModal from "../../modals/language/languageSwitcher.component";
import SupportRequestModal from "../../modals/supportRequest/supportRequest.component";
import { compareVersions } from "../../services/validation.service";
import { loginStatusBarUpdate } from "../../utils/tools/statusbar";
import { Links } from '../links';
import FacebookLogin from './components/facebookLogin.component';
import LinkLabel from "../../components/form/input/label.component";
import LoginForm from './form/loginForm.component';
import {
    StyledLoginContainer,
    StyledLoginFooterContainer,
    StyledLoginSecondTextContainer
} from './login.styles';
import FbIcon from "@assets/images/fb.svg";
import createdAccountIcon from "@assets/images/user-profile-add-square.svg";
import {Browser} from "@capacitor/browser";


const Login: React.FC = () => {
    const compareVersionsHandle = async () => {
        let version = null;

        await compareVersions().then(value => {
            version = value;
        });

        if (version) {
            updateShowAppUpdateModal(version);
        }
    }

    useIonViewWillEnter(() => {
        SplashScreen.hide();
        loginStatusBarUpdate();
        compareVersionsHandle();
    });

    
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [showSupportRequest, updateShowSupportRequest] = useState<boolean>(false);
    const [showAppUpdateModal, updateShowAppUpdateModal] = useState<boolean>(false);
    const [showLanguageModal, updateLanguageModal] = useState<boolean>(false);

    return (
        <IonPage>
            <IonContent fullscreen>
                <StyledLoginContainer>
                    <Logo />
                    <StyledTitle>{t("login.title")}</StyledTitle>
                    <LoginForm />

                    <StyledBlueButton onClick={() => history.push(Links.createAccount)}>
                        <img className={"position-left"} src={createdAccountIcon}/>
                        {t("login.createAccountLink")}
                    </StyledBlueButton>

                    {
                        !isPlatform('ios') &&
                        <>
                        <FacebookLogin/>
						</>
					}

                    <StyledLoginSecondTextContainer>
                        <StyledText>
                            <StyledTextLink to={Links.forgotPassword}>
                                &nbsp;{t("login.forgotPasswordLink")}
                            </StyledTextLink>
                        </StyledText>
                    </StyledLoginSecondTextContainer>

                    <StyledLoginSecondTextContainer>
                        <StyledText>
                            <StyledTextHref
                                onClick={() => history.push(Links.main + Links.offersOpen)}>{t("login.checkJobOffersWithoutLogin")}</StyledTextHref>
                        </StyledText>
                    </StyledLoginSecondTextContainer>

                    <StyledLoginFooterContainer>
                        <StyledText>
                            <LinkLabel urlString={t("loginPage.privacyPolicyLink")}
                                       url={PRIVACY_POLICY} external={true}>
                                <span>{t("loginPage.privacyPolicyLinkDesc")}</span>
                                &nbsp;
                                <StyledTextHref onClick={() => updateShowSupportRequest(true)}>{t("loginPage.helpDesk")}</StyledTextHref>
                            </LinkLabel>
                        </StyledText>
                    </StyledLoginFooterContainer>

                    <StyledLoginSecondTextContainer>
                        <StyledText>Copyright &copy; 2025 Exact x Forestall</StyledText>
                    </StyledLoginSecondTextContainer>
                    {/*<StyledLoginTextContainer>*/}
                    {/*    <StyledText>*/}
                    {/*        <StyledTextHref onClick={() => updateLanguageModal(true)}>{t(`languageTab.${i18n.language}`)}</StyledTextHref>*/}
                    {/*    </StyledText>*/}
                    {/*</StyledLoginTextContainer>*/}

                </StyledLoginContainer>
            </IonContent>
            <SupportRequestModal isOpen={showSupportRequest} onClose={() => updateShowSupportRequest(false)} />
            <AppUpdateModal isOpen={showAppUpdateModal} onClose={() => updateShowAppUpdateModal(false)} />
            <LanguageSwitcherModal isOpen={showLanguageModal} onClose={() => updateLanguageModal(false)} />



        </IonPage>
    );
};

export default Login;
