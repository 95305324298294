import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import Modal from '@components/modal/modal.component';
import {StyledModalContent} from '@components/modal/modal.style';
import Form from '@components/form';
import {StyledInput} from '@components/form/input/input.style';
import {StyledButton} from '@components/button/button.style';
import {DataType} from "@components/modal/paginatedListModal.component";
import {StyledContent} from "@app/reportPage/modals/commentsModal.style";

type PaginatedListManualModalProps = {
    isOpen: boolean;
    onClose: () => void;
    setValue: (value: DataType) => void;
};

const PaginatedListManualModal: React.FC<PaginatedListManualModalProps> = ({isOpen, onClose, setValue}: PaginatedListManualModalProps) => {
    const {t} = useTranslation();

    const {register, getValues} = useForm({
        mode: 'all'
    });
    const fieldName = 'manual';

    const handleClose = () => {
        onClose();
    };

    const handleSave = () => {
        const value = getValues(fieldName);

        setValue({
            id: Math.random(),
            name: value,
            manual: true,
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            disableClose={true}
            initialBreakpoint={0.5}
            breakpoints={[0, 0.5]}
            onDidDismiss={() => handleClose()}>
            <Form.Container className="modalForm">
                <StyledModalContent className="t-p-25">
                    <StyledContent className="content">
                        <div className="label">{t('manualModal.title')}</div>
                        <StyledInput name={fieldName} ref={register()}></StyledInput>
                        <StyledButton type="button" onClick={() => handleSave()}>{t('reportPage.manualModal.addButton')}</StyledButton>
                    </StyledContent>
                </StyledModalContent>
            </Form.Container>
        </Modal>
    );
};

export default PaginatedListManualModal;