import React, { PropsWithChildren, useEffect, useState } from 'react';
import { PaneProps, StyledStaticPaneContent } from './pane.style';

const StaticPane: React.FC<PropsWithChildren<PaneProps>> = (props: PropsWithChildren<PaneProps>) => {

    const [paddingBottom, updatePaddingBottom] = useState(props.paddingBottom);
    useEffect(() => {
        updatePaddingBottom(props.paddingBottom);
    }, [props.paddingBottom]);

    const [paddingTop, updatePaddingTop] = useState(props.paddingTop);
    useEffect(() => {
        updatePaddingTop(props.paddingTop);
    }, [props.paddingTop]);

    const [hideGrabber, updateHideGrabber] = useState(props.hideGrabber);
    useEffect(() => {
        updateHideGrabber(props.hideGrabber);
    }, [props.hideGrabber]);

    const [topEdge, updateTopEdge] = useState(props.topEdge);
    useEffect(() => {
        updateTopEdge(props.topEdge);
    }, [props.topEdge]);

    const [hasForm, updateHasForm] = useState(props.hasForm);
    useEffect(() => {
        updateHasForm(props.hasForm);
    }, [props.hasForm]);

    return (
        <StyledStaticPaneContent topEdge={topEdge} marginTop={props.marginTop} paddingBottom={paddingBottom} paddingTop={paddingTop} hideGrabber={hideGrabber} hasForm={hasForm}>
            {props.children}
        </StyledStaticPaneContent>
    );
};

export default StaticPane;