import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
    IonButtons,
    IonHeader,
    IonPage,
    useIonViewDidEnter,
} from '@ionic/react';
import {
    StyledIonContent,
    StyledIonTitle,
    StyledIonToolbar
} from '../../components/content/content.style';
import {
    StyledHeaderButton,
    StyledHeaderButtonImage,
} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import {useTranslation} from 'react-i18next';
import {StyledContent} from './manualBarcodePage.style';
import {getAddOrderConfiguration} from '../../services/qrCodePage.service';
import {useForm} from "react-hook-form";
import CheckImage from "../../assets/images/check.svg";
import StaticPane from "../../components/pane/static-pane.component";
import Form from "../../components/form";
import {StyledInput} from "../../components/form/input/input.style";

const AddNextOrderManualFindPage: React.FC = () => {

    const {t} = useTranslation();
    const history = useHistory();

    const handleBack = () => {
        history.goBack();
    };

    const configuration = getAddOrderConfiguration();
    if (!configuration) {
        handleBack();
    }

    const fieldName = "orderNumber";
    const [error, setError] = useState<string>();

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const { register, formState: {isValid}, getValues, handleSubmit } = useForm({
        mode: 'all'
    });

    const handleSave = async () => {
        if (configuration?.handleManualScan) {
            let error = await configuration?.handleManualScan(getValues(fieldName));
            if (typeof error == 'string') {
                setError(error);
            }
        }

    }

    useEffect(() => {
        updateHeight();
    });

    useIonViewDidEnter(() => {
        document.getElementsByName(fieldName)[0].focus();
    });

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current ?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle className="wrap-text">
                            <div className="wrap-text">
                                {t('manualOrderFind.title')}
                            </div>
                        </StyledIonTitle>
                        <IonButtons slot="end">
                            <StyledHeaderButton onClick={() => handleSave()}>
                                <StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                    <Form.Container onSubmit={handleSubmit(handleSave)}>
                        <StyledContent>
                            <h3 className="desc">{t("manualOrderFind.number")}</h3>
                            <StyledInput name={fieldName} className={error && 'hasErrors'} inputMode="text" ref={register({
                                required: true
                            })} />
                            {error && <p className="errorMessage">{t(error)}</p>}
                            <Form.Button type="submit" disabled={!isValid}>{t("manualOrderFind.addButton")}</Form.Button>
                        </StyledContent>
                    </Form.Container>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default AddNextOrderManualFindPage;
