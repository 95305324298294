import {sickLeaveApi} from "@services/api.service";

import {SickLeave} from "@models/sickLeave";

import {getWorkerNumber} from "@utils/tools/worker";

const getSickLeaveByDate = async (date: string, workerNumber: string | null = null): Promise<SickLeave[]> => {
    if (!workerNumber) {
        workerNumber = await getWorkerNumber();
    }

    const response: any = await sickLeaveApi.get(`sick-leaves/worker/${workerNumber}/by-date`, {
        params: {date: date},
    });

    return response.data;
}

export {
    getSickLeaveByDate,
};
