import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import Pane from '../../../../../components/pane/pane.component';
import {StyledNoOrders, StyledOrders} from './reports.style';
import {NoOrdersImage} from "../../../../../utils/styles/icons";
import AddImage from '../../../../../assets/images/e-add.svg';
import AvatarImage from '../../../../../assets/images/logo/x-gray.png';
import {ReportsImage} from "../../../../../utils/styles/icons";
import ArrowRightImage from '../../../../../assets/images/arrow-right-black.svg';
import {StyledButton, StyledRedButton, StyledSocialButton} from '../../../../../components/button/button.style';
import {Links} from '../../../../links';
import CircleImage, {ImageTypes} from "../../../../../components/circleImage/circleImage.component";
import {StyledEmptyContainer} from "../../../../../components/content/content.style";
import {IonAlert, IonBadge, IonIcon, IonToast, useIonViewWillEnter} from "@ionic/react";
import {isEmployed} from "../../../../../services/auth.service";
import {log} from "../../../../../services/firebaseAnalytics.service";
import {Report, ReportWorker} from "../../../../../models/raport";
import {
    filtersToParams,
    saveReport
} from "../../../../../services/report.service";
import {lockClosedOutline} from "ionicons/icons";
import {Filter} from "../../../../reportPage/components/filter/filterActionSheet.component";
import useNavigation from "../../../../../services/navigation.service";
import {Profile} from "../../../../../models/profile";
import {getPermission} from "../../../../../services/permission.service";
import {isCourseCompleted, isMandatoryCourseCompleted} from "../../../../../services/course.service";
import ReportsSkeleton from "@app/mainPage/tabs/reports/components/reportsSkeleton.component";
import {getOfflineDataStatus} from "../../../../../services/offlineSync.service";
import {Network} from "@capacitor/network";
import {
    getReports as getReportsFactory,
    removeReport as removeReportFactory
} from "../../../../../services/reportFactory.service";
import {ReportStatus} from "../../../../../enums/reportStatus";
import {Report as ReportForm} from "../../../../reportPage/reportPage.component";
import {
    getReports as getReportsOffline,
    removeReport as removeReportOffline
} from "../../../../../services/reportOffline.service";
import {EventType} from "../../../../../enums/eventType";
import {useEvent} from "../../../../../hooks/useEvent";
import {validate as validateUuid} from "uuid";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
const momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

type ReportsPaneProps = {
    topEdge?: number;
    status: string;
    filters?: Filter[];
    filtersName?: string[];
    profile?: Profile;
}

interface ReportsManager {
    setReports: Function;
    setFilters: Function;
    setNetworkStatus: Function;
}

const contextReports: ReportsManager = {
    setReports: () => {

    },
    setFilters: () => {

    },
    setNetworkStatus: () => {

    }
};

export const ReportsContext = React.createContext<ReportsManager>(contextReports);

const ReportsPane: React.FC<ReportsPaneProps> = (props: ReportsPaneProps) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [forceReload, setForceReload] = useState<boolean>(false);
    const [editPermission, setEditPermission] = useState<boolean>(false);
    const [reports, setReports] = useState<Report[]|undefined>(undefined);
    const [filters, setFilters] = useState<Filter[]>([]);
    const [showRemoveAlert, setShowRemoveAlert] = useState(false);
    const [selectedReport, setSelectedReport] = useState<Report | undefined>();
    const [hasReportsCourseCompleted, updateReportsCourseCompleted] = useState<boolean>(false);


    const [networkStatus, setNetworkStatus] = useState(true);
    const [isOfflineDataReady, updateIsOfflineDataReady] = useState<boolean>(false)

    const contextReports = useContext(ReportsContext);
    contextReports.setReports = setReports;
    contextReports.setFilters = setFilters;
    contextReports.setNetworkStatus = setNetworkStatus;

    const [employed, setEmployed] = useState<boolean>(false);
    const isEmployee = async() => {
        const response = await isEmployed();

        return response == '1';
    }

    const history = useHistory();
    const navigation = useNavigation(history);
    const {listen} = useEvent();

    const navigationToParams = () => {
        let params: string[] = [];
        if (props.filtersName) {
            props.filtersName.forEach(value => {
                if (navigation.getParam(value)) {
                    params.push(value + '=' + navigation.getParam(value))
                }
            });
        }

        return params.join('&');
    }

    const fetchUserData = async () => {
        let isEmployed: boolean = await isEmployee();
        setEmployed(isEmployed);
    }

    const fetchReportsCourseCompleted = async () => {
        if (!networkStatus && props.profile) {
            updateReportsCourseCompleted(false);
        } else {
            let isCompleted = await isMandatoryCourseCompleted();
            updateReportsCourseCompleted(isCompleted);
        }
    }

    const fetchReports = async (params: any = null) => {
        if ((reports === undefined && !loading) || forceReload) {
            setLoading(true);
            setReports([]);
            const tmpReports: Report[] = await getReportsFactory(props.status, params)
                .then(response => {
                    return response;
                })
                .catch(reason => {
                    return [];
                });

            setReports(tmpReports);
            setLoading(false);
            setForceReload(false)
        }
    }

    const fetchPermission = async () => {
        if (props.profile) {
            if (networkStatus) {
                await getPermission('REPORTS', 'EDIT')
                    .then(response => {
                        setEditPermission(response.data);
                    })
            } else {
                setEditPermission(true);
            }
        }
    }

    useEffect(() => {
        fetchPermission();
        fetchUserData();
        fetchReportsCourseCompleted();
        fetchReports(navigationToParams());

        listen(EventType['REPORT.LIST.RELOAD'], () => {
            setForceReload(true)
        });
    }, []);

    useEffect(() => {
        fetchReports(navigationToParams());
    }, [forceReload]);

    useEffect(() => {
        fetchPermission();
    }, [props.profile]);

    useEffect(() => {
        if (filtersToParams(filters)) {
            setForceReload(true);
        }
    }, [filters]);

    useIonViewWillEnter(() => {
        fetchUserData();
        fetchReports(navigationToParams());
        fetchReportsCourseCompleted();
    });

    useEffect(() => {
        const updateOfflineDataStatus = async () => {
            await getOfflineDataStatus()
                .then(response => {
                    updateIsOfflineDataReady(response.valid);
                });
        }

        updateOfflineDataStatus();
        setForceReload(true);
        fetchReports(navigationToParams());
        fetchReportsCourseCompleted();
        fetchPermission();
    }, [networkStatus]);

    useEffect(() => {
        const getCurrentNetworkStatus = async () => {
            await Network.getStatus()
                .then(result => {
                    setNetworkStatus(result.connected)
                });
        };

        getCurrentNetworkStatus();
    }, []);

	useEffect(() => {
		log('page_visit', {
			page: 'Raporty -> Raporty'
		});
	}, []);

    const addReport = () => {
        history.push(Links.qrCodeSpecification);
    };

    useEffect(() => {
		log('page_visit', {
			page: 'Raporty -> Raporty'
		});
	}, []);

    const removeDraft = async (report: Report) => {
        await removeReportFactory(report.id.toString(), report.offline);
        setForceReload(true);
    }

    return (
        <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
            {
                !employed && !props.profile &&
                <StyledEmptyContainer>
                    <CircleImage image={ImageTypes.REPORTS} color="grey"/>
                        <h3>{t('reportsTab.orders.soonAvailableNotWorker')}</h3>
                </StyledEmptyContainer>
            }
            {
                (employed || props.profile) &&
                    <>
                        {
                            ((!props.profile || (props.profile && editPermission))) &&
                            <StyledSocialButton id="reports-add-report" className={(!networkStatus && !isOfflineDataReady) || !hasReportsCourseCompleted ? 'inverted disabled' : 'inverted'} disabled={(!networkStatus && !isOfflineDataReady) || !hasReportsCourseCompleted} onClick={() => addReport()} >
                                {/* @todo disabled={!networkStatus && !isOfflineDataReady && !hasReportsCourseCompleted} bo był bug i nie działa mi z tym coś pobugowane pamiętac o tym*/}
                                <img src={AddImage}/>
                                {t('reportsTab.orders.addReportButton')}
                            </StyledSocialButton>
                        }
                        {
                            !loading && reports && reports.length === 0 &&
                            <StyledNoOrders>
                                {/* hide showJobOpportunitiesButton */}
                                <img src={NoOrdersImage}/>
                                <img className="reports" src={ReportsImage}/>
                                <span className="message">{t('reportsTab.orders.noReports')}</span>
                                {
                                    employed &&
                                    <StyledSocialButton className={!networkStatus ? 'auto-width disabled' : 'auto-width'} disabled={!networkStatus} onClick={() => history.push(Links.main+Links.offers)}>
                                        {t('reportsTab.orders.showJobOpportunitiesButton')}
                                    </StyledSocialButton>
                                }
                            </StyledNoOrders>
                        }
                        {
                            !loading && reports && reports.length !== 0 &&
                            <StyledOrders>
                                {
                                    reports.map((report: Report, i) => {
                                        let date = moment(report.service_date, moment.ISO_8601).tz('Europe/Warsaw');
                                        const previous: Report = reports[i - 1];
                                        let prevDate = previous && previous.service_date ? moment(previous.service_date, moment.ISO_8601).tz('Europe/Warsaw') : null;

                                        let sameDate = false;
                                        if (prevDate && date.format('DD.MM.YYYY') === prevDate?.format('DD.MM.YYYY')) {
                                            sameDate = true;
                                        }

                                        return <React.Fragment key={i}>
                                                {!sameDate && <div className="date-separator">
                                                    <div className="box">{date.format('DD.MM.YYYY')}</div>
                                                </div>}
                                                <div className="order">
                                                    <div className="order-number" onClick={() => {
                                                        if ((!props.profile && report.status !== 'OPEN' && report.status !== ReportStatus.OFFLINE_DRAFT && report.status !== ReportStatus.OFFLINE_READY_TO_SYNC) || props.profile) {
                                                            if ((!props.profile && report.is_editable) || (props.profile && editPermission)) {
                                                                history.push(`${Links.report}/${report.order.id}/${(report.ep2_scanned_specification_id ? report.ep2_scanned_specification_id : (report.order.specification ? report.order.specification.id: '0'))}/${report.id}/0/0`);
                                                            } else {
                                                                history.push(`${Links.report}/${report.order.id}/${(report.ep2_scanned_specification_id ? report.ep2_scanned_specification_id : (report.order.specification ? report.order.specification.id: '0'))}/${report.id}/1/0`);
                                                            }
                                                        }

                                                        if (report.status === ReportStatus.OFFLINE_DRAFT) {
                                                            history.push(`${Links.report}/${report.order.id}/${(report.ep2_scanned_specification_id ? report.ep2_scanned_specification_id : (report.order.specification ? report.order.specification.id: '0'))}/${report.id}/0_offline/0`);
                                                        }
                                                    }}>
                                                        {(report.status !== ReportStatus.OFFLINE_DRAFT && !report.is_editable && !props.profile) && <IonIcon src={lockClosedOutline} style={{"height": "15px", "fontSize": "14px", "color": '#888A98', "paddingRight": "10px"}} />}
                                                        {t('reportsTab.orders.report')} {report.formatted_number} { ((report.status !== 'OPEN' || props.profile) && report.is_editable) && <img src={ArrowRightImage}/> }
                                                        {report.status === 'DRAFT' && <><br /><IonBadge color="warning" className="draft">{t('reportsTab.orders.status.draft')}</IonBadge></>}
                                                        {report.status === 'NOT_VERIFIED' && <><br /><IonBadge color="warning" className="draft">{t('reportsTab.orders.status.not_verified')}</IonBadge></>}
                                                        {report.status === 'OPEN' && <><br /><IonBadge color="success" className="draft">{t('reportsTab.orders.status.verified')}</IonBadge></>}
                                                        {report.status === ReportStatus.OFFLINE_DRAFT && <><br /><IonBadge color="warning" className="draft">{t(!report.multiple_orders ? (!report.online_backup ? 'reportsTab.orders.status.offline' : 'reportsTab.orders.status.offline_backup') : 'reportsTab.orders.status.multiple_orders_offline')}</IonBadge></>}
                                                        {report.status === ReportStatus.OFFLINE_READY_TO_SYNC && <><br /><IonBadge color="warning" className="draft">{t('reportsTab.orders.status.offline_ready_to_sync')}</IonBadge></>}
                                                    </div>
                                                    <div className="members">
                                                        {
                                                            (report.status !== 'OPEN' || props.profile) && report.workers && report.workers.length > 0 && report.workers.map((person: ReportWorker, i) =>
                                                                <img
                                                                    src={person.user && (person.user?.photos || person.user?.avatar) ? (person.user.photos?.thumbnail ? person.user.photos.thumbnail : person.user.avatar) : AvatarImage}
                                                                    key={i}/>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="details">
                                                        {report.components.numbers.join(',') &&
                                                        <div
                                                            className="detail">{t('reportsTab.orders.componentsNumbers')}<span
                                                            className="value">{report.components.numbers.join(',')}</span>
                                                        </div>}
                                                        {report.components.names.join(',') &&
                                                        <div
                                                            className="detail">{t('reportsTab.orders.componentsNames')}<span
                                                            className="value">{report.components.names.join(',')}</span>
                                                        </div>}
                                                        <div className="detail">{t('reportsTab.orders.placeOfService')}<span
                                                            className="value">{report.place_of_service}</span></div>
                                                        {report.order_factory_number &&
                                                        <div className="detail">{t('reportsTab.orders.factoryNumber')}<span
                                                            className="value">{report.order_factory_number}</span></div>}
                                                        {report.order.complaint_number &&
                                                        <div className="detail">{t('reportsTab.orders.complaintNumber')}: <span
                                                            className="value">{report.order.complaint_number}</span></div>}
                                                        {report.checked_parts_sum > 0 && <div className="detail">
                                                            {t('reportsTab.orders.componentsSum')}
                                                            <span className="value">
                                                                {report.checked_parts_sum}
                                                            </span>
                                                        </div>}
                                                        { (report.status !== 'OPEN' || props.profile) && report.limits && report.limits.type === 'PARTS_QUANTITY' &&
                                                            <div className="detail">{t('reportsTab.orders.partsLimitAlreadyUsed')}: <span className={"value" + " " + report.limits.textClass}>
                                                                    {report.limits.current} / {report.limits.value}
                                                                </span>
                                                            </div>
                                                        }
                                                        { (report.status !== 'OPEN' || props.profile) && report.hours_sum_humanized &&
                                                            <div className="detail">
                                                                {t('reportsTab.orders.reportedHours')}
                                                                <span className="value">
                                                                {report.hours_sum_humanized}
                                                            </span>
                                                            </div>
                                                        }
                                                        { (report.status !== 'OPEN' || props.profile) && report.limits && report.limits.type === 'HOURS_LIMIT' &&
                                                            <div className="detail">{t('reportsTab.orders.hoursLimitAlreadyUsed')}: <span className={"value" + " " + report.limits.textClass}>
                                                                    {report.limits.current} / {report.limits.value}
                                                                </span>
                                                            </div>
                                                        }
                                                        { (report.status !== 'OPEN' || props.profile) && report.order.specification && report.avg_time_per_component &&
                                                        <div
                                                            className="detail">{t('reportsTab.orders.averageInspectionTime')}<span
                                                            className="value">{report.avg_time_per_component}s</span></div>}

                                                        <div className="detail">
                                                            {t('reportsTab.orders.createdBy')}
                                                            <span className="value">
                                                                {report.created_by}
                                                            </span>
                                                        </div>

                                                    </div>

                                                    {report.status === 'DRAFT' && ((!props.profile && report.is_editable) || (props.profile && editPermission && report.is_editable)) &&
                                                        <>
                                                            <StyledButton onClick={() => {
                                                                history.push(`${Links.report}/${report.order.id}/${(report.ep2_scanned_specification_id ? report.ep2_scanned_specification_id : (report.order.specification ? report.order.specification.id: '0'))}/${report.id}/0/0`);
                                                            }}>
                                                                {t('reportsTab.orders.continue')}
                                                            </StyledButton>
                                                        </>
                                                    }

                                                    {report.status === ReportStatus.OFFLINE_DRAFT &&
                                                        <>
                                                            <StyledButton onClick={() => {
                                                                history.push(`${Links.report}/${report.order.id}/${(report.ep2_scanned_specification_id ? report.ep2_scanned_specification_id : (report.order.specification ? report.order.specification.id: '0'))}/${report.id}/0_offline/0`);
                                                            }}>
                                                                {t('reportsTab.orders.continue')}
                                                            </StyledButton>
                                                        </>
                                                    }

                                                    {report.status === 'DRAFT' && !props.profile && report.is_editable && (report.order.is_extended_reporting || report.is_worker_report_delation_enabled) &&
                                                        <>
                                                            <StyledRedButton onClick={() => {
                                                                setSelectedReport(report);
                                                                setShowRemoveAlert(true);
                                                            }}>
                                                                {t('reportsTab.orders.remove')}
                                                            </StyledRedButton>
                                                        </>
                                                    }

                                                    {report.status === ReportStatus.OFFLINE_DRAFT && validateUuid(report.id.toString()) &&
                                                        <>
                                                            <StyledRedButton onClick={() => {
                                                                setSelectedReport(report);
                                                                setShowRemoveAlert(true);
                                                            }}>
                                                                {t('reportsTab.orders.remove')}
                                                            </StyledRedButton>
                                                        </>
                                                    }
                                                </div>
                                            </React.Fragment>
                                        }
                                    )
                                }
                            </StyledOrders>
                        }
                        {
                            loading && <ReportsSkeleton/>
                        }
                    </>
            }

            <IonAlert
                isOpen={showRemoveAlert}
                onDidDismiss={() => {
                    setSelectedReport(undefined);
                    setShowRemoveAlert(false);
                }}
                header={t('common.alertHeader')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowRemoveAlert(false);
                        }
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: () => {
                            if (selectedReport) {
                                removeDraft(selectedReport);
                            }
                        }
                    }
                ]}
            />
        </Pane>
    );
};

export default ReportsPane;
