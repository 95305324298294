import {appApi} from "./api.service";
import {ContractData} from "../models/contractData";
import {Preferences} from "@capacitor/preferences";
import {WorkerWarehouseTransfer} from "../models/worker";
import {getCurrentNetworkStatus} from "@services/offlineSync.service";

let endpointPrefix = 'worker/courses';

const getCoursesLoginLink = async () => {
	return appApi.get(endpointPrefix + '/get-login-link');
}

const setReadMoodleInfo = async () => {
	await Preferences.set({
		'key': 'read_moodle_info',
		'value': '1'
	});
	return appApi.post(endpointPrefix + '/set-read-moodle-info');
}

const getCoursesByName = async (name:string) => {
	return appApi.get(endpointPrefix + '/get-by-name/' + name);
}

const isCourseCompleted = async (name:string) => {
	let networkStatus = await getCurrentNetworkStatus();
	if (!networkStatus) {
		return true;
	}

	let course = await getCoursesByName(name);

	if (course.data.id && course.data.completed == true) {
		return true;
	}

	return false;
}

const isMandatoryCourseCompleted = async () => {
	let networkStatus = await getCurrentNetworkStatus();
	if (!networkStatus) {
		return true;
	}

	let result = await appApi.get(endpointPrefix + '/get-mandatory-course-result');

	if (result.data.completed == true) {
		return true;
	}

	return false;
}

export {
	getCoursesLoginLink,
	setReadMoodleInfo,
	getCoursesByName,
	isCourseCompleted,
	isMandatoryCourseCompleted
};
