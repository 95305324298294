import styled from 'styled-components';
import { StyledModalContent } from '../modal/modal.style';

export const StyledContent = styled(StyledModalContent)`
  height: auto;
  padding-top: 24px;  
  
  &.withFooter {
    
  }

  .title {
    width: 100%;
  }


  h1.title {
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    display: inline-block;
  }

  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;

    &.required:after {
      content: '*';
      color: red;
    }
  }

  .date-field {
    position: relative;

    img.calendar {
      position: absolute;
      width: 15px;
      right: 15px;
      top: 31%;
    }
  }
`;

export const StyledAccordion = styled.section`

  .row {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    text-wrap: nowrap;

    .title {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .toggle {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      padding: 0;
    }
  }

  hr {
    width: 100%;
    border-bottom: 1px solid #888A9852;
  }

  .aaa {
    text-align: center;
    padding: 12px 0;
}

.aaa :not(:first-child) {
    text-align: left;
}

  .answer-buttons {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    width: 103%;
    margin-left: -2%;
    padding: 12px 0;
  }

  ion-item {
    --padding-start: 0;
    --inner-border-width: 0;
  }

  ion-col {
    // padding: 10px;
  }

  ion-checkbox {
    --size: 24px;
    --background-checked: #0085FF;
    margin: 0 9px 0 0;
  }

  ion-checkbox::part(container) {
    border-radius: 4px;
    border: 1px solid #888A9852;
  }

  ion-label {
    font-size: 15px;
  }
  .select {
    background-color: rgb(244, 244, 249);
    border-color: rgb(244, 244, 249);
    width: 100%;
    border-radius: 12px !important;

    input {
      all: unset;
      font-weight: 600;
      color: #000000;
      border-radius: 12px;
      border-color: rgb(244, 244, 249);
    }

    img.down {
      display: none;
    }
  }

  ion-select {
    --placeholder-color: black;
    --placeholder-opacity: 1;
    border: 1px solid rgb(244, 244, 249);
    width: 100%;
    // justify-content: space-between;
    padding: 9px;
    border-radius: 12px;
    //margin-left: -5px;
  }

  .select-icon {
    color: #949494;
  }
    
  ion-select::part(text) {
    color: black;
    font-size: 12px;
  }
  ion-select::part(placeholder) {
    color: black;
    font-size: 12px;
  }
  
  ion-select::part(icon) {
    color: #949494;
  }

  .label {
    font-size: 12px;
  }

  .chose-other {
    display: flex;
    align-items: center;
    margin: 8px 0;

    hr {
      width: 100%;
      border-bottom: 1px solid #888A98;
    }

    .one-line {
      font-size: 11px;
      white-space: nowrap;
      padding: 0 8px;
    }
  }

  .no-padding {
    --inner-padding-end: 0;
  }

  .input-style {
    all: unset;
    display: block;
    width: 108%;
  }
  .input {
    all: unset;
    border: 1px solid #888A9852;
    border-radius: 4px;
    height: 42px;
    padding: 0;
    width: 108%;
  }

  .new-input {
    background: #F4F4F9;
    border-radius: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 0px 20px !important;
    margin-bottom: 8px;
    width: 100%;
    position: relative;
    color: #000000;
  }

  .new-input-szt {
    background: #F4F4F9;
    border-radius: 12px 0 0 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 0px 20px !important;
    width: 100%;
    position: relative;
    color: #000000;
    border-radius: 12px 0 0 12px;
    height: 45px;
  }

  .textarea-style {
    all: unset;
    display: flex;
    flex-direction: row;
    display: block;
    width: 108%;
  }
  .textarea {
    all: unset;
    border: 1px solid #888A9852;
    border-radius: 4px;
    padding: 0;
    width: 108%;
  }

  .gap-style {
    margin: 15px 0;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .color-0085FF {
    color: #0085FF;
  }

  .second-accordion {
    width: 100%;
    margin: 8px 0;

    .two-input-style {
      width: 100%;
      display: flex;
      flex-direction: column;
      --inner-padding-end: 0;

      .input {
        border-width: 1px;
        border-color: #888A9852;
        border-style: solid;
        border-radius: 4px 0 0 4px;
        flex-grow: 1;
      }
  
      .szt-input {
        background-color: #888A9852;
        color: #262532;
        font-size: 12px;
        border-radius: 0 4px 4px 0;
        padding: 15px;
        flex-shrink: 0;
      }
    }
  }

  .arrow-down {
    background-color: #D7EAFB;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 50%;
    }
  }

  .mb-8 {
    margin-bottom: 8px;
  }
  .mb-24 {
    margin-bottom: 24px;
  }
  .mt-8 {
    margin-top: 8px;
  }

  .range-values {
    display: flex;
    justify-content: space-between;
  }

  .range-frame {
    border: 1px solid #888A9852;
    margin-top: 8px;
    padding: 10px;
    border-radius: 4px;
  }

  .textarea-width {
    width: 100%;
  }

  .worker-row {
    .select {
      border: 1px solid #F4F4F9;
      background: #F4F4F9;
      border-radius: 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      padding: 14px 20px;
      margin-bottom: 8px;
      width: 100%;
      position: relative;
      color: #000000;
    }
    .trash {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;

      img {
        width: 14px;
      }
    }
  }

  .select-full-width {
    border: 1px solid #F4F4F9;
    background: #F4F4F9;
    border-radius: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 3px 0px;
    margin-bottom: 8px;
    width: 100%;
    position: relative;
    color: #000000;
    font-weight: 600;
  }

  no-padding {
    --inner-padding-end: 0 !important;
  }

  .hasErrors {
    border: 1px solid #F43030 !important;
  }

  .green {
    color: #00C917;
  }

  .red {
    color: #FE0200;
  }

  .padding-0-10 {
    padding: 0 10px;
  }


`

