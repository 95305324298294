import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledIonModal, StyledModalContent} from '../../../components/modal/modal.style';
import { StyledFooter, StyledPaneBar, StylePaneGrabber } from '../../../components/pane/pane.style';
import {IonAlert, IonCol, IonContent, IonGrid, IonRow, useIonViewWillLeave} from '@ionic/react';
import { StyledTextarea } from '../../../components/form/input/textarea.style';
import { StyledContent } from './commentsModal.style';
import { ReportRowComments } from '../reportPage.component';
import {StyledButton} from "../../../components/button/button.style";
import {BarcodeScanner} from "@capacitor-community/barcode-scanner";
import QrScannerModal from "./qrScannerModal.component";
import {CloseImage, QrImage} from "../../../utils/styles/icons";

type CommentsModalProps = {
    isOpen: boolean;
    reportPreview: boolean;
    comments: ReportRowComments;
    onClose: () => void;
    onSubmit: (comments: ReportRowComments) => void;
};

const CommentsModal: React.FC<CommentsModalProps> = (props: CommentsModalProps) => {

    const {t} = useTranslation();
    const [showComments, updateShowComments] = useState<boolean>(false);
    const [comment, setComment] = useState<string>(props.comments.comment);
    const [partNumber, setPartNumber] = useState<string>(props.comments.partNumber);
    const [additionalInfoA, setAdditionalInfoA] = useState<string>(props.comments.additionalInfoA);
    const [additionalInfoB, setAdditionalInfoB] = useState<string>(props.comments.additionalInfoB);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);
    const [scanField, setScanField] = useState<string>('');
    const [showScanModal, setShowScanModal] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
        stopScan();
    };

    useEffect(() => {
        handleOpen();
    }, [props.isOpen, props.comments]);

    const handleOpen = () => {
        updateShowComments(props.isOpen);
        setComment(props.comments.comment);
        setPartNumber(props.comments.partNumber);
        setAdditionalInfoA(props.comments.additionalInfoA);
        setAdditionalInfoB(props.comments.additionalInfoB);
        setScanField('');
        setShowScanModal(false);
    };

    const onSubmit = async () => {
        props.onSubmit({
            comment,
            partNumber,
            additionalInfoA,
            additionalInfoB
        });
    };

    useIonViewWillLeave(() => {
        stopScan();
    });

    const handleScan = async (value: string) => {
        if (scanField) {
            if (scanField == 'comment') {
                setComment((comment !== null ? comment : '') + ' ' + value + '\r\n')
            }

            if (scanField == 'partNumber') {
                setPartNumber((partNumber !== null ? partNumber : '') + ' ' + value + '\r\n')
            }

            if (scanField == 'additionalInfoA') {
                setAdditionalInfoA((additionalInfoA !== null ? additionalInfoA : '') + ' ' + value + '\r\n')
            }

            if (scanField == 'additionalInfoB') {
                setAdditionalInfoB((additionalInfoB !== null ? additionalInfoB : '') + ' ' + value + '\r\n')
            }
            stopScan();

            setShowScanModal(false);
            setScanField('');
        }
    };

    const startScan = async (field: string) => {
        setScanField(field);
        setShowScanModal(true);
    };

    const checkPermission = async () => {
        // check or request permission
        const status = await BarcodeScanner.checkPermission({ force: true });

        if (status.granted) {
            // the user granted permission
            return true;
        }

        return false;
    };

    const stopScan = () => {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
    };

    const scanComment = async (field: string) => {
        let hasPermission = await checkPermission();
        if (!hasPermission) {
            setShowPermissionAlert(true);
        }
        startScan(field);
    }

    return (
        <StyledIonModal
            isOpen={showComments}
            onDidDismiss={() => {handleClose()}}
            initialBreakpoint={0.99}
            breakpoints={[0, 0.65, 0.99]}
        >
                <StyledModalContent className="modal-wrap">
                    <img src={CloseImage} className="close" onClick={() => handleClose() } />
                    <StyledContent className="content">
                        <IonGrid>
                            <IonRow className="label">
                                <IonCol size="9">
                                    {t("reportPage.commentsModal.comment")}
                                </IonCol>
                                <IonCol size="3" className="scanComment">
                                    {!props.reportPreview && <div onClick={() => scanComment('comment')}>
                                        <span>{t("reportPage.commentsModal.scan")}</span> <img src={QrImage} />
                                    </div>}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <StyledTextarea
                                        disabled={props.reportPreview}
                                        value={comment}
                                        onChange={e => setComment(e.target.value)}
                                    ></StyledTextarea>
                                </IonCol>
                            </IonRow>
                            <IonRow className="label">
                                <IonCol size="9">
                                    {t("reportPage.commentsModal.partNumber")}
                                </IonCol>
                                <IonCol size="3" className="scanComment">
                                    {!props.reportPreview && <div onClick={() => scanComment('partNumber')}>
                                        <span>{t("reportPage.commentsModal.scan")}</span> <img src={QrImage} />
                                    </div>}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <StyledTextarea
                                        disabled={props.reportPreview}
                                        value={partNumber}
                                        onChange={e => setPartNumber(e.target.value)}
                                    ></StyledTextarea>
                                </IonCol>
                            </IonRow>
                            <IonRow className="label">
                                <IonCol size="9">
                                    {t("reportPage.commentsModal.additionalInfoA")}
                                </IonCol>
                                <IonCol size="3" className="scanComment">
                                    {!props.reportPreview && <div onClick={() => scanComment('additionalInfoA')}>
                                        <span>{t("reportPage.commentsModal.scan")}</span> <img src={QrImage} />
                                    </div>}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <StyledTextarea
                                        disabled={props.reportPreview}
                                        value={additionalInfoA}
                                        onChange={e => setAdditionalInfoA(e.target.value)}
                                    ></StyledTextarea>
                                </IonCol>
                            </IonRow>
                            <IonRow className="label">
                                <IonCol size="9">
                                    {t("reportPage.commentsModal.additionalInfoB")}
                                </IonCol>
                                <IonCol size="3" className="scanComment">
                                    {!props.reportPreview && <div onClick={() => scanComment('additionalInfoB')}>
                                        <span>{t("reportPage.commentsModal.scan")}</span> <img src={QrImage} />
                                    </div>}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <StyledTextarea
                                        disabled={props.reportPreview}
                                        value={additionalInfoB}
                                        onChange={e => setAdditionalInfoB(e.target.value)}
                                    ></StyledTextarea>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </StyledContent>
                <StyledFooter className="modal footer no-shadow">
                    {
                        !props.reportPreview &&
                        <StyledButton onClick={onSubmit}>{t("reportPage.commentsModal.updateButton")}</StyledButton>
                    }
                </StyledFooter>
            </StyledModalContent>
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            BarcodeScanner.openAppSettings();
                        }
                    }
                ]}
            />
            <QrScannerModal isOpen={showScanModal} onClose={() => {
                setShowScanModal(false);
                setScanField('');
            }} onSubmit={(value) => {
                handleScan(value);
            }} />
        </StyledIonModal>
    );
};

export default CommentsModal;