import {appApi} from "./api.service";
import {File as CommonFile, FileApiFormat} from "@models/common";

let endpointPrefix = 'file';

const uploadFile = async (file: Blob | File, name: string) => {
    let formData = new FormData();
    formData.append("files[]", file, name);
    return appApi.post(endpointPrefix + '/upload', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "No-Loader": true,
        }
    });
}

const b64toBlob = (b64Data: string, contentType: string, sliceSize: any) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
}

const changeBase46ToBlob = (imageData: string) => {
    var imageURL = 'data:image/jpeg;base64,' + imageData;
    var block = imageURL.split(";");
    var contentType = block[0].split(":")[1];
    var realData = block[1].split(",")[1];
    return b64toBlob(realData, contentType, null);
}

const base64FileHeaderMapper = (fileBase64: string) => {

    let fileHeader = new Map();

    //get the first 3 char of base64
    fileHeader.set("/9j", "jpg")
    fileHeader.set("iVB", "png")
    fileHeader.set("Qk0", "bmp")
    fileHeader.set("SUk", "tiff")
    fileHeader.set("JVB", "pdf")
    fileHeader.set("UEs", "ofd")

    let res = ""

    fileHeader.forEach((v, k) => {
        if (k == fileBase64.substr(0, 3)) {
            res = v
        }
    })

    return res;
}

const generateFileName = (folderName: string, rcp: string | null = null, base64: string | null = null) => {
    if (!rcp) {
        rcp = Math.random().toString(36).substring(3);
    }

    let ext = 'jpg'
    if (base64) {
        ext = base64FileHeaderMapper(base64);
    }
    return folderName + '-' + rcp + '.' + ext;
}

const getExtension = (filename: string) => {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : ext[1];
}

const blobToBase64 = (blob: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};

const prepareFileForApiFormat = (file: CommonFile): FileApiFormat => {
    const {extension, hash, mime_type, original_name, size, path} = file;

    return {
        extension,
        hash,
        mimeType: mime_type,
        originalName: original_name,
        size,
        path,
    }
}

const downloadFile = (blob:Blob, filename:string) => {
	const url = window.URL.createObjectURL(new Blob([blob]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute(
		'download',
		filename,
	);
	document.body.appendChild(link);
	link.click();
	link.parentNode?.removeChild(link);
}

export {
    changeBase46ToBlob,
    generateFileName,
    getExtension,
    blobToBase64,
    uploadFile,
    base64FileHeaderMapper,
    prepareFileForApiFormat,
    downloadFile
}



