import React, {useEffect, useState} from 'react';
import {IonCol, IonRow, IonToast} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {useFieldArray, useFormContext} from "react-hook-form";

import {StyledButtonOption} from '../../travelAllowance.style';
import {StyledInput, StyledInputGroup, StyledInputUnit} from "@components/form/input/input.style";
import PaginatedListModal from "@components/modal/paginatedListModal.component";

import {Coordinator, Passenger, PassengerFormField} from "@models/travelAllowance/ride";
import {PlaceOfService} from "@models/travelAllowance/rideRoute";

import {ReactComponent as AddIcon} from "@assets/images/travelAllowance/add.svg"
import {ReactComponent as BinIcon} from "@assets/images/travelAllowance/bin.svg"

import {getPossiblePassengers} from "@services/travelAllowance/ride.service";
import useComponentMounted from "@hooks/useComponentMounted";

type PassengersFormFieldProps = {
    prefix: 'ride' | 'backRide',
    onChange: (passengers: Passenger[]) => void,
    defaultPassengers?: PassengerFormField[],
    coordinator?: Coordinator,
    placeOfService?: PlaceOfService,
}

const PassengersFormField: React.FC<PassengersFormFieldProps> = ({
                                                                     prefix,
                                                                     onChange,
                                                                     defaultPassengers,
                                                                     coordinator,
                                                                     placeOfService
                                                                 }: PassengersFormFieldProps) => {
    const isMounted = useComponentMounted();
    const {t} = useTranslation();
    const {register, control} = useFormContext();
    const {fields, append, insert, remove} = useFieldArray<PassengerFormField>({
        control: control,
        name: `${prefix}.passengers`,
    });

    const [passengerCount, setPassengerCount] = useState(0);
    const [currentIndex, setCurrentIndex] = useState<number>();

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');
    const [isPassengerModalOpen, setIsPassengerModalOpen] = useState<boolean>(false);

    useEffect(() => {
        handleDefaultPassengers();
    }, [defaultPassengers]);

    useEffect(() => {
        if (!isMounted) return;

        handlePassengersSet();
    }, [passengerCount]);

    const handleDefaultPassengers = () => {
        if (defaultPassengers !== undefined) {
            if (defaultPassengers.length === 0) {
                remove([...Array.from(Array(fields.length).keys())]);
                setPassengerCount(0);
            }

            defaultPassengers.forEach(defaultPassenger => {
                const isPassenger = fields.some((passenger) => passenger.workerId === defaultPassenger.workerId);

                if (!isPassenger) {
                    append({
                        workerId: defaultPassenger.workerId,
                        name: defaultPassenger.name
                    });
                }
            })
        }
    }

    const handlePassengersSet = () => {
        const selectedPassengers: Passenger[] = [];

        fields.forEach((passenger) => {
            if (passenger.workerId !== undefined && passenger.name !== undefined) {
                selectedPassengers.push({
                    id: passenger.workerId,
                    name: passenger.name,
                })
            }
        })

        if (onChange !== undefined) {
            onChange(selectedPassengers);
        }
    }

    const handlePassengerSelect = (passengerValue: Passenger) => {
        const isPassengerSelected = fields.some((passenger) => {
            return passenger.workerId !== undefined && passenger.workerId.toString() === passengerValue.id.toString();
        })

        if (!isPassengerSelected) {
            if (currentIndex !== undefined) {
                remove(currentIndex);
                insert(currentIndex, {
                    workerId: passengerValue.id,
                    name: passengerValue.name
                });
            } else {
                append({
                    workerId: passengerValue.id,
                    name: passengerValue.name
                });
            }

            setPassengerCount(prevCount => prevCount + 1);
        } else {
            setToast(t("travelAllowance.ride.passengerAlreadySelected"));
            setShowToast(true);
        }
    }

    const handlePassengerAdd = () => {
        const values = control.getValues();

        let count;
        if (!('passengers' in values[prefix])) {
            count = 0;
        } else {
            count = values[prefix].passengers.length;
        }

        if (count === 4) {
            setToast(t("travelAllowance.ride.passengerLimitReached"));
            setShowToast(true);
            return;
        }

        if (placeOfService) {
            setIsPassengerModalOpen(prevState => !prevState);
        }
    }

    const handlePassengerRemove = (index: number) => {
        remove(index);
        setPassengerCount(prevCount => prevCount - 1);
    }

    const handleClosePassengerModal = () => {
        setIsPassengerModalOpen(false);
    }

    return (
        <section>
            <IonToast isOpen={showToast}
                      onDidDismiss={() => setShowToast(false)}
                      message={toast}
                      duration={6000}
                      position="top"
                      color="danger"
            />
            <PaginatedListModal isOpen={isPassengerModalOpen}
                                fetchData={getPossiblePassengers}
                                fetchParams={{coordinatorId: coordinator?.id, placeOfServiceId: placeOfService?.id, date: control.getValues().ride?.dateStart, cancelToken: undefined}}
                                setValue={handlePassengerSelect}
                                onClose={handleClosePassengerModal}
            />
            {
                fields.map((passenger, index: number) => {
                    return (
                        <div key={passenger.id}>
                            <IonRow>
                                <IonCol size="12" className="label mt-8 required">
                                    {t('travelAllowance.ride.passenger')} {index + 1}
                                </IonCol>
                            </IonRow>
                            <StyledInputGroup>
                                <IonCol size="10" className="label">
                                    <input type={"hidden"}
                                           ref={register()}
                                           name={`${prefix}.passengers.${index}.workerId`}
                                           value={passenger.workerId}
                                    />
                                    <StyledInput ref={register()}
                                                 name={`${prefix}.passengers.${index}.name`}
                                                 value={passenger.name}
                                                 style={{borderRadius: "12px"}}
                                                 onClick={() => {
                                                     setCurrentIndex(index);
                                                     setIsPassengerModalOpen(prevState => !prevState);
                                                 }}
                                    />
                                </IonCol>
                                <IonCol size="2" className="label" onClick={() => handlePassengerRemove(index)}>
                                    <StyledInputUnit icon={true}>
                                        <BinIcon/>
                                    </StyledInputUnit>
                                </IonCol>
                            </StyledInputGroup>
                        </div>
                    )
                })
            }
            <IonRow>
                <IonCol size="12">
                    <StyledButtonOption>
                        <div className="btn-option center" onClick={() => handlePassengerAdd()}>
                            <AddIcon/>
                            <span>{t('travelAllowance.ride.addPassenger')}</span>
                        </div>
                    </StyledButtonOption>
                </IonCol>
            </IonRow>
        </section>
    );
};

export default PassengersFormField;
