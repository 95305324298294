import {appApi} from "./api.service";
import {Preferences} from "@capacitor/preferences";
import moment, {Moment} from "moment";
import {SecureStoragePlugin} from "capacitor-secure-storage-plugin";
import {Network} from "@capacitor/network";
import {v4 as uuidv4} from 'uuid';

var CryptoJS = require("crypto-js");

let endpointPrefix = 'offline-sync';

export const getSettings = async () => {
    return appApi.get(endpointPrefix + '/settings');
}

const getPlaceOfServices = async () => {
    return appApi.get(endpointPrefix + '/reports-place-of-services');
}

const getReportsData = async (id: string, orderId: string|null = null) => {
    let data = appApi.get(endpointPrefix + '/reports/' + id + (orderId ? '?orderId=' + orderId : ''));

    let settings = await getSettings().then((response) => {
        return response.data;
    })

    await Preferences.set({
        'key': 'offline_sync_duration',
        'value': settings.ep2_offline_sync_duration.toString()
    });

    return data;
}

const getCurrentNetworkStatus = async () => {
    let status = false;
    await Network.getStatus()
        .then(result => {
            status = result.connected;
        });

    return status;
};

const saveDataToOfflineStorage = async (id: string, data: any) => {
    offlineStorageSet('pos_' + id, data);

    await Preferences.set({
        'key': 'last_offline_data_update',
        'value': moment().toDate().toString()
    });
}

const getOfflineDataStatus = async() => {
    let now = moment();
    let lastOfflineDataUpdate = await Preferences.get({
        'key': 'last_offline_data_update'
    }).then(value => {
        return value.value
    });
    let offlineSyncDuration = await Preferences.get({
        'key': 'offline_sync_duration'
    }).then(value => {
        return value.value
    });

    let valid;
    if (lastOfflineDataUpdate == null || offlineSyncDuration == null) {
        valid = false;
    } else {
        valid = moment.duration(now.diff(lastOfflineDataUpdate)).asMinutes() < parseInt(offlineSyncDuration);
    }

    return {
        date: lastOfflineDataUpdate,
        valid: valid
    }
}

const offlineStorageSet = async (key: string, data: any) => {
    var hash = uuidv4();
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), hash).toString();

    await Preferences
        .set({
            key: key,
            value: ciphertext
        });

    await SecureStoragePlugin.set({
        key: key,
        value: hash
    })
}

const offlineStorageGet = async (key: string) => {
    let data:[] = [];

    let hash= await SecureStoragePlugin.get({key: key}).then(value => {
        return value.value;
    })

    await Preferences
        .get({
            key: key
        })
        .then(value => {
            if (value.value) {
                var decrypted = CryptoJS.AES.decrypt(value.value, hash);
                data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
            }
        })

    return data;
}

export {
    getPlaceOfServices,
    getReportsData,
    getCurrentNetworkStatus,
    getOfflineDataStatus,
    offlineStorageSet,
    offlineStorageGet,
    saveDataToOfflineStorage
}



