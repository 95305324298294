import {appApi} from "@services/api.service";
import {ReportProblemApiFormat, ReportProblemForm} from "@models/helpdesk/reportProblem";
import {Preferences} from "@capacitor/preferences";

const endpointPrefix = 'helpdesk';

const reportProblem = async (reportProblemData: ReportProblemApiFormat) => {
    const profileId = await Preferences.get({'key': 'profile_id'});

    return await appApi.post(`${endpointPrefix}/report-problem` + (profileId.value !== 'undefined' ? `?profileId=${profileId.value}` : ''), reportProblemData);
}

export {
    reportProblem,
};
