import {
    checkSpecification as checkSpecificationOnline,
    findOrderById as findOrderByIdOnline,
    findOrderByNumber as findOrderByNumberOnline,
    findSpecificationByNumber as findSpecificationByNumberOnline,
    getMeasuringEquipments as getMeasuringEquipmentsOnline,
    getMeasuringEquipmentsItems as getMeasuringEquipmentsItemsOnline,
    getReport as getReportOnline,
    getReports as getReportsOnline,
    getWorkerData as getWorkerDataOnline,
    orderComponentsNumbers as orderComponentsNumbersOnline,
    orderDefects as orderDefectsOnline,
    orderWorkers as orderWorkersOnline,
    removeReport as removeReportOnline,
    saveReport as saveReportOnline,
    findContainerById as findContainerByIdOnline,
    findContainerByNumber as findContainerByNumberOnline,
} from './report.service';

import {
    checkSpecification as checkSpecificationOffline,
    findOrderById as findOrderByIdOffline,
    findOrderByNumber as findOrderByNumberOffline,
    findSpecificationByNumber as findSpecificationByNumberOffline,
    getMeasuringEquipments as getMeasuringEquipmentsOffline,
    getMeasuringEquipmentsItems as getMeasuringEquipmentsItemsOffline,
    getReport as getReportOffline,
    getReports as getReportsOffline,
    getWorkerData as getWorkerDataOffline,
    orderComponentsNumbers as orderComponentsNumbersOffline,
    orderDefects as orderDefectsOffline,
    orderWorkers as orderWorkersOffline,
    removeReport as removeReportOffline,
    saveReport as saveReportOffline,
    findContainerById as findContainerByIdOffline,
    findContainerByNumber as findContainerByNumberOffline,
} from './reportOffline.service';

import {getCurrentNetworkStatus} from "./offlineSync.service";
import {Order, Report} from "../app/reportPage/reportPage.component";
import {validate as validateUuid} from 'uuid';
import {Report as ReportApi} from "../models/raport";
import {ReportStatus} from "../enums/reportStatus";
import {
    getActiveContract as getActiveContractOnline,
    getActiveContractOffline
} from "@services/contract.service";

const findSpecificationByNumber = async (number: bigint | string) => {
    let networkStatus = await getCurrentNetworkStatus();

    if (networkStatus) {
        return findSpecificationByNumberOnline(number)
            .then(response => {
                return response.data.message;
            })
            .catch(reason => {
                return null;
            });
    } else {
        return findSpecificationByNumberOffline(number);
    }
}

const checkSpecification = async (id: bigint | string, reportId = '', skipDetailedCheck:boolean = false) => {
    let networkStatus = await getCurrentNetworkStatus();

    if (networkStatus) {
        let orderData:any = await checkSpecificationOnline(id, reportId, skipDetailedCheck)
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    return 'COMMON';
                }
            }).catch(reason => {
                try {
                    let message = reason.response.data?.message;
                    return message !== undefined ? message : 'COMMON';
                } catch (e) {
                    return 'COMMON';
                }
            });

        if (typeof orderData == 'string') {
            throw new Error(orderData);
        }

        return orderData;
    } else {
        let orderData = await checkSpecificationOffline(id, reportId, skipDetailedCheck);

        if (typeof orderData == 'string') {
            throw new Error(orderData);
        }

        return orderData;
    }
}

const findOrderById = async (number: bigint | string, skipDetailedCheck:boolean = false) => {
    let networkStatus = await getCurrentNetworkStatus();

    if (networkStatus) {
        let orderData:any = await findOrderByIdOnline(number, skipDetailedCheck)
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    return 'COMMON';
                }
            })
            .catch(reason => {
                try {
                    let message = reason.response.data?.message;
                    return message !== undefined ? message : 'COMMON';
                } catch (e) {
                    return 'COMMON';
                }
            });

        if (typeof orderData == 'string') {
            throw new Error(orderData);
        }

        return orderData;
    } else {
        let orderData = await findOrderByIdOffline(number);

        if (typeof orderData == 'string') {
            throw new Error(orderData);
        }

        return orderData;
    }
}

const findOrderByNumber =  async (number: bigint | string, skipDetailedCheck:boolean = false) => {
    let networkStatus = await getCurrentNetworkStatus();

    if (networkStatus) {
        let orderData = await findOrderByNumberOnline(number, skipDetailedCheck)
            .then(response => {
                return response.data;
            })
            .catch(reason => {
                try {
                    let message = reason.response.data?.message;
                    return message !== undefined ? message : 'COMMON';
                } catch (e) {
                    return 'COMMON';
                }
            });

        if (typeof orderData == 'string') {
            throw new Error(orderData);
        }
        return orderData;
    } else {
        let orderData = await findOrderByNumberOffline(number);

        if (typeof orderData == 'string') {
            throw new Error(orderData);
        }
        return orderData;
    }
}

const getReport = async (reportId: number|string, offline = false) => {

    if (!offline) {
        return await getReportOnline(reportId)
            .then(response => {
                return response.data;
            });
    } else {
        return await getReportOffline(reportId);
    }
}

const orderComponentsNumbers = async (orderId: number|string) => {
    let networkStatus = await getCurrentNetworkStatus();

    if (networkStatus) {
        return await orderComponentsNumbersOnline(orderId)
            .then(response => {
                return response.data;
            });
    } else {
        return orderComponentsNumbersOffline(orderId);
    }
}

const findContainerById = async (id: bigint | string) => {
    let networkStatus = await getCurrentNetworkStatus();

    if (networkStatus) {
        return await findContainerByIdOnline(id)
            .then(response => {
                return response.data;
            });
    } else {
        return findContainerByIdOffline(id);
    }
}

const findContainerByNumber = async (number: bigint | string, orderId: bigint | string) => {
    let networkStatus = await getCurrentNetworkStatus();

    if (networkStatus) {
        let data = await findContainerByNumberOnline(number, orderId)
            .then(response => {
                if (response.status === 200) {
                    return response.data.message;
                } else {
                    return 'COMMON';
                }
            }).catch(reason => {
                try {
                    let message = reason.response.data?.message;
                    return message !== undefined ? message : 'COMMON';
                } catch (e) {
                    return 'COMMON';
                }
            });

        if (typeof data == 'string') {
            throw new Error(data);
        }

        return data;
    } else {
        let data = await findContainerByNumberOffline(number, orderId);

        if (typeof data == 'string') {
            throw new Error(data);
        }

        return data;
    }
}

const orderDefects = async (specificationId: number|string) => {
    let networkStatus = await getCurrentNetworkStatus();

    if (networkStatus) {
        return await orderDefectsOnline(specificationId)
            .then(response => {
                return response.data;
            });
    } else {
        return orderDefectsOffline(specificationId);
    }
}

const getWorkerData = async (orderId: string, number: string, serviceDate: string) => {
    let networkStatus = await getCurrentNetworkStatus();

    if (networkStatus) {
        return await getWorkerDataOnline(orderId, number, serviceDate)
            .then(response => {
                return response;
            });
    } else {
        return getWorkerDataOffline(orderId, number, serviceDate);
    }
}

const orderWorkers = async (orderId: number|string, serviceDate: string, page: number = 1, searchText: string = '') => {
    let networkStatus = await getCurrentNetworkStatus();

    if (networkStatus) {
        return await orderWorkersOnline(orderId, serviceDate, page, searchText)
            .then(response => {
                return response.data;
            });
    } else {
        return orderWorkersOffline(orderId, serviceDate, page, searchText);
    }
}

const saveReport = async (order: Order, data: Report, reportId?: string, draft = false, skipLimits: boolean = false, removeOfflineDraft = false, logisticContainer = '0', closeContainer: boolean = false, skipValidation: boolean = false) => {
    let networkStatus = await getCurrentNetworkStatus();
    let multipleOrders: boolean = draft && data.additionalOrders.length > 0;

    if (!networkStatus) {
        return saveReportOffline(order, data, reportId, draft);
    } else if (multipleOrders) {
        return saveReportOffline(order, data, reportId, draft, false, true);
    } else {
        let offline: boolean = !!(reportId && validateUuid(reportId.toString()));
        let response = await saveReportOnline(order, data, reportId, draft, skipLimits, offline, false, logisticContainer, closeContainer, skipValidation)
            .then(response => {
                return response.data;
            });

        if (draft) {
            // backup offline
            await saveReportOffline(order, data, reportId && parseInt(reportId) !== 0 && !offline ? reportId : response.id, true, true)
        }

        if (offline && removeOfflineDraft && reportId) {
            await removeReportOffline(reportId);
        }

        return response;
    }
}

const getReports = async (status: string, params: any, forceOffline: boolean = false) => {
    let networkStatus = await getCurrentNetworkStatus();
    if (networkStatus && !forceOffline) {
        let reports: ReportApi[] = [];
        let offlineBackupReports: ReportApi[] = [];
        let tmp = await getReportsOnline(status, params)
            .then(response => {
                return response.data as ReportApi[];
            });

        if (status === ReportStatus.DRAFT) {
            reports = await getReportsOffline(ReportStatus.OFFLINE_DRAFT, false, true);

            // was draft but moved to not verified on backend, in this case we remove backup draft
            offlineBackupReports = await getReportsOffline(ReportStatus.OFFLINE_DRAFT, false, false, true);
            for (const value of offlineBackupReports) {
                let existingOnline = tmp.find(r => {
                    return r.id == value.id;
                })

                if (!existingOnline) {
                    await removeReportOffline(value.id.toString());
                }
            }
        } else if (status === ReportStatus.NOT_VERIFIED) {
            reports = await getReportsOffline(ReportStatus.OFFLINE_READY_TO_SYNC, false, true);
        }

        tmp.forEach(value => {
            let existingOffline = reports.find(r => {
                return r.id == value.id;
            })

            if (existingOffline) {
                existingOffline.formatted_number = value.formatted_number;
            }

            if (!existingOffline) {
                reports?.push(value)
            }
        })

        return reports;
    } else {
        if (status === ReportStatus.DRAFT) {
            return getReportsOffline(ReportStatus.OFFLINE_DRAFT);
        } else if (status === ReportStatus.NOT_VERIFIED) {
            return getReportsOffline(ReportStatus.OFFLINE_READY_TO_SYNC);
        }
        return [];
    }
}

export const removeReport = async (reportId: number|string, offline = false) => {
    if (!offline) {
        // Remove offline backup
        await removeReportOffline(reportId);
        return await removeReportOnline(reportId)
            .then(response => {
                return response.data;
            });
    } else {
        return removeReportOffline(reportId);
    }
}

export const getMeasuringEquipments = async (specificationId: number | string, serviceDate: string) => {
    let networkStatus = await getCurrentNetworkStatus();

    if (networkStatus) {
        return await getMeasuringEquipmentsOnline(specificationId, serviceDate)
            .then(response => {
                return response.data;
            })
    } else {
        return getMeasuringEquipmentsOffline(specificationId, serviceDate)
    }
}

export const getMeasuringEquipmentsItems = async (specificationId: number | string, serviceDate: string, categoryId: number | string) => {
    let networkStatus = await getCurrentNetworkStatus();

    if (networkStatus) {
        return await getMeasuringEquipmentsItemsOnline(specificationId, serviceDate, categoryId)
            .then(response => {
                return response.data;
            })
    } else {
        return getMeasuringEquipmentsItemsOffline(specificationId, serviceDate, categoryId)
    }
}

export const getActiveContract = async () => {
    let networkStatus = await getCurrentNetworkStatus();

    if (networkStatus) {
        return await getActiveContractOnline()
            .then(response => {
                return response.data;
            });
    } else {
        return getActiveContractOffline()
    }
}

export {
    findSpecificationByNumber,
    checkSpecification,
    findOrderById,
    findOrderByNumber,
    getReport,
    orderComponentsNumbers,
    orderDefects,
    getWorkerData,
    orderWorkers,
    saveReport,
    getReports,
    findContainerById,
    findContainerByNumber
}
