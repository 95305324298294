import React, {useEffect, useRef, useState} from 'react';
import {IonAlert, IonCol, IonIcon, IonItem, IonLoading, IonRow, IonSelectOption, IonToast} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {Controller, useFormContext, useWatch} from "react-hook-form";
import moment, {Moment} from "moment";

import {PlaceOfService, RideRoute} from "@models/travelAllowance/rideRoute";
import {Car} from "@models/travelAllowance/car";
import {Coordinator, Passenger, PassengerFormField, RideFormData} from "@models/travelAllowance/ride";
import {RideCost} from "@models/travelAllowance/rideCost";
import {RideMatrix} from "@models/travelAllowance/rideMatrix";

import Accordion from "@components/accordion/accordion";
import DateInput from '@components/form/input/date.component';
import PassengersFormField from "@app/travelAllowance/ride/components/passengersFormField.component";
import CityAutocomplete from "@components/google/cityAutocomplete/cityAutocomplete.component";
import PaginatedListModal from "@components/modal/paginatedListModal.component";

import CalendarIcon from '@assets/images/travelAllowance/calendar.svg';

import {calculateDistance, getPlacesOfService, getPossibleCoordinators, transformSubRoutesToFormField, validateRideRoute} from "@services/travelAllowance/rideRoute.service";
import {calculateRideCost} from "@services/travelAllowance/rideCost.service";
import {getRideMatrix} from "@services/travelAllowance/rideMatrix.service";
import {handleCommonRideErrorResponseMessage} from "@services/travelAllowance/ride.service";

import {useRideFormContext} from "@context/rideForm.context";

import {RidePaneType} from "@enums/travelAllowance/ride";

import useComponentMounted from "@hooks/useComponentMounted";

import {StyledInput, StyledInputGroup, StyledInputUnit} from "@components/form/input/input.style";
import {StyledIonLabel} from "@components/form/input/label.style";
import {StyledIonCheckbox} from "@components/form/input/checkbox.style";
import {StyledIonSelect} from "@components/form/input/select.style";
import RideCostInfoRow from "@app/travelAllowance/ride/components/rideCostInfoRow.component";
import SubRoutesFormField from "@app/travelAllowance/rideRoute/components/subRoutesFormField.component";
import {Preferences} from "@capacitor/preferences";

type RideFormFieldsProps = {}

const RideFormFields: React.FC<RideFormFieldsProps> = ({}: RideFormFieldsProps) => {
    const {t} = useTranslation();
    const {register, control, setValue, errors} = useFormContext<RideFormData>();
    const isMounted = useComponentMounted();

    const rideFormContext = useRideFormContext();

    const [shouldFetchRideCost, setShouldFetchRideCost] = useState<boolean>(false);
    const [shouldFetchDistance, setShouldFetchDistance] = useState<boolean>(false);

    const [selectedPassengers, setSelectedPassengers] = useState<Passenger[]>();
    const [selectedRoute, setSelectedRoute] = useState<RideRoute>();
    const [dateStart, setDateStart] = useState<string>();
    const [rideCost, setRideCost] = useState<RideCost>();
    const [coordinator, setCoordinator] = useState<Coordinator | undefined>();
    const [placeOfService, setPlaceOfService] = useState<PlaceOfService | undefined>();
    const [distanceValue, setDistanceValue] = useState<number>();
    const [rideMatrix, setRideMatrix] = useState<RideMatrix>();
    const [maxDaysNextPeriodForRide, setMaxDaysNextPeriodForRide] = useState<number | null>(null);
    const [maxDaysBackForRide, setMaxDaysBackForRide] = useState<number | null>(null);
    const [minDateStart, setMinDateStart] = useState<string>();
    const [defaultPassengers, setDefaultPassengers] = useState<PassengerFormField[]>([]);
    const [workerCoordinatorId, setWorkerCoordinatorId] = useState<number | null>(null)

    const [isCoordinatorModalOpen, setIsCoordinatorModalOpen] = useState<boolean>(false);
    const [isPlaceOfServiceModalOpen, setIsPlaceOfServiceModalOpen] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [showDistanceErrorAlert, setShowDistanceErrorAlert] = useState<boolean>(false);

    const startingPlace = useWatch({control: control, name: 'ride.route.startingPlace'});
    const destinationPlace = useWatch({control: control, name: 'ride.route.destinationPlace'});
    const subRoutes = useWatch({control: control, name: 'ride.route.subRoutes'});
    const distance = useWatch({control: control, name: 'ride.route.distance'});

    useEffect(() => {
        setRideMatrix(rideFormContext.rideMatrix);

        if (rideFormContext.rideMatrix) {
            setMaxDaysNextPeriodForRide(rideFormContext.rideMatrix.maxDaysNextPeriodForRide);
            setMaxDaysBackForRide(rideFormContext.rideMatrix.maxDaysBackForRide);
        }

        setDefaultValues();
        handleWorkerCoordinatorId();
    }, []);

    useEffect(() => {
        if (distance) {
            setDistanceValue(distance);
        }
    }, [distance]);

    useEffect(() => {
        handleMinDateStart();
    }, [maxDaysNextPeriodForRide, maxDaysBackForRide]);

    useEffect(() => {
        if (!startingPlace || !destinationPlace) {
            setValue('ride.route.distance', undefined);
            setDistanceValue(undefined);
        }
    }, [startingPlace, destinationPlace]);

    useEffect(() => {
        if (!isMounted || selectedRoute?.id) return;
        if (!shouldFetchDistance) return;
        if (subRoutes.length > 0) return;

        const {ride: {route}} = control.getValues();
        if (!route.startingPlace || !route.destinationPlace) {
            setShouldFetchDistance(false);
            return;
        }

        setShowLoader(true);

        const handleError = (error: any) => {
            const errorMessage = error.response.data.detail;

            if (errorMessage === 'Could not determine distance.') {
                setShowDistanceErrorAlert(true);
            } else {
                setToast(t('travelAllowance.rideRoute.distanceCalculateError'));
                setShowToast(true);
            }

            console.error(error);
        };

        fetchDistance(route.startingPlace, route.destinationPlace)
            .catch((error) => handleError(error))
            .finally(() => {
                setShouldFetchDistance(false);
                setShowLoader(false);
            });
    }, [isMounted, shouldFetchDistance]);

    useEffect(() => {
        if (!isMounted) return;
        if (!shouldFetchRideCost) return;

        const {ride: {route, car}} = control.getValues();
        if (!route.startingPlaceCountry || !route.destinationPlaceCountry || !distanceValue || !car || !dateStart) {
            setShouldFetchRideCost(false);
            return;
        }

        setShowLoader(true);

        const handleError = (error: any) => {
            const errorMessage = error.response.data.detail;
            const messageKey = handleCommonRideErrorResponseMessage(errorMessage, 'travelAllowance.rideCost.calculateError');

            if (errorMessage === 'Company vehicle rate not found') {
                setValue('ride.car', undefined);
            }

            setToast(t(messageKey));
            setShowToast(true);
            setRideCost(undefined);

            console.error(error);
        };

        fetchRideCost(route.startingPlaceCountry, route.destinationPlaceCountry, distanceValue, car, dateStart, selectedPassengers)
            .catch((error) => handleError(error))
            .finally(() => {
                setShouldFetchRideCost(false);
                setShowLoader(false);
            })
    }, [isMounted, shouldFetchRideCost]);

    const fetchDistance = async (startingPlace: string, destinationPlace: string) => {
        const distanceData = await calculateDistance({
            startingPlace,
            destinationPlace
        });

        setValue('ride.route.distance', distanceData.distance, {shouldValidate: true});
        setDistanceValue(distanceData.distance);
        setShouldFetchRideCost(true);
    }

    const fetchRideMatrix = async (date?: string) => {
        return await getRideMatrix(date);
    }

    const fetchRideCost = async (startingPlaceCountry: string, destinationPlaceCountry: string, distance: number, car: number, dateStart: string, passengers?: Passenger[]) => {
        const rideCostData = await calculateRideCost({
            startingPlaceCountry,
            destinationPlaceCountry,
            distance,
            dateStart,
            car,
            passengers: passengers ? passengers.map(passenger => passenger.id) : [],
        });

        setRideCost(rideCostData);
    }

    const handleWorkerCoordinatorId = async () => {
        const coordinatorId = await Preferences.get({'key': 'coordinator_id'});

        if (coordinatorId.value) {
            setWorkerCoordinatorId(parseInt(coordinatorId.value));
        }
    }

    const handleDateChange = async (dateInput: string, onChangeCallback: (...event: any[]) => void) => {
        setShowLoader(true);

        if (moment(dateInput).isAfter(moment())) {
            setToast(t("travelAllowance.ride.incorrectDate"));
            setShowToast(true);
            setShowLoader(false);
            return;
        }

        if (moment(rideMatrix?.validFrom.date).isAfter(moment(dateInput))) {
            setToast(t("travelAllowance.ride.rideMatrixValidFromGreaterThanDateStart"));
            setShowToast(true);
            setShowLoader(false);
        }

        if (moment(dateInput).isAfter(moment(rideMatrix?.validTo.date))) {
            setToast(t("travelAllowance.ride.rideMatrixValidToLowerThanDateStart"));
            setShowToast(true);
            setShowLoader(false);
        }

        dateInput = moment(dateInput).format('YYYY-MM-DD')

        let validationSuccess = true;
        if (selectedRoute) {
            await validateRideRoute(selectedRoute.id, dateInput)
                .catch((error) => {
                    handleRideRouteValidationError(error);
                    validationSuccess = false;
                })
        }

        if (!validationSuccess) {
            setShowLoader(false);
            return;
        }

        handlePassengersClear();

        if (!selectedRoute && workerCoordinatorId !== coordinator?.id) {
            handleCoordinatorClear();
            handlePlaceOfServiceClear();
        }

        setDateStart(dateInput);
        onChangeCallback(dateInput);

        setShowLoader(false);
    }

    const handleRouteSelect = async (routeId: number | null) => {
        setShowLoader(true);

        let validationSuccess = true;
        if (routeId) {
            await validateRideRoute(routeId, control.getValues().ride?.dateStart)
                .catch((error) => {
                    handleRideRouteValidationError(error);
                    validationSuccess = false;
                })
                .finally(() => {
                    setShowLoader(false);
                })
        }

        if (!validationSuccess) {
            return;
        }

        const rideRoute = rideFormContext.rideRoutes.find((rideRoue) => rideRoue.id === routeId);
        setSelectedRoute(rideRoute);

        setValue('ride.route.id', rideRoute?.id ?? 0);
        setValue('ride.route.startingPlace', rideRoute?.startingPlace ?? '', {shouldValidate: true});
        setValue('ride.route.destinationPlace', rideRoute?.destinationPlace ?? '', {shouldValidate: true});
        setValue('ride.route.startingPlaceCountry', rideRoute?.startingPlaceCountry ?? '');
        setValue('ride.route.destinationPlaceCountry', rideRoute?.destinationPlaceCountry ?? '');
        setValue('ride.route.distance', rideRoute?.distance ?? undefined);
        setValue('ride.route.coordinator', rideRoute?.coordinator?.id ?? undefined, {shouldValidate: true});
        setValue('ride.route.placeOfService', rideRoute?.placeOfService?.id ?? undefined, {shouldValidate: true});
        setValue('ride.route.isLocal', rideRoute?.isLocal ?? undefined);
        if (rideRoute?.subRoutes) {
            setValue('ride.route.subRoutes', transformSubRoutesToFormField(rideRoute.subRoutes));
            rideRoute.subRoutes.forEach((item, index, arr) => {
                register(`ride.route.subRoutes.${index}.startingPlace`);
                setValue(`ride.route.subRoutes.${index}.startingPlace`, item.startingPlace);

                register(`ride.route.subRoutes.${index}.startingPlaceCountry`);
                setValue(`ride.route.subRoutes.${index}.startingPlaceCountry`, item.startingPlaceCountry);

                register(`ride.route.subRoutes.${index}.destinationPlace`);
                setValue(`ride.route.subRoutes.${index}.destinationPlace`, item.destinationPlace);

                register(`ride.route.subRoutes.${index}.destinationPlaceCountry`);
                setValue(`ride.route.subRoutes.${index}.destinationPlaceCountry`, item.destinationPlaceCountry);

                register(`ride.route.subRoutes.${index}.distance`);
                setValue(`ride.route.subRoutes.${index}.distance`, item.distance);

                register(`ride.route.subRoutes.${index}.visible`);
                setValue(`ride.route.subRoutes.${index}.visible`, index !== 0);
            });
        } else {
            setValue('ride.route.subRoutes', []);
        }

        setDistanceValue(rideRoute?.distance);
        setCoordinator(rideRoute?.coordinator);
        setPlaceOfService(rideRoute?.placeOfService);
        setDefaultPassengers([]);

        rideFormContext.setCoordinator(rideRoute?.coordinator);
        rideFormContext.setPlaceOfService(rideRoute?.placeOfService);

        if (!rideRoute) {
            setRideCost(undefined);
        } else {
            setShouldFetchRideCost(true);
        }

        setShowLoader(false);
    }

    const handleRideRouteValidationError = (error: any) => {
        const errorMessage = error.response.data.detail;

        if (errorMessage.includes('Data integrity failed for coordinatorId')) {
            setToast(t("travelAllowance.rideRoute.coordinatorIntegrityViolation"));
        }

        if (errorMessage.includes('Data integrity failed for placeOfServiceId')) {
            setToast(t("travelAllowance.rideRoute.placeOfServiceIntegrityViolation"));
        }
        setShowToast(true);

        console.error(error);
    };

    const setStartingPlaceCountryCallback = (country: string) => {
        setValue('ride.route.startingPlaceCountry', country);
    }

    const setDestinationPlaceCountryCallback = (country: string) => {
        setValue('ride.route.destinationPlaceCountry', country);
    }

    const setDefaultValues = () => {
        if (rideFormContext.cars.length === 1) {
            setValue('ride.car', rideFormContext.cars[0].id);
        }

        if (rideFormContext.rideDefaultValues) {
            const {passengers, rideCost} = rideFormContext.rideDefaultValues;

            const tempPassengers: PassengerFormField[] = passengers.map((passenger: Passenger): PassengerFormField => {
                return {
                    workerId: passenger.id,
                    name: passenger.name,
                }
            })

            setDateStart(rideFormContext.rideDefaultValues.dateStart);
            setRideCost(rideCost);
            setDistanceValue(rideFormContext.rideDefaultValues.rideRoute.distance);
            setDefaultPassengers(tempPassengers);

            handleCoordinatorSelect(rideFormContext.rideDefaultValues.rideRoute.coordinator);
            handlePlaceOfServiceSelect(rideFormContext.rideDefaultValues.rideRoute.placeOfService);
        } else {
            setDateStart(moment().format());
        }
    }

    const handleCoordinatorSelect = (coordinator: Coordinator) => {
        setCoordinator(coordinator)
        setValue('ride.route.coordinator', coordinator.id, {shouldValidate: true});
        rideFormContext.setCoordinator(coordinator);

        if (coordinator.id !== workerCoordinatorId) {
            handlePlaceOfServiceClear();
        }
    }

    const handlePlaceOfServiceSelect = (placeOfService: PlaceOfService) => {
        setPlaceOfService(placeOfService);
        setValue('ride.route.placeOfService', placeOfService.id, {shouldValidate: true});
        rideFormContext.setPlaceOfService(placeOfService);
    }

    const handleCoordinatorClear = () => {
        setCoordinator(undefined)
        setValue('ride.route.coordinator', undefined, {shouldValidate: true});
        rideFormContext.setCoordinator(undefined);
    }

    const handlePlaceOfServiceClear = () => {
        setPlaceOfService(undefined);
        setValue('ride.route.placeOfService', undefined, {shouldValidate: true});
        rideFormContext.setPlaceOfService(undefined);
    }

    const handlePassengersClear = () => {
        setSelectedPassengers([]);
        setValue('ride.passengers', []);
        setValue('backRide.passengers', []);
    }

    const handleDistanceErrorAlertConfirm = () => {
        setValue('ride.route.startingPlace', '');
        setValue('ride.route.destinationPlace', '');
        setValue('ride.route.startingPlaceCountry', '');
        setValue('ride.route.destinationPlaceCountry', '');
        setValue('ride.route.distance', undefined);
        setValue('ride.route.isLocal', undefined);
    }

    const handleMinDateStart = () => {
        const minDate = getMinDate(maxDaysNextPeriodForRide, maxDaysBackForRide).format();

        setMinDateStart(minDate);
    };

    function getMinDate(maxDaysNextPeriodForRide: number | null, maxDaysBackForRide: number | null): Moment {
        const today = moment();
        const firstDayOfMonth = today.clone().startOf("month");
        const firstDayOfLastMonth = today.clone().subtract(1, "months").startOf("month");
        const daysInCurrentMonth = today.date();

        if (maxDaysNextPeriodForRide === null) {
            if (maxDaysBackForRide === null) {
                return firstDayOfLastMonth;
            }

            return today.clone().subtract(maxDaysBackForRide, "days");
        }

        if (maxDaysNextPeriodForRide === 0) {
            if (maxDaysBackForRide === null) {
                return firstDayOfMonth;
            }

            return daysInCurrentMonth > maxDaysBackForRide ? today.clone().subtract(maxDaysBackForRide, "days") : firstDayOfMonth;
        }

        if (maxDaysNextPeriodForRide > 0) {
            if (maxDaysBackForRide === null) {
                return daysInCurrentMonth > maxDaysNextPeriodForRide ? firstDayOfMonth : firstDayOfLastMonth;
            }

            if (maxDaysBackForRide > maxDaysNextPeriodForRide) {
                return firstDayOfMonth;
            }

            return today.clone().subtract(maxDaysBackForRide, "days");
        }

        return firstDayOfLastMonth;
    }

    const timerRef = useRef<number | null>(null);

    function handleDistanceChanged(value: string) {
        if (timerRef.current !== null) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = window.setTimeout(() => {
            setDistanceValue(parseInt(value));
            setShouldFetchRideCost(true);
        }, 1000);
    }

    return (
        <>
            <Accordion isOpen={true} allowToggle={true} title={t('travelAllowance.ride.generalData')}>
                <IonLoading onDidDismiss={() => setShowLoader(false)} isOpen={showLoader}/>
                <IonAlert
                    isOpen={showDistanceErrorAlert}
                    onDidDismiss={() => setShowDistanceErrorAlert(false)}
                    header={t('travelAllowance.rideRoute.distanceErrorAlert')}
                    buttons={[
                        {
                            text: t('common.ok'),
                            handler: () => {
                                handleDistanceErrorAlertConfirm();
                            }
                        }
                    ]}
                />
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={toast}
                    duration={6000}
                    position="top"
                    color="danger"
                />
                <PaginatedListModal isOpen={isCoordinatorModalOpen}
                                    fetchData={getPossibleCoordinators}
                                    fetchParams={{date: control.getValues().ride?.dateStart, cancelToken: undefined}}
                                    setValue={handleCoordinatorSelect}
                                    onClose={() => setIsCoordinatorModalOpen(false)}
                />
                <PaginatedListModal isOpen={isPlaceOfServiceModalOpen}
                                    fetchData={getPlacesOfService}
                                    fetchParams={{coordinatorId: coordinator?.id, cancelToken: undefined}}
                                    setValue={handlePlaceOfServiceSelect}
                                    onClose={() => setIsPlaceOfServiceModalOpen(false)}
                />
                <IonRow>
                    <IonCol size="12" className="label mt-8 required">
                        {t('travelAllowance.ride.startDate')}
                    </IonCol>
                </IonRow>
                <StyledInputGroup className={errors.ride?.dateStart ? 'error' : (rideFormContext.rideChanges?.hasOwnProperty('dateStart') ? 'warning' : '')}>
                    <IonCol size="10" className="label">
                        <Controller
                            name={'ride.dateStart'}
                            control={control}
                            defaultValue={dateStart ?? control.getValues().ride?.dateStart}
                            rules={{required: true}}
                            render={({onChange}) => (
                                <DateInput
                                    presentation="date"
                                    format="DD.MM.YYYY"
                                    onChange={value => {
                                        handleDateChange(value, onChange);
                                    }}
                                    min={minDateStart}
                                    max={moment().format()}
                                    cancelText={t("common.dateCancel")}
                                    doneText={t("common.dateDone")}
                                    placeholder={t("common.select")}
                                    value={moment(dateStart ?? control.getValues().ride?.dateStart).format('YYYY-MM-DD')}
                                />
                            )}
                        />
                    </IonCol>
                    <IonCol size="2" className="label">
                        <StyledInputUnit icon>
                            <IonIcon slot="icon-only" icon={CalendarIcon}/>
                        </StyledInputUnit>
                    </IonCol>
                </StyledInputGroup>
                <IonRow>
                    <IonCol size="12" className="label mt-8 required">
                        {t('travelAllowance.ride.rideRoute')}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <Controller
                            name={'ride.route.id'}
                            defaultValue={control.getValues()?.ride?.route.id || undefined}
                            control={control}
                            render={() => (
                                <StyledIonSelect
                                    placeholder={t('travelAllowance.ride.selectRoute')}
                                    onIonChange={(e) => handleRouteSelect(e.detail.value)}
                                    value={control.getValues()?.ride?.route.id || undefined}
                                >
                                    <IonSelectOption value={0}>{t('travelAllowance.ride.selectRoute')}</IonSelectOption>
                                    {rideFormContext.rideRoutes.map((rideRoute: RideRoute) => {
                                        return <IonSelectOption
                                            key={rideRoute.id}
                                            value={rideRoute.id}>{rideRoute.startingPlace} - {rideRoute.destinationPlace}</IonSelectOption>
                                    })}
                                </StyledIonSelect>
                            )}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="label required">
                        {t('travelAllowance.ride.startingPlace')}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="label">
                        <Controller
                            name={'ride.route.startingPlace'}
                            rules={{required: true}}
                            control={control}
                            defaultValue={''}
                            render={({onChange}) => (
                                <CityAutocomplete disabled={!!selectedRoute}
                                                  defaultValue={startingPlace}
                                                  setCountry={setStartingPlaceCountryCallback}
                                                  onChangeCallback={(place: any) => {
                                                      onChange(place);
                                                      setShouldFetchDistance(true);
                                                  }}
                                                  className={errors.ride?.route?.startingPlace ? 'error' : (rideFormContext.rideChanges?.hasOwnProperty('rideRoute.startingPlace') ? 'warning' : '')}
                                />
                            )}
                        />
                        <input ref={register}
                               name={'ride.route.startingPlaceCountry'}
                               type={"hidden"}
                        />
                    </IonCol>
                </IonRow>
                <SubRoutesFormField prefix={"ride.route"} setParentShouldFetchDistance={(shouldFetchDistance) => setShouldFetchDistance(shouldFetchDistance)} disabled={!!selectedRoute}/>
                <IonRow>
                    <IonCol size="12" className="label mt-8 required">
                        {t('travelAllowance.ride.destinationPlace')}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="label">
                        <Controller
                            name={'ride.route.destinationPlace'}
                            rules={{required: true}}
                            control={control}
                            defaultValue={''}
                            render={({onChange}) => (
                                <CityAutocomplete disabled={!!selectedRoute}
                                                  defaultValue={destinationPlace}
                                                  setCountry={setDestinationPlaceCountryCallback}
                                                  onChangeCallback={(place: any) => {
                                                      onChange(place);
                                                      setShouldFetchDistance(true);
                                                  }}
                                                  className={errors.ride?.route?.destinationPlace ? 'error' : (rideFormContext.rideChanges?.hasOwnProperty('rideRoute.destinationPlace') ? 'warning' : '')}
                                />
                            )}
                        />
                        <input ref={register}
                               name={'ride.route.destinationPlaceCountry'}
                               type={"hidden"}
                        />
                    </IonCol>
                </IonRow>
                <input ref={register({setValueAs: v => v === 'true'})}
                       name={'ride.route.isLocal'}
                       type={"hidden"}/>
                <IonRow>
                    <IonCol size="12" className="label mt-8 required">
                        {t('travelAllowance.ride.distance')}
                    </IonCol>
                </IonRow>
                <StyledInputGroup unit={true} className={errors.ride?.route?.distance ? 'error' : (rideFormContext.rideChanges?.hasOwnProperty('rideRoute.distance') ? 'warning' : '')}>
                    <IonCol size="10" className="label">
                        <StyledInput
                            readOnly={rideMatrix?.isDistanceEditBlocked ?? true}
                            disabled={!!selectedRoute}
                            type="number"
                            ref={register({
                                required: true,
                                valueAsNumber: true
                            })}
                            onInput={(event) => handleDistanceChanged((event.target as HTMLInputElement).value)}
                            name={'ride.route.distance'}
                        />
                    </IonCol>
                    <IonCol size="2" className="label">
                        <StyledInputUnit>km</StyledInputUnit>
                    </IonCol>
                </StyledInputGroup>
                <IonRow>
                    <IonCol size="12" className="label mt-8 required">
                        {t('travelAllowance.ride.coordinator')}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <Controller
                            name={'ride.route.coordinator'}
                            control={control}
                            defaultValue={''}
                            rules={{
                                required: true,
                                valueAsNumber: true,
                            }}
                            render={() => (
                                <StyledInput
                                    disabled={!!selectedRoute}
                                    placeholder={coordinator ? coordinator.name : t('travelAllowance.ride.selectCoordinator')}
                                    className={errors.ride?.route?.coordinator ? 'error' : (rideFormContext.rideChanges?.hasOwnProperty('rideRoute.coordinatorId') ? 'warning' : '')}
                                    onClick={() => setIsCoordinatorModalOpen(prevState => !prevState)}
                                >
                                </StyledInput>
                            )}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="label mt-8 required">
                        {t('travelAllowance.ride.placeOfService')}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <Controller
                            name={'ride.route.placeOfService'}
                            control={control}
                            defaultValue={''}
                            rules={{
                                required: true,
                                valueAsNumber: true,
                            }}
                            render={() => (
                                <StyledInput
                                    disabled={!!selectedRoute || !coordinator}
                                    placeholder={placeOfService ? placeOfService.name : t('travelAllowance.ride.selectPlaceOfService')}
                                    className={errors.ride?.route?.placeOfService ? 'error' : (rideFormContext.rideChanges?.hasOwnProperty('rideRoute.placeOfServiceId') ? 'warning' : '')}
                                    onClick={() => setIsPlaceOfServiceModalOpen(prevState => !prevState)}
                                >
                                </StyledInput>
                            )}
                        />
                    </IonCol>
                </IonRow>
                {
                    rideFormContext.type === RidePaneType.ADD &&
                    <IonRow>
                        <IonItem lines="none">
                            <StyledIonLabel
                                className="ion-text-wrap">{t('travelAllowance.ride.saveRoute')}</StyledIonLabel>
                            <Controller name={'ride.saveAsRoute'}
                                        control={control}
                                        defaultValue={true}
                                        render={({value, onChange}) => (
                                            <StyledIonCheckbox
                                                disabled={!!selectedRoute}
                                                checked={selectedRoute ? false : value}
                                                slot="start"
                                                onIonChange={(e) => onChange(e.detail.checked)}
                                            />
                                        )}
                            />
                        </IonItem>
                    </IonRow>
                }
                <IonRow>
                    <IonCol size="12" className="label mt-8 required">
                        {t('travelAllowance.ride.car')}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <Controller name={'ride.car'}
                                    control={control}
                                    rules={{required: true}}
                                    defaultValue={control.getValues()?.ride?.car || ''}
                                    render={({onChange}) => (
                                        <StyledIonSelect
                                            placeholder={t('travelAllowance.ride.selectCar')}
                                            onIonChange={(e) => {
                                                onChange(e.detail.value);
                                                setShouldFetchRideCost(true);
                                            }}
                                            value={control.getValues()?.ride?.car || ''}
                                            className={errors.ride?.car ? 'error' : ''}
                                        >
                                            {rideFormContext.cars.map((car: Car) => {
                                                return <IonSelectOption
                                                    key={car.id}
                                                    value={car.id}>{car.brand} {car.model} {car.licensePlate}</IonSelectOption>
                                            })}
                                        </StyledIonSelect>
                                    )}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="label required">
                        {t('travelAllowance.ride.rate')}
                    </IonCol>
                </IonRow>
                <StyledInputGroup unit={true}>
                    <IonCol size="10" className="label">
                        <StyledInput value={rideCost?.rate || '-'} disabled={true}/>
                    </IonCol>
                    <IonCol size="2" className="label">
                        <StyledInputUnit>{rideCost?.currency || '-'}/km</StyledInputUnit>
                    </IonCol>
                </StyledInputGroup>
                <IonRow>
                    <IonCol size="12" className="label required">
                        {t('travelAllowance.ride.cost')}
                    </IonCol>
                </IonRow>
                <StyledInputGroup unit={true}>
                    <IonCol size="10" className="label">
                        <StyledInput value={rideCost?.amount || '-'} disabled={true}/>
                    </IonCol>
                    <IonCol size="2" className="label">
                        <StyledInputUnit>{rideCost?.currency || '-'}</StyledInputUnit>
                    </IonCol>
                </StyledInputGroup>
                <RideCostInfoRow rideRouteDistance={distanceValue}
                                 rideCostDistance={!shouldFetchRideCost ? rideCost?.distance : undefined}
                                 rideCostAmount={!shouldFetchRideCost ? rideCost?.amount : undefined}
                />
            </Accordion>
            <Accordion isOpen={true} allowToggle={true} title={t('travelAllowance.ride.passengers')}>
                <PassengersFormField prefix={'ride'}
                                     onChange={(passengers) => {
                                         setSelectedPassengers(passengers);
                                         setShouldFetchRideCost(true);
                                     }}
                                     defaultPassengers={defaultPassengers}
                                     coordinator={coordinator}
                                     placeOfService={placeOfService}
                />
            </Accordion>
        </>
    );
};

export default RideFormFields;
