import React, {useContext, useState} from 'react';
import {StyledSocialButton} from '../../../components/button/button.style';
import FbIcon from '../../../assets/images/fb.svg';
import {useTranslation} from 'react-i18next';
import {getProfile, getRedirectAfterLogin} from '../../../services/profile.service';
import {useHistory} from 'react-router-dom';
import {AuthResult} from '../../../types/auth-result';
import {
    fbConnect,
    fbLogout,
    getFbToken,
    isEmployed,
    logout,
    storeProfile,
    storeToken, useAuth
} from '../../../services/auth.service';
import FbLoginModal from "../modals/fbLoginModal.component";
import {Preferences} from "@capacitor/preferences";
import {UserContext} from "../../../services/auth.service";
import {Links} from "../../links";
import {push} from "../../../services/push.service";
import AppApprovalsModal from "../../mainPage/tabs/settings/modals/appApprovalsModal.component";
import {IonAlert} from "@ionic/react";

const FacebookLogin: React.FC = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const auth = useAuth();

    const [showModal, updateShowModal] = useState<boolean>(false);
    const [showApprovals, updateShowApprovals] = useState<boolean>(false);
	const [showAlert, setShowAlert] = useState(false);

    const showModalOrLogin = async () => {
        let isAlreadyFbUser = await Preferences.get({'key': 'is_already_fb_user'});
        if (isAlreadyFbUser.value === '1') {
            await fbLogin(null);
        } else {
            updateShowModal(true)
        }
    }

    const fbLogin = async (data:any = null) => {
        updateShowModal(false)
		let token = await getFbToken();

        if (token) {
            const response = await fbConnect(token, data)
                .then(response => {
					let authResult = response.data as AuthResult;
					if (authResult.result == 'error' && authResult.message == 'Missing required permission') {
						setShowAlert(true);
						return false;
					} else {
						authResult.is_fb_user = true;
						storeToken(authResult);
						push();
						return true;
					}
                })
                .catch(error => {
                    fbLogout();
                    return false;
                });

            if (response === true) {
                let number = '';
                let terms = null;
                let isExact = false;
                const profileResponse = await getProfile(true)
                    .then(response => {
                        storeProfile(response.data, true);
                        number = response.data.number;
                        isExact = response.data.is_coordinator || response.data.is_team_leader;
                        terms = response.data.ep2_terms_acceptance;
                        return true;
                    })
                    .catch(error => {
                        logout();
                        fbLogout();

                        return false;
                    });

                let redirect = !isExact ? await getRedirectAfterLogin(number) : null;

                if (profileResponse) {
                    if (!isExact && !terms) {
                        updateShowApprovals(true);
                    } else {
                        auth.signIn();
                        if (redirect) {
                            history.replace(redirect);
                        } else {
                            history.replace(Links.main);
                        }
                    }
                }
            }
        }
    };

    return (
        <>
			<IonAlert
				isOpen={showAlert}
				onDidDismiss={() => setShowAlert(false)}
				header={t('login.loginSocialError')}
				buttons={[
					{
						text: t('common.ok'),
						cssClass: 'secondary',
						handler: () => {
							setShowAlert(false)
						}
					}
				]}
			/>
            <StyledSocialButton onClick={() => showModalOrLogin()}>
                <img className={"position-left"} src={FbIcon}/>
                {t("login.loginSocial")}
            </StyledSocialButton>
            <FbLoginModal isOpen={showModal} onClose={() => updateShowModal(false)} onSubmit={(data) => fbLogin(data)}/>
            <AppApprovalsModal withLogout={true} isOpen={showApprovals} onClose={() => updateShowApprovals(false)} onSubmit={async () => {
                updateShowApprovals(false);
                auth.signIn();
                let number = await isEmployed();
                let redirect = await getRedirectAfterLogin(number == '0' ? null : '1');
                if (redirect) {
                    history.replace(redirect);
                }
            }}></AppApprovalsModal>
        </>
    );
}

export default FacebookLogin;
