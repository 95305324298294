import React, {useEffect, useRef, useState} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {
    IonAlert,
    IonButtons,
    IonCol,
    IonGrid,
    IonHeader,
    IonPage, IonRow, isPlatform,
    useIonViewWillEnter,
    useIonViewWillLeave
} from '@ionic/react';
import {
    StyledEmptyContainer,
    StyledIonContentCameraPreview,
    StyledIonTitle,
    StyledIonToolbar
} from '../../components/content/content.style';
import {StyledButton, StyledHeaderButton, StyledHeaderButtonImage} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import CameraPreviewImage from '../../assets/images/takePhoto-small.png';
import {useTranslation} from 'react-i18next';
import {BarcodeScanner, SupportedFormat} from "@capacitor-community/barcode-scanner"
import {StyledContent} from '../takePhotoPage/takePhotoPage.style';
import CircleImage, {ImageTypes} from "../../components/circleImage/circleImage.component";
import {Links} from "../links";
import {findContainerByNumber} from "../../services/reportFactory.service";

interface RouterProps {
    orderId: string;
    specificationId: string;
}

interface ManualContainerProps extends RouteComponentProps<RouterProps> {
}

const QRCodeContainerPage: React.FC<ManualContainerProps> = ({match}) => {

        const {t} = useTranslation();
        const history = useHistory();

        const handleBack = () => {
            history.goBack();
        };

        const {params: {orderId, specificationId}} = match;

        const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
        const [showError, setShowError] = useState<boolean>(false);
        const [error, setError] = useState<string>();
        const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

        useEffect(() => {
            updateHeight();
        })

        const startScan = async () => {
            BarcodeScanner.hideBackground(); // make background of WebView transparent

            await BarcodeScanner.startScanning({
                targetedFormats: [
                    SupportedFormat.ITF,
                    SupportedFormat.ITF_14,
                    SupportedFormat.CODE_39,
                    SupportedFormat.CODE_39_MOD_43,
                    SupportedFormat.CODE_93,
                    SupportedFormat.CODE_128,
                    SupportedFormat.CODABAR
                ]
            }, async (value) => {
                if (value.hasContent && value.content) {
                    let labelName = value.content.charAt(0);
                    switch (labelName) {
                        case 'S':
                            let error = await handleScan(value.content?.replace('S', ''));
                            if (typeof error == 'string') {
                                if (error == 'CONTAINER_NOT_IN_THIS_ORDER') {
                                    setError(t('reportsTab.orders.scanContainerPage.error.container_not_in_this_order'))
                                } else if (error == 'CONTAINER_DONE') {
                                    setError(t('reportsTab.orders.scanContainerPage.error.container_done'))
                                } else if (error == 'NO_CONTAINER') {
                                    setError(t('reportsTab.container.manualContainerFind.error.no_container'))
                                } else {
                                    setError(t('reportsTab.orders.scanSpecificationPage.error.common'))
                                }

                                stopScan()
                                setShowError(true);
                            }
                            break;
                    }
                }
            });
        };

        const handleScan = async (val: any) => {
            let data = await findContainerByNumber(val, orderId);

            if (typeof data == 'string') {
                return data;
            }

            if (data) {
                history.push(`${Links.report}/${orderId}/${specificationId}/0/0/${data}`);
            }
        }


        const checkPermission = async () => {
            // check or request permission
            const status = await BarcodeScanner.checkPermission({force: false});

            if (status.granted) {
                // the user granted permission
                return true;
            }

            if (status.denied) {
                // user denied permission
                return false;
            }

            if (status.restricted || status.unknown) {
                // ios only
                // probably means the permission has been denied
                return false;
            }

            if (status.neverAsked) {
                const statusRequest = await BarcodeScanner.checkPermission({force: true});
                if (statusRequest.granted) {
                    // the user did grant the permission now
                    return true;
                }
            }

            return false;
        }

        const start = async () => {
            let hasPermission = await checkPermission();
            if (!hasPermission) {
                setShowPermissionAlert(true);
            }
            startScan();
            setShowError(false);
            setError('');
        }

        useIonViewWillEnter(() => {
            if (isPlatform('ios') || isPlatform('android')) {
                start();
            }
        });

        const stopScan = () => {
            BarcodeScanner.showBackground();
            BarcodeScanner.stopScan();
        };

        useIonViewWillLeave(() => {
            if (isPlatform('ios') || isPlatform('android')) {
                stopScan();
            }
        });

        const updateHeight = () => {
            if (header.current?.clientHeight == 0) {
                setTimeout(updateHeight);
            } else {
                updateTopEdge(header.current?.clientHeight);
            }
        };

        const header = useRef<HTMLIonHeaderElement>(null);

        return (
            <IonPage>
                <StyledIonContentCameraPreview>
                    <img className="background" src={CameraPreviewImage}/>
                    <IonHeader ref={header} className="ion-no-border">
                        <StyledIonToolbar>
                            <IonButtons slot="start">
                                <StyledHeaderButton onClick={() => handleBack()}>
                                    <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            </IonButtons>
                            <StyledIonTitle>{t('reportsTab.orders.scanContainerPage.title')}</StyledIonTitle>
                        </StyledIonToolbar>
                        <div className="sub-title-light">{t('reportsTab.orders.scanContainerPage.subtitle')}</div>
                    </IonHeader>
                    <StyledContent>
                        <div className="target">
                            {
                                showError &&
                                <StyledEmptyContainer className="preview">
                                    <CircleImage onClick={async (e) => {
                                        start();
                                    }} image={ImageTypes.QR} color="red"/>
                                </StyledEmptyContainer>
                            }
                        </div>
                        <div className={'action-buttons empty multiple-buttons'}>
                            <IonGrid>
                                {showError && <IonRow>
                                    <IonCol size="12">
                                        <p className="errorMessage">{error}</p>
                                    </IonCol>
                                </IonRow>}
                                <IonRow>
                                    <IonCol size="12"><StyledButton
                                        onClick={() => history.push(Links.manualContainerFind + '/' + orderId + '/' + specificationId)}>{t('reportsTab.orders.scanContainerPage.manualButton')}</StyledButton></IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>
                    </StyledContent>
                </StyledIonContentCameraPreview>
                <IonAlert
                    isOpen={showPermissionAlert}
                    onDidDismiss={() => setShowPermissionAlert(false)}
                    header={t('common.permissions.alert')}
                    buttons={[
                        {
                            text: t('common.alertCancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                setShowPermissionAlert(false);
                            },
                        },
                        {
                            text: t('common.permissions.goToAppSettings'),
                            handler: async () => {
                                setShowPermissionAlert(false);
                                BarcodeScanner.openAppSettings();
                            }
                        }
                    ]}
                />
            </IonPage>
        );
    }
;

export default QRCodeContainerPage;
