import {Preferences} from "@capacitor/preferences";

const getWorkerId = async () => {
    const workerId = await Preferences.get({'key': 'worker_id'});

    return workerId.value;
}

const getWorkerNumber = async () => {
    const workerNumber = await Preferences.get({'key': 'employee_number'});

    return workerNumber.value;
}

export {
    getWorkerId,
    getWorkerNumber,
};