import {IonContent, IonList, IonTabs, IonTitle, IonToolbar} from '@ionic/react';
import styled from 'styled-components';


export const StyledIonContent = styled(IonContent)`
  --background: #262532;
  --overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  overflow: hidden;

  ion-header {
    text-align: center;

    .sub-title-light {
      padding: 8px 54px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 140%;
      text-align: center;
      color: #FFFFFF;
    }

    .sub-title {
      text-align: center;
      color: #888A98;
      --color: #888A98;

      small {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 140%;

        img {
          width: 10px;
          height: 10px;
          margin-right: 8px;
        }

        span {
          font-weight: bolder;
        }
      }
    }
  }
  
  &.recruitment {
    --background: #ffffff;
    --padding-top: 20px;
    --padding-bottom: 5px;
  }
`;

export const StyledIonContentCameraPreview = styled(StyledIonContent)`
  --background: transparent;

  img.background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 85vh;
  }
`;

export const StyledIonToolbar = styled(IonToolbar)`
  --ion-safe-area-top: 35px;
  --background: #262532;
  --border-color: #262532;
  --color: #FFFFFF;
  padding: 0 24px;
  color: #FFFFFF;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-align: center;

  &.white {
    --background: #ffffff;
  }
`;

export const StyledIonTitle = styled(IonTitle)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  width: 85%;
  padding: 0;
  text-overflow: ellipsis;
  padding-left: 15%;

  &.left {
    text-align: left;
    width: 100%;
    padding-left: 0;
  }

  &.one-button {
    width: calc(100% - 44px);
  }

  &.two-buttons {
    width: calc(100% - 88px);
  }

  &.margin-start, &.two-buttons {
    margin-left: 44px;
  }

  .wrap-text {
    text-overflow: unset;
    white-space: pre-wrap;
  }
`;


export const StyledSteps = styled.div`
  .stepper-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    color: #ccc;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 1px solid #ccc;
    width: 100%;
    top: 7px;
    left: -50%;
    z-index: 2;
  }

  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 1px solid #ccc;
    width: 100%;
    top: 7px;
    left: 50%;
    z-index: 2;
  }

  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #262532;
    margin-bottom: 6px;
    border: 1px solid #ccc;
  }

  .stepper-item.active {
    font-weight: bold;
    color: #fff;
  }
  
  .stepper-item .step-name {
    width: 80%;
    text-overflow: ellipsis;
  }

  .stepper-item.active .step-counter {
    background-color: #0085FF;
    border: 5px solid #fff;
  }

  .stepper-item.completed .step-counter {
    background-color: #0085FF;
    color: #ccc;
    border: none;
  }

  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 1px solid #0085FF;
    width: 100%;
    top: 7px;
    left: 50%;
    z-index: 3;
  }

  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
`;



export const StyledIonTabs = styled(IonTabs)`
  ion-tab-bar {
    bottom: 0px;
    background: #FFFFFF;
    position: absolute;
    height: 92px;
    width: 100%;
    left: 0;
    box-shadow: 0px 16px 100px rgba(181, 183, 198, 0.6);
    border-radius: 20px 20px 0 0;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
  }

  ion-tab-button {
    border-top: 2px solid transparent;
    display: inline-flex;
    justify-content: start;
    white-space: nowrap;

    img.active, ion-icon.active {
      display: none;
    }

    &::part(native) {
      display: inline-flex;
      justify-content: center;
    }

    &.tab-selected {
      &::before {
        content: '';
        border-top: 2px solid #0085FF;
        width: 33px;
        position: absolute;
        top: 0;
      }

      ion-label {
        color: #0085FF;
      }

      img.active, ion-icon.active {
        display: unset;
      }

      img.non-active, ion-icon.non-active {
        display: none;
      }
    }

    ion-label {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 200%;
      text-align: center;
      color: #888A98;
    }

    ion-icon, img {
      width: 18px;
      height: 18px;
      display: inline-block;

      &.profile {
        background-color: #888A98;
        border-radius: 8px;
      }
    }
  }
`;

export const StyledDataTable = styled.div`
margin-top: 32px;
width: 100%;

&.no-margin {
  margin: 0;
}

.data-row {
  width: 100%;
  border-bottom: 1px solid #F6F6F8;
  display: grid;
  grid-template-columns: 30% 60% 10%;
  grid-template-rows: auto auto;
  min-height: 43px;

  &.no-line {
    border-bottom: none;
  }

  &.separator {
    margin-bottom: 32px;
  }

  .data-title {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;

    display: flex;
    align-items: center;

    font-weight: 500;
    font-size: 11px;
    line-height: 13px;

    color: #888A98;
  }

  .data-icon-title {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;

    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: 13px;
    line-height: 16px;

    color: #000000;

    padding: 10px 0;


    &.min-height {
      min-height: 36px;
    }

    img {
      margin-right: 18px;
      width: 15px;
    }

    &.blue {
      font-size: 13px;
      line-height: 16px;
      color: #0084FF;
    }

    .avatar {
      position: relative;
      display: inline-block;
      min-width: 56px;
      min-height: 56px;
      border-radius: 28px;

      &.active::before {
          content: '';
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          left: 40px;
          top: 40px;
          z-index: 1;
          border-radius: 10px;

          background: #7FDE35;
          border: 2px solid #FFFFFF;
      }

      img {
          width: 56px;
          height: 56px;
          border-radius: 28px;
      }
    }
    
    &.red::before {
            content: '';
            display: block;
            position: relative;
            width: 10px;
            height: 10px;
            left: 40px;
            top: 14px;
            z-index: 1;
            border-radius: 10px;
    
            background: red;
            border: 2px solid #FFFFFF;
        }
        
        &.green::before {
            content: '';
            display: block;
            position: relative;
            width: 10px;
            height: 10px;
            left: 40px;
            top: 14px;
            z-index: 1;
            border-radius: 10px;
    
            background: #7FDE35;
            border: 2px solid #FFFFFF;
        }
        
        &.orange::before {
            content: '';
            display: block;
            position: relative;
            width: 10px;
            height: 10px;
            left: 40px;
            top: 14px;
            z-index: 1;
            border-radius: 10px;
    
            background: orange;
            border: 2px solid #FFFFFF;
        }
    
    .avatar {
      width: 36px;
      min-width: 36px;
      height: 36px;
      min-height: 36px;
      border-radius: 18px;
      margin-bottom: 6px;
      margin-top: 6px;
    }
  }

  .data-value {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;

    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: 13px;
    line-height: 16px;

    color: #000000;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    &.no-cut {
      word-break: break-all;
    }

    &.long-text {
    	overflow-wrap: anywhere;
    }
  }

  .data-action {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      width: 12px;
      height: 12px;
    }
  }

  .data-details {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;

    &.extended {
      grid-column-end: 4;
    }

    &.small {
      font-weight: normal;
      font-size: 12px;
      line-height: 140%;
      color: #888A98;
    }

      margin-bottom: 16px;
  }

    .form-type-content {
       font-size: 14px;
        margin-left: 10px;
        color: #000000;
    }
}
`;

export const StyledEmptyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .top {
    position: absolute;
    top: 0;
  }
  
  &.preview {
    top: -160px;
  }

  h2 {
    margin-top: 38px;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #000000;
  }

  h3 {
    margin-top: 38px;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #000000;
  }

  .avatar {
    width: 100px;
    min-width: 100px;
    height: 100px;
    min-height: 100px;
    border-radius: 48px;
  }
`;
