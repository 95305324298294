import { CreateAnimation, createAnimation, IonCol, IonGrid, IonRow } from '@ionic/react';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {StyledActionIonModal, StyledActionSheet, StyledIonModal} from '../../../../components/modal/modal.style';
import {StyledDisclaimer, StyledWalletGrid} from '../../panes/wallet.style';
import { EmployerWallet, MonthWallet, WorkingHour } from '../../panes/walletPane.component';
import moment from 'moment';
import CloseImage from '../../../../assets/images/e-remove.svg';
import { StyledWalletDetailsActionSheetContainer } from './walletDetailsActionSheet.style';
import { StyledPaneBar, StylePaneGrabber } from '../../../../components/pane/pane.style';
import { useSwipeable } from 'react-swipeable';
import WalletHourlyDetailsActionSheet from '../walletHourlyDetailsActionSheet/walletHourlyDetailsActionSheet.component';
import {minutesToHoursMinutes} from "../../../../utils/tools/time";
import { groupWalletByDate } from '../../../../utils/tools/wallet';
import ModalService from '../../../../services/modal.service';

type WalletDetailsActionSheetProps = {
    isVisible: boolean;
    wallet?: MonthWallet;
    onDismiss: () => void;
}

const WalletDetailsActionSheet: React.FC<WalletDetailsActionSheetProps> = (props: WalletDetailsActionSheetProps) => {

    const [isVisible, changeVisibility] = useState(false);
    const [hiding, startHiding] = useState(false);
    const [showDetails, updateShowDetails] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (isVisible !== props.isVisible) {
            if (props.isVisible) {
                ModalService.modalOpened(props.onDismiss);
                startHiding(false);
                changeVisibility(props.isVisible);
            } else {
                ModalService.modalDismissed();
                startHiding(true);
                setTimeout(() => changeVisibility(props.isVisible), 275);
            }
        }
    }, [isVisible, props.isVisible]);

    const [workingHours, setWorkingHours] = useState<WorkingHour[]>([]);
    useEffect(() => {
        if (!props.wallet) {
            return;
        }

        let newWorkingHours: WorkingHour[] = [];

        for (let employer of props.wallet.employers) {
            newWorkingHours = [...newWorkingHours, ...employer.workingHours];
        }

        setWorkingHours(groupWalletByDate(newWorkingHours));
    }, [props.wallet]);

    const getTotalHours = (): number => {
        const sum = workingHours.reduce((prev: number, curr: WorkingHour) => {
            return prev + curr.quantity;
        }, 0);

        return sum;
    };

    const getTotal = (): number => {
        const sum = workingHours.reduce((prev: number, curr: WorkingHour) => {
            return prev + (curr.quantity * curr.rate);
        }, 0);

        return sum;
    };

    const config = {
        delta: 10,                            // min distance(px) before a swipe starts
        preventDefaultTouchmoveEvent: false,  // call e.preventDefault *See Details*
        trackTouch: true,                     // track touch input
        trackMouse: false,                    // track mouse input
        rotationAngle: 0,                     // set a rotation angle
      };

    const handlers = useSwipeable({
        onSwipedDown: (eventData) => {
            props.onDismiss();
        },
        ...config,
    });

    const [currentDate, setCurrentDate] = useState<Date|undefined>();
    const [hourlyWallet, setHourlyWallet] = useState<MonthWallet>();
    const displayHourlyDetails = (date: Date|undefined = undefined) => {
        if (!props.wallet) {
            return;
        }

		setCurrentDate(date);

        if (date === undefined) {
            setHourlyWallet(props.wallet);
        } else {
            const dateWallet: MonthWallet = {
                month: props.wallet?.month,
                employers: [],
                addons: [],
                cuts: [],
                apartmentFees: []
            };

            props.wallet?.employers.forEach((employer) => {
                const empl: EmployerWallet = {
                    name: employer.name,
                    workingHours: []
                }

                employer.workingHours.forEach((wh) => {
                    if (moment(wh.date).format('yyyyMMDD') === moment(date).format('yyyyMMDD')) {
                        empl.workingHours.push(wh);
                    }
                });

                if (empl.workingHours.length > 0) {
                    dateWallet.employers.push(empl);
                }
            });

            setHourlyWallet(dateWallet);
        }

        updateShowDetails(true);
    }

    return isVisible ? (
            <StyledIonModal
                isOpen={props.isVisible}
                onDidDismiss={() => props.onDismiss()}
                initialBreakpoint={0.99}
                breakpoints={[0, 0.65, 0.99]}
            >
                <StyledWalletDetailsActionSheetContainer className={hiding ? 'hide': 't-p-50'} onClick={(e) => e.stopPropagation()}>
                    <img src={CloseImage} className="close" onClick={(e) =>{ props.onDismiss(); e.stopPropagation()} } />
                    <StyledWalletGrid onClick={() => displayHourlyDetails() }>
                        <div className="title">{t("reportsTab.wallet.detailsView")}</div>
                        <IonGrid>
                            <IonRow>
                                <IonCol className="aligment header" size="5.5">
                                {t("reportsTab.wallet.date")}
                                </IonCol>
                                <IonCol className="aligment end  header" size="3">
                                {t("reportsTab.wallet.workingHoursCount")}
                                </IonCol>
                                <IonCol className="aligment end header" size="3.5">
                                {t("reportsTab.wallet.toPay")}<span className="required">*</span>
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <IonGrid className="content">
                                { workingHours && workingHours.length > 0 &&
                                    workingHours.map((workingHour, key) =>
                                    <IonRow key={key} onClick={(event) => { displayHourlyDetails(workingHour.date); event.stopPropagation(); } }>
                                        <IonCol className="aligment row" size="5.5">
                                            {moment(workingHour.date).format('YYYY-MM-DD')}
                                        </IonCol>
                                        <IonCol className="aligment end row value" size="3">
                                            {minutesToHoursMinutes(workingHour.quantity * 60)}
                                        </IonCol>
                                        <IonCol className="aligment end row value" size="3.5">
                                            {(workingHour.quantity * workingHour.rate * 1).toFixed(2)}
                                        </IonCol>
                                    </IonRow>
                                    )
                                }

							{ workingHours && workingHours.length == 0 &&
								<IonRow>
									<IonCol className="aligment row" size="12">
										{t("reportsTab.wallet.noHours")}
									</IonCol>
								</IonRow>
							}

						</IonGrid>

						<IonGrid>

							{ workingHours && workingHours.length > 0 &&
								<IonRow className="footer">
									<IonCol className="aligment footer" size="5.5">
									{t("reportsTab.wallet.sum")}
									</IonCol>
									<IonCol className="aligment end footer" size="3">
										{minutesToHoursMinutes(getTotalHours() * 60)}
									</IonCol>
									<IonCol className="aligment end footer" size="3.5">
										{(Math.floor(getTotal() * 100)/100).toFixed(2)}
									</IonCol>
								</IonRow>
							}
						</IonGrid>
                    </StyledWalletGrid>
                    <StyledDisclaimer>*{t("reportsTab.wallet.toPayDisclaimer")}</StyledDisclaimer>
                </StyledWalletDetailsActionSheetContainer>
            <WalletHourlyDetailsActionSheet isVisible={showDetails} onDismiss={() => updateShowDetails(false)} wallet={hourlyWallet} date={currentDate}></WalletHourlyDetailsActionSheet>
            </StyledIonModal>
    ) : (<span></span>);
};

export default WalletDetailsActionSheet;
