import React, {useContext, useEffect, useRef, useState} from 'react';
import {StyledComponentFormContainer} from './reportRowForm.style';
import {IonAlert, IonCol, IonGrid, IonItem, IonRow} from '@ionic/react';
import {
    Component,
    Defect,
    DefectGroup,
    Order,
    Report,
    ReportPageContext,
    ReportRow,
    ReportRowComments,
    ReportRowComponent,
    ReportRowFile,
    ReportRowMeasurement,
    ReportRowWorker
} from '../../reportPage.component';
import {useTranslation} from 'react-i18next';
import {
    StyledButton,
    StyledButtonBadge,
    StyledCircleButton,
    StyledSocialButton
} from '../../../../components/button/button.style';
import {StyledInput} from '../../../../components/form/input/input.style';
import ArrowDownImage from '../../../../assets/images/down-arrow.svg'
import ArrowRightImage from '../../../../assets/images/arrow-right.svg';
import AvatarImage from '../../../../assets/images/logo/x-gray.png'
import {
    FlameImage,
    ClockBlueImage,
    AddPersonImage,
    CommentImage,
    PhotosImage,
    TrashImage,
    TrashRedImage
} from "../../../../utils/styles/icons";
import CommentsModal from '../../modals/commentsModal.component';
import ListModal from '../../modals/listModal.component';
import {useHistory} from 'react-router-dom';
import {Links} from '../../../links';
import {setWorkerConfiguration} from '../../../../services/qrCodePage.service';
import {
    canScanNextComponent,
    getMeasuringEquipments,
    getReportPrevMoment,
    getReportTimepickerMinutesIterations,
    getWorkerData,
    hoursHumanized,
    orderComponentsNumbers,
    orderDefects,
    validateHoursPassingMidnight,
    validateMeasurements,
    validateReportRowQuantity
} from "../../../../services/report.service";
import {Preferences} from "@capacitor/preferences";
import {Profile} from "../../../../models/profile";
import ExtendedWorkerModal from "../../modals/extendedWorkerModal.component";
import DateInput from "../../../../components/form/input/date.component";
import MultipleExtendedWorkerModal from "../../modals/multipleExtendedWorkerModal.component";
import FilesModal from "../../modals/filesModal.component";
import MeasurementsModal from "../../modals/measurementsModal.component";
import {ISO_8601} from "moment/moment";
import ReportRowComponentForm from "./reportRowComponentForm.component";
import AddRoundImage from "../../../../assets/images/add-round-blue.svg";
import {StyledIonLabel} from "../../../../components/form/input/label.style";
import {StyledIonCheckbox} from "../../../../components/form/input/checkbox.style";
import {
    orderComponentsNumbers as orderComponentsNumbersFactory,
    orderDefects as orderDefectsFactory,
    getWorkerData as getWorkerDataFactory
} from "../../../../services/reportFactory.service"
import ReportRowComponentsModal from "../../modals/reportRowComponentsModal.component";
import {PricingType} from "../../../../enums/pricing";

import {prevMoment} from "../../../../utils/tools/time";
import TimeInput from "@components/form/input/time.component";
import {getCurrentNetworkStatus} from "../../../../services/offlineSync.service";
import {useEvent} from "../../../../hooks/useEvent";
import {EventType} from "../../../../enums/eventType";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
moment.tz.setDefault('Europe/Warsaw');

export interface DataSelectable {
    id: string;
    name: string;
    manual?: boolean;
    order?: Order;
}

export interface RemoveWorkerState {
    showAlert: boolean;
    worker?: ReportRowWorker;
    workerIndex?: number;
}

export interface RemoveDefectState {
    showAlert: boolean;
    defect?: Defect;
    defectGroupIndex?: number;
    defectIndex?: number;
}

export interface RemoveDefectGroupState {
    showAlert: boolean;
    defectGroup?: DefectGroup;
    defectGroupIndex?: number;
}

export interface RemoveRowState {
    showAlert: boolean;
    row?: ReportRow;
}

export interface ComponentMax {
    showAlert: boolean;
    row?: ReportRow;
}

export interface ComponentMax {
    showAlert: boolean;
    row?: ReportRow;
}

export type ReportRowProps = {
    orderId?: string,
    reportId?: string,
    profile?: Profile,
    specificationId?: string,
    orderType?: string,
    logisticContainerComponent?: Component,
    row: ReportRow;
    report: Report;
    order?: Order;
    reportPreview: boolean;
    maxInContainer: boolean;
    maxComponents?: number;
    hideOtherPersons?: boolean;
    onChange: (r: ReportRow) => void,
    onRemove: (r: ReportRow) => void,
    onSubmit: () => void,
}

const ReportRowForm: React.FC<ReportRowProps> = (props: ReportRowProps) => {
    const {t} = useTranslation();
    const {dispatch} = useEvent();

    const history = useHistory();
    const [row, setRow] = useState<ReportRow>(props.row);
    const [showComments, updateShowComments] = useState<boolean>(false);
    const [showFiles, updateShowFiles] = useState<boolean>(false);
    const [showMeasurements, updateShowMeasurements] = useState<boolean>(false);
    const [showComponentNumbers, updateShowComponentNumbers] = useState<boolean>(false);
    const [showDefectList, updateShowDefectList] = useState<boolean>(false);
    const [selectedDefect, setSelectedDefect] = useState<Defect>();
    const [selectedWorker, updateSelectedWorker] = useState<ReportRowWorker | null>();
    const [selectedRow, updateSelectedRow] = useState<ReportRow | null>();
    const [showExtendedWorkerModal, updateShowExtendedWorkerModal] = useState<boolean>(false);
    const [showMultiWorkersModal, updateShowMultiWorkersModal] = useState<boolean>(false);

    const [selectedDefectIndex, setSelectedDefectIndex] = useState<number>();
    const [selectedDefectGroupIndex, setSelectedDefectGroupIndex] = useState<number>();
    const [commentsCount, setCommentsCount] = useState<number>();
    const [componentNumbers, updateComponentNumbers] = useState<DataSelectable[]>();
    const [defects, updateDefects] = useState<DataSelectable[]>();
    const [moreDefects, updateMoreDefects] = useState<DataSelectable[]>();
    const [measurements, setMeasurements] = useState<ReportRowMeasurement[]>([]);

    const reportPageContext = useContext(ReportPageContext);

    const [removeWorkerState, updateRemoveWorkerState] = useState({
        showAlert: false,
        worker: undefined,
        workerIndex: undefined
    } as RemoveWorkerState);
    const [removeDefectState, updateRemoveDefectState] = useState({
        showAlert: false,
        defect: undefined,
        defectIndex: undefined,
        defectGroupIndex: undefined
    } as RemoveDefectState);
    const [removeDefectGroupState, updateRemoveDefectGroupState] = useState({
        showAlert: false,
        defectGroup: undefined,
        defectIndex: undefined
    } as RemoveDefectGroupState);
    const [removeRowState, updateRemoveRowState] = useState({showAlert: false, row: undefined} as RemoveRowState);
    const [showComponentsModal, setShowComponentsModal] = useState<boolean>(false);
    const [lastComponent, setLastComponent] = useState<ReportRowComponent>();

    const [componentMax, updateComponentMax] = useState({showAlert: false, row: undefined} as ComponentMax);

    const addDefect = (defectGroupIndex: number) => {
        const r = {...props.row};
        const d = r.defectGroups[defectGroupIndex];

        if (defects && defects.length == 1) {
            if (d.defects) {
                d.defects = [...d.defects, {
                    name: defects[0].name,
                    id: defects[0].id,
                    defect: defects[0],
                }];
            }
        } else {
            if (d.defects) {
                d.defects = [...d.defects, {
                    name: '',
                }];
            }
        }

        updateDefectGroup(d, defectGroupIndex);

        props.onChange(r);
    }

    const addDefectGroup = () => {
        const r = {...props.row};

        if (defects && defects.length == 1) {
            r.defectGroups = [...r.defectGroups, {
                quantity: undefined,
                defects: [
                    {
                        name: defects[0].name,
                        id: defects[0].id,
                        defect: defects[0],
                    }
                ]
            }];
        } else {
            r.defectGroups = [...r.defectGroups, {
                quantity: undefined,
                defects: [
                    {
                        name: ''
                    }
                ]
            }];
        }

        props.onChange(r);
    }

    const addComponent = () => {
        const r = {...props.row};

        if (r.components) {
            let components = r.components;

            components.unshift({
                comment: '',
                defectGroups: [],
                files: [],
                componentNumberText: '',
                quantity: props.order?.enable_single_components ? '1' : '',
                deliveryNote: '',
                levNumber: '',
                serialNumber: '',
                batchNumber: '',
                materialNumber: '',
                new: true,
                id: Math.random().toString(),
            })
            r.components = components;

            props.onChange(r);
        }
    }

    const countRowComments = () => {
        let commentsCount = 0;

        commentsCount += (props.row.comments.comment ? 1 : 0);
        commentsCount += (props.row.comments.partNumber ? 1 : 0);
        commentsCount += (props.row.comments.additionalInfoA ? 1 : 0);
        commentsCount += (props.row.comments.additionalInfoB ? 1 : 0);

        return commentsCount;
    }

    const updateComments = (comments: ReportRowComments) => {
        const r = {...props.row};
        r.comments = {...comments}

        let commentsCount = 0;

        commentsCount += (comments.comment ? 1 : 0);
        commentsCount += (comments.partNumber ? 1 : 0);
        commentsCount += (comments.additionalInfoA ? 1 : 0);
        commentsCount += (comments.additionalInfoB ? 1 : 0);

        setCommentsCount(commentsCount);

        props.onChange(r);
    }

    const updateSkipMeasurements = (value: boolean) => {
        row.skipMeasurements = value;
        props.onChange(row);
    }

    const updateFiles = (files: ReportRowFile[]) => {
        const r = row;
        r.files = files
        updateShowFiles(false)
        props.onChange(r);
    }

    const updateMeasurements = (measurements: ReportRowMeasurement[]) => {
        const r = row;
        r.measurements = measurements

        updateShowMeasurements(false)
        props.onChange(r);
    }

    const updateDefectField = (defect: DefectGroup, field: string, value: string | number, key: number) => {
        let isNum = value.toString().match(/^[0-9]+$/) != null;
        let valueNumeric = undefined;
        if (isNum) {
            valueNumeric = parseInt(value.toString());
        } else {
            if (value === '') {
                valueNumeric = undefined;
            } else {
                valueNumeric = 0;
            }
        }

        switch (field) {
            case 'quantity':
                updateDefectGroup({...defect, quantity: valueNumeric}, key);
                break;
            case 'repaired':
                updateDefectGroup({...defect, repaired: valueNumeric}, key);
                break;
            case 'notRepaired':
                updateDefectGroup({...defect, notRepaired: valueNumeric}, key);
                break;
        }
    }

    const updateDefectGroup = (defect: DefectGroup, index: number) => {
        const r = {...props.row};
        r.defectGroups.splice(index, 1, defect);
        updateShowDefectList(false);
        props.onChange(r);
    }

    const updateComponent = (component: ReportRowComponent, index: number) => {
        const r = {...props.row};
        r.components?.splice(index, 1, component);
        props.onChange(r);
    }

    const updateComponentNumber = (componentNumber: DataSelectable) => {
        const r = {...props.row};

        r.component.id = componentNumber.id;
        r.component.name = componentNumber.name;
        updateShowComponentNumbers(false);

        props.onChange(r);
    }

    const updateSelectedDefect = (defect: DataSelectable) => {
        const r = {...props.row};

        const group = selectedDefectGroupIndex !== undefined ? r.defectGroups[selectedDefectGroupIndex] : null;
        if (group && group.defects) {
            const d = selectedDefectIndex !== undefined ? group.defects[selectedDefectIndex] : null;

            if (d) {
                d.id = defect.id;
                d.name = defect.name;
                d.defect = defect;
                d.other = defect.manual ? defect.name : undefined;
            }
            updateShowDefectList(false);

            props.onChange(r);
        }
    }

    const updateFromTime = (worker: ReportRowWorker, time: string) => {
        worker.from = time;
        props.onChange(row);
    }

    const updateToTime = (worker: ReportRowWorker, time: string) => {
        worker.to = time;
        props.onChange(row);
    }

    const updateDeliveryDate = (time: string) => {
        row.deliveryDate = time;

        props.onChange(row);
    }

    const updateWorker = (worker: ReportRowWorker) => {

        // if (row.workers.find((ah) => ah.personId === worker.personId)) {
        //     return;
        // }

        const r = {...props.row};

        r.workers = [...r.workers, worker];

        props.onChange(r);
    }

    const updateComponentQuantity = (quantity: number | string) => {
        const r = {...props.row};

        let valueNumeric = undefined;
        let isNum = quantity.toString().match(/^[0-9]+$/) != null;
        if (isNum) {
            valueNumeric = parseInt(quantity.toString());
        } else {
            if (quantity === '') {
                valueNumeric = '';
            } else {
                valueNumeric = 0;
            }
        }

        if (props.maxComponents && valueNumeric && valueNumeric > props.maxComponents) {
            updateComponentMax({showAlert: true, row: r})
        }

        r.quantity = valueNumeric;
        props.onChange(r);
    }

    const updateExtendedWorker = (updatedWorker: ReportRowWorker) => {
        if (selectedWorker) {
            selectedWorker.to = updatedWorker.to;
            selectedWorker.from = updatedWorker.from;
            selectedWorker.workerOvertime = updatedWorker.workerOvertime;
            selectedWorker.clientOvertime = updatedWorker.clientOvertime;
            selectedWorker.pricingRate = updatedWorker.pricingRate;
            selectedWorker.rate = updatedWorker.rate;

            props.onChange(row);
        }

        updateSelectedWorker(null);
        updateShowExtendedWorkerModal(false);
    }

    const updateExtendedRow = (updatedRow: ReportRow) => {
        if (selectedRow) {
            selectedRow.workers = updatedRow.workers;

            props.onChange(selectedRow);
        }

        updateSelectedRow(null);
        updateShowMultiWorkersModal(false);
    }

    const selectDefect = (defectGroupIndex: number, defect: Defect, index: number) => {
        setSelectedDefect(defect);
        setSelectedDefectIndex(index);
        setSelectedDefectGroupIndex(defectGroupIndex);
        updateShowDefectList(true);
    };

    const addPerson = () => {
        setWorkerConfiguration({
            title: 'reportsTab.orders.scanPersonPage.title',
            subtitle: 'reportsTab.orders.scanPersonPage.subtitle',
            manualButtonText: 'reportsTab.orders.scanPersonPage.search',
            errorText: 'reportsTab.orders.scanPersonPage.error',
            handleManualButton: () => {
                history.replace(Links.choosePerson + '/' + props.orderId + '/' + moment(props.report.serviceDate).format('DD-MM-YYYY'))
            },
            handleScan: async (val: any) => {
                if (props.orderId) {
                    let workerData: any = await getWorkerDataFactory(props.orderId, val, moment(props.report.serviceDate).format('DD-MM-YYYY'));
                    if (workerData === null) {
                        return false;
                    }

                    if (workerData) {
                        updateWorker({
                            personId: workerData.id,
                            name: workerData.name,
                            avatarUrl: workerData.user?.photos?.thumbnail ? workerData.user?.photos?.thumbnail : workerData.user?.avatar,
                            from: undefined,
                            number: workerData.number,
                            to: undefined,
                        });
                    }

                    history.goBack();
                }
            }
        });

        history.push(Links.qrCodeWorker);
    }

    const addPersonExtended = () => {
        setWorkerConfiguration({
            title: 'reportsTab.orders.scanPersonPage.title',
            subtitle: 'reportsTab.orders.scanPersonPage.subtitle',
            manualButtonText: 'reportsTab.orders.scanPersonPage.manualButton',
            errorText: 'reportsTab.orders.scanPersonPage.error',
            handleManualButton: () => {
                history.replace(Links.choosePersonExtended + '/' + props.orderId + '/' + props.specificationId + '/' + moment(props.report.serviceDate).format('DD-MM-YYYY') + '/' + props.reportId)
            },
            handleExtendedSelected: async (worker: ReportRowWorker) => {
                if (props.orderId && worker.number) {
                    let workerData: any = await getWorkerDataFactory(props.orderId, worker.number, moment(props.report.serviceDate).format('DD-MM-YYYY'));
                    if (workerData === null) {
                        return false;
                    }

                    if (workerData) {
                        updateWorker(worker);
                    }

                    history.goBack();
                }
            }
        });

        history.push(Links.choosePersonExtended + '/' + props.orderId + '/' + props.specificationId + '/' + moment(props.report.serviceDate).format('DD-MM-YYYY') + '/' + props.reportId)
    }

    useEffect(() => {
        setRow({...props.row})

        if (commentsCount === undefined) {
            updateComments(props.row.comments)
        }
    }, [props.row])

    useEffect(() => {
        if (props.row.deliveryDate) {
            updateDeliveryDate(props.row.deliveryDate);
        }
    }, [])

    const fetchComponents = async () => {
        if (componentNumbers === undefined && props.orderId) {
            await orderComponentsNumbersFactory(props.orderId)
                .then(response => {
                    updateComponentNumbers(response);
                    const r = {...props.row};

                    let result = response;
                    if (result.length == 1) {
                        result.forEach((value: any) => {
                            r.component.id = value.id;
                            r.component.name = value.name;
                            props.onChange(r);
                        })
                    }else if(props.logisticContainerComponent){
                        result.forEach((value: any) => {
                            if (value.id == props.logisticContainerComponent?.id){
                                r.component.id = props.logisticContainerComponent?.id;
                                r.component.name = props.logisticContainerComponent?.number;
                                props.onChange(r);
                                return;
                            }
                        })
                    }
                });
        }
    }

    const fetchDefects = async () => {
        if (defects === undefined && props.specificationId && props.specificationId != '0') {
            updateDefects([]);
            updateMoreDefects([]);
            let tmpDefects:DataSelectable[] = [];

            await orderDefectsFactory(props.specificationId)
                .then(response => {
                    for(let defect of response.specification_defects) {
                        let d = defect as DataSelectable;
                        d.order = props.order;
                        tmpDefects.push(d);
                    }
                    updateMoreDefects(response.general_defects);
                });

            props.report.additionalOrders.map(async (order) => {
                await orderDefects(order.specification.id)
                    .then(response => {
                        for(let defect of response.data.specification_defects) {
                            let d = defect as DataSelectable;
                            d.order = order;
                            tmpDefects.push(d);
                        }
                    });
            });

            updateDefects(tmpDefects);
        }
    }

    const addCurrentWorker = async () => {
        let number = await Preferences.get({'key': 'employee_number'});
        if (number.value && props.orderId) {
            let workerData: any = await getWorkerDataFactory(props.orderId, number.value, moment(props.report.serviceDate).format('DD-MM-YYYY'));
            if (workerData) {
                updateWorker({
                    personId: workerData.id,
                    name: workerData.name,
                    avatarUrl: workerData.user?.photos?.thumbnail ? workerData.user?.photos?.thumbnail : workerData.user?.avatar,
                    from: undefined,
                    to: undefined,
                });
            }
        }
    }

    const removeWorker = (worker: ReportRowWorker, index: number) => {
        const r = {...props.row};
        r.workers.splice(index, 1);

        props.onChange(r);
    }

    const removeDefectGroup = (defectGroup: DefectGroup, index: number) => {
        const r = {...props.row};
        r.defectGroups.splice(index, 1);

        props.onChange(r);
    }

    const removeDefect = (defectGroupIndex: number, defect: Defect, index: number) => {
        const r = {...props.row};
        const d = r.defectGroups[defectGroupIndex];

        if (d.defects) {
            d.defects.splice(index, 1);
        }

        updateDefectGroup(d, defectGroupIndex);

        props.onChange(r);
    }

    const removeComponent = (component: ReportRowComponent, index: number) => {
        const r = {...props.row};
        r.components?.splice(index, 1);

        props.onChange(r);
    }

    const fetchMeasurements = async () => {
        const networkStatus = await getCurrentNetworkStatus();
        if (props.specificationId && networkStatus) {
            await getMeasuringEquipments(props.specificationId, moment(props.report.serviceDate).format('DD-MM-YYYY'))
                .then(response => {
                    setMeasurements(response.data);
                });


        }
    }
    const {listen} = useEvent();
    useEffect(() => {
        fetchComponents();
        fetchDefects();
        fetchMeasurements();
        listen(EventType["REPORT.DEFECTS.RELOAD"], fetchDefects);
    }, []);


    useEffect(() => {
        if (!row.skipMeasurements) {
            if (row.measurements && measurements.length === row.measurements.filter(value => value.hide).length) {
                updateSkipMeasurements(true);
            }
        }
    }, [measurements, row.measurements]);

    const handleSkipMeasurements = (skip: boolean) => {
        updateSkipMeasurements(skip);
        if (skip) {
            let tmp = measurements;
            tmp = tmp.map(el => ({...el, hide: true}));
            updateMeasurements(tmp);
        }
    }

    const rowRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (row.components && row.components.length > 0) {
            setLastComponent(row.components[0]);
        }
    }, [row.components]);

    return (
        <div ref={rowRef}>
            <StyledComponentFormContainer>
                <IonGrid>
                    <IonRow>
                        <IonCol size="1" offset="11">
                            {!props.reportPreview && <StyledCircleButton className="light-red"
                                                                         onClick={() => updateRemoveRowState({
                                                                             showAlert: true,
                                                                             row: row
                                                                         })}><img
                                src={TrashImage}/></StyledCircleButton>}
                        </IonCol>
                    </IonRow>

                    {props.specificationId != '0' && <>
                        <IonRow>
                            <IonCol size="6" className={((props.report.additionalOrders && props.report.additionalOrders.length === 0) || props.profile) ? "label required" : "label"}>
                                {props.order && props.order.is_extended_reporting ? t('reportPage.reportForm.nestedReportingComponentNumber') : t('reportPage.reportForm.componentNumber')}
                            </IonCol>
                            <IonCol size="6" className="label required">
                                {t('reportPage.reportForm.componentQuantity')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            {((props.report.additionalOrders && props.report.additionalOrders.length === 0) || props.profile) &&
                                <IonCol size="6" className="select report-row-component-number" onClick={() => {
                                    if (!props.reportPreview) {
                                        updateShowComponentNumbers(true)
                                    }
                                }}>
                                    <StyledInput readOnly={true} disabled={!!props.logisticContainerComponent}
                                                 className={reportPageContext.getFormSubmitted() && !row.component.name ? 'hasErrors' : ''}
                                                 value={row.component.name} placeholder={t("common.select")}/>
                                    <img className="down" src={ArrowDownImage}/>
                                </IonCol>
                            }

                            {props.report.additionalOrders && props.report.additionalOrders.length > 0 && !props.profile &&
                                <IonCol size="6" className="select">
                                    <StyledInput readOnly={true}
                                                 placeholder={t("reportsTab.orders.scanSpecificationPage.error.multiple_orders_components_not_required")}/>
                                </IonCol>
                            }

                            {props.reportPreview &&
                                <IonCol size="6" className="select">
                                    {row.component.name && <StyledInput readOnly={true}
                                                 value={row.component.name} placeholder={t("common.select")}/>}

                                    {!row.component.name && <StyledInput readOnly={true}
                                                 placeholder={t("reportsTab.orders.scanSpecificationPage.error.multiple_orders_components_not_required")}/>}
                                </IonCol>
                            }
                            <IonCol size="6">
                                <StyledInput disabled={props.reportPreview || props.order?.is_extended_reporting}
                                             type="text" pattern="[0-9]*" inputMode="tel" min={0}
                                             onKeyPress={event => {
                                                 if (event.key == 'Enter' && !props.reportPreview) {
                                                     const input = event.target as HTMLInputElement;
                                                     input.blur();
                                                 }
                                             }}
                                             className={reportPageContext.getFormSubmitted() && props.order && ((!row.quantity && row.quantity !== 0) || !validateReportRowQuantity(props.order, row)) ? 'report-row-component-number hasErrors' : 'report-row-component-number'}
                                             onChange={(e) => updateComponentQuantity(e.target.value ?? undefined)}
                                             value={row.quantity}
                                             placeholder={t('reportPage.reportForm.enter')}
                                />
                            </IonCol>
                        </IonRow>

                        {
                            !props.order?.is_extended_reporting &&
                            <>
                                <IonRow>
                                    <IonCol size="12" className="label">
                                        {t('reportPage.reportForm.deliveryDate')}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12" className="date-field">
                                        <DateInput
                                            presentation="date"
                                            disabled={props.reportPreview}
                                            format="DD.MM.YYYY"
                                            value={row.deliveryDate}
                                            onChange={(value) => {
                                                updateDeliveryDate(value);
                                            }}
                                            cancelText={t("common.dateCancel")}
                                            doneText={t("common.dateDone")}
                                            placeholder={t("common.select")}
                                            max={props.report && props.report.serviceDate ? moment(props.report.serviceDate, moment.ISO_8601).tz('Europe/Warsaw').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                                        />
                                    </IonCol>

                                </IonRow>
                            </>
                        }
                    </>}

                    {
                        !props.order?.is_extended_reporting && row.defectGroups.map((defectGroup, key) =>
                            <div key={'defect_groups_' + key}>
                                {props.orderType === 'CONTROL' &&
                                    <IonRow>
                                        <IonCol size="12" className="label required">
                                            {t('reportPage.reportForm.defects.quantity')}
                                        </IonCol>

                                        <IonCol size="11">
                                            <StyledInput disabled={props.reportPreview} type="text" pattern="[0-9]*"
                                                         inputMode="tel" min={0} value={defectGroup.quantity}
                                                         onKeyPress={event => {
                                                             if (event.key == 'Enter' && !props.reportPreview) {
                                                                 const input = event.target as HTMLInputElement;
                                                                 input.blur();
                                                             }
                                                         }}
                                                         className={reportPageContext.getFormSubmitted() && !defectGroup.quantity && defectGroup.quantity !== 0 ? 'hasErrors' : ''}
                                                         onChange={(e) => updateDefectField(defectGroup, 'quantity', e.target.value ?? undefined, key)}
                                                         placeholder={t('reportPage.reportForm.enter')}/>
                                        </IonCol>
                                        <IonCol size="1" className="center">
                                            {!props.reportPreview && <StyledCircleButton className="white"
                                                                                         onClick={() => updateRemoveDefectGroupState({
                                                                                             showAlert: true,
                                                                                             defectGroup: defectGroup,
                                                                                             defectGroupIndex: key
                                                                                         })}><img
                                                src={TrashRedImage}/></StyledCircleButton>}
                                        </IonCol>
                                    </IonRow>
                                }

                                {props.orderType === 'OPTIMIZATION' &&
                                    <IonRow>
                                        <IonCol size="5" className="label required">
                                            {t('reportPage.reportForm.defects.repaired')}
                                        </IonCol>
                                        <IonCol size="5" className="label required">
                                            {t('reportPage.reportForm.defects.notRepaired')}
                                        </IonCol>

                                        <IonCol size="5">
                                            <StyledInput disabled={props.reportPreview} type="text" pattern="[0-9]*"
                                                         inputMode="tel" min={0} value={defectGroup.repaired}
                                                         className={reportPageContext.getFormSubmitted() && !defectGroup.repaired && defectGroup.repaired !== 0 ? 'report-row-component-defects-repaired hasErrors' : 'report-row-component-defects-repaired'}
                                                         onChange={(e) => updateDefectField(defectGroup, 'repaired', e.target.value ?? undefined, key)}
                                                         placeholder={t('reportPage.reportForm.enter')}/>
                                        </IonCol>
                                        <IonCol size="5">
                                            <StyledInput disabled={props.reportPreview} type="text" pattern="[0-9]*"
                                                         inputMode="tel" min={0} value={defectGroup.notRepaired}
                                                         className={reportPageContext.getFormSubmitted() && !defectGroup.notRepaired && defectGroup.notRepaired !== 0 ? 'report-row-component-defects-not-repaired hasErrors' : 'report-row-component-defects-not-repaired'}
                                                         onChange={(e) => updateDefectField(defectGroup, 'notRepaired', e.target.value ?? undefined, key)}
                                                         placeholder={t('reportPage.reportForm.enter')}/>
                                        </IonCol>

                                        <IonCol size="1" className="center">
                                            {!props.reportPreview && <StyledCircleButton className="white"
                                                                                         onClick={() => updateRemoveDefectGroupState({
                                                                                             showAlert: true,
                                                                                             defectGroup: defectGroup,
                                                                                             defectGroupIndex: key
                                                                                         })}><img
                                                src={TrashRedImage}/></StyledCircleButton>}
                                        </IonCol>
                                    </IonRow>
                                }

                                {defectGroup.defects?.map((defect: Defect, defectKey) =>
                                    <IonRow key={'defects_' + key + '_' + defectKey}>
                                        <IonCol size="5" className="select" onClick={() => {
                                            if (!props.reportPreview) {
                                                selectDefect(key, defect, defectKey)
                                            }
                                        }}>
                                            <StyledInput readOnly={true}
                                                         className={reportPageContext.getFormSubmitted() && (!defect.name) ? 'hasErrors' : ''}
                                                         value={defect.name || defect.other}
                                                         placeholder={t("common.select")}/>
                                            <img className="down" src={ArrowDownImage}/>
                                        </IonCol>
                                        {defectGroup.defects.length > 1 && <IonCol size="1" className="center">
                                            {!props.reportPreview && <StyledCircleButton className="white"
                                                                                         onClick={() => updateRemoveDefectState({
                                                                                             showAlert: true,
                                                                                             defect: defect,
                                                                                             defectIndex: defectKey,
                                                                                             defectGroupIndex: key
                                                                                         })}><img
                                                src={TrashRedImage}/></StyledCircleButton>}
                                        </IonCol>}

                                        {props.specificationId != '0' && !props.reportPreview && defectKey === 0 &&
                                            <IonCol size="5">
                                                <StyledSocialButton className="report-button black-text"
                                                                    onClick={() => addDefect(key)}>
                                                    <img className="button-image" src={FlameImage}/>
                                                    {t('reportPage.reportForm.addAdditionalDefect')}
                                                </StyledSocialButton>
                                            </IonCol>}
                                    </IonRow>
                                )}
                            </div>
                        )
                    }
                    {!props.order?.is_extended_reporting && props.specificationId != '0' && !props.reportPreview &&
                        <IonRow>
                            <IonCol>
                                <StyledSocialButton className="report-button black-text report-row-add-defect"
                                                    onClick={() => addDefectGroup()}>
                                    <img className="button-image" src={FlameImage}/>
                                    {t('reportPage.reportForm.addDefect')}
                                </StyledSocialButton>
                            </IonCol>
                        </IonRow>
                    }

                    {props.specificationId != '0' && <IonRow>
                        <IonCol>
                            <StyledSocialButton className="report-button black-text report-row-add-comment"
                                                onClick={() => updateShowComments(true)}>
                                <img className="button-image" src={CommentImage}/>
                                {!props.reportPreview ? t('reportPage.reportForm.addComment') : t('reportPage.reportForm.previewComment')}
                                {countRowComments() > 0 && <StyledButtonBadge
                                    className="badge default-position">{countRowComments()}</StyledButtonBadge>}
                            </StyledSocialButton>
                        </IonCol>
                    </IonRow>}

                    {props.specificationId != '0' && props.order?.specification.hasMeasurements && <>
                        <IonRow>
                            <IonCol>
                                <StyledSocialButton
                                    className={row.skipMeasurements ? "report-button black-text report-row-add-measurements disabled" : "report-button black-text report-row-add-measurements"}
                                    onClick={() => !row.skipMeasurements || (row.measurements && row.measurements.length > 0) ? updateShowMeasurements(true) : () => {
                                    }}>
                                    <img className="button-image" src={AddRoundImage}/>
                                    {!props.reportPreview ? t('reportPage.reportForm.addMeasurements') : t('reportPage.reportForm.previewMeasurements')}
                                    {row.measurements && (row.measurements.filter(value => !value.hide).length > 0) &&
                                        <StyledButtonBadge
                                            className="badge default-position">{row.measurements.filter(value => !value.hide).length}</StyledButtonBadge>}
                                </StyledSocialButton>
                            </IonCol>
                        </IonRow>

                        {reportPageContext.getFormSubmitted() && (!row.skipMeasurements && (!row.measurements || (row.measurements && !validateMeasurements(row.measurements)))) &&
                            <p className="errorMessage">{t('reportPage.reportForm.measurementsRequired')}</p>
                        }

                        <IonRow>
                            <IonCol size="12">
                                <IonItem lines="none">
                                    <StyledIonLabel
                                        className="ion-text-wrap report-row-skip-meausrements">{t('report.measurements.skipAdd')}</StyledIonLabel>
                                    <StyledIonCheckbox name="hide" slot="start" checked={row.skipMeasurements}
                                                       disabled={props.reportPreview || (row.measurements && (row.measurements.filter(value => !value.hide).length > 0))}
                                                       onIonChange={(e) => {
                                                           handleSkipMeasurements(e.target.checked)
                                                       }}/>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </>}

                    {!props.profile && row.workers.map((ah, key) =>
                        <>
                            <IonRow key={'workers_' + key} className="worker-row">
                                <IonCol size="2">
                                    <img className="avatar" src={ah.avatarUrl || AvatarImage}/>
                                </IonCol>
                                <IonCol size="9" className="label name">
                                    {ah.name}
                                </IonCol>
                                <IonCol size="1" className="center">
                                    {!props.reportPreview && <StyledCircleButton className="white"
                                                                                 onClick={() => updateRemoveWorkerState({
                                                                                     showAlert: true,
                                                                                     worker: ah,
                                                                                     workerIndex: key
                                                                                 })}><img
                                        src={TrashRedImage}/></StyledCircleButton>}
                                </IonCol>
                                <IonCol size="5.5" className="report-row-time-start">
                                    <TimeInput
                                        keepContentsMounted={true}
                                        disabled={props.reportPreview}
                                        minuteValues={getReportTimepickerMinutesIterations(props.order)}
                                        cancelText={t("common.dateCancel")}
                                        doneText={t("common.dateDone")}
                                        placeholder={t("common.type")}
                                        className={reportPageContext.getFormSubmitted() && !ah.from ? 'hasErrors' : ''}
                                        onChange={value => updateFromTime(ah, value)}
                                        value={ah.from}
                                    />
                                </IonCol>
                                <IonCol className="center" size="1"><img src={ArrowRightImage}/></IonCol>
                                <IonCol size="5.5" className="report-row-time-end">
                                    <TimeInput
                                        keepContentsMounted={true}
                                        disabled={props.reportPreview}
                                        minuteValues={getReportTimepickerMinutesIterations(props.order)}
                                        cancelText={t("common.dateCancel")}
                                        doneText={t("common.dateDone")}
                                        placeholder={t("common.type")}
                                        onChange={value => updateToTime(ah, value)}
                                        value={ah.to}/>
                                </IonCol>
                            </IonRow>
                            {props.order && validateHoursPassingMidnight(ah, props.report, props.order) !== true &&
                                <p className="errorMessage">
                                    {t('reportPage.reportForm.hoursPassingMidnight.error')}: {validateHoursPassingMidnight(ah, props.report, props.order).format('DD.MM.YYYY HH:mm')}
                                </p>
                            }
                        </>
                    )}

                    {props.profile && row.workers.map((ah: ReportRowWorker, key) =>
                        <IonRow key={'workers_' + key} className="worker-row">
                            <IonCol size="2">
                                <img className="avatar" src={ah.avatarUrl || AvatarImage}/>
                            </IonCol>
                            <IonCol size="8"
                                    className={ah.rate && (ah.pricingRate || (props.order && [PricingType.FIXED_PRICE, PricingType.FIXED_PRICE_PER_MONTH].includes(props.order.pricing_type))) && ah.from && ah.to && (moment(ah.from, ISO_8601).format('HH:mm') !== moment(ah.to, ISO_8601).format('HH:mm')) ? "label name done no-flex" : "label name no-flex" + (reportPageContext.getFormSubmitted() && (!ah.rate || (!ah.pricingRate && (props.order && [PricingType.FIXED_PRICE, PricingType.FIXED_PRICE_PER_MONTH].includes(props.order.pricing_type))) || (moment(ah.from, ISO_8601).format('HH:mm') === moment(ah.to, ISO_8601).format('HH:mm'))) ? ' hasErrors' : '')}
                                    onClick={() => {
                                        if (!props.reportPreview) {
                                            updateSelectedRow(row);
                                            updateShowMultiWorkersModal(true);
                                        }
                                    }}>
                                {ah.name} <br/>
                                <small>{moment(ah.from, ISO_8601).format('HH:mm')} - {ah.to ? moment(ah.to, ISO_8601).format('HH:mm') : ''} {ah.from && ah.to &&
                                    <span>({hoursHumanized(moment(ah.from, ISO_8601).format('HH:mm'), moment(ah.to, ISO_8601).format('HH:mm'))})</span>}</small> {ah.rate &&
                                <small> / {ah.rate.name}</small>}
                            </IonCol>
                            <IonCol size="2" className="center">
                                {!props.reportPreview && <StyledCircleButton className="white"
                                                                             onClick={() => updateRemoveWorkerState({
                                                                                 showAlert: true,
                                                                                 worker: ah,
                                                                                 workerIndex: key
                                                                             })}><img
                                    src={TrashRedImage}/></StyledCircleButton>}
                            </IonCol>
                        </IonRow>
                    )}
                    {!props.reportPreview && !props.profile && <IonRow
                        className={reportPageContext.getFormSubmitted() && row.workers.length === 0 ? 'hasErrors' : ''}>
                        {!props.profile && <IonCol size={!props.hideOtherPersons ? "6" : "12"}>
                            <StyledSocialButton className="black-text report-row-my-hours" onClick={() => addCurrentWorker()}>
                                <img className="button-image" src={ClockBlueImage}/>
                                {t('reportPage.reportForm.myHours')}
                            </StyledSocialButton>
                        </IonCol>
                        }
                        {!props.hideOtherPersons && <IonCol size={props.profile ? "12" : "6"}>
                            <StyledSocialButton className="black-text report-row-other-person" onClick={() => addPerson()}>
                                <img className="button-image" src={AddPersonImage}/>
                                {t('reportPage.reportForm.otherPerson')}
                            </StyledSocialButton>
                        </IonCol>
                        }
                    </IonRow>
                    }
                    {!props.reportPreview && props.profile && <IonRow
                        className={reportPageContext.getFormSubmitted() && row.workers.length === 0 ? 'hasErrors' : ''}>
                        <IonCol size="12">
                            <StyledSocialButton className="black-text report-row-other-person-extended" onClick={() => addPersonExtended()}>
                                <img className="button-image" src={AddPersonImage}/>
                                {t('reportPage.reportForm.addWorker')}
                            </StyledSocialButton>
                        </IonCol>
                    </IonRow>
                    }
                    {reportPageContext.getFormSubmitted() && row.workers.length === 0 &&
                        <p className="errorMessage">{t('reportPage.reportForm.workersRequired')}</p>
                    }

                    {props.specificationId === '0' && <>
                        <IonRow>
                            <IonCol>
                                <StyledSocialButton className="report-button black-text report-row-add-comment"
                                                    onClick={() => updateShowComments(true)}>
                                    <img className="button-image" src={CommentImage}/>
                                    {!props.reportPreview ? t('reportPage.reportForm.addComment') : t('reportPage.reportForm.previewComment')}
                                    {countRowComments() > 0 && <StyledButtonBadge
                                        className="badge default-position">{countRowComments()}</StyledButtonBadge>}
                                </StyledSocialButton>
                            </IonCol>
                        </IonRow>

                        <IonRow className="extraTopPadding">
                            <IonCol size="6" className="label">
                                {t('reportPage.reportForm.componentNumber')}
                            </IonCol>
                            <IonCol size="6" className="label">
                                {t('reportPage.reportForm.componentQuantity')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="6" className="select" onClick={() => {
                                if (!props.reportPreview) {
                                    updateShowComponentNumbers(true)
                                }
                            }
                            }>
                                <StyledInput disabled={true} value={row.component.name} className="report-row-component-name"/>
                                <img className="down" src={ArrowDownImage}/>
                            </IonCol>
                            <IonCol size="6">
                                <StyledInput disabled={props.reportPreview} type="text" pattern="[0-9]*" inputMode="tel"
                                             min={0}
                                             onKeyPress={event => {
                                                 if (event.key == 'Enter' && !props.reportPreview) {
                                                     const input = event.target as HTMLInputElement;
                                                     input.blur();
                                                 }
                                             }}
                                             onChange={(e) => updateComponentQuantity(e.target.value ?? undefined)}
                                             value={row.quantity} placeholder={t('reportPage.reportForm.enter')}
                                             className="report-row-component-quantity"/>
                            </IonCol>
                        </IonRow>
                    </>}

                    {!props.order?.is_extended_reporting && (!props.reportPreview || (props.reportPreview && row.files.length > 0)) &&
                        <IonRow>
                            <IonCol>
                                <StyledSocialButton className="report-button black-text report-row-add-files"
                                                    onClick={() => updateShowFiles(true)}>
                                    <img className="button-image" src={PhotosImage}/>
                                    {!props.reportPreview ? t('reportPage.reportForm.addFiles') : t('reportPage.reportForm.previewFiles')}
                                    {row.files.length > 0 && <StyledButtonBadge
                                        className="badge default-position">{row.files.length}</StyledButtonBadge>}
                                </StyledSocialButton>
                            </IonCol>
                        </IonRow>}
                </IonGrid>
            </StyledComponentFormContainer>
            {props.order?.is_extended_reporting && <>
                {
                    reportPageContext.getFormSubmitted() && row && row.components && row.components.length === 0 &&
                    <p className="errorMessage">{t('reportPage.reportForm.componentsRequired')}</p>
                }

                {
                    row.components && row.components.slice(0, 1).map((component: ReportRowComponent, key) =>
                        <ReportRowComponentForm
                            key={'component_' + key + component.id}
                            orderId={props.orderId}
                            specificationId={props.specificationId}
                            reportId={props.reportId}
                            profile={props.profile}
                            row={props.row}
                            component={component}
                            report={props.report}
                            order={props.order}
                            reportPreview={props.reportPreview}
                            maxComponents={props.maxComponents}
                            onChange={(component) => updateComponent(component, key)}
                            onRemove={(component) => removeComponent(component, key)}
                            onSubmit={() => {
                            }}
                            onAdd={async () => {
                                dispatch(EventType["REPORT.AUTO_SAVE"]);
                                await addComponent()
                                const componentElement = rowRef.current?.querySelector(
                                    `.row-component`
                                )

                                if (componentElement) {
                                    componentElement.scrollIntoView({behavior: 'smooth'})
                                }
                            }}
                        />
                    )
                }

                {
                    props.order?.is_extended_reporting && !props.reportPreview && !props.maxInContainer &&
                    <>
                        <IonRow>
                            <IonCol>
                                <StyledButton className="report-button black-text"
                                              disabled={!canScanNextComponent(props.order, props.row, props.specificationId == '0')}
                                              onClick={async () => {
                                                  dispatch(EventType["REPORT.AUTO_SAVE"]);
                                                  await addComponent()
                                              }}>
                                    {t('reportPage.reportForm.addComponent')}
                                </StyledButton>
                            </IonCol>
                        </IonRow>
                    </>
                }

                {props.order?.is_extended_reporting && row.components && row.components?.length > 1 &&
                    <IonRow>
                        <IonCol>
                            <StyledButton className="report-button black-text"
                                          onClick={() => setShowComponentsModal(true)}>
                                {t('reportPage.reportForm.showAllComponents')} ({row.components.length})
                            </StyledButton>
                        </IonCol>
                    </IonRow>
                }
            </>}

            <ListModal list={componentNumbers} isOpen={showComponentNumbers}
                       onClose={() => updateShowComponentNumbers(false)} selected={row.component.id}
                       onSubmit={updateComponentNumber} search={true}/>
            <ListModal list={defects} moreList={moreDefects} isOpen={showDefectList}
                       onClose={() => updateShowDefectList(false)} selected={selectedDefect?.id}
                       allowManual={!props.profile && props.report.additionalOrders && props.report.additionalOrders.length === 0} onSubmit={updateSelectedDefect} search={true}/>
            <CommentsModal reportPreview={props.reportPreview} comments={row.comments} isOpen={showComments}
                           onClose={() => updateShowComments(false)} onSubmit={(comments) => {
                updateComments(comments);
                updateShowComments(false)
            }}/>
            <FilesModal report={props.report} reportPreview={props.reportPreview} files={row.files} isOpen={showFiles}
                        onClose={() => updateShowFiles(false)} onSubmit={(files) => {
                updateFiles(files);
            }}/>
            {props.specificationId && props.specificationId != '0' && props.order?.specification.hasMeasurements &&
                <MeasurementsModal specificationId={props.specificationId}
                                   serviceDate={props.report.serviceDate}
                                   reportPreview={props.reportPreview}
                                   measurements={row.measurements}
                                   isOpen={showMeasurements}
                                   onClose={() => updateShowMeasurements(false)}
                                   onSubmit={(measurements) => {
                                       updateMeasurements(measurements);
                                   }}/>
            }
            {props.report && selectedWorker && <ExtendedWorkerModal
                isOpen={showExtendedWorkerModal}
                onClose={() => {
                    updateShowExtendedWorkerModal(false);
                    updateSelectedWorker(null);
                }}
                onSubmit={(worker) => updateExtendedWorker(worker)}
                worker={selectedWorker}
                order={props.order}
                report={props.report}
                orderId={props.orderId ? props.orderId : props.report.orderId}
                specificationId={props.specificationId ? props.specificationId : props.report.specificationId}

            />}
            {props.report && selectedRow && <MultipleExtendedWorkerModal
                isOpen={showMultiWorkersModal}
                onClose={() => {
                    updateShowMultiWorkersModal(false);
                    updateSelectedRow(null);
                }}
                onSubmit={(row) => {
                    updateExtendedRow(row);
                }}
                row={selectedRow}
                order={props.order}
                report={props.report}
                orderId={props.orderId ? props.orderId : props.report.orderId}
                specificationId={props.specificationId ? props.specificationId : props.report.specificationId}
            />}

            <IonAlert
                isOpen={removeWorkerState.showAlert}
                onDidDismiss={() => updateRemoveWorkerState({
                    showAlert: false,
                    worker: undefined,
                    workerIndex: undefined
                })}
                header={t('common.alertHeader')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            updateRemoveWorkerState({showAlert: false, worker: undefined, workerIndex: undefined})
                        },
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: async () => {
                            if (removeWorkerState.worker !== undefined && removeWorkerState.workerIndex !== undefined) {
                                removeWorker(removeWorkerState.worker, removeWorkerState.workerIndex);
                            }
                        }
                    }
                ]}
            />

            <IonAlert
                isOpen={componentMax.showAlert}
                onDidDismiss={() => updateComponentMax({showAlert: false, row: undefined})}
                header={t('report.maxComponents.alertHeader')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            updateComponentMax({showAlert: false, row: undefined})
                            row.quantity = '';
                            props.row.quantity = '';
                        },
                    },
                    {
                        text: t('common.alertConfirm'),
                    }
                ]}
            />

            <IonAlert
                isOpen={removeDefectGroupState.showAlert}
                onDidDismiss={() => updateRemoveDefectGroupState({
                    showAlert: false,
                    defectGroup: undefined,
                    defectGroupIndex: undefined
                })}
                header={t('common.alertHeader')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            updateRemoveDefectGroupState({
                                showAlert: false,
                                defectGroup: undefined,
                                defectGroupIndex: undefined
                            })
                        },
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: async () => {
                            if (removeDefectGroupState.defectGroup !== undefined && removeDefectGroupState.defectGroupIndex !== undefined) {
                                removeDefectGroup(removeDefectGroupState.defectGroup, removeDefectGroupState.defectGroupIndex);
                            }
                        }
                    }
                ]}
            />

            <IonAlert
                isOpen={removeDefectState.showAlert}
                onDidDismiss={() => updateRemoveDefectState({
                    showAlert: false,
                    defect: undefined,
                    defectIndex: undefined
                })}
                header={t('common.alertHeader')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            updateRemoveDefectState({showAlert: false, defect: undefined, defectIndex: undefined})
                        },
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: async () => {
                            if (removeDefectState.defect !== undefined && removeDefectState.defectIndex !== undefined && removeDefectState.defectGroupIndex !== undefined) {
                                removeDefect(removeDefectState.defectGroupIndex, removeDefectState.defect, removeDefectState.defectIndex);
                            }
                        }
                    }
                ]}
            />

            <IonAlert
                isOpen={removeRowState.showAlert}
                onDidDismiss={() => updateRemoveRowState({showAlert: false, row: undefined})}
                header={t('common.alertHeader')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            updateRemoveRowState({showAlert: false, row: undefined})
                        },
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: async () => {
                            if (removeRowState.row !== undefined) {
                                props.onRemove(removeRowState.row);
                            }
                        }
                    }
                ]}
            />

            <ReportRowComponentsModal
                isOpen={showComponentsModal}
                orderId={props.orderId}
                specificationId={props.specificationId}
                reportId={props.reportId}
                profile={props.profile}
                row={props.row}
                report={props.report}
                order={props.order}
                reportPreview={props.reportPreview}
                onClose={(r) => {
                    setShowComponentsModal(false);
                }}
                onRemove={(component, key) => {
                    removeComponent(component, key)
                }}
                onChange={(component, key) => {
                    component.id = Math.random().toString(); // to re-render last component
                    updateComponent(component, key)
                }}
            />
        </div>
    );
};

export default ReportRowForm;