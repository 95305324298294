import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Pane from "@components/pane/pane.component";
import {Controller, useForm} from "react-hook-form";
import Form from "@components/form";
import {IonAlert, IonCol, IonLoading, IonRow, IonToast} from "@ionic/react";
import {StyledInput} from "@components/form/input/input.style";
import {StyledTextarea} from "@components/form/input/textarea.style";
import {StyledContent} from "@components/accordion/accordion.style";

import {ReportProblemApiFormat, ReportProblemForm} from "@models/helpdesk/reportProblem";
import {IonCardShadowStyle, StyledButtonOption, StyledButtonWrapper, StyledButton} from "@app/travelAllowance/travelAllowance.style";
import {Camera, CameraResultType, CameraSource} from "@capacitor/camera";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";

import {ReactComponent as AddIcon} from "@assets/images/travelAllowance/add.svg"
import {ReactComponent as BinIcon} from '@assets/images/travelAllowance/bin.svg';
import {ReactComponent as SaveIcon} from '@assets/images/travelAllowance/save.svg';
import {Preferences} from "@capacitor/preferences";
import {Profile} from "@models/profile";
import {ProfileType} from "@enums/profileType";
import {getCoordinatorWorkers} from "@services/coordinator.service";
import {DataSelectable} from "@models/common";
import PaginatedListModal from "@components/modal/paginatedListModal.component";
import {changeBase46ToBlob, generateFileName, prepareFileForApiFormat, uploadFile} from "@services/file.service";
import moment from "moment/moment";
import {reportProblem} from "@services/helpdesk/helpdesk.service";

type ReportProblemPaneProps = {
    topEdge?: number;
}

const ReportProblemPane: React.FC<ReportProblemPaneProps> = ({topEdge}: ReportProblemPaneProps) => {
    const {t} = useTranslation();

    const {register, control, setValue, errors, handleSubmit} = useForm<ReportProblemForm>({
        mode: 'onChange'
    });

    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);
    const [showRemoveAttachmentAlert, setShowRemoveAttachmentAlert] = useState<boolean>(false);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [isCoordinatorWorkersModalOpen, setIsCoordinatorWorkersModalOpen] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');

    const [currentProfile, setCurrentProfile] = useState<Profile | undefined>(undefined);
    const [selectedWorker, setSelectedWorker] = useState<DataSelectable | undefined>(undefined);
    const [photoPreviewImage, setPhotoPreviewImage] = useState<string | undefined>(undefined);

    useEffect(() => {
        getProfileData()
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (photoPreviewImage) {
            setShowLoader(true);

            saveFile(photoPreviewImage)
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setShowLoader(false);
                })
        }
    }, [photoPreviewImage]);

    const getProfileData = async () => {
        const profileId = await Preferences.get({'key': 'profile_id'});
        const profileType = await Preferences.get({'key': 'profile_type'});

        if (profileId.value !== null && profileType.value !== null) {
            setCurrentProfile({
                id: parseInt(profileId.value),
                type: profileType.value,
            });
        }
    };

    const takeAttachmentPhoto = async () => {
        const hasPermissions = await Camera.checkPermissions();

        if (hasPermissions.camera === 'denied') {
            setShowPermissionAlert(true);
        } else {
            const image = await Camera.getPhoto({
                source: CameraSource.Camera,
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.Base64
            });

            if (image.base64String) {
                setPhotoPreviewImage(image.base64String);
            }
        }
    };

    const saveFile = async (base64String: string) => {
        const blob = changeBase46ToBlob(base64String);

        await uploadFile(blob, generateFileName(moment().format('YYYY-MM-DD H:m:s'), null, base64String))
            .then(response => {
                const uploadedFiles = response.data.files;
                const uploadedFile = prepareFileForApiFormat(uploadedFiles[0]);

                setValue('file', JSON.stringify(uploadedFile), {shouldValidate: true});
            });
    }

    const removeAttachmentPhoto = () => {
        setPhotoPreviewImage(undefined);
        setValue('file', null)
    }

    const handleWorkerSelect = (worker: DataSelectable) => {
        setSelectedWorker(worker);
        setValue('onBehalfOfWorker', worker.id, {shouldValidate: true});
    }

    const isCoordinator = () => {
        return currentProfile?.type === ProfileType.COORDINATOR;
    }

    const onSubmit = (data: ReportProblemForm) => {
        setShowLoader(true);

        const { title, description, onBehalfOfWorker, file } = data;

        const reportProblemApiData: ReportProblemApiFormat = {
            title,
            description,
            onBehalfOfWorker,
            ...(file && { file: JSON.parse(file) })
        };

        reportProblem(reportProblemApiData)
            .then(() => {
                setToast('helpdesk.reportProblem.report_sent');
                setShowToast(true);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setShowLoader(false);
            })
    }

    return (
        <Pane topEdge={topEdge} marginTop={40} paddingBottom={147}>
            <IonLoading onDidDismiss={() => setShowLoader(false)} isOpen={showLoader}/>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="primary"
            />
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            await NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
            <IonAlert
                isOpen={showRemoveAttachmentAlert}
                onDidDismiss={() => setShowRemoveAttachmentAlert(false)}
                header={t('helpdesk.reportProblem.removeAttachment.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowRemoveAttachmentAlert(false);
                        },
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: () => {
                            removeAttachmentPhoto();
                        }
                    }
                ]}
            />
            <PaginatedListModal isOpen={isCoordinatorWorkersModalOpen}
                                fetchData={getCoordinatorWorkers}
                                setValue={handleWorkerSelect}
                                onClose={() => setIsCoordinatorWorkersModalOpen(false)}
            />
            <StyledContent>
                <Form.Container onSubmit={handleSubmit(onSubmit)}>
                    <IonRow>
                        <IonCol size="12" className="label mt-8 required">
                            {t('helpdesk.reportProblem.title')}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12" className="label">
                            <StyledInput readOnly={false}
                                         placeholder=""
                                         name="title"
                                         ref={register({
                                             required: true
                                         })}
                                         className={errors.title ? 'error' : ''}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12" className="label mt-8 required">
                            {t('helpdesk.reportProblem.description')}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12" className="label">
                            <StyledTextarea readOnly={false}
                                            placeholder=""
                                            name="description"
                                            ref={register({
                                                required: true
                                            })}
                                            className={`full-width resize-none ${errors.description ? 'error' : ''}`}
                            />
                        </IonCol>
                    </IonRow>
                    {
                        isCoordinator()
                            ? <>
                                <IonRow>
                                    <IonCol size="12" className="label mt-8 required">
                                        {t('helpdesk.reportProblem.worker')}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <Controller
                                            name="onBehalfOfWorker"
                                            control={control}
                                            defaultValue={''}
                                            rules={{
                                                required: true,
                                                valueAsNumber: true,
                                            }}
                                            render={() => (
                                                <StyledInput
                                                    placeholder={selectedWorker ? selectedWorker.name : t('helpdesk.reportProblem.selectWorker')}
                                                    onClick={() => setIsCoordinatorWorkersModalOpen(true)}
                                                    className={errors.onBehalfOfWorker ? 'error' : ''}
                                                >
                                                </StyledInput>
                                            )}
                                        />
                                    </IonCol>
                                </IonRow>
                            </>
                            : <></>
                    }
                    <IonRow>
                        <IonCol size="12">
                            <StyledButtonOption>
                                <input type="hidden"
                                       name="file"
                                       ref={register({
                                           required: false
                                       })}
                                       accept="image/*"
                                />
                                <div onClick={() => takeAttachmentPhoto()}
                                     className="btn-option center"
                                     style={{width: "100%"}}
                                >
                                    <AddIcon/>
                                    <span>{t('helpdesk.reportProblem.addAttachment')}</span>
                                </div>
                            </StyledButtonOption>
                        </IonCol>
                    </IonRow>
                    <IonCardShadowStyle style={{width: "99%", marginLeft: "0.5%"}}>
                        <div className="wrapper">
                            <div>
                                <IonRow className="">
                                    {photoPreviewImage &&
                                        <img
                                            src={`data:image/jpeg;base64,${photoPreviewImage}`}
                                            style={{
                                                width: "150px",
                                                height: "40px",
                                                objectFit: "cover"
                                            }}
                                            alt={t('helpdesk.reportProblem.attachment')}/>}
                                </IonRow>
                            </div>
                            <div className="wrapper-btn"
                                 style={{flexDirection: "row", alignItems: "center"}}>
                                <StyledButton type="button" onClick={() => setShowRemoveAttachmentAlert(true)}>
                                    <div className="btn center">
                                        <BinIcon/>
                                    </div>
                                </StyledButton>
                            </div>
                        </div>
                    </IonCardShadowStyle>
                    <StyledButtonWrapper>
                        <StyledButton type="submit" className="center" style={{width: "99%", marginLeft: "0.5%"}}>
                            <div className="btn center">
                                <SaveIcon/>
                                <span>{t('helpdesk.reportProblem.send')}</span>
                            </div>
                        </StyledButton>
                    </StyledButtonWrapper>
                </Form.Container>
            </StyledContent>
        </Pane>
    );
};

export default ReportProblemPane;