import React, {useEffect, useState} from 'react';
import axios from "axios";

import MyDataSkeleton from "@app/myData/components/myDataSkeleton.component";
import RidePreviewFormFields from "@app/travelAllowance/ride/components/ridePreviewFormFields.component";

import {StyledDangerInfo, StyledTravelAllowanceContent} from "@app/travelAllowance/travelAllowance.style";
import {ReactComponent as DangerIcon} from '@assets/images/travelAllowance/danger.svg';

import {Ride} from "@models/travelAllowance/ride";

import {getRide} from "@services/travelAllowance/ride.service";
import {useTranslation} from "react-i18next";
import StaticPane from "@components/pane/static-pane.component";

type RidePreviewPaneProps = {
    topEdge?: number,
    rideId: number
}

const RidePreviewPane: React.FC<RidePreviewPaneProps> = ({topEdge, rideId}: RidePreviewPaneProps) => {
    const cancelToken = axios.CancelToken.source();

    const {t} = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [ride, setRide] = useState<Ride>();

    useEffect(() => {
        setLoading(true);

        fetchRide()
            .then(() => {
                setLoading(false)
            })
            .catch((e) => {
                console.error(e);
            });

        return () => {
            cancelToken.cancel();
        };
    }, []);

    const fetchRide = async () => {
        if (rideId !== undefined) {
            const rideData = await getRide(rideId, cancelToken);

            setRide(rideData);
        }
    }

    return (
        <StaticPane topEdge={topEdge} marginTop={40} paddingTop={30} hideGrabber={true}>
            {
                <StyledTravelAllowanceContent>
                    {
                        loading && !ride &&
                        <>
                            <MyDataSkeleton></MyDataSkeleton>
                            <MyDataSkeleton></MyDataSkeleton>
                            <MyDataSkeleton></MyDataSkeleton>
                            <MyDataSkeleton></MyDataSkeleton>
                            <MyDataSkeleton></MyDataSkeleton>
                        </>
                    }
                    {
                        !loading && ride && ride.rejectionReason &&
                        <StyledDangerInfo>
                            <div className="rejection-header">
                                <DangerIcon/>
                                <span>{t('travelAllowance.ride.rejectionReason')}:</span>
                            </div>
                            <div>
                                { ride.rejectionReason }
                            </div>
                        </StyledDangerInfo>
                    }
                    {
                        !loading && ride && <RidePreviewFormFields ride={ride}/>
                    }
                </StyledTravelAllowanceContent>
            }
        </StaticPane>
    );
};

export default RidePreviewPane;