import React, {useEffect, useRef, useState} from 'react';
import {IonHeader, IonPage} from '@ionic/react';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '@components/content/content.style';
import {useTranslation} from 'react-i18next';
import {Profile} from "@models/profile";
import {Preferences} from "@capacitor/preferences";
import AuditReportsPane from "./panes/auditReportsPane.component";
import {AuditType} from "@enums/audit";
import SubNavigation, {SubNavigationOption} from "../../components/sub-navigation/subNavigation.component";
import {useHistory} from "react-router-dom";
import useNavigation from "../../services/navigation.service";
import {ReportsTabOptions} from "../mainPage/tabs/reports/reportsTab.component";
import AuditReportsPlannedPane from "@app/auditReport/panes/auditReportsPlannedPane.component";
import {getAuditTypes} from "@services/audit.service";
import Pane from "@components/pane/pane.component";
import AuditReportsSkeleton from "@app/auditReport/components/auditReportsSkeleton.component";

export enum AuditTabOptions {
    QUALITY = 'QUALITY',
    BHP = 'BHP',
    OTHER = 'OTHER',
    PLANNED = 'PLANNED',
}

let timeout: number;

const AuditReports: React.FC = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);

    const defaultOptions = [
        {
            name: t("auditReports.quality")
        },
        {
            name: t("auditReports.bhp")
        },
        {
            name: t("auditReports.other")
        },
        {
            name: t("auditReports.planned")
        }
    ];

    const [loading, setLoading] = useState<boolean>(true);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const [auditTypes, setAuditTypes] = useState<Record<AuditType, string>>();
    const [options, setOptions] = useState<SubNavigationOption[]>(defaultOptions);
    const [selectedProfile, updateSelectedProfile] = useState<Profile | undefined>();
    const [selectedOption, updateSelectedOption] = useState<number>(parseInt(navigation.getParam<string>('selectedOption')) || 0);
    const [selectedOptionName, setSelectedOptionName] = useState<string>(AuditType.QUALITY);

    const header = useRef<HTMLIonHeaderElement>(null);

    useEffect(() => {
        getProfileData();
        fetchAuditTypes();
    }, []);

    useEffect(() => {
        if (auditTypes) {
            const options = Object.values(auditTypes).map((auditType) => ({
                name: t(auditType)
            }));

            setOptions(options);
            setLoading(false)
        }
    }, [auditTypes]);

    useEffect(() => {
        updateHeight();

        return () => {
            clearTimeout(timeout);
        };
    });

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            timeout = setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const getProfileData = async () => {
        let profileId = await Preferences.get({'key': 'profile_id'});
        let profileType = await Preferences.get({'key': 'profile_type'});
        let profileCompanyName = await Preferences.get({'key': 'profile_company_name'});

        if (profileId.value !== null && profileType.value !== null && profileCompanyName.value !== null) {
            updateSelectedProfile({
                id: parseInt(profileId.value),
                type: profileType.value,
                company: {
                    name: profileCompanyName.value
                }
            })
        }
    };

    const fetchAuditTypes = async () => {
        await getAuditTypes()
            .then(response => {
                setAuditTypes(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const handleSelect = (option: SubNavigationOption) => {
        const optionIndex = options.indexOf(option);
        if (optionIndex === selectedOption) {
            return;
        }

        navigation.setParam('selectedOption', optionIndex.toString());

        if (auditTypes) {
            updateSelectedOption(optionIndex);
            setSelectedOptionName(Object.keys(auditTypes)[optionIndex]);
        }
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <StyledIonTitle className="left">{t("auditReports.title")}</StyledIonTitle>
                    </StyledIonToolbar>

                    <SubNavigation options={options} defaultValue={selectedOption || ReportsTabOptions.DRAFT} onSelect={handleSelect}/>
                </IonHeader>
                {
                    loading && <Pane topEdge={topEdge} marginTop={40} paddingBottom={147}>
                        <AuditReportsSkeleton/>
                    </Pane>
                }
                {
                    !loading && <>
                        {
                            selectedOptionName === AuditTabOptions.QUALITY &&
                            <AuditReportsPane topEdge={topEdge} profile={selectedProfile} auditType={AuditType.QUALITY}/>
                        }
                        {
                            selectedOptionName === AuditTabOptions.BHP &&
                            <AuditReportsPane topEdge={topEdge} profile={selectedProfile} auditType={AuditType.BHP}/>
                        }
                        {
                            selectedOptionName === AuditTabOptions.OTHER &&
                            <AuditReportsPane topEdge={topEdge} profile={selectedProfile} auditType={AuditType.OTHER}/>
                        }
                        {
                            selectedOptionName === AuditTabOptions.PLANNED &&
                            <AuditReportsPlannedPane topEdge={topEdge} profile={selectedProfile}/>
                        }
                        {
                            ![AuditTabOptions.QUALITY, AuditTabOptions.BHP, AuditTabOptions.OTHER, AuditTabOptions.PLANNED].includes(selectedOptionName as AuditTabOptions) &&
                            <AuditReportsPane topEdge={topEdge} profile={selectedProfile} auditType={selectedOptionName as AuditType}/>
                        }
                    </>
                }
            </StyledIonContent>
        </IonPage>
    );
};

export default AuditReports;
