import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { PaneProps, StyledPaneContent } from './pane.style';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';

enum DIRECTION {
    UP = 'UP',
    DOWN = 'DOWN'
}

const Pane: React.FC<PropsWithChildren<PaneProps>> = (props: PropsWithChildren<PaneProps>) => {

    const container = useRef<HTMLDivElement>(null);
    const content = useRef<HTMLDivElement>(null);
    const [currentBreak, updateCurrentBreak] = useState('middle');

    const enableScrolling = (enable: boolean) => {
        if (enable) {
            if (content && content.current) {
                content.current.classList.remove('expanding');
            }
        } else {
            if (content && content.current) {
                content.current.classList.add('expanding');
            }
        }
    }

    const handleOrientationChange = () => {
        updateCurrentBreak(pane.currentBreak() || 'middle');
        setTimeout(() => {
            updateBreakpoints();
        }, 200);
    }

    let previousTouch: number | undefined;
    const getDirection = (currentTouch: number) : DIRECTION => {
        //console.log(`prev - ${previousTouch} currentTouch - ${currentTouch}`)
        if ((previousTouch ?? 0) > currentTouch) {
            return DIRECTION.UP;
        } else {
            return DIRECTION.DOWN;
        }
    };

    let settings: CupertinoSettings = {
        buttonClose: false,
        initialBreak: 'middle',
        showDraggable: true,
        lowerThanBottom: false,
        handleKeyboard: false,
        animationDuration: 10,
        onDrag: (e) => {
            if(!(e as any as TouchEvent).changedTouches) {
                return;
            }

            const currentTouch = (e as any as TouchEvent).changedTouches[0].screenY;
            const isAtTopOfList = content.current ? content.current.scrollTop === 0 : false;

            if ((getDirection(currentTouch) === DIRECTION.UP)) {
                if (pane.currentBreak() === 'top') {
                    enableScrolling(true);
                } else {
                    pane.moveToBreak('top');
                }
            } else if ((getDirection(currentTouch) === DIRECTION.DOWN)) {
                if (isAtTopOfList) {
                    pane.moveToBreak('middle');
                }

                enableScrolling(true);

                const targetElement = (e as any as TouchEvent).target as HTMLElement;
                if (!targetElement.classList.contains("draggable")) {
                    pane.disableDrag()
                } else {
                    pane.enableDrag()
                }
            }

            previousTouch = currentTouch;
        },
        onTransitionStart: () => {
            enableScrolling(true);
        },
        onTransitionEnd: () => {
            enableScrolling(true);
        },
        onDragEnd: () => {
            enableScrolling(true);
            pane.enableDrag()
            previousTouch = undefined;
        },
        breaks: {
            bottom: {
                enabled: false
            },
            middle: {
                enabled: true,
                bounce: false,
                height: document.body.clientHeight - (props.topEdge ?? 0) + 5
            },
            top: {
                enabled: true,
                height: document.body.clientHeight
            }
        }
    };

    let pane: CupertinoPane;

    const updateBreakpoints = () => {
        let middle = document.body.clientHeight - (props.topEdge ?? 0) + 5;
        let top = document.body.clientHeight;

        pane.setBreakpoints({
            bottom: {
                enabled: false
            },
            middle: {
                enabled: true,
                bounce: false,
                height: middle < top ? middle : top
            },
            top: {
                enabled: true,
                height: top
            }
        });

        pane.updateScreenHeights();

        if (pane.isPanePresented()) {
            pane.moveToBreak(currentBreak);
        }
    };

    useEffect(() => {
        const initPane = async () => {
            if (!container.current || !props.topEdge) {
                return;
            }

            if (settings.breaks?.top) {
                settings.breaks.top.height = document.body.clientHeight;
            }

            if (settings.breaks?.middle) {
                settings.breaks.middle.height = document.body.clientHeight - (props.topEdge ?? 0) + 5;
            }

            pane = new CupertinoPane(container.current, settings);
            await pane.present();

            window.addEventListener('keyboardWillShow', () => {
                setTimeout(() => {
                    updateBreakpoints();
                }, 350)
            });
            window.addEventListener('keyboardWillHide', () => {
                setTimeout(() => {
                    updateBreakpoints();
                }, 350)
            });

            window.onresize = handleOrientationChange;

            return () => {
                try {
                    console.log('destroy');
                    pane.destroy({animate: false});
                } catch {

                }

                window.removeEventListener('keyboardWillShow', updateBreakpoints);
                window.removeEventListener('keyboardWillHide', updateBreakpoints);

                window.onresize = null;
            };
        }

        initPane();
    }, [props.topEdge]);

    return (
        <div>
           <div ref={container}>
                <StyledPaneContent ref={content} overflow-y paddingBottom={props.paddingBottom} hideGrabber={props.hideGrabber}>
                    {props.children}
                </StyledPaneContent>
            </div>
        </div>
    );
};

export default Pane;
