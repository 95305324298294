import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton } from '../../../../components/button/button.style';
import {StyledAvailabilityExActionSheetContainer} from './availabilityExActionSheet.style';
import { StyledPaneBar, StylePaneGrabber } from '../../../../components/pane/pane.style';
import { useSwipeable } from 'react-swipeable';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { StyledIonDatetime } from '../../../../components/form/input/date.style';
import ArrowRightImage from '../../../../assets/images/arrow-right.svg';
import { StyledTextarea } from '../../../../components/form/input/textarea.style';
import { Task, TaskStatus } from '../../../timetablePage/components/taskItem/taskItem.component';
import Form from '../../../../components/form';
import { useForm } from 'react-hook-form';
import {StyledActionIonModal, StyledActionSheet, StyledIonModal} from '../../../../components/modal/modal.style';
import DateInput from "../../../../components/form/input/date.component";
import TimeInput from "@components/form/input/time.component";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
moment.tz.setDefault('Europe/Warsaw');

type AvatarActionSheetProps = {
	isVisible: boolean;
	task?: Task,
	onDismiss: () => void;
	onSave: (task: Task) => void;
}

interface LocationState {
	toastInit: string;
	showToastInit: boolean;
}

interface AvailabilityExActionSheetManager {
	setErrors: Function;
}

const contextAvailabilityExActionSheet: AvailabilityExActionSheetManager = {
	setErrors: () => {

	}
};

interface ErrorTypes {
	[key: string]: string;
}

export const AvailabilityExActionSheetContext = React.createContext<AvailabilityExActionSheetManager>(contextAvailabilityExActionSheet);

const AvailabilityExActionSheet: React.FC<AvatarActionSheetProps> = (props: AvatarActionSheetProps) => {

	const [isVisible, changeVisibility] = useState(false);
	const {t} = useTranslation();

	useEffect(() => {
		changeVisibility(props.isVisible);
	}, [props.isVisible]);

	const { handleSubmit } = useForm<Task>({
		mode: 'all'
	});

	const [showTimeRange, updateShowTimeRange] = useState(false);
	const [id, updateId] = useState<number | undefined>();
	const [startDate, updateStartDate] = useState<Date | undefined>();
	const [endDate, updateEndDate] = useState<Date | undefined>();
	const [description, updateDescription] = useState<string>();
	const [errors, setErrors] = useState<any>();

	useEffect(() => {
		updateId(props.task?.id);
		updateStartDate(props.task?.startDate);
		updateEndDate(props.task?.endDate);
		updateDescription(props.task?.description);
	}, [props.task]);

	useEffect(() => {
		if (!startDate || !endDate) {
			updateShowTimeRange(false);
			return;
		}

		let showRange = startDate.getFullYear() === endDate.getFullYear() &&
			startDate.getMonth() === endDate.getMonth() &&
			startDate.getDate() === endDate.getDate();

		let addTime = startDate.getHours() === endDate.getHours() ||
			startDate.getMinutes() === endDate.getMinutes();

		if (showRange === true && showTimeRange === false && addTime === true) {
			updateEndDate(moment(endDate).add(30, 'minutes').toDate());
		}

		updateShowTimeRange(true); // showRange
	}, [startDate, endDate])

	const config = {
		delta: 10,                            // min distance(px) before a swipe starts
		preventDefaultTouchmoveEvent: false,  // call e.preventDefault *See Details*
		trackTouch: true,                     // track touch input
		trackMouse: false,                    // track mouse input
		rotationAngle: 0,                     // set a rotation angle
	};

	const handlers = useSwipeable({
		onSwipedDown: (eventData) => {
			dismiss();
		},
		...config,
	});

	const save = async () => {
		let result = await props.onSave({
			id: id,
			startDate: startDate || new Date(),
			endDate,
			description: description || '',
			status: TaskStatus.ABSENCE,
			allDay: false,
			place: ''
		});

		if (result as unknown as boolean === true) {
			clearData();
		}
	};

	const dismiss = () => {
		clearData();
		props.onDismiss();
	}

	const clearData = () => {
		updateStartDate(undefined);
		updateEndDate(undefined);
		updateDescription(undefined);
		setErrors([]);
	}

	const roundTime = (date?: Date) => {
		if (date) {
			let minutes = Math.ceil(date.getMinutes() / 15) * 15;
			minutes = minutes == 60 ? 0 : minutes;
			date.setMinutes(minutes);

			return moment(date).format();
		}

		return new moment(Date()).format();
	}

	const contextAvailabilityExActionSheet = useContext(AvailabilityExActionSheetContext);
	contextAvailabilityExActionSheet.setErrors = setErrors;

	var errorTypes: ErrorTypes = {
		'Availability is too short.': t('profileTab.availability.addExceptionActionSheet.availabilityTooShort'),
		'There is already an absence in given period.': t('profileTab.availability.addExceptionActionSheet.absenceExistsInGivenPeriod'),
		'End date can not be before start date.': t('profileTab.availability.addExceptionActionSheet.endDateCanNotBeBeforeStartDate')
	};

	return isVisible ? (
		<AvailabilityExActionSheetContext.Provider value={contextAvailabilityExActionSheet}>
			<StyledIonModal
				isOpen={props.isVisible}
				onDidDismiss={() => dismiss()}
				initialBreakpoint={0.99}
				breakpoints={[0, 0.65, 0.99]}
			>
					<StyledAvailabilityExActionSheetContainer onClick={(e) => e.stopPropagation()}>
						<div className="modal-wrap">
							<h1 className="title">{t('profileTab.availability.addExceptionActionSheet.title')}</h1>
							{errors?.errors && <p className="errorMessage">
								{(errors.errors[0] in errorTypes) ? errorTypes[errors.errors[0]] : errors.errors[0]}
							</p>}
							<h2 className="label">{t('profileTab.availability.addExceptionActionSheet.chooseDay')}</h2>
							<Form.Container onSubmit={handleSubmit(save)}>
								<IonGrid className="ion-no-padding">
									<IonRow>
										<IonCol size="12">

										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="5.5">
											<DateInput
												format="DD.MM.YYYY"
												presentation="date"
												cancelText={t("common.dateCancel")}
												doneText={t("common.dateDone")}
												placeholder={t('profileTab.availability.addExceptionActionSheet.from')}
												value={startDate ? moment(startDate).format() : moment().format('YYYY-MM-DD')}
												onChange={value => updateStartDate(new Date(value as any as string)) }
												min={moment().format('YYYY-MM-DD')}
												max={moment().add(1, 'year').format('YYYY-MM-DD')}
											></DateInput>
										</IonCol>
										<IonCol className="arrow" size="1"><img src={ArrowRightImage} /></IonCol>
										<IonCol size="5.5">
											<DateInput
												format="DD.MM.YYYY"
												presentation="date"
												cancelText={t("common.dateCancel")}
												doneText={t("common.dateDone")}
												placeholder={t('profileTab.availability.addExceptionActionSheet.to')}
												value={endDate ? moment(endDate).format() : moment().format('YYYY-MM-DD')}
												onChange={value => updateEndDate(new Date(value as any as string))}
												min={moment().format('YYYY-MM-DD')}
												max={moment().add(1, 'year').format('YYYY-MM-DD')}
											/>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="5.5">
											{errors?.children && errors?.children?.startDate && errors?.children?.startDate?.errors && <p className="errorMessage">
												{errors.children.startDate.errors[0] == 'Date should not be in the past' ? t('profileTab.availability.addExceptionActionSheet.dateShouldNotBeInThePast') : errors.children.startDate.errors[0]}
											</p>}
										</IonCol>
										<IonCol className="arrow" size="1">
										</IonCol>
										<IonCol size="5.5">
											{errors?.children && errors?.children?.endDate && errors?.children?.endDate?.errors && <p className="errorMessage">
												{errors.children.endDate.errors[0] == 'Date cannot be in the past' ? t('profileTab.availability.addExceptionActionSheet.dateShouldNotBeInThePast') : errors.children.endDate.errors[0]}
											</p>}
										</IonCol>
									</IonRow>
								</IonGrid>
								<div className={`${showTimeRange ? 'visible' : 'hidden'} time-range`}>
									<h2 className="label">{t('profileTab.availability.addExceptionActionSheet.hours')}</h2>
									<IonGrid className="ion-no-padding">
										<IonRow>
											<IonCol size="5.5">
												<TimeInput
													keepContentsMounted={true}
													cancelText={t("common.dateCancel")}
													doneText={t("common.dateDone")}
													placeholder={t('profileTab.availability.addExceptionActionSheet.from')}
													value={roundTime(startDate)}
													onChange={value => updateStartDate(new Date(value as any as string)) }
													minuteValues="0,15,30,45"
												/>
											</IonCol>
											<IonCol className="arrow" size="1"><img src={ArrowRightImage} /></IonCol>
											<IonCol size="5.5">
												<TimeInput
													keepContentsMounted={true}
													cancelText={t("common.dateCancel")}
													doneText={t("common.dateDone")}
													placeholder={t('profileTab.availability.addExceptionActionSheet.to')}
													value={endDate ? roundTime(endDate) : (startDate ? (startDate.setHours(startDate.getHours() + 0.5), roundTime(startDate)) : '')}
													onChange={value => updateEndDate(new Date(value as any as string)) }
													minuteValues="0,15,30,45"
												/>
											</IonCol>
										</IonRow>
									</IonGrid>
								</div>
								<div className="reason-wrapper">
									<StyledTextarea
										className="reason"
										name="description"
										value={description}
										onChange={e => updateDescription(e.target.value)}
										placeholder={t('profileTab.availability.addExceptionActionSheet.reason')} ></StyledTextarea>
								</div>
								{/*{errors?.children && errors?.children?.reason && errors?.children?.reason?.errors && <p className="errorMessage">
									{errors.children.reason.errors[0] == 'This value should not be blank.' ? t('profileTab.availability.addExceptionActionSheet.valueShouldNotBeBlank') : errors.children.reason.errors[0]}
								</p>}*/}
								<StyledButton type="submit">
									{t('profileTab.availability.addExceptionActionSheet.sendButton')}
								</StyledButton>
							</Form.Container>
						</div>
					</StyledAvailabilityExActionSheetContainer>
			</StyledIonModal>
		</AvailabilityExActionSheetContext.Provider>
	) : (<span></span>);
};

export default AvailabilityExActionSheet;
