import React from 'react';
import {IonBadge, IonCardHeader, IonCardSubtitle} from '@ionic/react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import moment from "moment";

import {Ride} from "@models/travelAllowance/ride";

import {StyledButton, StyledTravelAllowanceItem} from "@app/travelAllowance/travelAllowance.style";

import {ReactComponent as CarIcon} from '@assets/images/travelAllowance/car.svg';
import {ReactComponent as RideIcon} from '@assets/images/travelAllowance/ride.svg';
import {ReactComponent as BadgeAcceptedIcon} from '@assets/images/travelAllowance/badge-accepted.svg';
import {ReactComponent as BadgeWarningIcon} from '@assets/images/travelAllowance/badge-warning.svg';
import {ReactComponent as BadgeRejectedIcon} from '@assets/images/travelAllowance/badge-rejected.svg';
import {ReactComponent as BadgeWaitingIcon} from '@assets/images/travelAllowance/badge-waiting.svg';

import {Links} from "@app/links";

import {RideStatus} from "@enums/travelAllowance/ride";
import TruncateText from "@components/truncateText/truncateText.component";
import {ReactComponent as ZoomIcon} from "@assets/images/travelAllowance/zoom.svg";

type RideListRowProps = {
    ride: Ride;
    handleRideDetailsPreview: (ride: Ride) => void;
}

const RideListRow: React.FC<RideListRowProps> = ({ride, handleRideDetailsPreview}: RideListRowProps) => {
    const history = useHistory();
    const {t} = useTranslation();

    const handleRedirect = (rideId: number, rideStatus: RideStatus) => {
        if (rideStatus === RideStatus.QUARANTINE) {
            history.push(Links.main + Links.travelAllowance.ride.edit + '/' + rideId)
        }

        if ([RideStatus.ACCEPTED, RideStatus.REJECTED].includes(rideStatus)) {
            history.push(Links.main + Links.travelAllowance.ride.preview + '/' + rideId)
        }
    }

    return (
        <StyledTravelAllowanceItem className={ride.status.toLowerCase()} style={{padding: "5px"}}>
            <IonCardHeader style={{flexDirection: "column", gap: "5px"}} onClick={() => handleRedirect(ride.id, ride.status)}>
                <IonCardSubtitle>
                    <span>{moment(ride.dateStart).format('YYYY-MM-DD')}</span>
                    <IonBadge className="center">
                        {
                            (ride.status === RideStatus.ACCEPTED && <BadgeAcceptedIcon/>)
                            ||
                            (ride.status === RideStatus.QUARANTINE && <BadgeWarningIcon/>)
                            ||
                            (ride.status === RideStatus.REJECTED && <BadgeRejectedIcon/>)
                            ||
                            (ride.status === RideStatus.WAITING && <BadgeWaitingIcon/>)
                        }
                        <span>{t(`travelAllowance.ride.status.${ride.status.toLowerCase()}`)}</span>
                    </IonBadge>
                </IonCardSubtitle>
                <IonCardSubtitle className="travel-destinations">
                    <span className="place">
                        <TruncateText width={150}>{ride.rideRoute.startingPlace}</TruncateText>
                    </span>
                    <span className="dots">
                        <div className="dot left">
                            <div className="gradient"></div>
                            &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022;
                        </div>
                        <div className="center">
                            <CarIcon/>
                        </div>
                        <div className="dot right">
                            <div className="gradient"></div>
                            &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022; &#x2022;
                        </div>
                    </span>
                    <span className="place">
                        <TruncateText width={150}>{ride.rideRoute.destinationPlace}</TruncateText>
                    </span>
                </IonCardSubtitle>
                <IonCardSubtitle>
                    <span>{ride.rideRoute.placeOfService.name}</span>
                    <span>
                        <RideIcon style={{marginRight: "3px"}}/>
                        {ride.rideRoute.distance}
                    </span>
                </IonCardSubtitle>
            </IonCardHeader>
            <StyledButton className={ride.status.toLowerCase()} onClick={() => handleRideDetailsPreview(ride)}>
                <div className="btn center">
                    <ZoomIcon/> {t('travelAllowance.ride.showDetails')}
                </div>
            </StyledButton>
        </StyledTravelAllowanceItem>
    );
};

export default RideListRow;