import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import Form from '../../../components/form'
import {useForm} from 'react-hook-form';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {StyledButton, StyledWiteButton} from "../../../components/button/button.style";
import {DataSelectable} from "../components/report/reportRowForm.component";
import {StyledField} from "../../../components/form/input/input.style";
import {StyledIonSelect} from "../../../components/form/input/select.style";
import {IonLoading, IonRow, IonSelectOption} from "@ionic/react";
import {compareWith} from "../../../services/report.service";
import {
    getPlaceOfServices,
    getReportsData,
    offlineStorageSet
} from "../../../services/offlineSync.service";
import moment from "moment";
import {disableSwipeToClose} from "../../../utils/tools/modals";
import {Preferences} from "@capacitor/preferences";

type ModalProps = {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
};

const OfflineSyncModal: React.FC<ModalProps> = (props: ModalProps) => {

    const {t} = useTranslation();

    const [showModal, updateShowModal] = useState<boolean>(false);
    const [loading, updateLoading] = useState<boolean>(false);
    const [placeOfServices, updatePlaceOfServices] = useState<DataSelectable[]>();
    const [selectedPlaceOfServiceObject, updateSelectedPlaceOfServiceObject] = useState<DataSelectable | undefined>();

    const fetchPlaceOfServices = async () => {
        await getPlaceOfServices()
            .then(response => {
                updatePlaceOfServices(response.data);
            });
    };

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const handleCancel = () => {
        updateShowModal(false);
        props.onCancel();
    };

    const handleSync = async () => {
        updateLoading(true);
        if (selectedPlaceOfServiceObject) {
            let placeOfServiceData = await getReportsData(selectedPlaceOfServiceObject.id);
            offlineStorageSet('pos_' + selectedPlaceOfServiceObject.id, placeOfServiceData.data);

            await Preferences.set({
                'key': 'last_offline_data_update',
                'value': moment().toDate().toString()
            });
        }
        updateLoading(false);
        updateShowModal(false);
        props.onConfirm();
    };

    useEffect(() => {
        handleOpen();
        if (props.isOpen) {
            fetchPlaceOfServices();
        }
    }, [props.isOpen]);

    const handleOpen = () => {
        updateShowModal(props.isOpen);
    };

    return (
        <Modal
            isOpen={showModal}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => props.onCancel()}>
            <Form.Container className="modalForm" onSubmit={handleSubmit(handleSync)}>
                <StyledModalContent>
                    <h1 className="title">{t("offlineSync.title")}</h1>
                    <div className="content">
                        <IonRow>
                            <StyledField>
                                <label>{t("offlineSync.placeOfService")}</label>
                                <StyledIonSelect className="bolded" okText={t('common.selectOk')}
                                                 cancelText={t('common.selectCancel')}
                                                 value={selectedPlaceOfServiceObject}
                                                 compareWith={compareWith}
                                                 name="placeOfService"
                                                 interfaceOptions={
                                                     {
                                                         header: t("reportsTab.filtersActionSheet.placeOfService")
                                                     }
                                                 }
                                                 onIonChange={e => {
                                                     updateSelectedPlaceOfServiceObject(e.detail.value);
                                                 }}>
                                    {placeOfServices?.map((value: DataSelectable, key) =>
                                        <IonSelectOption key={key}
                                                         value={value}>{value.name}</IonSelectOption>)}
                                </StyledIonSelect>
                            </StyledField>
                        </IonRow>
                    </div>
                    <StyledFooter className="modal footer no-shadow">
                        <StyledWiteButton type="button" onClick={handleCancel}>{t("offlineSync.cancel")}</StyledWiteButton>
                        <StyledButton type="submit">{t("offlineSync.confirm")}</StyledButton>
                    </StyledFooter>
                </StyledModalContent>
            </Form.Container>
            <IonLoading onDidDismiss={() => updateLoading(false)} isOpen={loading} duration={10000}/>
        </Modal>
    );
};

export default OfflineSyncModal;