import React from 'react';
import {IonCol, IonRow} from '@ionic/react';
import {useTranslation} from "react-i18next";

type RideCostInfoRowProps = {
    rideRouteDistance?: number;
    rideCostDistance?: number;
    rideCostAmount?: number;
}

const RideCostInfoRow: React.FC<RideCostInfoRowProps> = ({rideRouteDistance, rideCostDistance, rideCostAmount}: RideCostInfoRowProps) => {
    const {t} = useTranslation();

    if (typeof rideRouteDistance === 'undefined' || typeof rideCostDistance === 'undefined') {
        return <></>
    }

    if (rideRouteDistance > rideCostDistance) {
        return (
            <IonRow>
                <IonCol size="12">
                        <span style={{fontSize: '12px', color: "#f8ac59", marginLeft: "0"}}>
                            {t('travelAllowance.ride.rideCostReducedInfo')}
                        </span>
                </IonCol>
            </IonRow>
        );
    }

    if (rideCostDistance && rideCostAmount === 0) {
        return (
            <IonRow>
                <IonCol size="12">
                        <span style={{fontSize: '12px', color: "#f8ac59", marginLeft: "0"}}>
                            {t('travelAllowance.ride.rideNotToSettle')}
                        </span>
                </IonCol>
            </IonRow>
        );
    }

    return <></>
};

export default RideCostInfoRow;