import React from "react";
import MyDataSkeleton from "@app/myData/components/myDataSkeleton.component";

const TravelAllowanceFormSkeleton: React.FC = () => {
    return (
        <>
            <>
                <MyDataSkeleton></MyDataSkeleton>
                <MyDataSkeleton></MyDataSkeleton>
                <MyDataSkeleton></MyDataSkeleton>
                <MyDataSkeleton></MyDataSkeleton>
                <MyDataSkeleton></MyDataSkeleton>
            </>
        </>
    )
}

export default TravelAllowanceFormSkeleton;
