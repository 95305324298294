import {IonCol, IonGrid, IonRow, IonSelectOption, useIonViewWillEnter} from '@ionic/react';
import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton, StyledButtonBadge } from '../../../../components/button/button.style';
import {SearchInput, StyledField} from '../../../../components/form/input/input.style';
import {
    StyledActionIonModal,
    StyledActionSheet,
    StyledActionSheetContainer,
    StyledIonModal
} from '../../../../components/modal/modal.style';
import { StyledFilterContainer } from './filterActionSheet.style';
import {CloseImage} from "../../../../utils/styles/icons";
import {StyledIonSelect} from "../../../../components/form/input/select.style";
import {useForm} from "react-hook-form";
import Form from "../../../../components/form";
import {Preferences} from "@capacitor/preferences";
import {
    filtersToParams,
    getPlaceOfServiceDepartments,
    getPlaceOfServices,
    getReportsCount
} from "../../../../services/report.service";
import {ReportsContext} from "../../../mainPage/tabs/reports/panes/reportsPane.component";
import {ReportsTabOptions} from "../../../mainPage/tabs/reports/reportsTab.component";

type FilterActionSheetProps = {
    isVisible: boolean;
    status: number;
    filters?: Filter[];
    onSave: (filter: Filter[]) => void;
    onDismiss: () => void;
}

export enum FilterType {
    ORDER_NUMBER,
    COMPONENT_NAME,
    COMPONENT_NUMBER,
    PLACE_OF_SERVICE,
    PLACE_OF_SERVICE_DEPARTMENT
}

export type Filter = {
    type: FilterType;
    description?: string;
    value: any;
}

export type FilterParam = {
    name: string;
    value: any;
}

export type ReportsCounts = {
    all: bigint
}

type Selectable = {
    id: number,
    name: string
}

interface ReportsFilterActionSheetManager {
    updateSelectedOrderNumber: Function;
    updateSelectedComponentNumber: Function;
    updateSelectedComponentName: Function;
    updateSelectedPlaceOfServiceObject: Function;
    updateSelectedPlaceOfServiceDepartmentObject: Function;
}

const contextFilterActionSheet: ReportsFilterActionSheetManager = {
    updateSelectedOrderNumber: () => {

    },
    updateSelectedComponentNumber: () => {

    },
    updateSelectedComponentName: () => {

    },
    updateSelectedPlaceOfServiceObject: () => {

    },
    updateSelectedPlaceOfServiceDepartmentObject: () => {

    }
};

export const ReportsFilterActionSheetContext = React.createContext<ReportsFilterActionSheetManager>(contextFilterActionSheet);

const FilterActionSheet: React.FC<FilterActionSheetProps> = (props: FilterActionSheetProps) => {
    const reportsContext = useContext(ReportsContext);

    const [isVisible, changeVisibility] = useState(false);
    const {t} = useTranslation();

    const [resultsCount, setResultsCount] = useState<bigint|null>(null);
    const [profileId, updateProfileId] = useState<number>();
    const [activeFilters, setActiveFilters] = useState(0);

    const [placeOfServices, updatePlaceOfServices] = useState<Selectable[]>();
    const [placeOfServicesDepartments, updatePlaceOfServicesDepartments] = useState<Selectable[]>();

    const fetchProfileData = async () => {
        let profileId = await Preferences.get({'key': 'profile_id'});

        if (profileId && profileId.value) {
            updateProfileId(parseInt(profileId.value));
        }
    }

    const fetchPlaceOfServices = async () => {
        if (profileId) {
            await getPlaceOfServices(profileId)
                .then(response => {
                    updatePlaceOfServices(response.data);
                });
        }
    };

    const fetchPlaceOfServiceDepartments = async (placeOfService: number) => {
        if (profileId) {
            await getPlaceOfServiceDepartments(placeOfService, profileId)
                .then(response => {
                    updatePlaceOfServicesDepartments(response.data);
                });
        }
    };

    useEffect(() => {
        fetchProfileData();
    }, []);

    useEffect(() => {
        fetchPlaceOfServices();
    }, [profileId]);

    useEffect(() => {
        changeVisibility(props.isVisible);
    });

    const [selectedOrderNumber, updateSelectedOrderNumber] = useState<string | undefined | null>();
    const [selectedComponentNumber, updateSelectedComponentNumber] = useState<string | undefined>();
    const [selectedComponentName, updateSelectedComponentName] = useState<string | undefined>();
    const [selectedPlaceOfServiceObject, updateSelectedPlaceOfServiceObject] = useState<Selectable | undefined>();
    const [selectedPlaceOfServiceDepartmentObject, updateSelectedPlaceOfServiceDepartmentObject] = useState<Selectable | undefined>();

    useEffect(() => {
        if (selectedPlaceOfServiceObject) {
            fetchPlaceOfServiceDepartments(selectedPlaceOfServiceObject.id);
        }
    }, [selectedPlaceOfServiceObject, profileId]);

    const updateActiveFilters = () => {
        let count: number = 0;

        if (selectedOrderNumber) {
            count++;
        }

        if (selectedComponentName) {
            count++;
        }

        if (selectedComponentNumber) {
            count++;
        }

        if (selectedPlaceOfServiceObject !== undefined) {
            count++;
        }

        if (selectedPlaceOfServiceDepartmentObject !== undefined) {
            count++;
        }

        setActiveFilters(count);
    };
    
    useEffect(() => {
        updateActiveFilters();
    }, [selectedOrderNumber, selectedComponentNumber, selectedComponentName, selectedPlaceOfServiceObject, selectedPlaceOfServiceDepartmentObject]);

    useEffect(() => {
        const debounceId = setTimeout(() => updateCountOnChange(), 300);
        return () => clearTimeout(debounceId);
    }, [selectedOrderNumber, selectedComponentNumber, selectedComponentName, selectedPlaceOfServiceObject, selectedPlaceOfServiceDepartmentObject]);

    const handleSave = () => {
        const filters: Filter[] = [];

        if (selectedOrderNumber) {
            filters.push({
                type: FilterType.ORDER_NUMBER,
                description: selectedOrderNumber,
                value: selectedOrderNumber
            });
        }

        if (selectedComponentName) {
            filters.push({
                type: FilterType.COMPONENT_NAME,
                description: selectedComponentName,
                value: selectedComponentName
            });
        }

        if (selectedComponentNumber) {
            filters.push({
                type: FilterType.COMPONENT_NUMBER,
                description: selectedComponentNumber,
                value: selectedComponentNumber
            });
        }

        if (selectedPlaceOfServiceObject !== undefined) {
            filters.push({
                type: FilterType.PLACE_OF_SERVICE,
                description: selectedPlaceOfServiceObject.name,
                value: selectedPlaceOfServiceObject.id
            });
        }

        if (selectedPlaceOfServiceDepartmentObject !== undefined) {
            filters.push({
                type: FilterType.PLACE_OF_SERVICE_DEPARTMENT,
                description: selectedPlaceOfServiceDepartmentObject.name,
                value: selectedPlaceOfServiceDepartmentObject.id
            });
        }

        reportsContext.setFilters(filters);
        props.onSave(filters);
    };

    const { handleSubmit } = useForm({
        mode: 'all'
    });

    const refreshFilters = async (filters: Filter[]) => {
        updateSelectedOrderNumber('');
        updateSelectedComponentName('');
        updateSelectedComponentNumber('');
        updateSelectedPlaceOfServiceObject(undefined);
        updateSelectedPlaceOfServiceDepartmentObject(undefined);

        filters.forEach((filter) => {
            switch (filter.type) {
                case FilterType.ORDER_NUMBER:
                    updateSelectedOrderNumber(filter.value);
                    break;

                case FilterType.COMPONENT_NAME:
                    updateSelectedComponentName(filter.value);
                    break;

                case FilterType.COMPONENT_NUMBER:
                    updateSelectedComponentNumber(filter.value);
                    break;

                case FilterType.PLACE_OF_SERVICE:
                    updateSelectedPlaceOfServiceObject({
                        id: parseInt(filter.value),
                        name: filter.description ?? ''
                    });
                    break;
                case FilterType.PLACE_OF_SERVICE_DEPARTMENT:
                    updateSelectedPlaceOfServiceDepartmentObject({
                        id: parseInt(filter.value),
                        name: filter.description ?? ''
                    });
                    break;
            }
        });

        updateCounts(filtersToParams(filters));
    }

    const updateCountOnChange = async () => {
        let params = [];
        params.push('orderNumber=' + encodeURIComponent(selectedOrderNumber ? selectedOrderNumber.toString() : ''));
        params.push('componentName=' + encodeURIComponent(selectedComponentName ? selectedComponentName.toString() : ''));
        params.push('componentNumber=' + encodeURIComponent(selectedComponentNumber ? selectedComponentNumber.toString() : ''));

        if (selectedPlaceOfServiceObject != null) {
            params.push('placeOfService=' + encodeURIComponent(selectedPlaceOfServiceObject.id.toString()));
        }

        if (selectedPlaceOfServiceDepartmentObject != null) {
            params.push('placeOfServiceDepartment=' + encodeURIComponent(selectedPlaceOfServiceDepartmentObject.id.toString()));
        }

        updateCounts(params.join('&'));
    }

    const updateCounts = async (params: any = null) => {
        if (profileId) {
            let status = null;
            switch (props.status) {
                case ReportsTabOptions.DRAFT:
                    status = 'DRAFT';
                    break;
                case ReportsTabOptions.OPEN:
                    status = 'OPEN';
                    break;
                case ReportsTabOptions.NOT_VERIFIED:
                    status = 'NOT_VERIFIED';
                    break;
            }

            if (status) {
                await getReportsCount(status, profileId, params)
                    .then(response => {
                        let counts = response.data as ReportsCounts;
                        setResultsCount(counts.all);
                    });
            }
        }
    }

    const contextFilterActionSheet = useContext(ReportsFilterActionSheetContext);
    contextFilterActionSheet.updateSelectedOrderNumber = updateSelectedOrderNumber;
    contextFilterActionSheet.updateSelectedComponentNumber = updateSelectedComponentNumber;
    contextFilterActionSheet.updateSelectedComponentName = updateSelectedComponentName;
    contextFilterActionSheet.updateSelectedPlaceOfServiceObject = updateSelectedPlaceOfServiceObject;
    contextFilterActionSheet.updateSelectedPlaceOfServiceDepartmentObject = updateSelectedPlaceOfServiceDepartmentObject;

    const compareWith = (o1: Selectable, o2: Selectable) => {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    };

    useEffect(() => {
        if (props.filters) {
            refreshFilters(props.filters);
        }
    }, [props.filters]);

    return isVisible ? (
        <StyledIonModal
            isOpen={props.isVisible}
            onDidDismiss={() => props.onDismiss()}
            initialBreakpoint={0.65}
            breakpoints={[0, 0.65, 0.99]}
        >
                <StyledActionSheetContainer className="t-p-50 small-padding" onClick={(e) => e.stopPropagation()}>
                    <StyledFilterContainer>
                        <Form.Container onSubmit={handleSubmit(handleSave)}>
                            <IonGrid>
                                <IonRow className="header-row">
                                    <IonCol size="7"  className="ion-justify-content-center header">
                                        {t("reportsTab.filtersActionSheet.title")}
                                    </IonCol>
                                    <IonCol size="4">
                                        <div className="clear-button" onClick={() => refreshFilters([])}>
                                            <span>{t("reportsTab.filtersActionSheet.clearButton")}</span>
                                            <StyledButtonBadge className="default-position">{activeFilters}</StyledButtonBadge>
                                        </div>
                                    </IonCol>
                                    <IonCol size="1" className="ion-justify-content-center">
                                        <img src={CloseImage} className="close" onClick={() => props.onDismiss() } />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <SearchInput className="reports-filters-order-number" placeholder={t("reportsTab.filtersActionSheet.orderNumber")} initValue={selectedOrderNumber?.toString()} onChange={event => {
                                            updateSelectedOrderNumber(event.target.value)
                                        }}/>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <SearchInput className="reports-filters-component-number" placeholder={t("reportsTab.filtersActionSheet.componentNumber")} initValue={selectedComponentNumber?.toString()} onChange={event => {
                                            updateSelectedComponentNumber(event.target.value)
                                        }}/>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <SearchInput className="reports-filters-component-name" placeholder={t("reportsTab.filtersActionSheet.componentName")} initValue={selectedComponentName?.toString()} onChange={event => {
                                            updateSelectedComponentName(event.target.value)
                                        }}/>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <StyledField>
                                        <label>{t("reportsTab.filtersActionSheet.placeOfService")}</label>
                                        <StyledIonSelect className="bolded reports-filters-place-of-service" okText={t('common.selectOk')}
                                                         cancelText={t('common.selectCancel')}
                                                         value={selectedPlaceOfServiceObject}
                                                         compareWith={compareWith}
                                                         name="placeOfService"
                                                         justify="end"
                                                         interfaceOptions={
                                                             {
                                                                 header: t("reportsTab.filtersActionSheet.placeOfService")
                                                             }
                                                         }
                                                         onIonChange={e => {
                                                             updateSelectedPlaceOfServiceObject(e.detail.value);
                                                         }}>
                                            {placeOfServices?.map((value: Selectable, key) =>
                                                <IonSelectOption key={key}
                                                                 value={value}>{value.name}</IonSelectOption>)}
                                        </StyledIonSelect>
                                    </StyledField>
                                </IonRow>
                                <IonRow>
                                    <StyledField>
                                        <label>{t("reportsTab.filtersActionSheet.placeOfServiceDepartment")}</label>
                                        <StyledIonSelect className="bolded reports-filters-place-of-service-department" okText={t('common.selectOk')}
                                                         cancelText={t('common.selectCancel')}
                                                         value={selectedPlaceOfServiceDepartmentObject}
                                                         compareWith={compareWith}
                                                         name="placeOfServiceDepartment"
                                                         justify="end"
                                                         interfaceOptions={
                                                             {
                                                                 header: t("reportsTab.filtersActionSheet.placeOfServiceDepartment")
                                                             }
                                                         }
                                                         onIonChange={e => {
                                                             updateSelectedPlaceOfServiceDepartmentObject(e.detail.value);
                                                         }}>
                                            {placeOfServicesDepartments?.map((value: Selectable, key) =>
                                                <IonSelectOption key={key}
                                                                 value={value}>{value.name}</IonSelectOption>)}
                                        </StyledIonSelect>
                                    </StyledField>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <StyledButton id="reports-filters-save" onClick={() => handleSave()}>{t("reportsTab.filtersActionSheet.showResults")} {resultsCount !== null ? `(${resultsCount})` : ''}</StyledButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </Form.Container>
                    </StyledFilterContainer>
                </StyledActionSheetContainer>
        </StyledIonModal>
    ) : (<span></span>);
};

export default FilterActionSheet;
