import React from 'react';
import Modal from "@components/modal/modal.component";
import {StyledModalContent} from "@components/modal/modal.style";
import {RideRoute, SubRoute} from "@models/travelAllowance/rideRoute";
import {IonRow} from "@ionic/react";
import {useTranslation} from "react-i18next";

type RideRouteDetailsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    rideRoute?: RideRoute;
}

const RideRouteDetailsModal: React.FC<RideRouteDetailsModalProps> = ({isOpen, onClose, rideRoute}: RideRouteDetailsModalProps) => {
    const {t} = useTranslation();

    return (
        <Modal initialBreakpoint={0.75} breakpoints={[0, 0.75]} isOpen={isOpen} onDidDismiss={() => onClose()}>
            {
                rideRoute &&
                <>
                    <StyledModalContent>
                        <h1 className="title center">{t("travelAllowance.rideRoute.detailsTitle")}</h1>
                        <div className="content">
                            <IonRow class="data-row">
                                {t("travelAllowance.rideRoute.startingPlace")}: {rideRoute.startingPlace}
                            </IonRow>
                            <IonRow class="data-row">
                                {t("travelAllowance.rideRoute.destinationPlace")}: {rideRoute.destinationPlace}
                            </IonRow>
                            {
                                rideRoute.subRoutes && rideRoute.subRoutes.map((subRoute: SubRoute, index: number) => {
                                    return (
                                        <IonRow key={subRoute.id} class="data-row">
                                            {t("travelAllowance.rideRoute.subRoute")} {index + 1}: {subRoute.destinationPlace}
                                        </IonRow>
                                    )
                                })
                            }
                            <IonRow class="data-row">
                                {t("travelAllowance.rideRoute.distance")}:{rideRoute.distance}&nbsp;km
                            </IonRow>
                            <IonRow class="data-row">
                                {t("travelAllowance.rideRoute.coordinator")}: {rideRoute.coordinator.name}
                            </IonRow>
                            <IonRow class="data-row">
                                {t("travelAllowance.rideRoute.placeOfService")}: {rideRoute.placeOfService.name}
                            </IonRow>
                        </div>
                    </StyledModalContent>
                </>
            }

        </Modal>

    );
};

export default RideRouteDetailsModal;