import {appApi, travelAllowanceApi} from "@services/api.service";
import {getWorkerId} from "@utils/tools/worker";

const getRegulationTravelPdf = async (id: bigint | string) => {
    return travelAllowanceApi.get(`regulation/download/${id}`, {
        responseType: "blob"
    });
}

const acceptRegulation = async (regulationId: any) => {
    return appApi.post('regulation/accept', {
        'regulationId': regulationId
    });
}

const getRegulationInfo = async () => {
    const workerId = await getWorkerId();
    const response: any = await travelAllowanceApi.get(`regulation/worker/${workerId}`);

    return response.data;
}

export {
    getRegulationInfo,
    getRegulationTravelPdf,
    acceptRegulation
}



