import React, {useContext, useEffect, useRef, useState} from 'react';
import {IonButtons, IonHeader, IonPage, IonToast} from '@ionic/react';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '../../../../components/content/content.style';
import {useTranslation} from 'react-i18next';
import SubNavigation, {SubNavigationOption} from '../../../../components/sub-navigation/subNavigation.component';
import {useHistory, useLocation} from 'react-router-dom';
import useNavigation from '../../../../services/navigation.service';
import {ReportStatus} from "../../../../enums/reportStatus";
import {
    StyledButtonBadge,
    StyledHeaderButton,
    StyledHeaderButtonImage
} from "../../../../components/button/button.style";
import FilterActionSheet, {
    Filter,
    FilterParam,
    FilterType,
    ReportsFilterActionSheetContext
} from "../../../reportPage/components/filter/filterActionSheet.component";
import {Profile} from "../../../../models/profile";
import {Preferences} from "@capacitor/preferences";
import {filtersToParamsObjects, getFilterTypeForParamName, saveReport} from "../../../../services/report.service";
import PreferencesImage from "../../../../assets/images/system-preferences.svg";
import ReportsPane, {ReportsContext} from "./panes/reportsPane.component";
import OfflineSyncModal from "../../../reportPage/modals/offlineSyncModal.component";
import {StyledBanner} from "../profile/profileTab.style";
import {getOfflineDataStatus} from "../../../../services/offlineSync.service";
import {Report as ReportForm} from "@app/reportPage/reportPage.component";
import {getReports as getReportsOffline, removeReport as removeReportOffline} from "@services/reportOffline.service";
import {useEvent} from "@hooks/useEvent";
import {EventType} from "@enums/eventType";
import {validate as validateUuid} from "uuid";
import {Network} from "@capacitor/network";

let timeout: number;

export enum ReportsTabOptions {
    DRAFT,
    NOT_VERIFIED,
    OPEN,
}

export interface IReportsTabState {
    activeTab: ReportsTabOptions;
}

interface OfflineDataStatus {
    date: [],
    valid: boolean
}

interface ReportsTabManager {
    setNetworkStatus: Function;
}

const contextReportsTab: ReportsTabManager = {
    setNetworkStatus: () => {

    }
};

export const ReportsTabContext = React.createContext<ReportsTabManager>(contextReportsTab);

const ReportTab: React.FC = () => {
    const {t} = useTranslation();
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const history = useHistory();
    const navigation = useNavigation(history);
    const {dispatch} = useEvent();

    const [isCoordinator, setIsCoordinator] = useState<any>();
    const [selectedProfile, updateSelectedProfile] = useState<Profile | undefined>();
    const [options, setOptions] = useState<SubNavigationOption[]>([
        {
            name: t("reportsTab.draft")
        },
        {
            name: t("reportsTab.not_verified")
        },
        {
            name: t("reportsTab.open")
        },
    ]);

    const filterActionSheet = useContext(ReportsFilterActionSheetContext);
    const [networkStatus, setNetworkStatus] = useState(true);
    const [offlineDataStatus, setOfflineDataStatus] = useState<OfflineDataStatus>();
    const [toast, setToast] = useState<string>('');

    const filtersName = [
        'orderNumber',
        'componentName',
        'componentNumber',
        'placeOfService',
        'placeOfServiceDepartment'
    ];

    const contextReportsTab = useContext(ReportsTabContext);
    contextReportsTab.setNetworkStatus = setNetworkStatus;

    const getProfileData = async () => {

        let isCoordinator = await Preferences.get({'key': 'is_coordinator'});
        setIsCoordinator(isCoordinator.value);

        let profileId = await Preferences.get({'key': 'profile_id'});
        let profileType = await Preferences.get({'key': 'profile_type'});
        let profileCompanyName = await Preferences.get({'key': 'profile_company_name'});

        if (profileId.value !== null && profileType.value !== null && profileCompanyName.value !== null) {
            updateSelectedProfile({
                id: parseInt(profileId.value),
                type: profileType.value,
                company: {
                    name: profileCompanyName.value
                }
            })
        }
    };

    const syncReadyOfflineReports = async () => {
        if (networkStatus) {
            const reportsReadyToSync: ReportForm[] = await getReportsOffline(ReportStatus.OFFLINE_READY_TO_SYNC, true);
            for (const report of reportsReadyToSync) {
                const key = reportsReadyToSync.indexOf(report);
                if (report.order) {
                    setToast(t('reportsTab.orders.sync_offline_in_progress') + ': ' + (key+1).toString() + '/' + reportsReadyToSync.length)
                    let isOnlyOffline: boolean = !!(report.reportId && validateUuid(report.reportId.toString()));
                    let reportResponse = await saveReport(report.order, report, report.reportId, false, true, isOnlyOffline, true)
                        .then(response => {
                            return response.data;
                        });

                    if (reportResponse.id && report.reportId) {
                        await removeReportOffline(report.reportId);
                    }
                }
            }

            if (reportsReadyToSync.length > 0) {
                setToast(t('reportsTab.orders.sync_offline_complete'))
                dispatch(EventType['REPORT.LIST.RELOAD'])
            }
        }
    }

    useEffect(() => {
        getProfileData();
        navigationToFilters();

        const getCurrentNetworkStatus = async () => {
            await Network.getStatus()
                .then(result => {
                    setNetworkStatus(result.connected)
                });
        };

        getCurrentNetworkStatus();
    }, []);

    const [selectedOption, updateSelectedOption] = useState<number>(parseInt(navigation.getParam<string>('selectedOption')) || ReportsTabOptions.DRAFT);
    const handleSelect = (option: SubNavigationOption) => {
        const optionIndex = options.indexOf(option);
        if (optionIndex === selectedOption) {
            return;
        }

        navigation.setParam('selectedOption', optionIndex.toString());
        updateSelectedOption(optionIndex);
        updateFilters([]);
        handleFiltersNavigation([]);
    };

    useEffect(() => {
        updateHeight();

        return () => {
            clearTimeout(timeout);
        };
    });

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            timeout = setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    const [filters, updateFilters] = useState<Filter[]>([]);
    const [showFilters, updateShowFilters] = useState(false);
    const [showOfflineSyncModal, updateShowOfflineSyncModal] = useState(false);

    const navigationToFilters = () => {
        const filters: Filter[] = [];

        filtersName.forEach(value => {
            if (navigation.getParam(value)) {
                let type = getFilterTypeForParamName(value);
                if (type !== undefined) {
                    filters.push({
                        type: type,
                        description: navigation.getParam(value),
                        value: type === (FilterType.PLACE_OF_SERVICE || type === FilterType.PLACE_OF_SERVICE_DEPARTMENT) ? parseInt(navigation.getParam(value)) : navigation.getParam(value)
                    });
                }
            }
        });

        updateFilters(filters);
    }

    const handleFiltersNavigation = async (filters: Filter[]) => {
        let params: FilterParam[] = filtersToParamsObjects(filters);
        filtersName.forEach(value => navigation.setParam(value, ''));

        params.forEach((value, index) => {
            navigation.setParam(value.name, value.value);
        })
    }

    const handleSelectFilter = async (filters: Filter[]) => {
        updateFilters(filters);
        handleFiltersNavigation(filters)
        updateShowFilters(false);
    }

    const handleClearFilter = (filter: Filter) => {
        const index = filters.indexOf(filter);

        if (index !== -1) {
            filters.splice(index, 1);
            updateFilters([...filters]);
        }

        handleSelectFilter(filters);
        handleFiltersNavigation(filters);

        if (filter.type === FilterType.ORDER_NUMBER) {
            filterActionSheet.updateSelectedOrderNumber('');
        }

        if (filter.type === FilterType.COMPONENT_NUMBER) {
            filterActionSheet.updateSelectedComponentNumber(undefined);
        }

        if (filter.type === FilterType.COMPONENT_NAME) {
            filterActionSheet.updateSelectedComponentName(undefined);
        }

        if (filter.type === FilterType.PLACE_OF_SERVICE) {
            filterActionSheet.updateSelectedPlaceOfServiceObject(undefined);
        }

        if (filter.type === FilterType.PLACE_OF_SERVICE_DEPARTMENT) {
            filterActionSheet.updateSelectedPlaceOfServiceDepartmentObject(undefined);
        }
    }

    useEffect(() => {
        updateOfflineDataStatus();
        syncReadyOfflineReports();
    }, [networkStatus]);

    const updateOfflineDataStatus = async () => {
        let offlineDataStatus = await getOfflineDataStatus() as unknown as OfflineDataStatus;
        setOfflineDataStatus(offlineDataStatus);
    }

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <StyledIonTitle className="left">{t("reportsTab.title")}</StyledIonTitle>

                        {isCoordinator === '1' && <IonButtons slot="end">
                            <StyledHeaderButton id="reports-filters">
                                <StyledHeaderButtonImage src={PreferencesImage} onClick={() => updateShowFilters(true)}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        }

                        {!selectedProfile && <IonButtons slot="end">
                                <StyledHeaderButton disabled={!networkStatus}>
                                    <StyledHeaderButtonImage src={PreferencesImage} onClick={() => updateShowOfflineSyncModal(true)}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            </IonButtons>
                        }

                    </StyledIonToolbar>
                    {
                        !selectedProfile && !networkStatus && !offlineDataStatus?.valid &&
                            <StyledBanner color="#000000" backgroundColor="#ff0000">{t("offlineSync.invalidOfflineData")}</StyledBanner>
                    }

                    {
                        !selectedProfile && !networkStatus && offlineDataStatus?.valid &&
                        <StyledBanner color="#000000" backgroundColor="#ffc409">{t("offlineSync.validOfflineData")}</StyledBanner>
                    }

                    {/*{*/}
                    {/*    isCoordinator !== '1' && networkStatus && offlineDataStatus?.valid &&*/}
                    {/*    <StyledBanner color="#000000" backgroundColor="#ffc409">{t("offlineSync.offlineDataReady")}</StyledBanner>*/}
                    {/*}*/}

                    { isCoordinator === '1' && filters && filters.length > 0 && <StyledButtonBadge className="header-right-badge">{filters.length}</StyledButtonBadge>}
                    {/*{ isCoordinator === '1' && <FilterChips filters={filters} onFilterClear={handleClearFilter}/> }*/}
                    <SubNavigation options={options} defaultValue={selectedOption || ReportsTabOptions.DRAFT} onSelect={handleSelect}/>
                </IonHeader>
                {
                    selectedOption === ReportsTabOptions.DRAFT &&
                    <ReportsPane status={ReportStatus.DRAFT} topEdge={topEdge} filters={filters} filtersName={filtersName} profile={selectedProfile}/>
                }
                {
                    selectedOption === ReportsTabOptions.NOT_VERIFIED &&
                    <ReportsPane status={ReportStatus.NOT_VERIFIED} topEdge={topEdge} filters={filters} filtersName={filtersName} profile={selectedProfile}/>
                }
                {
                    selectedOption === ReportsTabOptions.OPEN &&
                    <ReportsPane status={ReportStatus.OPEN} topEdge={topEdge} filters={filters} filtersName={filtersName} profile={selectedProfile}/>
                }
            </StyledIonContent>

            <IonToast
                isOpen={!!toast}
                onDidDismiss={() => setToast('')}
                message={toast}
                duration={6000}
                position="top"
                color="warning"
            />

            {isCoordinator === '1' &&
                <FilterActionSheet status={selectedOption} isVisible={showFilters} filters={filters} onDismiss={() => updateShowFilters(false)}
                                   onSave={(filters) => handleSelectFilter(filters)}/>}
            { !selectedProfile && <OfflineSyncModal isOpen={showOfflineSyncModal} onCancel={() => updateShowOfflineSyncModal(false)} onConfirm={() => updateShowOfflineSyncModal(false)} /> }
        </IonPage>
    );
};

export default ReportTab;
