import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import Pane from '../../../components/pane/pane.component';
import {StyledNoAuditReports, StyledAuditReports} from './auditReports.style';
import NoOrdersImage from '../../../assets/images/no-orders.svg';
import ReportsImage from '../../../assets/images/chart-pie-36.svg';
import {Links} from '@app/links';
import {IonCol, IonRow, IonSkeletonText, useIonViewWillEnter} from "@ionic/react";
import {log} from "@services/firebaseAnalytics.service";
import {Profile} from "@models/profile";
import {getPermission} from "@services/permission.service";
import {getAuditReportsPlanned, getShitPeriodReadable} from "@services/audit.service";
import {AuditReportFormModel, AuditReportPlanned} from "@models/auditReport";
import {AuditType} from "@enums/audit";
import {StyledSocialButton} from "@components/button/button.style";
import AddImage from "@assets/images/e-add.svg";
import AuditReportsSkeleton from "@app/auditReport/components/auditReportsSkeleton.component";
import {getPlaceOfServices} from "@services/audit.service";
import {StyledInput} from "@components/form/input/input.style";
import {StyledComponentFormContainer} from "../../reportPage/components/report/reportRowForm.style";
import PaginatedListModal from "@app/auditReport/modals/paginatedListModal.component";
import {DataSelectable} from "@models/common";
import CrossImage from "@assets/images/e-remove.svg";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone');
const moment = MomentRange.extendMoment(Moment);
const momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

type ReportsPlannedPaneProps = {
    topEdge?: number;
    profile?: Profile;
}

const AuditReportsPlannedPane: React.FC<ReportsPlannedPaneProps> = (props: ReportsPlannedPaneProps) => {
    const {t} = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [editPermission, setEditPermission] = useState<boolean>(false);
    const [reports, setReports] = useState<[] | undefined>(undefined);

    const [showPlaceOfServiceModal, updateShowPlaceOfServicesModal] = useState<boolean>(false);
    const [selectedPlaceOfService, setSelectedPlaceOfService] = useState<DataSelectable|null>();

    const history = useHistory();

    const loadPlaceOfServices = async (page: number, searchText?: string) => {
        let data: DataSelectable[] = await getPlaceOfServices(page, searchText).then(response => {
            return response.data as DataSelectable[];
        });

        return data;
    }

    const fetchReports = async () => {
        let placeOfService = null

        if (selectedPlaceOfService) {
            placeOfService = selectedPlaceOfService.id
        }

        if (((reports === undefined || placeOfService) && !loading)) {
            setLoading(true);
            setReports([]);
            await getAuditReportsPlanned(placeOfService)
                .then(response => {
                    setReports(response.data);
                })
                .catch(reason => {
                    setReports([]);
                });
            setLoading(false);
        }
    }

    const fetchPermission = async () => {
        await getPermission('QUALITY_DEPARTMENT', 'EDIT')
            .then(response => {
                setEditPermission(response.data);
            })
    }

    useEffect(() => {
        fetchPermission();
        fetchReports();
    }, []);

    useEffect(() => {
        fetchReports();
    }, [selectedPlaceOfService]);

    useIonViewWillEnter(() => {
        fetchReports();
    });

    useEffect(() => {
        log('page_visit', {
            page: 'Zaplanowane Audyty -> Zaplanowane Audyty'
        });
    }, []);

    const createAuditReport = (type: AuditType, auditReportPlanned: AuditReportPlanned) => {
        if (auditReportPlanned.specification) {
            history.push(`${Links.audit}/${type}/0/${auditReportPlanned.specification.id}/${auditReportPlanned.id}`)
            return;
        }

        if (auditReportPlanned.order) {
            history.push(`${Links.audit}/${type}/${auditReportPlanned.order.id}/0/${auditReportPlanned.id}`)
            return;
        }

        if (type === AuditType.OTHER) {
            history.push(`${Links.audit}/${type}/0/0/${auditReportPlanned.id}`)
            return;
        }
    };

    return (
        <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
            {
                <>
                    <StyledComponentFormContainer className="padding-top">
                        <p className="desc">{t("auditReport.bhpStart.place_of_service")}</p>
                        <IonRow>
                            <IonCol size="12" className="select" onClick={() => {
                                updateShowPlaceOfServicesModal(true)
                            }}>
                                <StyledInput readOnly={true}
                                             value={selectedPlaceOfService ? selectedPlaceOfService.name : ''}
                                             placeholder={t("common.select")}/>

                                {selectedPlaceOfService?.id && <img className="clear" src={CrossImage} onClick={(e) => {
                                    e.stopPropagation();
                                    setReports(undefined)
                                    setSelectedPlaceOfService(null);
                                }}/>}
                            </IonCol>
                        </IonRow>
                    </StyledComponentFormContainer>

                    {
                        !loading && reports && reports.length === 0 &&
                        <StyledNoAuditReports>
                            <img src={NoOrdersImage}/>
                            <img className="reports" src={ReportsImage}/>
                            <span className="message">{t('auditsTab.noReports')}</span>
                        </StyledNoAuditReports>
                    }
                    {
                        !loading && reports && reports.length !== 0 &&
                        <StyledAuditReports>
                            {
                                reports.map((audit: AuditReportPlanned, i) => {
                                        return <React.Fragment key={i}>
                                            <div className="order">
                                                <div className="order-number">
                                                    #{audit.id} {audit.place_of_service?.name}
                                                </div>
                                                <div className="members"></div>
                                                <div className="details">
                                                    <div className="detail">{t('auditsPlannedTab.dateExecution')}: <span
                                                        className="value">{moment(audit.date_execution, moment.ISO_8601).tz('Europe/Warsaw').format('DD.MM.YYYY')}</span>
                                                    </div>

                                                    <div className="detail">{t('auditsTab.placeOfService')}: <span
                                                        className="value">{audit.place_of_service?.name}</span></div>

                                                    <div className="detail">{t('auditsTab.order.number')}: <span
                                                        className="value">{audit.order?.formatted_number}</span></div>

                                                    <div className="detail">{t('auditsTab.type')}: <span
                                                        className="value">{audit.type}</span></div>

                                                    <div className="detail">{t('auditsTab.shift_period')}: <span
                                                        className="value">{getShitPeriodReadable(audit.shift_period)}</span>
                                                    </div>
                                                </div>
                                                {
                                                    editPermission &&
                                                    <StyledSocialButton className="inverted"
                                                                        onClick={() => createAuditReport(audit.type, audit)}>
                                                        <img src={AddImage}/>
                                                        {t('auditsTab.createReportButton')}
                                                    </StyledSocialButton>
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                )
                            }
                        </StyledAuditReports>
                    }
                    {
                        loading && <AuditReportsSkeleton/>
                    }
                    {showPlaceOfServiceModal &&
                        <PaginatedListModal
                            search={true}
                            report={{} as AuditReportFormModel} // hack
                            isOpen={showPlaceOfServiceModal}
                            onFetchGeneric={(page: number, searchText?: string) => {
                                let data = loadPlaceOfServices(page, searchText);
                                return data;
                            }}
                            onSubmit={(selected) => {
                                setSelectedPlaceOfService(selected);
                                updateShowPlaceOfServicesModal(false);
                            }}
                            onClose={() => {
                                updateShowPlaceOfServicesModal(false);
                            }}
                        />
                    }
                </>
            }
        </Pane>
    );
};

export default AuditReportsPlannedPane;
