import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import axios from "axios";

import {getCars, removeCar} from "@services/travelAllowance/car.service";

import Accordion from "@components/accordion/accordion";
import CarListRow from "@app/travelAllowance/car/components/carListRow.component";
import MyDataSkeleton from "@app/myData/components/myDataSkeleton.component";
import CircleImage, {ImageTypes} from "@components/circleImage/circleImage.component";

import {
    StyledTravelAllowanceContent,
    StyledButton,
} from '@app/travelAllowance/travelAllowance.style';
import {StyledEmptyContainer} from "@components/content/content.style";

import {Car} from "@models/travelAllowance/car";

import {Links} from "@app/links";

import {ReactComponent as AddIcon} from "@assets/images/travelAllowance/add.svg"
import StaticPane from "@components/pane/static-pane.component";

type CarListPaneProps = {
    topEdge?: number;
}

const CarListPane: React.FC<CarListPaneProps> = ({topEdge}: CarListPaneProps) => {
    const cancelToken = axios.CancelToken.source();

    const {t} = useTranslation();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [cars, setCars] = useState<Car[]>([]);

    useEffect(() => {
        setLoading(true);

        fetchCars()
            .then(() => {
                setLoading(false);
            })
            .catch((e) => {
                console.error(e);
            });

        return () => {
            cancelToken.cancel();
        };
    }, [history.location.pathname]);

    const fetchCars = async () => {
        const carsData = await getCars(cancelToken);

        setCars(carsData);
    }

    const handleRemove = async (carId: number) => {
        setLoading(true);

        await removeCar(carId);
        await fetchCars();

        setLoading(false);
    }

    return (
        <StaticPane topEdge={topEdge} marginTop={40} paddingTop={30} hideGrabber={true}>
            {
                <StyledTravelAllowanceContent>
                    <Accordion isOpen={true} allowToggle={true} title={t('travelAllowance.car.yourCars')}>
                        <div className="travel-division">
                            <section className="car-box">
                                {
                                    loading && <MyDataSkeleton></MyDataSkeleton>
                                }
                                {
                                    !loading && cars.length > 0 && cars.map((car) => {
                                        return <CarListRow key={car.id} car={car} handleRemove={handleRemove}/>
                                    })
                                }
                                {
                                    !loading && cars.length === 0 &&
                                    <StyledEmptyContainer style={{marginTop: "20px"}}>
                                        <CircleImage image={ImageTypes.T} color="grey"/>
                                        <h3>{t('travelAllowance.car.empty')}</h3>
                                    </StyledEmptyContainer>
                                }
                            </section>
                        </div>
                    </Accordion>
                    <section className="button-box">
                        <StyledButton onClick={() => history.push(Links.main + Links.travelAllowance.car.add)}>
                            <div className="btn center">
                                <AddIcon style={{color: "#ffffff"}}/>
                                <span style={{marginLeft: "4px"}}>{t('travelAllowance.car.add')}</span>
                            </div>
                        </StyledButton>
                    </section>
                </StyledTravelAllowanceContent>
            }
        </StaticPane>
    );
};

export default CarListPane;