import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
    IonAlert,
    IonButtons,
    IonCol,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    isPlatform,
    useIonViewWillEnter,
    useIonViewWillLeave
} from '@ionic/react';
import {
    StyledEmptyContainer,
    StyledIonContentCameraPreview,
    StyledIonTitle,
    StyledIonToolbar
} from '../../components/content/content.style';
import {StyledButton, StyledHeaderButton, StyledHeaderButtonImage} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import CameraPreviewImage from '../../assets/images/takePhoto-small.png';
import {useTranslation} from 'react-i18next';
import {BarcodeScanner, SupportedFormat} from "@capacitor-community/barcode-scanner"
import {StyledContent} from '../takePhotoPage/takePhotoPage.style';
import {getAddOrderConfiguration} from '../../services/qrCodePage.service';
import CircleImage, {ImageTypes} from "../../components/circleImage/circleImage.component";

const AddNextOrderPage: React.FC = () => {

    const {t} = useTranslation();
    const history = useHistory();

    const handleBack = () => {
        history.goBack();
    };

    const configuration = getAddOrderConfiguration();

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [error, setError] = useState<string>();
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const startScan = async () => {
        BarcodeScanner.hideBackground(); // make background of WebView transparent

        const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] }); // start scanning and wait for a result

        if (result.hasContent) {
            if (configuration?.handleScan) {
                let isOrder = result.content?.includes('order_');
                let error = await configuration?.handleScan(result.content?.replace('order_', ''), isOrder, false);
                if (typeof error == 'string') {
                    stopScan()
                    setError(error);
                }
            }
        }
    };

    const checkPermission = async () => {
        // check or request permission
        const status = await BarcodeScanner.checkPermission({ force: true });

        if (status.granted) {
            // the user granted permission
            return true;
        }

        return false;
    };

    useIonViewWillEnter(() => {
        if (isPlatform('ios') || isPlatform('android')) {
            start();
        }
    });

    const start = async () => {
        let hasPermission = await checkPermission();
        if (!hasPermission) {
            setShowPermissionAlert(true);
        }
        startScan();
        setError('');
    }

    const stopScan = () => {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
    };

    useIonViewWillLeave(() => {
        if (isPlatform('ios') || isPlatform('android')) {
            stopScan();
        }
    });

    if (!configuration) {
        history.goBack();
    }

    const header = useRef<HTMLIonHeaderElement>(null);

    useEffect(() => {
        updateHeight();
    })

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    return (
        <IonPage>
            <StyledIonContentCameraPreview>
                <img className="background" src={CameraPreviewImage}/>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t(configuration?.title ?? '')}</StyledIonTitle>
                    </StyledIonToolbar>
                    <div className="sub-title-light">{t(configuration?.subtitle ?? '')}</div>
                </IonHeader>
                <StyledContent>
                    <div className="target">
                        {
                            error &&
                            <StyledEmptyContainer className="preview">
                                <CircleImage onClick={async (e) => {
                                    start();
                                }} image={ImageTypes.QR} color="red" />
                            </StyledEmptyContainer>
                        }
                    </div>
                    <div className={'action-buttons empty multiple-buttons'}>
                        <IonGrid>
                            {error && <IonRow>
                                <IonCol size="12">
                                    <p className="errorMessage">{t(error)}</p>
                                </IonCol>
                            </IonRow>}
                            <IonRow>
                                {configuration?.manualButtonText &&
                                    <IonCol size="12"><StyledButton onClick={() => configuration?.handleManualButton && configuration?.handleManualButton()}>{t(configuration?.manualButtonText)}</StyledButton></IonCol>
                                }
                                {/*{configuration?.manualFindButtonText &&*/}
                                {/*    <IonCol size="6"><StyledButton onClick={() => configuration?.handleManualFindButton && configuration?.handleManualFindButton()}>{t(configuration?.manualFindButtonText)}</StyledButton></IonCol>*/}
                                {/*}*/}
                            </IonRow>
                        </IonGrid>
                    </div>
                </StyledContent>
            </StyledIonContentCameraPreview>
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            BarcodeScanner.openAppSettings();
                        }
                    }
                ]}
            />
        </IonPage>
    );
};

export default AddNextOrderPage;
