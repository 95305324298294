import { IonCol, IonItem, IonRow, IonSelect, IonSelectOption, IonToast } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    AuditReportFormModel,
    AuditReportPenalty, AuditReportQuestion,
    AuditReportRandomInspection,
    Form,
    FormQuestion,
    FormQuestionAnswer
} from "../../../../models/auditReport";
import {DataSelectable, File} from "../../../../models/common";
import { Profile } from "../../../../models/profile";
import { StyledContent } from "../../../reportPage/reportPage.style";
import { AuditReportContext } from "../../auditReport.component";
import Accordion from './../../../auditReport/components/form/accordion';
import { StyledAnswerButton } from './../../../auditReport/components/form/step2.styles';
import { IonInput } from '@ionic/react';
import { StyledAccordion } from './../../../reportPage/reportPage.style';
import EmployeePenalty from "./employee-penalty";
import { StyledIonCheckbox } from "../../../../components/form/input/checkbox.style";
import { StyledIonLabel } from "../../../../components/form/input/label.style";
import {
    checkIfAnyAnswerIsNotOk,
    checkIfRandomInspectionIsNok, getButtonType,
    getForms,
    validateStep2
} from "../../../../services/audit.service";
import { getPenalties, loadPenaltyCoordinatorProfiles, loadPenaltyTeamLeaderProfiles, loadPenaltyWorkerProfiles } from "../../helpers";
import {AuditReportPenaltyType} from "../../../../enums/audit";
import FormComponent from "../../../../components/form";
import { StyledStaticFooter } from "../../../../components/pane/pane.style";
import {StyledComponentFormContainer} from "../../../reportPage/components/report/reportRowForm.style";
import {StyledIonSelect} from "../../../../components/form/input/select.style";
import {StyledTextarea} from "../../../../components/form/input/textarea.style";
import AuditReportQuestionFilesModal from "../../modals/filesModal.component";
import PhotosImage from "../../../../assets/images/photos.svg";
import {StyledButtonBadge, StyledSocialButton} from "../../../../components/button/button.style";

export type AuditReportStep2Props = {
    report: AuditReportFormModel,
    profile?: Profile,
    onChange: (report: AuditReportFormModel) => void,
    onSubmit: () => void,
}
const AuditReportStep2: React.FC<AuditReportStep2Props> = (props: AuditReportStep2Props) => {
    const { t } = useTranslation();
    const [report, setReport] = useState(props.report)
    const [formSubmitted, setFormSubmitted] = useState<any>(false);
    const [ forms, setForms ] = useState<Form[]>();
    const [showToast, setShowToast] = useState(false);
    const [toast, setToast] = useState<any>('');
    const [nokPercentageValue, setNokPercentageValue] = useState(report.auditPercentageCorrectValue || 100);
    const [selectedQuestion, setSelectedQuestion] = useState<FormQuestion>();

    useEffect(() => {
        props.onChange(report);
    }, [report]);

    function setAnswer(question: FormQuestion, answer:FormQuestionAnswer) {
        const questions = [ ...(report.questions || []) ]

        let lastAnswer: FormQuestionAnswer | undefined;
        if (report.questions) {
            const answeredQuestion: AuditReportQuestion|undefined = report.questions.find((reportQuestion) => {
                if (reportQuestion.id === question.id) {
                    return reportQuestion;
                }
            })

            lastAnswer = answeredQuestion?.answer;
        }

        for (let questionAnswer of question.answers) {
            const answerIndex = questions.findIndex((question) => question.answer.id === questionAnswer.id)
            if (answerIndex !== undefined && answerIndex !== -1) {
                questions.splice(answerIndex, 1)
            }
        }

        questions.push({
            answer: answer,
            id: question.id,
            label: question.label,
            template_id: question.template_id,
            files: [],
        })

        let newReport = {
            ...report,
            questions: questions
        }

        if (!checkIfRandomInspectionIsNok(report) && !checkIfAnyAnswerIsNotOk(report)) {
            newReport = {
                ...newReport,
                userPenalties: []
            }
        }

        if (report.form?.is_enabled_audit_percentage_correct && question.audit_percentage_correct_value) {
            let percentageValue = nokPercentageValue;

            if (answer.value === 'NOK') {
                percentageValue -= question.audit_percentage_correct_value;
            } else if (lastAnswer?.value === 'NOK') {
                percentageValue += question.audit_percentage_correct_value;
            }

            if (percentageValue !== nokPercentageValue) {
                setNokPercentageValue(percentageValue);
                newReport = {
                    ...newReport,
                    auditPercentageCorrectValue: percentageValue
                };
            }
        }

        setReport(newReport)
    }

    function isNOKselected(question: FormQuestion) {
        return report.questions?.some((questionAnswer) => question.answers.some((answer) => answer.id === questionAnswer.answer.id && answer.value === 'NOK'))
    }

    function getStrokeColor(question:FormQuestion) {
        const isSelected = report.questions?.some((questionAnswer) => question.answers.some((answer) => answer.id === questionAnswer.answer.id))
        if (isSelected) {
            return '#ffffff'
        } else {
            return '#0085FF'
        }
    }

    function isAnswerSelected(answer:FormQuestionAnswer, question:FormQuestion) {
        const isSelected = report.questions?.some((questionAnswer) => questionAnswer.answer.id === answer.id)
        if (isSelected) {
            return `active`
        } else if (report.questions?.some((questionAnswer) => question.answers.some((answer) => answer.id === questionAnswer.answer.id))) {
            return 'disabled'
        } else {
            return ''
        }
    }

    function selectForm(form:Form) {
        setReport({
            ...report,
            form: form
        })
    }

    function isPenaltyTypeChecked(type:AuditReportPenaltyType){
        return report.userPenalties?.some((penalty) => penalty.type === type);
    }

    function updatePenaltyTypeChecked(type:AuditReportPenaltyType) {
        const penalties = [ ...(report.userPenalties || []) ];
        let penaltyIndex = penalties.findIndex((penalty) => penalty.type === type);

        if (penaltyIndex !== undefined && penaltyIndex !== -1) {
            do {
                penalties.splice(penaltyIndex, 1)
                penaltyIndex = penalties.findIndex((penalty) => penalty.type === type);
            }
            while( penaltyIndex !== -1)
        } else {
            penalties.push({type: type, personOther: '', penalties: []})
        }
        setReport({
            ...report,
            userPenalties: penalties
        })

    }

    function selectJustification(justification:DataSelectable, question:FormQuestion) {
        const questions = [ ...(report.questions || []) ];
        const nokAnswer = question.answers.find((answer) => answer.value === 'NOK');
        const nokQuestionIndex = questions.findIndex((question) => question.answer.id === nokAnswer?.id)
        if (nokQuestionIndex !== undefined && nokQuestionIndex !== -1) {
            questions[nokQuestionIndex].nokDictionaryItem = justification;
        }
        
        setReport({
            ...report,
            questions: questions
        })
    }

    function updateRandomInspection(propertyName: keyof AuditReportRandomInspection, value:string) {
        setReport({
            ...report,
            randomInspection: {
                ...report.randomInspection,
                [propertyName]: value
            } as AuditReportRandomInspection
        })
    }


    function addPenaltyWorker(){
        const penaltiesCount = getPenalties(AuditReportPenaltyType.WORKER, report)?.length;
        const reportPenalties = [ ...(report.userPenalties || []) ];
        reportPenalties.splice(penaltiesCount, 0, {type: AuditReportPenaltyType.WORKER, penalties: []})
        setReport({
            ...report,
            userPenalties: reportPenalties
        })
    }

    function getJustification(question:FormQuestion) {
        const questions = [ ...(report.questions || []) ];
        const nokAnswer = question.answers.find((answer) => answer.value === 'NOK');
        const nokQuestionIndex = questions.findIndex((question) => question.answer.id === nokAnswer?.id)
        if (nokQuestionIndex !== undefined && nokQuestionIndex !== -1) {
            return questions[nokQuestionIndex].nokDictionaryItem;
        } else {
            return undefined
        }
    }

    function getNokComment(question:FormQuestion) {
        const questions = [ ...(report.questions || []) ];
        const nokAnswer = question.answers.find((answer) => answer.value === 'NOK');
        const nokQuestionIndex = questions.findIndex((question) => question.answer.id === nokAnswer?.id)
        if (nokQuestionIndex !== undefined && nokQuestionIndex !== -1) {
            return questions[nokQuestionIndex].nokComment;
        } else {
            return undefined
        }
    }

    function setNokComment(comment:string, question:FormQuestion) {
        const questions = [ ...(report.questions || []) ];
        const nokAnswer = question.answers.find((answer) => answer.value === 'NOK');
        const nokQuestionIndex = questions.findIndex((question) => question.answer.id === nokAnswer?.id)
        if (nokQuestionIndex !== undefined && nokQuestionIndex !== -1) {
            questions[nokQuestionIndex].nokComment = comment;
        }

        setReport({
            ...report,
            questions: questions
        })
    }

    function getFiles(question: FormQuestion) {
        const questions = [ ...(report.questions || []) ];
        const nokAnswer = question.answers.find((answer) => answer.value === 'NOK');
        const nokQuestionIndex = questions.findIndex((question) => question.answer.id === nokAnswer?.id)
        if (nokQuestionIndex !== undefined && nokQuestionIndex !== -1) {
            return questions[nokQuestionIndex].files;
        } else {
            return [];
        }
    }

    function setFiles(files: File[], question: FormQuestion) {
        const questions = [ ...(report.questions || []) ];
        const nokAnswer = question.answers.find((answer) => answer.value === 'NOK');
        const nokQuestionIndex = questions.findIndex((question) => question.answer.id === nokAnswer?.id)
        if (nokQuestionIndex !== undefined && nokQuestionIndex !== -1) {
            questions[nokQuestionIndex].files = files;
        }

        setReport({
            ...report,
            questions: questions
        })
    }

    function validateNumberItems() {
        return (((parseInt(report.randomInspection?.ok || '0')) + (parseInt(report.randomInspection?.nok || '0'))) !== parseInt(report.randomInspection?.checked || '0'))
    }

    const onSubmit = () => {
        setFormSubmitted(true);
        if (!validateStep2(report)) {
            setToast(t("reportPage.validation.commonError"));
            setShowToast(true);

            const errorElement = document.querySelector(
                `.hasErrors`
            )

            if (errorElement) {
                errorElement.scrollIntoView({ behavior: 'smooth' })
            }
        } else {
            props.onSubmit();
        }
    }


    const contextAuditReport = useContext(AuditReportContext);
    contextAuditReport.setFormSubmitted = setFormSubmitted;
    contextAuditReport.getFormSubmitted = () => {
        return formSubmitted;
    };

    const auditContext = useContext(AuditReportContext);

    const loadForms = async () => {
        let defaultFormId = undefined;
        if (props.report.auditReportPlanned) {
            defaultFormId = props.report.auditReportPlanned.formId
        }

        await getForms(props.report.type, defaultFormId).then((response:any) => {
            setForms(response.data);
            // sprawdzić czy jest tylko jeden formularz i go wybrać
            if (response.data && response.data.length === 1) {
                setReport({
                    ...report,
                    form: response.data[0] as Form
                });
            }
        })
    }

    useEffect(() => {
        loadForms();
    }, []);

    function getButtonLabel(answer: FormQuestionAnswer, stroke:string) {
        if (answer.value === 'OK') {
            return <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><path fill="none" stroke={stroke} stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M416 128L192 384l-96-96"/></svg>
        } else if (answer.value === 'NOK') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke={stroke} stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
        } else {
            return <span style={{ color: stroke}}>{answer.label}</span>
        }
    }
    
    return <>
        <StyledContent className="withFooter">
            <StyledComponentFormContainer className="padding-top-bg">

                {forms && forms.length === 0 &&
                    <section className="gap-style">
                        <p className="errorMessage">{t('auditReport.form.no_forms')}</p>
                    </section>
                }

                {forms && forms.length > 1 && !report.form && // >0
                    <section className="gap-style">
                        <IonRow>
                            <IonCol size="12" className="label required">
                                {t('auditReport.form.form')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="select">
                                <StyledIonSelect
                                    interface="action-sheet"
                                    placeholder={t('common.selectPlaceholder')}
                                    onIonChange={e => {selectForm(e.detail.value)}}
                                    okText={t('common.selectOk')}
                                    cancelText={t('common.selectCancel')}
                                    interfaceOptions={
                                        {
                                            header: t('auditReport.form.selectForm'),
                                        }
                                    }
                                >
                                    { forms.map( (form, key) => {
                                        return <IonSelectOption key={'form_'+key} value={form}>{form.name}</IonSelectOption>
                                    })}
                                </StyledIonSelect>
                            </IonCol>
                        </IonRow>
                    </section>
                }
                {
                    report.form &&  <>
                        <Accordion isOpen={true} allowToggle={false} title={t('auditReport.form.questions')}>
                            { auditContext.getFormSubmitted() && !checkIfRandomInspectionIsNok(report) && report.form?.questions.length !== report.questions?.length &&
                                <p className="errorMessage">{t('auditReport.form.allQuestionsRequired')}</p>
                            }
                        {
                            report.form?.questions.map( (question) => {
                                return    <div key={ question.id } className="aaa">
                                    { question.label }
                                        <StyledAccordion>
                                            <div className="answer-buttons">
                                                { question.answers.map( (answer) => {
                                                    return <StyledAnswerButton className={ `${getButtonType(answer.value)} ${isAnswerSelected(answer, question)}`  } onClick={ () => setAnswer( question, answer)}>
                                                        { getButtonLabel(answer, getStrokeColor(question)) }
                                                    </StyledAnswerButton>
                                                })}
                                            </div>
                                            { (isNOKselected(question)) &&
                                                <div className="mb-20">
                                                    <IonRow>
                                                        <IonCol size="12" className="label mt-8 required">
                                                            {t('auditReport.form.justification')}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="12" className="select-full-width">
                                                            <IonSelect
                                                                interface="action-sheet"
                                                                className={auditContext.getFormSubmitted() && !getJustification(question) ? 'hasErrors' : ''}
                                                                onIonChange={ (e) => selectJustification(e.detail.value, question)}
                                                                placeholder={t('common.selectPlaceholder')}
                                                                okText={t('common.selectOk')}
                                                                cancelText={t('common.selectCancel')}
                                                                value={getJustification(question)}
                                                                compareWith={(o1: DataSelectable, o2: DataSelectable) => {
                                                                    return o1 && o2 ? o1.id === o2.id : o1 === o2;
                                                                }}
                                                                interfaceOptions={
                                                                    {
                                                                        header: t('common.select')
                                                                    }
                                                                }
                                                            >
                                                                { report.form?.nok_dictionary?.audit_nok_dictionary_items.map( (answer, key) => {
                                                                    return <IonSelectOption key={'answer_'+key} value={answer}>{answer.name}</IonSelectOption>
                                                                })}
                                                            </IonSelect>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="12" className="label mt-8 required">
                                                            {t('auditReport.form.justification_comment')}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="12">
                                                            <StyledTextarea
                                                                className="full-width"
                                                                value={getNokComment(question)}
                                                                onChange={(e) => setNokComment(e.target.value, question)}
                                                                placeholder={t('auditReport.form.enter')}
                                                            />
                                                        </IonCol>
                                                    </IonRow>

                                                    <IonRow>
                                                        <IonCol size="12">
                                                            <StyledSocialButton className="report-button black-text"
                                                                                onClick={() => setSelectedQuestion(question)}>
                                                                <img className="button-image" src={PhotosImage}/>
                                                                {t('auditReport.form.addFiles')}
                                                                {getFiles(question).length > 0 && <StyledButtonBadge
                                                                    className="badge default-position">{getFiles(question).length}</StyledButtonBadge>}
                                                            </StyledSocialButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </div>
                                            }
                                            <hr className="hr" />
                                    </StyledAccordion>
                                    </div>
                                }
                            )
                        }
                        </Accordion>

                        {
                            report.form?.is_random_inspection &&
                             <Accordion isOpen={true} allowToggle={false} title={t('auditReport.form.randomInspection.title')}>
                                 <IonRow>
                                     <IonCol size="12" className="label">
                                         <IonItem lines="none">
                                             <StyledIonLabel className="ion-text-wrap">{t('auditReport.form.randomInspectionEnabled')}</StyledIonLabel>
                                             <StyledIonCheckbox name="randomInspectionEnabled" checked={report.randomInspectionEnabled} slot="start" onIonChange={(e) => {
                                                 setReport({
                                                     ...report,
                                                     randomInspectionEnabled: e.detail.checked
                                                 });
                                             }}/>
                                         </IonItem>
                                     </IonCol>
                                 </IonRow>

                                 {report.randomInspectionEnabled && <StyledAccordion>
                                    <section className="second-accordion">
                                        <div className="label mb-8">{t('auditReport.form.randomInspection.amountInPackage')}</div>
                                        <IonItem className="two-input-style">
                                            <IonCol size="9">
                                                <IonInput className="new-input-szt"
                                                          type="text" pattern="[0-9]*" inputMode="tel" min={0}
                                                          value={report.randomInspection?.amountInPackage}
                                                          onIonChange={ (e) => updateRandomInspection('amountInPackage', e.detail.value as string )}>
                                                </IonInput>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="szt-input">{t('auditReport.form.piece')}</div>
                                            </IonCol>
                                        </IonItem>
                                    </section>
                                    <section className="second-accordion">
                                        <div className="label mb-8 required">{t('auditReport.form.randomInspection.checked')}</div>
                                        <IonItem className="two-input-style">
                                            <IonCol size="9">
                                                <IonInput
                                                    className={"new-input-szt " + (auditContext.getFormSubmitted() && (!report.randomInspection?.checked || validateNumberItems()) ? 'hasErrors' : '')}
                                                    type="text" pattern="[0-9]*" inputMode="tel" min={0}
                                                    value={report.randomInspection?.checked}
                                                    onIonChange={ (e) => updateRandomInspection('checked', e.detail.value as string )}>
                                                </IonInput>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="szt-input">{t('auditReport.form.piece')}</div>
                                            </IonCol>
                                        </IonItem>
                                    </section>
                                    <section className="second-accordion">
                                        <div className="label mb-8 required">{t('auditReport.form.randomInspection.ok')}</div>
                                        <IonItem className="two-input-style">
                                            <IonCol size="9">
                                                <IonInput
                                                    className={"new-input-szt " + (auditContext.getFormSubmitted() && (!report.randomInspection?.ok || validateNumberItems()) ? 'hasErrors' : '')}
                                                    type="text" pattern="[0-9]*" inputMode="tel" min={0}
                                                    value={report.randomInspection?.ok}
                                                    onIonChange={ (e) => updateRandomInspection('ok', e.detail.value as string )}>
                                                </IonInput>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="szt-input">{t('auditReport.form.piece')}</div>
                                            </IonCol>
                                        </IonItem>
                                    </section>
                                    <section className="second-accordion mb-24">
                                        <div className="label mb-8 required">{t('auditReport.form.nok')}</div>
                                        <IonItem className="two-input-style">
                                            <IonCol size="9">
                                                <IonInput className={"new-input-szt "  + (auditContext.getFormSubmitted() && (!report.randomInspection?.nok || validateNumberItems()) ? 'hasErrors' : '')}
                                                          type="text" pattern="[0-9]*" inputMode="tel" min={0}
                                                          value={report.randomInspection?.nok}
                                                          onIonChange={ (e) => updateRandomInspection('nok', e.detail.value as string )}>
                                                </IonInput>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="szt-input">{t('auditReport.form.piece')}</div>
                                            </IonCol>
                                        </IonItem>
                                    </section>
                                </StyledAccordion>}
                            </Accordion>
                        }

                        {
                            report.form.is_add_penalty && (checkIfAnyAnswerIsNotOk(report) || checkIfRandomInspectionIsNok(report)) &&
                            <Accordion isOpen={true} allowToggle={false} title={t('auditReport.form.penalties.title')}>

                                {!report.form.blocked_add_penalty_worker && <StyledAccordion>
                                    <IonRow >
                                        <IonCol size="12">
                                            <IonItem>
                                                <StyledIonCheckbox slot="start" checked={isPenaltyTypeChecked(AuditReportPenaltyType.WORKER)} onIonChange={(e) => { updatePenaltyTypeChecked(AuditReportPenaltyType.WORKER) }}></StyledIonCheckbox>
                                                <StyledIonLabel>{t('auditReport.form.workerPenalty')}</StyledIonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </StyledAccordion>}

                                { 
                                    !report.form.blocked_add_penalty_worker && isPenaltyTypeChecked(AuditReportPenaltyType.WORKER) &&
                                    <>
                                        { getPenalties(AuditReportPenaltyType.WORKER, report)?.map( (penalty, index) => {
                                            return  <EmployeePenalty key={'WORKER_'+index} auditReportPenalty={penalty} report={report} fetchProfiles={loadPenaltyWorkerProfiles} onChange={(report) => setReport({...report})}></EmployeePenalty>
                                        })}
                                        {
                                            getPenalties(AuditReportPenaltyType.WORKER, report)?.length < (report.workers?.length || 0) &&
                                            <IonRow className="mb-20">
                                                <FormComponent.Button onClick={() => addPenaltyWorker()}>{t('auditReport.form.addPerson')}</FormComponent.Button>
                                            </IonRow>
                                        }

                                    </>
                                }

                                <StyledAccordion>
                                    <IonRow >
                                        <IonCol size="12">
                                            <IonItem>
                                                <StyledIonCheckbox slot="start" checked={isPenaltyTypeChecked(AuditReportPenaltyType.TEAM_LEADER)} onIonChange={(e) => { updatePenaltyTypeChecked(AuditReportPenaltyType.TEAM_LEADER) }}></StyledIonCheckbox>
                                                <StyledIonLabel>{t('auditReport.form.teamLeaderPenalty')}</StyledIonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </StyledAccordion>

                                {
                                    isPenaltyTypeChecked(AuditReportPenaltyType.TEAM_LEADER) &&
                                    getPenalties(AuditReportPenaltyType.TEAM_LEADER, report)?.map( (penalty, index) => {
                                        return <EmployeePenalty key={'TEAM_LEADER_'+index} auditReportPenalty={penalty} report={report} fetchProfiles={loadPenaltyTeamLeaderProfiles} onChange={(report) => setReport({...report})}></EmployeePenalty>
                                    })
                                }

                                <StyledAccordion>
                                    <IonRow >
                                        <IonCol size="12">
                                            <IonItem>
                                                <StyledIonCheckbox slot="start" checked={isPenaltyTypeChecked(AuditReportPenaltyType.COORDINATOR)} onIonChange={(e) => { updatePenaltyTypeChecked(AuditReportPenaltyType.COORDINATOR) }}></StyledIonCheckbox>
                                                <StyledIonLabel>{t('auditReport.form.coordinatorPenalty')}</StyledIonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>

                                </StyledAccordion>

                                {
                                    isPenaltyTypeChecked(AuditReportPenaltyType.COORDINATOR) &&
                                    getPenalties(AuditReportPenaltyType.COORDINATOR, report)?.map( (penalty, index) => {
                                        return <EmployeePenalty key={'COORDINATOR_'+index} auditReportPenalty={penalty} report={report} fetchProfiles={loadPenaltyCoordinatorProfiles} onChange={(report) => setReport({...report})}></EmployeePenalty>
                                    })
                                }
                            </Accordion>
                        }
                </>
            }
            </StyledComponentFormContainer>

            {report.form && <StyledStaticFooter className="no-shadow">
                <FormComponent.Button type="submit" onClick={onSubmit}>{t('auditReport.form.sumUp')}</FormComponent.Button>
            </StyledStaticFooter>}
        </StyledContent>

        {selectedQuestion && <AuditReportQuestionFilesModal files={getFiles(selectedQuestion)} isOpen={true}
                    onClose={() => setSelectedQuestion(undefined)} onSubmit={(files) => {
                        setFiles(files, selectedQuestion);
                        setSelectedQuestion(undefined);
                    }}/>
        }

        <IonToast
            isOpen={showToast}
            onDidDismiss={() => {
                setShowToast(false);
                setToast('');
            }}
            message={toast}
            duration={6000}
            position="top"
            color="danger"
        />
    </>
}

export default AuditReportStep2;