import {ReportRowWorker} from "../app/reportPage/reportPage.component";
import {AuditReportFormModel} from "../models/auditReport";
import {DataSelectable} from "../models/common";

export type QRCodePageConfiguration = {
    title: string;
	subtitle: string;
    manualButtonText: string;
    errorText?: string;
    handleManualButton?: () => void;
    handleScan?: (value: any) => any;
    handleExtendedSelected?: (worker: ReportRowWorker) => any;
}

export type QRCodeAddOrderPageConfiguration = {
    title: string;
	subtitle: string;
    manualButtonText: string;
    manualFindButtonText: string;
    errorText?: string;
    handleManualButton?: () => void;
    handleManualFindButton?: () => void;
    handleScan?: (value: any, isOrder:boolean, byNumber: boolean) => any;
    handleManualScan?: (value: any) => any;
}

export type QRCodeAuditPageConfiguration = {
    title: string;
	subtitle: string;
    manualButtonText: string;
    errorText?: string;
    handleManualButton?: () => void;
    handleScan?: (value: any) => any;
    report: AuditReportFormModel;
    handleListSelected: (worker: DataSelectable) => any;
}

let workerConfiguration: QRCodePageConfiguration | undefined = undefined;

export function setWorkerConfiguration(newConfiguration: QRCodePageConfiguration) {
    workerConfiguration = newConfiguration;
}

export function getWorkerConfiguration(): QRCodePageConfiguration | undefined {
    return workerConfiguration;
}

let addOrderConfiguration: QRCodeAddOrderPageConfiguration | undefined = undefined;

export function setAddOrderConfiguration(newConfiguration: QRCodeAddOrderPageConfiguration) {
    addOrderConfiguration = newConfiguration;
}

export function getAddOrderConfiguration(): QRCodeAddOrderPageConfiguration | undefined {
    return addOrderConfiguration;
}

let auditReportWorkerConfiguration: QRCodeAuditPageConfiguration | undefined = undefined;

export function setAuditReportWorkerConfiguration(newConfiguration: QRCodeAuditPageConfiguration) {
    auditReportWorkerConfiguration = newConfiguration;
}

export function getAuditReportWorkerConfiguration(): QRCodeAuditPageConfiguration | undefined {
    return auditReportWorkerConfiguration;
}

