export interface WorkerWarehouseTransfer {
    id: bigint;
    created_at: string;
    confirmation_status?: WorkerWarehouseTransferConfirmationStatus;
    quantity: number;
    warehouse_item: {
        id: bigint;
        name: string;
    },
    can_confirm: boolean;
}

export interface Worker {
    id: number,
    company: string,
    first_name?: string,
    last_name?: string
    form_type_comment?: string
}

export enum WorkerWarehouseTransferConfirmationStatus {
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    CONFIRMED_AUTOMATICALLY = 'CONFIRMED_AUTOMATICALLY',
    REJECTED = 'REJECTED',
}
