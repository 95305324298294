import React, {useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {IonButtons, IonCol, IonHeader, IonRow} from '@ionic/react';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '@components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage} from '@components/button/button.style';
import ArrowLeftImage from '@assets/images/arrow-left.svg';
import {useTranslation} from 'react-i18next';
import {Links} from "@app/links";
import {getPlaceOfServices} from "@services/audit.service";
import {AuditReportContext} from "./../auditReport.component";
import {AuditReportFormModel} from "@models/auditReport";
import {DataSelectable} from "@models/common";
import PaginatedListModal from "./../modals/paginatedListModal.component";
import StaticPane from "@components/pane/static-pane.component";
import Form from '@components/form';
import {StyledInput} from "@components/form/input/input.style";
import {useForm} from "react-hook-form";

type AuditReportPlaceOfServiceStartProps = {
    type: string,
}

const AuditReportPlaceOfServiceStart: React.FC<AuditReportPlaceOfServiceStartProps> = ({type}) => {
    const {t} = useTranslation();
    const history = useHistory();

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [showPlaceOfServiceModal, updateShowPlaceOfServicesModal] = useState<boolean>(false);
    const [selectedPlaceOfService, setSelectedPlaceOfService] = useState<DataSelectable>();

    const contextAuditReport = useContext(AuditReportContext);
    contextAuditReport.setPlaceOfService = setSelectedPlaceOfService;
    contextAuditReport.getPlaceOfService = () => {
        return selectedPlaceOfService;
    };

    const {formState: {isSubmitted}, handleSubmit} = useForm({
        mode: 'all'
    });

    const header = useRef<HTMLIonHeaderElement>(null);

    useEffect(() => {
        updateHeight();
    })

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const handleBack = () => {
        history.goBack();
    };

    const loadPlaceOfServices = async (page: number, searchText?: string) => {
        let data: DataSelectable[] = await getPlaceOfServices(page, searchText).then(response => {
            return response.data as DataSelectable[];
        });

        return data;
    }

    const handleBhpSave = async () => {
        history.push(`${Links.audit}/${type}/0/0`);
    }

    return (
        <>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle className="wrap-text">
                            <div className="wrap-text">
                                {t('auditReport.bhpStart.title')}
                            </div>
                        </StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                    <Form.Container onSubmit={handleSubmit(handleBhpSave)}>
                        <p className="desc">{t("auditReport.bhpStart.place_of_service")}</p>
                        <IonRow>
                            <IonCol size="12" className="select" onClick={() => {
                                updateShowPlaceOfServicesModal(true)
                            }}>
                                <StyledInput readOnly={true}
                                             className={isSubmitted && !selectedPlaceOfService ? 'hasErrors' : ''}
                                             value={selectedPlaceOfService?.name}
                                             placeholder={t("common.select")}/>
                            </IonCol>
                        </IonRow>
                        <Form.Button type="submit"
                                     disabled={!selectedPlaceOfService}>{t("auditReport.bhpStart.addButton")}</Form.Button>
                    </Form.Container>
                </StaticPane>
            </StyledIonContent>

            {showPlaceOfServiceModal &&
                <PaginatedListModal
                    search={true}
                    report={{} as AuditReportFormModel} // hack
                    isOpen={showPlaceOfServiceModal}
                    onFetchGeneric={(page: number, searchText?: string) => {
                        let data = loadPlaceOfServices(page, searchText);
                        return data;
                    }}
                    onSubmit={(selected) => {
                        setSelectedPlaceOfService(selected);
                        updateShowPlaceOfServicesModal(false);
                    }}
                    onClose={() => {
                        updateShowPlaceOfServicesModal(false);
                    }}
                />
            }
        </>
    );
};

export default AuditReportPlaceOfServiceStart;
