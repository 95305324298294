import React, {useEffect, useRef, useState} from 'react';
import {IonButtons, IonHeader, IonPage} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {RouteComponentProps, useHistory} from "react-router-dom";

import {log} from "@services/firebaseAnalytics.service";
import {getCar} from "@services/travelAllowance/car.service";

import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '@components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage} from "@components/button/button.style";

import ArrowLeftImage from "@assets/images/arrow-left.svg";

import {Car} from "@models/travelAllowance/car";
import Pane from "@components/pane/pane.component";
import CarAddEditPane from "@app/travelAllowance/car/panes/carAddEditPane.component";
import TravelAllowanceFormSkeleton from "@app/travelAllowance/common/components/travelAllowanceFormSkeleton.component";

import {CarPaneType} from "@enums/travelAllowance/car";

interface RouterProps {
    carId: string;
}

interface CarEditPageProps extends RouteComponentProps<RouterProps> {
}

const CarEditPage: React.FC<CarEditPageProps> = ({match}: CarEditPageProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const {params: {carId}} = match;

    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(false);
    const [car, setCar] = useState<Car>();

    useEffect(() => {
        log('page_visit', {
            page: 'Samochody -> Edycja'
        });

        updateHeight();
    }, []);

    useEffect(() => {
        setLoading(true);

        fetchCar()
            .then(() => {
                setLoading(false)
            });
    }, []);

    const fetchCar = async () => {
        if (carId !== undefined) {
            const carData = await getCar(parseInt(carId));

            setCar(carData);
        }
    }

    const updateHeight = () => {
        if (header.current?.clientHeight === 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const handleBack = () => {
        history.goBack();
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("carEditPage.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                {
                    loading &&
                    <Pane topEdge={topEdge}>
                        <TravelAllowanceFormSkeleton/>
                    </Pane>
                }
                {
                    !loading && <CarAddEditPane type={CarPaneType.EDIT} topEdge={topEdge} carDefaultValues={car}/>
                }
            </StyledIonContent>
        </IonPage>
    );
};

export default CarEditPage;