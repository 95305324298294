import React from 'react';
import AvatarImage from '../../../assets/images/avatar.svg';
import {IonSkeletonText} from "@ionic/react";
import {StyledAuditReports} from "@app/auditReport/panes/auditReports.style";

const AuditReportsSkeleton: React.FC = () => {
    return (
        <StyledAuditReports>
            <div className="date-separator">
                <div className="box"><IonSkeletonText animated style={{width: '50%'}}/></div>
            </div>
            <div className="order">
                <div className="order-number">
                    <IonSkeletonText animated style={{width: '50%'}}/>
                </div>
                <div className="members">
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                </div>
                <div className="details">
                    <div className="detail"><IonSkeletonText animated style={{width: '50%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '90%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '70%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '30%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '70%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '30%'}}/></div>
                </div>
            </div>
            <div className="order">
                <div className="order-number">
                    <IonSkeletonText animated style={{width: '50%'}}/>
                </div>
                <div className="members">
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                </div>
                <div className="details">
                    <div className="detail"><IonSkeletonText animated style={{width: '50%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '90%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '70%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '30%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '70%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '30%'}}/></div>
                </div>
            </div>
        </StyledAuditReports>
    );
};

export default AuditReportsSkeleton;
