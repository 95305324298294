import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {IonAlert, IonButtons, IonCol, IonGrid, IonHeader, IonRow, isPlatform, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import {StyledEmptyContainer, StyledIonContentCameraPreview, StyledIonTitle, StyledIonToolbar} from '@components/content/content.style';
import {StyledButton, StyledHeaderButton, StyledHeaderButtonImage} from '@components/button/button.style';
import ArrowLeftImage from '@assets/images/arrow-left.svg';
import CameraPreviewImage from '@assets/images/takePhoto-small.png';
import {useTranslation} from 'react-i18next';
import {BarcodeScanner, SupportedFormat} from "@capacitor-community/barcode-scanner"
import {StyledContent} from '@app/takePhotoPage/takePhotoPage.style';
import CircleImage, {ImageTypes} from "../../../components/circleImage/circleImage.component";
import {Links} from "@app/links";
import {findOrder, findSpecification} from "@services/audit.service";

type AuditReportCameraStartProps = {
    type: string,
}

const AuditReportCameraStart: React.FC<AuditReportCameraStartProps> = ({type}) => {
    const {t} = useTranslation();
    const history = useHistory();

    const [showError, setShowError] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    useIonViewWillEnter(() => {
        if (isPlatform('ios') || isPlatform('android')) {
            start();
        }
    });

    useIonViewWillLeave(() => {
        if (isPlatform('ios') || isPlatform('android')) {
            stopScan();
        }
    });

    const handleBack = () => {
        history.goBack();
    };

    const startScan = async () => {
        BarcodeScanner.hideBackground(); // make background of WebView transparent

        const result = await BarcodeScanner.startScan({targetedFormats: [SupportedFormat.QR_CODE]});

        if (result.hasContent) {
            const isOrder = result.content?.includes('order_');
            const error = await handleScan(result.content?.replace('order_', ''), isOrder);

            if (error === 'SPECIFICATION_NOT_FOUND') {
                setError(t('auditReport.find.error.specification_not_found'))
            } else if (error === 'ORDER_NOT_FOUND') {
                setError(t('auditReport.find.error.order_not_found'))
            } else {
                setError(t('auditReport.find.error.common'))
            }

            stopScan()
            setShowError(true);
        }
    };

    const handleScan = async (val: any, isOrder: boolean = false) => {
        if (isOrder) {
            let data: any = await findOrder(val)
                .then(response => {
                    if (response.status === 200) {
                        return response.data.id as bigint;
                    } else {
                        return 'COMMON';
                    }
                }).catch(reason => {
                    try {
                        let message = reason.response.data?.message;
                        return message !== undefined ? message : 'COMMON';
                    } catch (e) {
                        return 'COMMON';
                    }
                });

            if (typeof data == 'string') {
                return data;
            }

            if (data) {
                history.push(`${Links.audit}/${type}/${data}/0`);
            }
        } else {
            let data: any = await findSpecification(val)
                .then(response => {
                    if (response.status === 200) {
                        return response.data.order.id;
                    } else {
                        return 'COMMON';
                    }
                }).catch(reason => {
                    try {
                        let message = reason.response.data?.message;
                        return message !== undefined ? message : 'COMMON';
                    } catch (e) {
                        return 'COMMON';
                    }
                });

            if (typeof data == 'string') {
                return data;
            }

            if (data) {
                history.push(`${Links.audit}/${type}/${data}/${val}`);
            }
        }
    }

    const checkPermission = async () => {
        // check or request permission
        const status = await BarcodeScanner.checkPermission({force: false});

        if (status.granted) {
            // the user granted permission
            return true;
        }

        if (status.denied) {
            // user denied permission
            return false;
        }

        if (status.restricted || status.unknown) {
            // ios only
            // probably means the permission has been denied
            return false;
        }

        if (status.neverAsked) {
            const statusRequest = await BarcodeScanner.checkPermission({force: true});
            if (statusRequest.granted) {
                // the user did grant the permission now
                return true;
            }
        }

        return false;
    }

    const start = async () => {
        let hasPermission = await checkPermission();
        if (!hasPermission) {
            setShowPermissionAlert(true);
        }
        startScan();
        setShowError(false);
        setError('');
    }

    const stopScan = () => {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
    };

    return (
        <>
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            BarcodeScanner.openAppSettings();
                        }
                    }
                ]}
            />

            <StyledIonContentCameraPreview>
                <img className="background" src={CameraPreviewImage}/>
                <IonHeader className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t('auditReport.start.title')}</StyledIonTitle>
                    </StyledIonToolbar>
                    <div className="sub-title-light">{t('auditReport.start.subtitle')}</div>
                </IonHeader>
                <StyledContent>
                    <div className="target">
                        {
                            showError &&
                            <StyledEmptyContainer className="preview">
                                <CircleImage onClick={async (e) => {
                                    start();
                                }} image={ImageTypes.QR} color="red"/>
                            </StyledEmptyContainer>
                        }
                    </div>
                    <div className={'action-buttons empty multiple-buttons'}>
                        <IonGrid>
                            {showError && <IonRow>
                                <IonCol size="12">
                                    <p className="errorMessage">{error}</p>
                                </IonCol>
                            </IonRow>}
                            <IonRow>
                                <IonCol size="6"><StyledButton onClick={() => history.push(`${Links.auditManualSpecificationFind}/${type}`)}>{t('auditReport.find.manualSpecification')}</StyledButton></IonCol>
                                <IonCol size="6"><StyledButton onClick={() => history.push(`${Links.auditManualOrderFind}/${type}`)}>{t('auditReport.find.manualOrder')}</StyledButton></IonCol>
                                <IonCol size="12"><StyledButton onClick={() => history.push(`${Links.audit}/${type}/0/0`)}>{t('auditReport.find.skip')}</StyledButton></IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                </StyledContent>
            </StyledIonContentCameraPreview>
        </>
    );
};

export default AuditReportCameraStart;
