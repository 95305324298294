import styled from 'styled-components';

type StyledInputType = {
    error?: boolean;
};

export const StyledTextarea = styled.textarea<StyledInputType>`
  border: 1px solid ${props => props.color === 'dark' ? '#363543' : '#F4F4F9'};
  height: 120px;
  left: 24px;
  top: 303px;
  background: #F4F4F9;
  padding: 16px;
  border-radius: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #000000;

  &.full-width {
    width: 100%;
  }

  &.margin-bottom {
    margin-bottom: 5px;
  }

  &.error {
    border-color: #F43030;
  }
  
  &.resize-none {
    resize: none;
  }
`;
