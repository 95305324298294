import React, {useContext, useEffect, useRef, useState} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {IonButtons, IonHeader, IonSkeletonText, IonPage, useIonViewDidEnter} from '@ionic/react';
import SubNavigation, {SubNavigationOption} from '../../components/sub-navigation/subNavigation.component';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '../../components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage,} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import ClockImage from '../../assets/images/time-clock.svg';
import {useTranslation} from 'react-i18next';
import {getContract} from '../../services/contract.service';
import {Contract as ContractModel} from '../../models/contract';
import moment from "moment";
import AttachementsFolderPane from './panes/attachementsFolderPane.component';
import {ContractStatus} from '../../enums/contractStatus';
import ContentPane from "./panes/contentPane.component";
import {ContractAttachmentFolder} from "../../models/contractAttachmentFolder";
import useNavigation from '../../services/navigation.service';
import { Links } from '../links';
import RegulationsPane from "./panes/regulationsPane.component";
import {ContractCommentsStatus} from "../../enums/contractCommentsStatus";
import StaticPane from "../../components/pane/static-pane.component";

export enum ContractPageOptions {
    CONTRACT_CONTENT = 0,
    ATTACHEMENTS = 1,
    REGULATIONS = 2
}

interface RouterProps {
    contractId?: string | undefined;
}

interface ContractProps extends RouteComponentProps<RouterProps> {
}

interface ContractPageManager {
    setStatus: Function;
    setCommentsStatus: Function;
    setSelectedTab: Function;
}

const contextContractPage: ContractPageManager = {
    setStatus: () => {

    },
    setCommentsStatus: () => {

    },
    setSelectedTab: () => {

    }
};

export const ContractPageContext = React.createContext<ContractPageManager>(contextContractPage);

const ContractPage: React.FC<ContractProps> = ({match}) => {
        const {t} = useTranslation();
        const history = useHistory();
        const [loading, setLoading] = useState<boolean>(false);
        const navigation = useNavigation(history);
        const [contract, setContract] = useState<ContractModel>();
        const [status, setStatus] = useState<ContractStatus>();
        const [commentsStatus, setCommentsStatus] = useState<ContractCommentsStatus|null>();
        const [selectedOption, updateSelectedOption] = useState<number>(parseInt(navigation.getParam<string>('selectedOption')) || 0);

        const {params: {contractId}} = match;

        const contextContractPage = useContext(ContractPageContext);
        contextContractPage.setStatus = setStatus;
        contextContractPage.setCommentsStatus = setCommentsStatus;
        contextContractPage.setSelectedTab = updateSelectedOption;

        const fetchContract = async () => {
            if (match.params.contractId) {
                setLoading(true);
                await getContract(match.params.contractId)
                    .then(response => {
                        let contract = response.data.contract as ContractModel;
                        contract.content = response.data.contract_content;
                        contract.termination_content = response.data.termination_content;
                        contract.regulations_content = response.data.regulations_content;
                        contract.agreement_content = response.data.agreement_content;
                        contract.changed_type_content = response.data.changed_type_content;
                        contract.documents_statement_content = response.data.documents_statement_content;
                        contract.tisax_content = response.data.tisax_content;
                        contract.foreign_terms_content = response.data.foreign_terms_content;
                        contract.form_of_employment_info_content = response.data.form_of_employment_info_content;
                        setContract(contract);
                        setStatus(contract.sign_status);
                        setCommentsStatus(contract.comments_status);
                    })
                    .catch(error => {
                        // todo what we show??
                    });

                setLoading(false);
            }
        };

        useEffect(() => {
            fetchContract();
        }, [match.params.contractId]);

        useIonViewDidEnter(() => {
            fetchContract();
        })

        const handleSelect = (option: SubNavigationOption) => {
            const optionIndex = options.indexOf(option);
            navigation.setParams({
                'selectedOption': optionIndex.toString()
            });
            updateSelectedOption(optionIndex);
        };

        const handleBack = () => {
            history.push(`${Links.main}${Links.contracts}`);
        };

        let [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

        useEffect(() => {
            updateHeight();
        });

        const updateHeight = () => {
            if (header.current?.clientHeight == 0) {
                setTimeout(updateHeight);
            } else {
                updateTopEdge(header.current?.clientHeight);
            }
        };

        const openFolder = (folder: ContractAttachmentFolder) => {
            if (!folder.qualification_date) {
                history.push(Links.contract + '/' + contractId + Links.folder + '/' + folder.id);
            }
        };

        const header = useRef<HTMLIonHeaderElement>(null);

        const options = [{
                name: t("contractPage.optionContractContent"),
            },
            {
                name: t("contractPage.optionAttachements"),
            },
            {
                name: t("contractPage.optionRegulations"),
            }];
        
        if (contract?.has_parent || contract?.is_ap) {
            options.splice(1,1)
        }

        return (
            <IonPage>
                <StyledIonContent>
                    <IonHeader ref={header} className="ion-no-border">
                        <StyledIonToolbar>
                            <IonButtons slot="start">
                                <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                    <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            </IonButtons>
                            <StyledIonTitle>{contract?.number}</StyledIonTitle>
                        </StyledIonToolbar>
                            <div className="sub-title">
                                <small>
                                    <img src={ClockImage}/>
                                    <span>{contract?.start_at ? moment(contract?.start_at).format('DD.MM.YYYY') : ''} - {contract?.terminated_at ? moment(contract?.terminated_at).format('DD.MM.YYYY') : ''} {(contract?.end_at && !contract.terminated_at) ? moment(contract?.end_at).format('DD.MM.YYYY') : ''}</span>
                                </small>
                            </div>
                            <SubNavigation options={options}
                                        defaultValue={selectedOption || 0}
                                        onSelect={handleSelect}></SubNavigation>
                    </IonHeader>
                    {
                        !loading && selectedOption === ContractPageOptions.CONTRACT_CONTENT &&
                        <ContentPane topEdge={topEdge} contract={contract} status={status} commentsStatus={commentsStatus} />
                    }
                    {
                        selectedOption === ContractPageOptions.ATTACHEMENTS && !contract?.is_ap &&
                        <AttachementsFolderPane topEdge={topEdge} contract={contract} status={status}
                                                openFolder={(folder: ContractAttachmentFolder) => openFolder(folder)}/>
                    }
                    {
                        selectedOption === ContractPageOptions.ATTACHEMENTS && contract?.is_ap &&
                        <RegulationsPane topEdge={topEdge} contract={contract} />
                    }
                    {
                        selectedOption === ContractPageOptions.REGULATIONS &&
                        <RegulationsPane topEdge={topEdge} contract={contract} />
                    }
                    {
                        loading && selectedOption === ContractPageOptions.CONTRACT_CONTENT &&
                        <StaticPane topEdge={topEdge}>
                            <div>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                                <IonSkeletonText animated />
                                <IonSkeletonText animated style={{ width: '88%' }} />
                                <IonSkeletonText animated style={{ width: '80%' }} />
                                <IonSkeletonText animated style={{ width: '80%' }} />
                                <IonSkeletonText animated style={{ width: '90%' }} />
                                <IonSkeletonText animated />
                                <IonSkeletonText animated style={{ width: '98%' }} />
                                <IonSkeletonText animated style={{ width: '80%' }} />
                                <IonSkeletonText animated style={{ width: '81%' }} />
                                <IonSkeletonText animated style={{ width: '85%' }} />
                                <IonSkeletonText animated />
                                <IonSkeletonText animated style={{ width: '88%' }} />
                                <IonSkeletonText animated style={{ width: '85%' }} />
                                <IonSkeletonText animated style={{ width: '83%' }} />
                                <IonSkeletonText animated style={{ width: '83%' }} />
                                <IonSkeletonText animated />
                                <IonSkeletonText animated style={{ width: '86%' }} />
                                <IonSkeletonText animated style={{ width: '80%' }} />
                                <IonSkeletonText animated style={{ width: '90%' }} />
                                <IonSkeletonText animated style={{ width: '88%' }} />
                                <IonSkeletonText animated style={{ width: '80%' }} />
                                <IonSkeletonText animated style={{ width: '80%' }} />
                                <IonSkeletonText animated style={{ width: '90%' }} />
                                <IonSkeletonText animated />
                                <IonSkeletonText animated style={{ width: '98%' }} />
                                <IonSkeletonText animated style={{ width: '80%' }} />
                                <IonSkeletonText animated style={{ width: '81%' }} />
                                <IonSkeletonText animated style={{ width: '85%' }} />
                            </div>
                        </StaticPane>
                    }
                </StyledIonContent>
            </IonPage>
        );
    }
;

export default ContractPage;
