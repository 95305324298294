import {IonButton, IonInput} from '@ionic/react';
import styled from 'styled-components';

export const StyledTime = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 20px;
  padding: 20px; 
  gap: 5px;
`;

export const StyledTimeInput = styled(IonInput)`
  border: 1px solid #F4F4F9;
  background: #F4F4F9;
  border-radius: 12px;
  font-weight: 500;
  line-height: 15px;
  padding: 5px 10px !important;
  font-size: 20px;
  width: auto;
  position: relative;
  text-align: center;
  
  input {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }

  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &.error {
      border-color: #F43030 !important;
  }
`;

export const StyledTimeButton = styled(IonButton)`
  font-size: 16px;
  font-weight: 400;
`;