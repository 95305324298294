import React from 'react';
import {IonPage} from '@ionic/react';
import {AuditReportProps} from "./auditReport.component";
import {AuditType} from "@enums/audit";
import AuditReportCameraStart from "@app/auditReport/components/auditReportCameraStart.component";
import AuditReportPlaceOfServiceStart from "@app/auditReport/components/auditReportPlaceOfServiceStart.component";

const AuditReportStart: React.FC<AuditReportProps> = ({match}) => {
    const {params: {type}} = match;

    return (
        <IonPage>
            {
                type === AuditType.QUALITY && <AuditReportCameraStart type={AuditType.QUALITY}/>
            }
            {
                type === AuditType.BHP && <AuditReportPlaceOfServiceStart type={AuditType.BHP}/>
            }
            {
                type === AuditType.OTHER && <AuditReportCameraStart type={AuditType.OTHER}/>
            }
            {
                ![AuditType.QUALITY, AuditType.BHP, AuditType.OTHER].includes(type as AuditType) && <AuditReportCameraStart type={type}/>
            }
        </IonPage>
    );
};

export default AuditReportStart;
