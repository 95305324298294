import React, {useContext, useEffect, useRef, useState} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {IonButtons, IonHeader, IonIcon, IonLoading, IonPage, IonToast} from '@ionic/react';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar, StyledSteps} from '@components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage} from '@components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import StaticPane from '../../components/pane/static-pane.component';
import {useTranslation} from 'react-i18next';
import PageLeavingGuard from "../../components/page-leaving/pageLeavingGuard.component";
import {Preferences} from "@capacitor/preferences";
import {Profile} from "@models/profile";
import {AuditReportFormModel, AuditReportPlanned} from "@models/auditReport";
import {checkmark} from "ionicons/icons";
import {Links} from "../links";
import {Order, OrderSpecification} from "@models/order";
import {checkNewestSpecification, findOrder, findSpecification, getAuditReportPlanned, getCurrentShitPeriod, saveAudit} from "@services/audit.service";
import AuditReportStep1 from "./components/form/step1.component";
import AuditReportStep2 from "./components/form/step2.component";
import AuditReportStep3 from './components/form/step3.component';
import {MainPageContext} from "../mainPage/mainPage.component";
import {getMenu} from "@services/menu.service";
import {AuditType} from "@enums/audit";
import {Project} from "@models/project";
import {getProject} from "@services/project.service";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
moment.tz.setDefault('Europe/Warsaw');

interface AuditReportManager {
    setFormSubmitted: Function;
    getFormSubmitted: Function;
    setPlaceOfService: Function;
    getPlaceOfService: Function;
}

const contextReportPage: AuditReportManager = {
    setFormSubmitted: () => {

    },
    getFormSubmitted: () => {

    },
    setPlaceOfService: () => {

    },
    getPlaceOfService: () => {

    }
};

export const AuditReportContext = React.createContext<AuditReportManager>(contextReportPage);

export interface RouterProps {
    type: string;
    specificationId?: string;
    orderId?: string;
    plannedAudit?: string;
}

export interface AuditReportProps extends RouteComponentProps<RouterProps> {
}

const AuditReportComponent: React.FC<AuditReportProps> = ({match}) => {
    const history = useHistory();
    const {t} = useTranslation();

    const {params: {type, specificationId, orderId, plannedAudit}} = match;
    const [formSaved, setFormSaved] = useState(false);

    const [selectedProfile, updateSelectedProfile] = useState<Profile | undefined>();
    const [report, setReport] = useState<AuditReportFormModel>({
        type: type as AuditType,
        componentNumber: undefined,
        coordinatorOrTeamLeader: undefined,
        coordinatorOrTeamLeaderOther: "",
        date: moment().format(),
        order: undefined,
        orderNumber: "",
        userPenalties: [],
        placeOfService: undefined,
        questions: [],
        shiftPeriod: getCurrentShitPeriod(),
        specificationNumber: "",
        workers: [],
        specification: undefined,
        additionalEmails: [],
        clientEmails: [],
        auditReportPlanned: undefined,
    });

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [showLoading, updateShowLoading] = useState(false);
    const [loadStartData, setLoadStartData] = useState(false);
    const [currentStep, updateCurrentStep] = useState(1);
    const [toast, setToast] = useState<any>('');
    const [showToast, setShowToast] = useState(false);

    const mainPageManager = useContext(MainPageContext);
    const contextAuditReport = useContext(AuditReportContext);

    const header = useRef<HTMLIonHeaderElement>(null);

    const steps = [
        {
            name: t("auditReport.step.1"),
            number: 1,
        },
        {
            name: t("auditReport.step.2"),
            number: 2,
        },
        {
            name: t("auditReport.step.3"),
            number: 3,
        }
    ];

    useEffect(() => {
        updateHeight();
    });

    useEffect(() => {
        getProfileData();
        loadOrderAndSpecification();
        loadContextPlaceOfService();
    }, []);

    useEffect(() => {
        const specification = report.specification;

        if (specification !== undefined) {
            const interval = setInterval(() => checkSpecification(specification), 60000);
            return () => clearInterval(interval);
        }
    }, [report.specification]);

    const getProfileData = async () => {
        const name = await Preferences.get({'key': 'full_name'});
        const profileId = await Preferences.get({'key': 'profile_id'});
        const profileType = await Preferences.get({'key': 'profile_type'});
        const profileCompanyName = await Preferences.get({'key': 'profile_company_name'});

        if (profileId.value !== null && profileType.value !== null && profileCompanyName.value !== null && name.value !== null) {
            updateSelectedProfile({
                id: parseInt(profileId.value),
                name: name.value,
                type: profileType.value,
                company: {
                    name: profileCompanyName.value
                }
            })
        }
    };

    const handleBack = () => {
        if (currentStep === 1) {
            history.goBack();
        } else {
            updateCurrentStep((prevState => prevState - 1))
        }
    };

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const loadOrderAndSpecification = async () => {
        if (specificationId && specificationId != '0') {
            const spec = await findSpecification(specificationId)
                .then((response) => {
                    return response.data as OrderSpecification;
                })
                .catch((error) => {
                    console.error(error);
                    history.replace(Links.main + Links.audits);
                })

            if (spec) {
                let project;
                if (spec.order) {
                    project = await loadProject(spec.order?.project.id);
                }

                setReport({
                    ...report,
                    specification: spec,
                    order: spec.order,
                    placeOfService: spec.order?.place_of_service,
                    project: spec.order?.project,
                    coordinatorEmail: project?.coordinator_email,
                    regionalManagerEmail: project?.regional_manager_email
                });
            }

        } else if (report.order === undefined && orderId && orderId != '0') {
            const order = await findOrder(orderId, true)
                .then((response) => {
                    return response.data as Order;
                })
                .catch((error) => {
                    console.log(error);
                    history.replace(Links.main + Links.audits);
                })

            if (order) {
                let project;
                if (order) {
                    project = await loadProject(order.project.id);
                }

                setReport({
                    ...report,
                    order: order,
                    placeOfService: order.place_of_service,
                    project: order.project,
                    coordinatorEmail: project?.coordinator_email,
                    regionalManagerEmail: project?.regional_manager_email
                });
            }
        }

        if (plannedAudit && parseInt(plannedAudit) !== 0) {
            const auditReportPlanned =  await getAuditReportPlanned(parseInt(plannedAudit))
                .then(response => {
                    return response.data as AuditReportPlanned
                })
                .catch((error) => {
                    console.log(error);
                    history.replace(Links.main + Links.audits);
                });

            if (auditReportPlanned) {
                setReport(report => ({
                    ...report,
                    auditReportPlanned: auditReportPlanned,
                    ...(report.order === undefined ? {
                        placeOfService: auditReportPlanned.place_of_service,
                        project: auditReportPlanned.project
                    } : {})
                }));
            }
        }

        setLoadStartData(true)
    }

    const loadProject = async (projectId: bigint): Promise<Project> => {
        const response = await getProject(projectId);

        return response.data as Project;
    }

    const loadContextPlaceOfService = () => {
        let contextPlaceOfService = contextAuditReport.getPlaceOfService();
        if (contextPlaceOfService && !report.placeOfService) {
            setReport({
                ...report,
                placeOfService: contextPlaceOfService,
            })
        }
    }

    const handleSaveReport = async () => {
        updateShowLoading(true);

        const saved = await saveAudit(report, type, plannedAudit)
            .then(() => {
                setFormSaved(true);
                return true;
            })
            .catch((error) => {
                setToast(t("common.serverErrorMsg"));
                setShowToast(true);
                console.error(error);
                return false;
            })

        updateShowLoading(false);

        if (saved) {
            await getMenu().then(response => {
                mainPageManager.setMenuItems(response.data);
            });

            history.replace(Links.main + Links.audits);
        }
    }

    const handleStep = async () => {
        if (currentStep === 3) {
            await handleSaveReport();
        } else {
            updateCurrentStep((prevState => prevState + 1))
        }
    }

    const onReportChange = (r: AuditReportFormModel) => {
        setReport({
            ...report,
            ...r
        })
    }

    const checkSpecification = async (specification: OrderSpecification) => {
        await checkNewestSpecification(specification.id)
            .then(response => {
                if (!response.data.is_newest) {
                    setToast(t("auditReport.validation.not_newest_specification"));
                    setShowToast(true);
                }
            });
    }

    return (
        <>
            <IonLoading onDidDismiss={() => updateShowLoading(false)} isOpen={showLoading}/>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => {
                    setShowToast(false);
                    setToast('');
                }}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
            <IonPage className="report">
                <StyledIonContent>
                    <IonHeader ref={header} className="ion-no-border">
                        <StyledIonToolbar>
                            <IonButtons slot="start">
                                <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                    <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            </IonButtons>
                            <StyledIonTitle className="wrap-text">
                                <div className="wrap-text">
                                    {t('auditReport.title')}
                                </div>
                            </StyledIonTitle>
                        </StyledIonToolbar>
                        <StyledSteps>
                            <div className="stepper-wrapper">
                                {steps.map((step, key) => {
                                    return <div className={step.number === currentStep ? "stepper-item active" : (step.number < currentStep ? "stepper-item completed" : "stepper-item")} key={key}>
                                        <div className="step-counter">
                                            {step.number < currentStep && <IonIcon src={checkmark} style={{"fontSize": "11px", "color": '#FFFFFF'}}/>}
                                        </div>
                                        <div className="step-name">{step.name}</div>
                                    </div>
                                })}
                            </div>
                        </StyledSteps>
                    </IonHeader>
                    <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                        {currentStep === 1 && loadStartData && report &&
                            <AuditReportStep1
                                report={report}
                                profile={selectedProfile}
                                onChange={onReportChange}
                                onSubmit={handleStep}/>
                        }
                        {currentStep === 2 && report &&
                            <AuditReportStep2
                                report={report}
                                profile={selectedProfile}
                                onChange={onReportChange}
                                onSubmit={handleStep}/>
                        }
                        {currentStep === 3 && report &&
                            <AuditReportStep3
                                report={report}
                                profile={selectedProfile}
                                onChange={onReportChange}
                                onSubmit={handleStep}/>
                        }
                    </StaticPane>
                </StyledIonContent>
            </IonPage>
            <PageLeavingGuard navigate={path => history.goBack()} shouldBlockNavigation={location => {
                if (formSaved) {
                    return false;
                }

                if (location.pathname === Links.auditWorkerFind || location.pathname === Links.auditManualWorkerFind || location.pathname === (Links.audit + '/' + type + '/' + orderId + '/' + specificationId)) {
                    return false;
                }

                return true;
            }}/>
        </>
    );
};

export default AuditReportComponent;
